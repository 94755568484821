import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_CATEGORIES_PAGINATED,
  ACTION_SAVE_CATEGORY_DETAILS,
  ACTION_SAVE_CATEGORY_SELECTIVES,
} from '../actions/category';

const initialState = Immutable({
  categoriesPaginated: null,
  categoryDetails: null,
  categorySelectives: null,
});

export default function category (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_CATEGORIES_PAGINATED:
      state = {
        ...state,
        categoriesPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_CATEGORY_DETAILS:
      state = {
        ...state,
        categoryDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_CATEGORY_SELECTIVES:
      state = {
        ...state,
        categorySelectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getCategoriesPaginated (state) {
  return state.category.categoriesPaginated;
}

export function getCategoryDetails (state) {
  return state.category.categoryDetails;
}

export function getCategoriesKanban (state) {
  return state.category.categoriesKanban;
}

export function getCategorySelectives (state) {
  return state.category.categorySelectives;
}
