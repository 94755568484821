import React from 'react';
import { Translate } from 'react-redux-i18n';

const QuestionDatabaseOptions = {
  BRONZE: 1,
  SILVER: 2,
  GOLD: 3,
  BLACK: 4,
};

export const QuestionDatabaseOptionsArray = [
  { id: QuestionDatabaseOptions.BRONZE, name: <Translate value="userPlanOption.bronze" />},
  { id: QuestionDatabaseOptions.SILVER, name: <Translate value="userPlanOption.silver" />},
  { id: QuestionDatabaseOptions.GOLD, name: <Translate value="userPlanOption.gold" />},
  { id: QuestionDatabaseOptions.BLACK, name: <Translate value="userPlanOption.black" /> },
];


export default QuestionDatabaseOptions;