import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Divider,
  message,
  Row,
  Steps,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import {
  SaveOutlined,
} from '@ant-design/icons';
import {
  UtilsActions,
} from '../../redux/actions/index';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors, LoadingSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput';
import { removeSpecialChars, validateEmail } from '../../utils/string';

class CompanyForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      current: 0,
      formDataHeight: 0,
      form: {
        name: '',
        email: null,
        cnpj: null,
        cep: null,
        phone: null,
        state: null,
        city: null,
        address: null,
        addressNumber: null,
        responsible: null,
        description: null,
        extension: null,
      },
    };
  }

  async componentDidMount () {
    const formDataHeight = this.formData.clientHeight;
    this.setState({ formDataHeight });
    const { data } = this.props;

    if (data) {
      this.setState({
        form: {
          ...data,
          addressNumber: data.number,
        },
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    const { form } = this.state;

    if (form.email && !validateEmail(form.email)) {
      return message.error(I18n.t('routes.panel.companyDetails.messages.errors.invalid_email'));
    }

    if (!form.extension) {
      form.extension = null;
    }

    form.number = form.addressNumber;

    const companySchema = yup.object().shape({
      phone: yup.string().nullable(),
      number: yup.string().nullable(),
      address: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup.string().nullable(),
      cep: yup.string().nullable(),
      cnpj: yup.string().nullable(),
      name: yup.string().required(I18n.t('routes.panel.companyDetails.messages.errors.invalid_name')),
    });

    companySchema.validate(form).then(() => {
      const { data, submitFunction } = this.props;
      submitFunction(data ? data.id : null, form);
    }).catch((error) => {
      message.error(error.message);
    });
  }

  getAddressByCep = async (cep) => {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    this.fieldChange('address', completeAddress.street);
    this.fieldChange('city', completeAddress.city);
    this.fieldChange('state', completeAddress.state);
  }

  fieldChange (name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render () {
    const {
      form, current,
    } = this.state;
    const { Step } = Steps;
    return (
      <Row className="company-form-row">
        <Col
          span={6}
          className="company-form-col"
        >
          <Steps
            direction="vertical"
            size="small"
            current={current}
            onChange={this.changeStep}
            className="company-form-steps"
          >
            <Step
              title="Dados"
              className="company-form-step"
              style={{ minHeight: this.state.formDataHeight }}
            />
            <Step
              icon={<div />}
              className="company-form-step"
            />
          </Steps>
        </Col>
        <Col span={18}>
          <form
            name="companyForm"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            {current >= 0
              && (
                <div
                  ref={(ref) => { this.formData = ref; }}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.name.label')}
                        value={form && form.name}
                        onChange={(val) => this.fieldChange('name', val)}
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.email.label')}
                        value={form && form.email}
                        onChange={(val) => this.fieldChange('email', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedInput
                        label={I18n.t('forms.company.description.label')}
                        value={form && form.description}
                        onChange={(val) => this.fieldChange('description', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.company.cnpj.label')}
                        value={form && form.cnpj}
                        onChange={(val) => this.fieldChange('cnpj', val)}
                        mask="99.999.999/9999-99"
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.company.cep.label')}
                        value={form && form.cep}
                        onChange={(val) => {
                          this.fieldChange('cep', val);

                          if (val && removeSpecialChars(val).length === 8) {
                            this.getAddressByCep(removeSpecialChars(val));
                          }
                        }}
                        mask="99999-999"
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.state.label')}
                        value={form && form.state}
                        onChange={(val) => this.fieldChange('state', val)}
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.city.label')}
                        value={form && form.city}
                        onChange={(val) => this.fieldChange('city', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.address.label')}
                        value={form && form.address}
                        onChange={(val) => this.fieldChange('address', val)}
                      />
                    </Col>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.addressNumber.label')}
                        value={form && form.addressNumber}
                        onChange={(val) => this.fieldChange('addressNumber', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.company.responsible.label')}
                        value={form && form.responsible}
                        onChange={(val) => this.fieldChange('responsible', val)}
                      />
                    </Col>
                    <Col span={4}>
                      <AdvancedInput
                        label={I18n.t('forms.company.extension.label')}
                        value={form && form.extension}
                        onChange={(val) => this.fieldChange('extension', val)}
                      />
                    </Col>
                    <Col span={8}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.company.phone.label')}
                        value={form && form.phone}
                        onChange={(val) => this.fieldChange('phone', val)}
                        mask="(99) 9999-9999"
                        type="tel"
                      />
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.goBackButtonText')}
                        href={I18n.t('routes.panel.companies.url')}
                      />
                      <Divider
                        className="form-button-divider"
                        type="vertical"
                      />
                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('shared.save')}
                        icon={<SaveOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              )}

          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAddressByCep: (cep) => dispatch(
    UtilsActions.getAddressByCep(cep),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CompanyForm,
);
