import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { I18n } from 'react-redux-i18n';
import { ALTERNATIVE_TYPE } from '../../enum/questionEnum';
import { getBase64Image } from '../base64';

pdfMake.vfs = pdfFonts.pdfMake.vfs
let pdfGlobalImages = {}

function getMultipleChoisesAnswers (questions, exporWithAnswers) {
  return questions.answers.map((answer, answerIndex) => {
    const currentAnswerImage = {}

    if (answer.labelType === ALTERNATIVE_TYPE.PHOTO && answer.labelFileUrl) {
      const currentImageIndex = `${answer.id}-image`
      pdfGlobalImages[currentImageIndex] = answer.labelFileUrl
      currentAnswerImage['image'] = currentImageIndex
      currentAnswerImage['fit'] = [ 200, 800 ]
    }

    let questionLabel = {}

    const currentIndex = answerIndex + 1
    const correctAnswer = answer.isCorrect && exporWithAnswers ? I18n.t('forms.newQuestion.exportPdf.correctAnswer') : ''

    switch (answer.labelType) {
      case ALTERNATIVE_TYPE.PHOTO:
        questionLabel = {
          text: `${currentIndex}. ${correctAnswer}`,
        }
        break;
      case ALTERNATIVE_TYPE.VIDEO:
        questionLabel = {
          text: `${currentIndex}. ${I18n.t('forms.newQuestion.exportPdf.alternatives.videoLink')} ${correctAnswer}`,
          link: answer.labelFileUrl,
          style: 'link',
        }
        break;
      case ALTERNATIVE_TYPE.AUDIO:
        questionLabel = {
          text: `${currentIndex}. ${I18n.t('forms.newQuestion.exportPdf.alternatives.audioLink')} ${correctAnswer}`,
          link: answer.labelFileUrl,
          style: 'link',
        }
        break;
      case ALTERNATIVE_TYPE.TEXT:
        if(!answer.label) return

        questionLabel = {
          text: `${currentIndex}. ${answer.label} ${correctAnswer}`,
        }
        break;
      default:
        questionLabel = {
          text: `${currentIndex}. ${answer.label || ''} ${correctAnswer}`,
          margin: [ 0, 30, 0, 0 ]
        }
    }

    return [
      {
        ...questionLabel,
        margin: [ 0, 0, 0, 3 ],
        style: [ questionLabel.style || '', 'normalFont', (answer.isCorrect && exporWithAnswers) && 'correctAnswer' ]
      },
      currentAnswerImage
    ]
  }).filter(formatedQuestion => !!formatedQuestion)
}

function getQuestionsJustifications (questionObject) {
  const justifications = []

  if (
    questionObject.justification ||
    questionObject.justificationAudioUrl ||
    questionObject.justificationImageUrl ||
    questionObject.justificationVideoUrl) {
    justifications.push({
      text: I18n.t('forms.newQuestion.exportPdf.justifications.title'),
      bold: true,
      margin: [ 0, 10, 0, 0 ]
    })
  }

  if (questionObject.justification) {
    justifications.push({
      text: questionObject.justification,
    })
  }

  if (questionObject.justificationImageUrl) {
    const imageIndex = `justification-${questionObject.id}`
    pdfGlobalImages[imageIndex] = questionObject.justificationImageUrl
    justifications.push({
      image: imageIndex,
      fit: [ 200, 800 ]
    })
  }

  if (questionObject.justificationAudioUrl) {
    justifications.push({
      text: I18n.t('forms.newQuestion.exportPdf.justifications.audioLink'),
      link: questionObject.justificationAudioUrl,
      style: 'link'
    })
  }


  if (questionObject.justificationVideoUrl) {
    justifications.push({
      text: I18n.t('forms.newQuestion.exportPdf.justifications.videoLink'),
      link: questionObject.justificationVideoUrl,
      style: 'link'
    })
  }

  return justifications
}

function getQuestionOtherDescriptionsMedias (imageFileUrl, audioFileUrl, videoFileUrl) {
  const otherDescriptions = []

  if (imageFileUrl) {
    const imageIndex = `description-image-${Math.random()}`
    pdfGlobalImages[imageIndex] = imageFileUrl
    otherDescriptions.push({
      image: imageIndex,
      fit: [ 400, 800 ]
    })
  }

  if (audioFileUrl) {
    otherDescriptions.push({
      text: I18n.t('forms.newQuestion.exportPdf.justifications.audioLink'),
      link: audioFileUrl,
      style: 'link'
    })
  }


  if (videoFileUrl) {
    otherDescriptions.push({
      text: I18n.t('forms.newQuestion.exportPdf.justifications.videoLink'),
      link: videoFileUrl,
      style: 'link'
    })
  }

  return otherDescriptions
}

function getQuestions (questionsToFormat, exporWithAnswers, exporWithJustifications) {
  const questionsFormated = questionsToFormat.map((question, questionIndex) => {
    const multipleChoiceAnswers = getMultipleChoisesAnswers(question, exporWithAnswers)

    const questionJustifications = exporWithJustifications ? getQuestionsJustifications(question) : []
    const questionOtherDescriptionsMedias = getQuestionOtherDescriptionsMedias(
      question.imageFileUrl,
      question.audioFileUrl,
      question.videoFileUrl
    )
    return [
      '\n',
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 0,
            x2: 515,
            y2: 0,
            lineWidth: 1,
          }
        ]
      },
      {
        text: `${questionIndex + 1}. ${question.location} - ${question.year}`,
        style: 'placeAndDate',
        margin: [ 0, 10, 0, 0 ]
      },
      {
        text: question.description,
        style: 'questionStatement'
      },
      questionOtherDescriptionsMedias,
      questionJustifications,
      multipleChoiceAnswers.length ? [ {
        text: I18n.t('forms.newQuestion.exportPdf.alternativesText'),
        style: 'alternatives',
        margin: [ 0, 20, 0, 5 ]
      } ] : {},
      ...multipleChoiceAnswers
    ]
  })

  return questionsFormated
}

export async function generateAndDownloadExamePdf (
  fileName,
  topText,
  headerName,
  headerDescription,
  questions,
  coverImageUrl,
  exporWithJustifications,
  exporWithAnswers
) {
  pdfGlobalImages = {}

  const questionsFormated = getQuestions(questions, exporWithAnswers, exporWithJustifications)

  for (const [ imageIndex, imageUrl ] of Object.entries(pdfGlobalImages)) {
    // eslint-disable-next-line no-await-in-loop
    pdfGlobalImages[imageIndex] = await getBase64Image(imageUrl)
  }

  const pdfDefinitions = {
    watermark: { 
      text: I18n.t('forms.newQuestion.exportPdf.watherMark'), 
      color: 'blue', 
      opacity: 0.03, 
      bold: true, 
      italics: true 
    },
    content: [
      {
        text: topText,
        style: 'top',
        margin: [ 0, 0, 0, 20 ]
      },
      coverImageUrl ? {
        image: 'cover',
        width: 515
      } : {
        canvas: [ {
          type: 'rect',
          x: 0,
          y: 0,
          w: 515,
          h: 10,
          color: '#86ACD2',
        } ]
      }
      ,
      {
        text: headerName,
        style: 'title',
        margin: [ 10, 10, 0, 10 ],
      },
      {
        text: headerDescription,
        style: 'description',
        margin: [ 10, 0, 0, 10 ],
      },
      ...questionsFormated
    ],
    styles: {
      bold: {
        bold: true
      },
      normalFont: {
        fontSize: 10
      },
      top: {
        fontSize: 12,
        alignment: 'center'
      },
      title: {
        fontSize: 14,
        bold: true
      },
      description: {
        fontSize: 13,
      },
      placeAndDate: {
        fontSize: 10,
        bold: true
      },
      questionStatement: {
        fontSize: 10,
        margin: [ 0, 5, 0, 0 ]
      },
      alternatives: {
        bold: true,
        fontSize: 11,
        color: 'black',
      },
      correctAnswer: {
        color: 'green',
        bold: true,
        decoration: 'underline'
      },
      link: {
        color: 'blue',
      },
    },
    images: {
      ...pdfGlobalImages,
      cover: coverImageUrl || ''
    }
  }

  pdfMake.createPdf(pdfDefinitions).download(fileName || 'file');
}

