import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UserActivity = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const UserActivityArray = [
  { id: UserActivity.ACTIVE, name: <Translate value="enum.userActivity.active" />},
  { id: UserActivity.INACTIVE, name: <Translate value="enum.userActivity.inactive" />},
];