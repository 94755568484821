import React, { useEffect, useRef, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Layout,
  Modal,
  Radio,
  Row,
  Tooltip,
} from 'antd';
import Icon, {
  BookOutlined,
  DeleteOutlined,
  EyeOutlined,
  MessageFilled,
} from '@ant-design/icons';
import { I18n, syncTranslationWithStore } from 'react-redux-i18n';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import {
  ChatActions,
  FilterActions,
  UserActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  ChatSelectors,
  FilterSelector,
  LoadingSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import DataTable from '../../../components/shared/DataTable';
import * as DateUtils from '../../../app/utils/date';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { removeEmptyField } from '../../../app/utils/functionsUtils';
import messageReadStatus from '../../../app/enum/messageReadStatus';
import moment from 'moment-timezone';
import { CHAT_TYPE } from '../../../app/enum/questionEnum';
import { ProfileType } from '../../../app/enum/profileType';
import { cleanPagination } from '../../../app/utils/pagination';
import { showCompanies } from '../../../app/utils/showCompanies';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';

const { Panel } = Collapse;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const cleanParameters = {
  read: messageReadStatus.all,
  name: '',
  question: '',
  sentAtStart: null,
  SentAtEnd: null,
};

const ChatList = (props) => {
  const { messagesFilter } = props;
  const { directFilter } = messagesFilter;
  const parametersInitalValues = {
    ...cleanPagination,
    ...directFilter,
    read: directFilter?.read ?? messageReadStatus.all,
    name: directFilter?.name ?? '',
    question: directFilter?.question ?? '',
    sentAtStart: directFilter?.sentAtStart ?? null,
    SentAtEnd: directFilter?.SentAtEnd ?? null,
  };
  const [ parameters, setParameters ] = useState(parametersInitalValues);
  const [ deleteModal, setDeleteModal ] = useState(false);
  const [ modalInfo, setModalInfo ] = useState(null);
  const dataTableRef = useRef(null);
  const [ timeOut, setTimeOut ] = useState(0);
  const [ offSet, setOffSet ] = useState(0);
  const [ companyList, setCompanyList ] = useState([]);
  const [ company, setCompany ] = useState('');
  const filterUserId = props.chatPaginated.rows.filter(
    (item) => item.lastMessageOfTeacher?.whoSent,
  );
  const userIds = filterUserId
    ?.map((item) => item.lastMessageOfTeacher?.whoSent)
    .join(',');

  const getChats = async (params, isFromDataTable, isReset) => {
    if (!isFromDataTable) {
      setParameters({
        ...directFilter,
        ...parameters,
        ...params,
      });
    }

    if (params) {
      setParameters({ ...directFilter, ...params });
      params = removeEmptyField(params);
      await props.getChatPaginated({ ...params, type: 1 });
      props.setMessageDirectFilters(params);
    } else {
      params = removeEmptyField(parameters);
      await props.getChatPaginated(params);
      props.setMessageDirectFilters(params);
    }
  };

  useEffect(() => {
    getChats(parameters)
  }, [])

  useEffect(() => {
    let intervalID;
    intervalID = setInterval(async () => {
      setTimeOut((state) => {
        return state + 1;
      });
      await props.getChatPaginated({ type: CHAT_TYPE.MESSAGE, offset: offSet, ...parameters });
    }, 30000);

    return () => clearInterval(intervalID);
  }, [ offSet, parameters, props ]);

  useEffect(() => {
    getCompanyList()
    props.getUsersAll({ userIds });
  }, [ userIds ]);

  const getCompanyList = async () => {
    props.getCompanies((data) => {
      setCompanyList(data);
    });
  }

  const getAnswerBy = (id) => {
    const userName = props.usersAll?.find(
      (item) => item?.id === id &&
        item?.profileType !== ProfileType.USER
    );
    return userName?.name;
  };

  const returnMessages = (value) => {
    if (
      value.messages &&
      value.messages.length > 0 &&
      value.messages[value.messages.length - 1].whoSent === value.user.id &&
      !value.messages[value.messages.length - 1].read
    ) {
      return value.messages[value.messages.length - 1].fileType ? (
        <strong>Enviou um arquivo</strong>
      ) : (
        <strong>{value.messages[value.messages.length - 1].message}</strong>
      );
    } else if (
      value.messages &&
      value.messages.length > 0 &&
      value.messages[value.messages.length - 1].fileType
    ) {
      return 'Enviou um arquivo';
    }

    return value && value.messages && value.messages.length > 0
      ? value.messages[value.messages.length - 1].message
      : 'Não há mensagens aqui';
  };

  const returnDate = (message, value, isAnswerDate = false) => {
    if (handleUnreadMessage(value)) {
      return (
        <strong>
          {(message &&
            message.sentAt &&
            DateUtils.humanizeDate(message.sentAt, 'DD/MM/YYYY')) ||
            '--'}
        </strong>
      );
    }

    const isAdmin = getAnswerBy(value.messages[value.messages.length - 1]?.whoSent)

    if (isAdmin) {
      return (
        (
          value &&
          value.messages &&
          DateUtils.humanizeDate(value.messages[value.messages.length - 1].sentAt, 'DD/MM/YYYY')
        )
      );
    }
    else if (!isAdmin && value.messages && isAnswerDate) {
      return (I18n.t('routes.panel.chat.dataTable.columns.items.noAnswerred'))
    }

    return (
      (message &&
        message.sentAt &&
        DateUtils.humanizeDate(message.sentAt, 'DD/MM/YYYY')) ||
      ('--'
      )
    );
  };

  const fieldChange = (name, val) => {
    parameters[name] = val;
    setParameters({ ...parameters });
  };

  const handleChangeDate = (value) => {
    let sentAtStart = null;
    let sentAtEnd = null;

    if (value) {
      sentAtStart = value[0].toDate();
      sentAtEnd = value[1].toDate();
    }

    setParameters({ ...parameters, sentAtStart, sentAtEnd });
  };

  const deleteChat = () => {
    props.deleteChat(modalInfo.chatId);
    getChats(parameters);
    setDeleteModal(false);
  };

  const handleUnreadMessage = (value) => {
    return (
      value.messages &&
      value.messages.length > 0 &&
      value.messages[value.messages.length - 1].whoSent === value.user.id &&
      !value.messages[value.messages.length - 1].read
    );
  };

  return (
    <>
      <Content className='panel__layout__content panel__layout__content--breadcrumb'>
        <Modal
          title='Dados do usuário'
          visible={deleteModal}
          onOk={deleteChat}
          onCancel={() => setDeleteModal(false)}
        >
          <h5>
            {I18n.t('routes.panel.chat.messages.delete')}{' '}
            <strong>{modalInfo && modalInfo.user?.name}</strong>
          </h5>
        </Modal>
        <Breadcrumb>
          <Breadcrumb.Item>
            <span>{I18n.t('routes.panel.pageTitle')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${I18n.t('routes.panel.chat.url')}`}>
              <span>{I18n.t('routes.panel.chat.pageTitle')}</span>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <Content className='panel__layout__content panel__layout__content--advanced-filter'>
        <Collapse className='advanced-filter'>
          <Panel header={I18n.t('shared.advancedFilters.title')}
            key='1'>
            <Col>
              <Row gutter={24}>
                <Col span={8}>
                  <AdvancedInput
                    label={'Nome do usuário'}
                    value={parameters && parameters.name}
                    onChange={(val) => fieldChange('name', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={'Questão'}
                    value={parameters && parameters.question}
                    onChange={(val) => fieldChange('question', val)}
                  />
                </Col>
                <Col span={8}>
                  <br />
                  <Row>
                    <Radio.Group
                      onChange={(e) => fieldChange('read', e.target.value)}
                      value={parameters && parameters.read}
                    >
                      <Radio value={messageReadStatus.notRead}>Não Lidas</Radio>
                      <Radio value={messageReadStatus.read}>Lidas</Radio>
                      <Radio value={messageReadStatus.all}>Todas</Radio>
                    </Radio.Group>
                  </Row>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={5}>
                  <span
                    className="advanced-select__label__inner"
                  >
                    {I18n.t('shared.advancedFilters.form.period')}
                  </span>
                  <RangePicker
                    value={
                      parameters &&
                        parameters.sentAtStart &&
                        parameters.sentAtEnd
                        ? [
                          moment(parameters.sentAtStart),
                          moment(parameters.sentAtEnd),
                        ]
                        : []
                    }
                    format={'DD/MM/YYYY'}
                    onChange={handleChangeDate}
                    placeholder={[ 'data inicial', 'data final' ]}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.questionDatabase.dataTable.filters.company')}
                    value={company}
                    onChange={(val) => setCompany(val)}
                    options={companyList}
                  />
                </Col>
              </Row>
              <br />
              <Row gutter={24}>
                <Col span={24}
                  align='right'>
                  <AdvancedButton
                    onClick={() => {
                      if (company) {
                        parameters.companyId = company;
                      }

                      getChats({...parameters, ...cleanPagination});
                      props.setMessageDirectFilters({...parameters, ...cleanPagination});
                    }}
                    text={'Filtrar'}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <AdvancedButton
                    onClick={() => {
                      setCompany('');
                      setParameters({ ...parametersInitalValues, ...cleanPagination });
                      getChats({...cleanParameters, ...cleanPagination}, true, true);
                      props.setMessageDirectFilters(null);
                    }}
                    text={'Limpar filtros'}
                  />
                </Col>
              </Row>
              <br />
            </Col>
          </Panel>
        </Collapse>
      </Content>
      <Content className='panel__layout__content'>
        <div className='users'>
          <Row>
            <Col className='pr-2 mr-2 active-tag'>
              <h4>
                <span className='panel__layout__content__title__value__icon'>
                  <MessageFilled />
                </span>
                {I18n.t('routes.panel.chat.title')}
              </h4>
            </Col>
            <Col>
              <div
                className='bar-text--unactive'
                onClick={() => navigate(I18n.t('routes.panel.contentChat.url'))}
              >
                <h4 className='bar-text--unactive'>
                  <span className='bar-icon--unactive'>
                    <BookOutlined />
                  </span>
                  {I18n.t('routes.panel.contentChat.pageTitle')}
                </h4>
              </div>
            </Col>
          </Row>
          <Divider />
          <DataTable
            notGetOnStart
            setOffset={(params) => {
              setOffSet(params - 1);
            }}
            getMethod={(params) => getChats(params, true)}
            data={props.chatPaginated}
            loading={props.loading > 0}
            ref={dataTableRef}
            showSizeChanger
            pageSizeOptions={
              props.chatPaginated &&
                props.chatPaginated.count &&
                props.chatPaginated.count > 100
                ? [ '10', '50', '100', props.chatPaginated.count ]
                : [ '10', '20', '50', '100' ]
            }
            params={parameters}
            columns={[
              {
                key: '',
                title: '',
                render: (value) => value || '--',
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.student',
                ),
                render: (value) =>
                  (value ? (
                    handleUnreadMessage(value) ? (
                      <strong>{value.user.name}</strong>
                    ) : (
                      value.user.name
                    )
                  ) : (
                    '--'
                  )),
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.email',
                ),
                render: (value) =>
                  (value ? (
                    handleUnreadMessage(value) ? (
                      <strong>{value.user.email}</strong>
                    ) : (
                      value.user.name
                    )
                  ) : (
                    '--'
                  )),
              },
              {
                width: '120px',
                key: I18n.t('routes.panel.questionDatabase.dataTableCorrections.columns.userName.key'),
                title: I18n.t('pushNotification.dataTable.company.title'),
                render: (value) => (value && value.companies.length ? showCompanies(value.companies) : '--'),
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.answers',
                ),
                render: (value) =>
                  (value ? (
                    handleUnreadMessage(value) ? (
                      <strong>
                        {`${value.question?.description?.substring(0, 30)  }...`}
                      </strong>
                    ) : (
                      `${value.question?.description?.substring(0, 30)  }...`
                    )
                  ) : (
                    '--'
                  )),
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.messege',
                ),
                render: (value) => returnMessages(value),
              },
              {
                key: '',
                title: I18n.t('routes.panel.chat.dataTable.columns.items.date'),
                render: (value) =>
                  returnDate(value.messages[value.messages.length - 1], value),
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.answerDate',
                ),
                render: (value) =>
                  returnDate(value, value, true),
              },
              {
                key: '',
                title: I18n.t(
                  'routes.panel.chat.dataTable.columns.items.answerTeacher',
                ),
                render: (value) =>
                  getAnswerBy(value.messages[value.messages.length - 1]?.whoSent) ?? '--'
              },
              {
                width: '140px',
                key: '',
                title: '',
                render: (value) => (
                  <div className='dataTable__item--right'>
                    <Tooltip
                      title={'Ver detalhes da conversa'}
                      overlayStyle={{ fontSize: 11 }}
                    >
                      <Button
                        disabled={!value.messages.length}
                        type='link'
                        icon={<EyeOutlined />}
                        onClick={() =>
                          navigate(
                            `${I18n.t('routes.panel.chat.url')}${value.id}`,
                          )
                        }
                      />
                    </Tooltip>
                    {props.me?.profileType !== ProfileType.OPERATOR && (
                      <Tooltip
                        title={'Deletar conversa'}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setModalInfo({ user: value.user, chatId: value.id });
                            setDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </Content>
    </>
  );
};

const mapStateToProps = (state) => ({
  chatPaginated: ChatSelectors.getChatPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  messagesFilter: FilterSelector.getMessagesFilters(state),
  usersAll: UserSelectors.getUserAll(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
  getChatPaginated: (parameters) =>
    dispatch(ChatActions.getChatPaginated(parameters)),
  deleteChat: (id) => dispatch(ChatActions.deleteChat(id)),
  setMessageDirectFilters: (params) =>
    dispatch(FilterActions.setMessageDirectFilters(params)),
  getUsersAll: (params) => dispatch(UserActions.getAllUser(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatList);
