import { I18n } from 'react-redux-i18n';
import { removeSpecialChars } from './string';


function validateCep (cep) {
  const cepValidation = removeSpecialChars(cep);

  if (cep === '' || !cep || cepValidation.length < 8) {
    return I18n.t('routes.panel.userDetails.messages.errors.without_cep')
  }
}

export {
  validateCep,
};