import React from 'react';
import { Translate } from 'react-redux-i18n';

export const UserStatusType = {
  REQUESTED: 1,
  APPROVED: 2,
  AWAITING_PAYMENT: 3,
  INACTIVE: 4,
};

export const UserPlanStatus = {
  OK: 1,
  DUE: 2,
  OVERDUE: 3,
};

export const UserPlanStatusArray = [
  { id: UserPlanStatus.OK, name: <Translate value="enum.userStatusType.ok" />, color: '#80FF33' },
  { id: UserPlanStatus.DUE, name: <Translate value="enum.userStatusType.due" />, color: '#FFC300 ' },
  { id: UserPlanStatus.OVERDUE, name: <Translate value="enum.userStatusType.overdue" />, color: '#C70039' },
];

export const UserStatusTypeArray = [
  { id: UserStatusType.REQUESTED, name: 'Cadastros em análise' },
  { id: UserStatusType.APPROVED, name: 'Aprovados' },
  { id: UserStatusType.AWAITING_PAYMENT, name: 'Aguardando pagamento' },
  { id: UserStatusType.INACTIVE, name: 'Inativos' },
];

export const GetUserStatus = (value) => UserStatusTypeArray.find((o) => o.id === value);
