import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import ChatRequests from '../../api/chat';

export const ACTION_SAVE_CHAT_PAGINATED = 'ACTION_SAVE_CHAT_PAGINATED';
export const ACTION_SAVE_CHAT_DETAILS = 'ACTION_SAVE_CHAT_DETAILS';
export const ACTION_SAVE_MESSAGES = 'ACTION_SAVE_MESSAGES';
export const ACTION_SEND_MESSAGES = 'ACTION_SEND_MESSAGES';

export const getChatPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  let usersPaginated = [];

  try {
    parameters = {
      ...parameters,
      limit: (parameters && parameters.pageSize) || 10,
      offset: (parameters && parameters.offset) || 0,
      orderBy: 'createdAt',
      isDESC: true,
    };
    usersPaginated = await ChatRequests.getChatPaginated(parameters);
  } catch (err) {
    message.error('Não foi possivel acessar as mensagens')
  } finally {
    dispatch({
      type: ACTION_SAVE_CHAT_PAGINATED,
      payload: usersPaginated,
    });
    dispatch(removeLoading());
  }
};

export const getUsergetDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await ChatRequests.getChatDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_CHAT_DETAILS,
      payload: data,
    });
  } catch (err) {
    message.error('Não foi possivel acessar a mensagem')
  } finally {
    dispatch(removeLoading());
  }
};

export const getMessages = (id, clear) => async (dispatch) => {
  try {
    let data = null;

    if (id && !clear) {
      data = await ChatRequests.getMessages(id);
    } else {
      data = []
    }

    dispatch({
      type: ACTION_SAVE_MESSAGES,
      payload: data,
    });
  } catch (err) {
    message.error('Não foi possivel acessar as mensagens')
  } finally {

  }
};

export const sendMessage = (params) => async (dispatch) => {
  try {
    if (params) {
      await ChatRequests.sendMessage(params);
      message.success('Mensagem enviada');
    }
  } catch (err) {
    message.success('Erro ao enviar a mensagem');
  } finally {

  }
};

export const deleteChat = (id) => async (dispatch) => {
  try {
    if (id) {
      const data = await ChatRequests.deleteChat(id);
      message.success('Chat removido');
      return data
    }
  } catch (err) {
    message.success('Erro ao deletar chat');
  } finally {

  }
};

