import React, { useEffect, useRef, useState } from 'react'
import 'emoji-mart/css/emoji-mart.css';
import {
  AudioOutlined,
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import {
  message,
} from 'antd';
import { Picker } from 'emoji-mart';
import TextareaAutosize from 'react-textarea-autosize';
import { I18n } from 'react-redux-i18n';
import { ContentActions, QuizActions } from '../../app/redux/actions';
import { connect } from 'react-redux';
import ChatFileBar from './ChatFileBar';
import Uploader from  '../shared/Uploader/Uploader';
import axios from 'axios';
import RecordAudio from '../RecordAudio/RecordAudio'

const ChatFooter = (props) => {
  const [ emoji, setEmoji ] = useState(false);
  const [ fileBarOpen, setFileBarOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ file, setFile ] = useState(null);
  const [ recordModal, setRecorModal ] = useState(false);
  const emojiRef = useRef();
  const [ textToSend, setTextToSend ] = useState('');

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setEmoji(false);
      }
    });

    return () => {
      document.removeEventListener('mousedown', (event) => {
        if (emojiRef.current && !emojiRef.current.contains(event.target)) {
          setEmoji(false);
        }
      });
    };
  }, []);

  const send = () => {
    if(textToSend !== '') {
      props.sendMessage(textToSend)
      setTextToSend('')
    }
  }

  const keyPressed = (event) => {
    const keyCode = event.which || event.keyCode;

    if (keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      send();
    }

    if (keyCode === 13 && event.shiftKey) {
      setTextToSend((state) => `${state}\n`);
    }
  };


  const sendAudio = async (audio, type) => {
    if(!audio || !type) return message.error('erro ao enviar arquivo');

    let fileNameAndUrl;

    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      fileNameAndUrl = await props.getUrlToUploadFile({
        fileOriginalName: audio.name,
      });
      await props.uploadFile(audio, fileNameAndUrl.url, type, () => {
      }, source.token, () => {
      });
    } catch (err) {
      message.error('erro ao enviar arquivo');
    } finally {
      props.sendMessage(fileNameAndUrl.fileName, type)
    }
  }



  const sendFile = async (e) => {
    setLoading(true)
    let fileNameAndUrl;

    try {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      fileNameAndUrl = await props.getUrlToUploadFile({
        fileOriginalName: file.name,
      });
      await props.uploadFile(file, fileNameAndUrl.url, file.type, () => {
      }, source.token, () => {});
    } catch (err) {
      message.error('erro ao enviar arquivo');
      setFileBarOpen(false)
      setFile(null)
      setLoading(false)
    } finally {
      props.sendMessage(fileNameAndUrl.fileName, file.type)
      setFileBarOpen(false)
      setFile(null)
      setLoading(false)
    }
  }

  return (
    <div className="chat__footer">
      {file && fileBarOpen && (
        <ChatFileBar
          isLoading={loading}
          file={file}
          onClose={() => setFileBarOpen(false)}
          onSend={() => sendFile()}
        />
      )}
      {recordModal &&
        <RecordAudio
          onSend={(f, t) => sendAudio(f, t)}
          onClose={() => setRecorModal(false)}
        />
      }
      <div className="chat__footer__left">
        <div onClick={() => setEmoji(!emoji)}
          className="chat__footer__left__emoji">
          <SmileOutlined />
          <div ref={emojiRef}
            style={{display: emoji ? 'block' : 'none'}}>
            <Picker
              title='Selecione seu emoji'
              emoji='point_up'
              style={{ position: 'absolute', bottom: '50px', left: '16px' }}
              i18n={I18n.t('emoji')}
              onSelect={e => setTextToSend(textToSend + e.native)}
            />
          </div>
        </div>
      </div>
      <div className="chat__footer__text">
        <TextareaAutosize
          onKeyPress={keyPressed}
          minRows={1}
          maxRows={5}
          className="chat__footer__text__textarea"
          onChange={e => setTextToSend(e.target.value)}
          value={textToSend}
        />
      </div>
      <div className="chat__footer__right">
        <div onClick={() => setRecorModal(true)}
          className="chat__footer__right__audio">
          <AudioOutlined />
        </div>
        <label htmlFor="contained-button-file"
          className="chat__footer__right__file">
          <Uploader
            onChange={(e) => {
              setFile(e)
              setFileBarOpen(true)
            }}
            value={file?.base64}
          >
            <PaperClipOutlined />
          </Uploader>
        </label>
        <div onClick={() => send()}
          className={`chat__footer__right__send ${textToSend === '' && 'chat__footer__right__send--disabled'}`}>
          <SendOutlined />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  getUrlToUploadFile: (fileName, callback) => dispatch(
    ContentActions.getUrlToUploadFile(fileName, callback),
  ),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) => dispatch(
    ContentActions.uploadFile(file, url, contentType, onProgress, cancelToken, callback),
  ),
  getUrlTomedia: (name) => dispatch(QuizActions.getUrlPreviewMedia(name)),
});

export default connect(null, mapDispatchToProps)(ChatFooter);