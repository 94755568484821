import Immutable from 'seamless-immutable';
import * as ReleaseNoteActions from '../actions/releaseNote';

const initialState = Immutable({
  releaseNotePaginated: null,
  releaseNoteDetails: null,
});

export default function releaseNote (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ReleaseNoteActions.ACTION_SAVE_RELEASE_NOTE_PAGINATED:
      return ({
        ...state,
        releaseNotePaginated: action.payload,
      });
    case ReleaseNoteActions.ACTION_SAVE_RELEASE_NOTE_DETAILS:
      return ({
        ...state,
        releaseNoteDetails: action.payload,
      });
    default:
      return state;
  }
}

export function getReleaseNotesPaginated (state) {
  return state.releaseNote.releaseNotePaginated;
}

export function getReleaseNotesDetails (state) {
  return state.releaseNote.releaseNoteDetails;
}
