import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Col,
  Divider, Layout, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { UserActions } from '../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/user_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { UserStatusTypeArray } from '../../../app/enum/userStatusType';
import * as DateUtils from '../../../app/utils/date';
import { getCompaniesKanban } from '../../../app/redux/reducers/company';
import { returnPlanName } from '../../../app/utils/returnPlanName';

class UserInfo extends React.Component {
  async componentDidMount () {
    const { getUserDetails, id } = this.props;
    await getUserDetails(id);
  }

  render () {
    const { Content } = Layout;
    const {
      userDetails,
      loading,
      id,
    } = this.props;

    const getCompanies = () => {
      let companiesName = [];

      if (userDetails && userDetails.companies && userDetails.companies.length > 0) {
        companiesName = userDetails.companies.map((item) => item.name);
        return companiesName.join(', ');
      }

      return '--';
    };

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.users.url')}`}>
                <span>{I18n.t('routes.panel.users.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.userInfo.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="user-info">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <Row>
                <Col span={12}>
                  <h5>
                    <span className="panel__layout__content__title__value__icon">
                      {userDetails && userDetails.photoUrl
                        ? <Avatar src={userDetails.photoUrl} />
                        : <Icon component={UserIcon} />}
                    </span>
                    {userDetails && userDetails.name}
                  </h5>
                </Col>
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    style={{ borderRadius: 5 }}
                    text={I18n.t('routes.panel.userInfo.actions.editUser')}
                    onClick={() => navigate(`${I18n.t('routes.panel.userDetails.url')}${id}`)}
                  />
                  <Divider type="vertical" />
                </Col>
              </Row>

              <Divider />

              <div className="row mx-1">
                <div className="col-3">
                  <div className="table-header row py-3 mb-1">
                    <div className="col-12">
                      <span><strong>{I18n.t('routes.panel.userInfo.tableHeader.userData')}</strong></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.name.label')}: </strong>
                      {userDetails && userDetails.name}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.email.label')}: </strong>
                      {userDetails && userDetails.email}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.cellphone.label')}: </strong>
                      {
                        userDetails
                        && userDetails.cellphone
                        && userDetails.cellphone !== 'null null'
                        && `+${userDetails.cellphone.slice(0, 2)}${''
                        }(${userDetails.cellphone.slice(3, 5)})${''
                        }${userDetails.cellphone.slice(5, 9)}${''
                        }-${userDetails.cellphone.slice(9)}`
                      }
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.cpf.label')}: </strong>
                      {
                        userDetails
                        && userDetails.cpf
                        && `${userDetails.cpf.slice(0, 3)}${''
                        }.${userDetails.cpf.slice(3, 6)}${''
                        }.${userDetails.cpf.slice(6, 9)}${''
                        }-${userDetails.cpf.slice(9)}`
                      }
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.cep.label')}: </strong>
                      {
                        userDetails
                        && userDetails.cep
                        && `${userDetails.cep.slice(0, 5)}${''
                        }-${userDetails.cep.slice(5)}`
                      }
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.state.label')}: </strong>
                      {(userDetails && userDetails.state) || ' --'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.city.label')}: </strong>
                      {(userDetails && userDetails.city) || ' --'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.address.label')}: </strong>
                      {(userDetails && userDetails.address) || ' --'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.addressNumber.label')}: </strong>
                      {(userDetails && userDetails.number) || ' --'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.neighborhood.label')}: </strong>
                      {(userDetails && userDetails.neighborhood) || ' --'}
                    </div>
                  </div>
                </div>
                <div className="col-3 offset-1">
                  <div className="table-header row py-3 mb-1">
                    <div className="col-12">
                      <span><strong>{I18n.t('routes.panel.userInfo.tableHeader.userProfessionalData')}</strong></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.company.label')}: </strong>
                      {getCompanies()}
                    </div>

                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.specializations.label')}: </strong>
                      {(userDetails
                        && userDetails.specializations
                        && userDetails.specializations.length > 0
                        && userDetails.specializations.map((spc, i, self) => (i !== self.length - 1
                          ? `${spc.title}, `
                          : spc.title))) || '--'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.hospitals.label')}: </strong>
                      {(userDetails
                        && userDetails.userHospitals
                        && userDetails.userHospitals.length > 0
                        && userDetails.userHospitals.map((hosp, i, self) => (i !== self.length - 1
                          ? `${(hosp.hospitalName) || '--'}, `
                          : (hosp.hospitalName || '--')))) || '--'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.hasTemi.label')}: </strong>
                      {userDetails && userDetails.hasTemi ? 'Sim' : 'Não'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.hasUtiExpertise.label')}: </strong>
                      {userDetails && userDetails.hasUtiExpertise ? 'Sim' : 'Não'}
                    </div>
                  </div>

                  <div className="table-header row py-3 mb-1 mt-5">
                    <div className="col-12">
                      <span><strong>{I18n.t('routes.panel.userInfo.tableHeader.courses')}</strong></span>
                    </div>
                  </div>
                  {userDetails
                    && userDetails.courses
                    && userDetails.courses.length > 0
                    ? (
                      userDetails.courses.map((course, i) => (
                        <div
                          key={course.id}
                          className={i !== 0 ? 'mt-3' : ''}
                        >
                          <div className="row">
                            <div className="col-12 my-2">
                              <strong>{I18n.t('forms.user.course.label')}: </strong>
                              {course.title}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 my-2">
                              <strong>{I18n.t('forms.user.startAt.label')}: </strong>
                              {course.userCourse && DateUtils.humanizeDate(course.userCourse.startAt, 'DD/MM/YYYY')}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 my-2">
                              <strong>{I18n.t('forms.user.endAt.label')}: </strong>
                              {course.userCourse && DateUtils.humanizeDate(course.userCourse.endAt, 'DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>
                      ))
                    )
                    : ' --'}

                </div>
                <div className="col-3 offset-1">
                  <div className="table-header row py-3 mb-1">
                    <div className="col-12">
                      <span><strong>{I18n.t('routes.panel.userInfo.tableHeader.others')}</strong></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.hasPlatformAccess.label')}: </strong>
                      {userDetails && userDetails.hasPlatformAccess ? 'Sim' : 'Não'}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('forms.user.status.label')}: </strong>
                      {userDetails && userDetails.status && UserStatusTypeArray[userDetails.status - 1].name}
                    </div>
                  </div>

                  {userDetails && userDetails.questionDatabase && (
                    <>
                      <div className="table-header row py-3 mt-5">
                        <div className="col-12">
                          <span><strong>{I18n.t('routes.panel.questionDatabase.pageTitle')}</strong></span>
                        </div>
                      </div>
                      <div
                        className="row"
                      >
                        <div className="col-12 my-2">
                          <strong>{I18n.t('routes.panel.questionDatabase.chat.filter.startAt')}: </strong>
                          {userDetails.questionDatabase && DateUtils.humanizeDate(userDetails.questionDatabase.startAt, 'DD/MM/YYYY')}
                        </div>
                        <div className="col-12 my-2">
                          <strong>{I18n.t('routes.panel.questionDatabase.chat.filter.endAt')}: </strong>
                          {userDetails.questionDatabase && DateUtils.humanizeDate(userDetails.questionDatabase.endAt, 'DD/MM/YYYY')}
                        </div>
                        <div className="col-12 my-2">
                          <strong>{I18n.t('routes.panel.questionDatabase.dataTable.columns.planOption.title')}: </strong>
                          {userDetails.questionDatabase && returnPlanName(userDetails.questionDatabase.planOption)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: UserSelectors.getUserDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  removeUser: (id, callback) => dispatch(UserActions.removeUser(id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  UserInfo,
);
