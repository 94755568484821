export const searchOnArraysByQueryParams = () => {
  const stringfy = (text) => String(text).toUpperCase();
  const removeSpecialCharacters = (text) => stringfy(text).replace(/[^a-zA-Z0-9,;\-.!? ]/g, '');

  const compare = (val1, val2) => {
    const arrayFromVal1 = Array.from(new Set(Array.from(removeSpecialCharacters(val1))));
    const arrayFromVal2 = Array.from(new Set(Array.from(removeSpecialCharacters(val2))));
    const fullArray = Array.from(new Set([ ...arrayFromVal1, ...arrayFromVal2 ]));

    return (
      JSON.stringify(fullArray) === JSON.stringify(arrayFromVal1) ||
      JSON.stringify(fullArray) === JSON.stringify(arrayFromVal2)
    );
  };

  const filter = (arr, criteria) =>
    arr.filter((obj) =>
      Object.keys(criteria).every((c) => {
        if (c === 'dateRange') {
          const range = criteria[c].split(',');

          return obj.createdAt >= range[0] && obj.createdAt <= range[1];
        } else {
          return compare(stringfy(obj[c]), stringfy(criteria[c]));
        }
      })
    );

  return filter;
};

export const simpleSearchOnArray = (arrayToSearch, queryObject) => {
  const queryObjectKeys = Object.keys(queryObject).filter(queryKey => !!queryObject[queryKey] && !!queryObject[queryKey] !== '')

  if(!queryObjectKeys?.length) return arrayToSearch

  return arrayToSearch.filter(arrayItem => {
    const matchResultsList = queryObjectKeys.map(queryKey => {
      return arrayItem[queryKey]?.toString().toUpperCase().includes(queryObject[queryKey].toString().toUpperCase())
    })
    return !(matchResultsList.some(matchedItem => !matchedItem))
  })
}