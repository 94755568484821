import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Modal,
  Row,
  Tooltip,
} from 'antd';
import Icon, { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, navigate } from '@reach/router';
import Moment from 'moment';
import DataTable from '../../../components/shared/DataTable';
import {
  QuestionDatabaseActions,
  QuizActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  LoadingSelectors,
  QuestionDatabaseSelectors,
  QuizSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import {
  ALTERNATIVE_TYPE,
  UserQuizStatus,
} from '../../../app/enum/questionEnum';
import { ProfileType } from '../../../app/enum/profileType';
import { removeEmptyField } from '../../../app/utils/functionsUtils';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { StatusCorrection, StatusCorrectionOptionsArray } from '../../../app/enum/statusCorrection'
import logo from '../../../app/assets/img/ic_logo.svg';
import moment from 'moment-timezone';
import { cleanPagination } from '../../../app/utils/pagination';

const { Panel } = Collapse;
class QuestionDatabaseCorrections extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      exportingDataLoading: false,
      params: {
        orderBy: 'correctAnswersPercentage',
        isDESC: true,
        userName: '',
        userEmail: '',
        status: '',
        ...cleanPagination
      },
      form: {
        text: '',
      },
      statusCorrected: StatusCorrection.INITIAL_CORRECT,
      correctAnswer: 0,
      showModal: false,
      justification: [],
      rowValue: {},
      showJustification: false,
      contentQuestion: [],
      company: '',
      companyList: [],
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const {params} = this.state;
    this.getCorrections(params);
    this.getCompanyList()
  }

  getCorrections = async (parameters) => {
    const { getQuestionDatabaseCorrections } = this.props;

    const params = { ...parameters };
    this.setState({ params })
    await getQuestionDatabaseCorrections(params);
  };

  getCorrectStatus = (value) => {
    switch (value) {
      case StatusCorrection.TO_CORRECT:
        return I18n.t('routes.panel.questionDatabase.corrections.toCorrect');
      case StatusCorrection.CORRECT:
        return I18n.t('routes.panel.questionDatabase.corrections.corrected');
      case StatusCorrection.INCORRECT:
        return I18n.t('routes.panel.questionDatabase.corrections.corrected');
      default:
        return I18n.t(
          'routes.panel.questionDatabase.corrections.toCorrect',
        );
    }
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    const {company} = this.state
    params = removeEmptyField(params);

    if (company) {
      params.companyId = company;
    }

    this.getCorrections({ ...params, ...cleanPagination });
    this.setState({
      params: {...params, ...cleanPagination}
    })
  };

  clearFilter = () => {
    const params = {
      userName: '',
      status: '',
      userEmail: '',
      ...cleanPagination
    };

    this.getCorrections(params);
    this.setState({ params, company: '' });
  };

  changeParams (name, value) {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  }

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  // eslint-disable-next-line class-methods-use-this
  transformDate (date) {
    date = moment(date).format('DD/MM/YYYY');
    return date;
  }

  onOpen = (url) => {
    window.open(url, '_blank').focus();
  };

  getAudio = (url) => {
    if (url !== '' && url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <div className='margin_top'>
              <Col span={8}>
                <audio controls>
                  <source
                    src={url}
                    type='audio/mp3'
                    onDoubleClick={() => this.onOpen(url)}
                  />
                </audio>
              </Col>
            </div>
          </Row>
        </span>
      );
    }
  };

  getVideo = (url) => {
    if (url) {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='block_media margin_top'>
                <video
                  width='100%'
                  height='100%'
                  controls
                  src={url}
                  onDoubleClick={() => this.onOpen(url)}
                />
              </div>
            </Col>
          </Row>
        </span>
      );
    }
  };

  getImage = (url) => {
    if (url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='margin_top section_photo'>
                <img
                  width='100%'
                  height='100%'
                  alt={I18n.t('routes.panel.questionDatabase.corrections.alternativeImage')}
                  src={url}
                  className='statistics__image'
                  onClick={() => this.onOpen(url)}
                />
              </div>
              <span className='margin_top'>
                {I18n.t('forms.newQuestion.previewImageNewTab')}
              </span>
            </Col>
          </Row>
        </span>
      );
    }
  };

  correctQuiz = () => {
    this.getCorrections()
    this.setState({ contentQuestion: [] });
    this.closeModal();
  };

  getStyleStatus = (status) => {
    switch (status) {
      case StatusCorrection.TO_CORRECT:
        return 'status__to-correct';
      case StatusCorrection.REPLIED_AGAIN:
        return 'status__to-correct';
      case StatusCorrection.CORRECT:
        return 'status__corrected';
      case StatusCorrection.INCORRECT:
        return 'status__corrected';
      default:
        return 'status__to-correct';
    }
  }

  render () {
    const { Content } = Layout;
    const {
      params,
      showModal,
      rowValue,
      showJustification,
      statusCorrected,
      company,
      companyList,
    } = this.state;
    const { loading, correctionQuestionDatabase, me, getQuestion, question, fixQuestion } = this.props;

    const modalCorrect = (
      <Modal
        visible={showModal}
        onCancel={() => this.closeModal()}
        okButtonProps={{
          disabled:  ([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status),
        }}
        onOk={async () => {
          await fixQuestion(rowValue.id, statusCorrected, () => {
            message.success(I18n.t('routes.panel.questionDatabase.messages.success.corrected'))
            this.closeModal()
            this.getCorrections(params);
            this.setState({ statusCorrected: StatusCorrection.INITIAL_CORRECT })
          })
        }}
        width={850}
        okText={I18n.t('routes.panel.questionDatabase.corrections.finishCorrection')}
      >
        <div className='ant-modal-body'>
          <div className='statistics__modalMargin'>
            <div>
              <img width='8%'
                height='8%'
                src={logo} />
            </div>
            <div className='statistics__modal__quiz'>
              <h6 className='statistics__modal__quiz__descriptionLabel'>
                {I18n.t('routes.panel.questionDatabase.corrections.modal.title')}
              </h6>
              <h6 className='statistics__modal__quiz__descriptionLabel__description'>
                {this.state.rowValue.answer?.question?.description
                  ? this.state.rowValue.answer?.question?.description
                  : I18n.t('routes.panel.statistics.modal.notHaveDescription')}
              </h6>
            </div>

            <Col className='statistics__content'>
              <h5 className='statistics__modal__question__number'>
                {`${I18n.t('routes.panel.statistics.modal.labelQuestion')} ${
                  rowValue.answer?.question?.number
                }`}
              </h5>
              <div>
                <h6>
                  {I18n.t('forms.newQuestion.labelLocal')} -{' '}
                  {I18n.t('forms.newQuestion.labelYear')}
                </h6>
                <p>
                  {rowValue.answer?.question?.location} - {rowValue.answer?.question?.year}
                </p>
              </div>
              <div>
                <h6>
                  {I18n.t('routes.panel.statistics.modal.labelQuestion')}
                </h6>
                <p>{rowValue.answer?.question?.description}</p>
              </div>
              <div>
                <h6>
                  {I18n.t('routes.panel.statisticsList.models.title_student')}
                </h6>
                {!rowValue.medias?.length && (
                  <p
                    className={
                      rowValue.value
                        ? 'statistics__haveValue'
                        : 'statistics__notValue'
                    }
                  >
                    {rowValue.value
                      ? rowValue.value
                      : I18n.t(
                        'routes.panel.statisticsList.models.notHaveAnswer',
                      )}
                  </p>
                )}
                {rowValue.medias && rowValue.medias[0]?.type ===
                    ALTERNATIVE_TYPE.PHOTO &&
                    this.getImage(rowValue.medias[0]?.mediaUrl)}
                {rowValue.medias && rowValue.medias[0]?.type ===
                    ALTERNATIVE_TYPE.AUDIO &&
                    this.getAudio(rowValue.medias[0].mediaUrl)}
                {rowValue.medias && rowValue.medias[0]?.type ===
                    ALTERNATIVE_TYPE.VIDEO &&
                    this.getVideo(rowValue.medias[0].mediaUrl)}
              </div>
              <div className='statistics__buttonJustification'>
                <AdvancedButton
                  text={
                    !showJustification
                      ? I18n.t('routes.panel.questionDatabase.corrections.modal.showJustification')
                      : I18n.t('routes.panel.questionDatabase.corrections.modal.closeJustification')
                  }
                  onClick={() => {
                    this.setState({ showJustification: !showJustification });
                  }}
                />
                {showJustification && (
                  <div className='statistics__buttonJustification__content'>
                    <div className='statistics__border'></div>
                    <h6 className='statistics__buttonJustification__content__title'>
                      {I18n.t('routes.panel.statistics.modal.justification')}
                    </h6>
                    {question &&
                      question.justification ||
                      question.justificationAudio ||
                      question.justificationVideo ||
                      question.justificationImage ?
                      (
                        <Row className='statistics__correction__question-database'>
                          <Col>
                            <p>{question.justification && question.justification}</p>
                            <div className='statistics__buttonJustification__content'>
                              {question.justificationImage &&
                          this.getImage(question.justificationImageUrl)}
                            </div>
                          </Col>
                          <Col>
                            <div className='statistics__buttonJustification__content'>
                              {question.justificationVideo &&
                          this.getVideo(question.justificationVideoUrl)}
                            </div>
                          </Col>
                          <Col>
                            <div className='statistics__buttonJustification__content'>
                              {question.justificationAudio &&
                              this.getAudio(question.justificationAudioUrl)}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <p>
                          {I18n.t(
                            'routes.panel.questionDatabase.corrections.modal.noHaveJustification',
                          )}
                        </p>
                      )}
                  </div>
                )}
              </div>

              <Row className='statistics__button'>
                <AdvancedButton
                  text= {I18n.t('routes.panel.questionDatabase.corrections.modal.correct')}
                  className={
                    (statusCorrected === StatusCorrection.CORRECT || rowValue?.status ===
                      StatusCorrection.CORRECT &&
                      ([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status))
                      &&
                      'statistics__button__correctChose'
                  }
                  onClick={() => {
                    this.setState({ statusCorrected: StatusCorrection.CORRECT });
                  }}
                  disabled={([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status)}
                />
                <AdvancedButton
                  className={
                    (statusCorrected === StatusCorrection.INCORRECT || rowValue?.status ===
                      StatusCorrection.INCORRECT &&
                      ([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status))
                      ? 'statistics__button__incorrectChose'
                      : 'statistics__button__incorrect'
                  }
                  text= {I18n.t('routes.panel.questionDatabase.corrections.modal.incorrect')}
                  disabled={([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status)}
                  onClick={() => {
                    this.setState({ statusCorrected: StatusCorrection.INCORRECT });
                  }}
                />
              </Row>
              <div className='statistics__border' />
            </Col>

            {([ StatusCorrection.INCORRECT, StatusCorrection.CORRECT ]).includes(rowValue?.status) && (
              <>
                <Row>
                  <h6>
                    {I18n.t('routes.panel.statistics.modal.dateCorrection')}
                  </h6>
                  <div className='statistics__modal__correction'>
                    {this.transformDate(rowValue.correctedAt && rowValue.correctedAt)}
                  </div>
                </Row>
                <Row>
                  <h6>{I18n.t('routes.panel.statistics.modal.correctBy')}</h6>
                  <div className='statistics__modal__correction'>
                    {rowValue.userCorrector && rowValue.userCorrector.name}
                  </div>
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <>
        {modalCorrect}
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.questionDatabase.corrections.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params.userName}
                      onChange={(e) => this.changeParams('userName', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.questionDatabase.dataTableCorrections.filters.userName')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params.userEmail}
                      onChange={(e) => this.changeParams('userEmail', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.questionDatabase.dataTableCorrections.filters.userEmail')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={StatusCorrectionOptionsArray}
                      label={I18n.t('routes.panel.questionDatabase.dataTableCorrections.filters.status')}
                      value={params?.status}
                      onChange={(val) => this.changeParams('status', val)}
                    />
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={6}>
                    <AdvancedSelect
                      label={I18n.t('routes.panel.questionDatabase.dataTable.filters.company')}
                      value={company}
                      onChange={(val) => this.setState({company: val})}
                      options={companyList}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.clearFilter()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>
        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuizIcon} />
                  </span>
                  {I18n.t('routes.panel.questionDatabase.corrections.sideBarTitle')}
                </h3>
              </Col>
            </Row>
            <Divider />
            <DataTable
              getMethod={(parameters) => this.getCorrections(parameters)}
              data={correctionQuestionDatabase}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              params={params}
              columns={[
                {
                  width: '100px',
                  key: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.questionNumber.key',
                  ),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.questionNumber.title',
                  ),
                  render: (value) => value.question?.number || '--',
                },
                {
                  key: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userName.key',
                  ),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userName.title',
                  ),
                  render: (value) => value.name || '--',
                },
                {
                  key: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userEmail.key',
                  ),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userEmail.title',
                  ),
                  render: (value) => value.email || '--',
                },
                {
                  key: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userName.key',
                  ),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTable.columns.company.title',
                  ),
                  render: (value) => (value.questionDatabase && value.questionDatabase.company ? value?.questionDatabase.company.name : '--'),
                },
                {
                  key: I18n.t('routes.panel.questionDatabase.dataTableCorrections.columns.answerDate.key'),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.answerDate.title',
                  ),
                  render: (value) => Moment(value).format('DD/MM/YYYY') || '--',
                },
                {
                  key: I18n.t('routes.panel.questionDatabase.dataTableCorrections.columns.userCorrector.key'),
                  title: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.userCorrector.title',
                  ),
                  render: (value) => value && value.name || '--',
                },
                {
                  key: I18n.t(
                    'routes.panel.questionDatabase.dataTableCorrections.columns.correctionStatus.key',
                  ),
                  title: I18n.t('routes.panel.questionDatabase.dataTable.columns.statusCorrection'),
                  render: (value, record) =>
                    (me.profileType !== ProfileType.QUIZER
                      ? (
                        <AdvancedButton
                          onClick={ async () => {
                            await getQuestion(record.answer.question && record.answer.question.id)
                            this.setState({
                              showModal: !showModal,
                              rowValue: correctionQuestionDatabase?.rows.find(correctionIdentifier => correctionIdentifier.id === record.id),
                            });
                          }}
                          className={this.getStyleStatus(value)}
                          text={this.getCorrectStatus(value)}
                        />
                      ) : (
                        <Tooltip
                          title={
                            me.profileType !== ProfileType.QUIZER
                              ? (I18n.t('routes.panel.questionDatabase.corrections.noDissertationQuestion'))
                              : (I18n.t('routes.panel.questionDatabase.corrections.noPermission'))
                          }
                        >
                        ---
                        </Tooltip>
                      )),
                },
                {
                  key: I18n.t(
                    'routes.panel.users.dataTable.columns.actions.key',
                  ),
                  title: '',
                  render: (id, row) =>
                    row.toCorrect &&
                    row.status === UserQuizStatus.WAITING && (
                      <Tooltip
                        title={I18n.t('routes.panel.users.dataTable.columns.actions.goToEditText')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<EditOutlined />}
                          onClick={() =>
                            navigate(
                              `${I18n.t(
                                'routes.panel.quiz.urlQuizCorrections',
                              )}${id}`,
                            )
                          }
                        />
                      </Tooltip>
                    ),
                },
              ]}
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  correctionQuestionDatabase: QuestionDatabaseSelectors.getUserQuestionCorrectionsDatabase(state),
  question: QuizSelectors.getSpecificQuestion(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
  getQuestionDatabaseCorrections: (params) => dispatch(QuestionDatabaseActions.getQuestionDatabaseCorrectionsPaginated(params)),
  getQuestion: (id) => dispatch(QuizActions.getQuestionById(id)),
  fixQuestion: (id, status, callback) => dispatch(QuestionDatabaseActions.fixQuestion(id, status, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDatabaseCorrections);
