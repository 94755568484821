import getInstance from './config';

export default class ReleaseNoteApi {
  static async getReleaseNotePaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/release-note', { params });
    return data;
  }

  static async getReleaseNoteDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/release-note/${id}`);
    return data;
  }

  static async update (id, params) {
    const instance = await getInstance();
    const { data } = await instance.put(`/release-note/${id}`, params);
    return data;
  }

  static async delete (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/release-note/${id}`);
    return data;
  }

  static async create (params) {
    const instance = await getInstance();
    const { data } = await instance.post('/release-note/', params);
    return data;
  }

}
