import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Layout, Row, Col, message, Divider, Checkbox } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
import AdvancedRadioGroup from '../shared/AdvancedRadioGroup/AdvancedRadioGroup';
import AdvancedInput from '../shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../shared/AdvancedButton/AdvancedButton';
import AdvancedDragger from '../shared/AdvancedDragger/AdvancedDragger';
import {
  ALTERNATIVE_TYPE,
  QuestionAnswerType,
  QuestionType,
} from '../../app/enum/questionEnum';
import { ContentActions, QuizActions } from '../../app/redux/actions';

class QuestionAlternative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileAlternative: [],
      fileAlterantiveProgress: 0,
      fileAlternativeAxiosSource: null,
    };
  }

  customRequest = async (
    onProgress,
    onSuccess,
    onError,
    file,
    type,
    progress,
    axiosSource,
    multiple = false,
  ) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    let fileNameAndUrl = null;
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });
    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });
      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }
      onProgress({ percent: (event.loaded / event.total) * 100 });
    };
    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }
      fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
      });
      const newFile = new File([file], fileNameAndUrl.fileName, {
        type: file.type,
      });
      await uploadFile(
        newFile,
        fileNameAndUrl.url,
        newFile.type,
        onUploadProgress,
        this.state[axiosSource].cancelToken,
        () => {
          message.success(
            I18n.t('routes.panel.contentDetails.messages.fileSuccess'),
          );
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });
      if (!multiple) {
        this.props.changeField(
          'labelFileName',
          [{ name: fileNameAndUrl.fileName, uid: 95 }],
          this.props.index,
        );
        const res = await this.props.getUrlTomedia(fileNameAndUrl.fileName);
        this.props.changeField('labelFileUrl', res.url, this.props.index);
      } else {
        this.handleMultFileChange(type, {
          fileName: fileNameAndUrl.fileName,
          title: file.name,
        });
      }
    } catch (error) {
      //
    } finally {
      //
    }
  };

  render() {
    const { Content } = Layout;

    const getAlternativeField = () => {
      switch (this.props.labelType) {
        case ALTERNATIVE_TYPE.TEXT:
          return (
            <Col span={10}>
              <AdvancedInput
                placeholder={I18n.t('forms.newQuestion.placeholderTextInput')}
                value={this.props.label}
                onChange={(val) =>
                  this.props.changeField('label', val, this.props.index)
                }
              />
            </Col>
          );
        case ALTERNATIVE_TYPE.AUDIO:
          if (!this.props.isEditing) {
            return (
              <Col span={8} className='paddingTop5'>
                <AdvancedDragger
                  customRequest={({ onProgress, onSuccess, onError, file }) =>
                    this.customRequest(
                      onProgress,
                      onSuccess,
                      onError,
                      file,
                      'fileAlternative',
                      'fileAlternativeProgress',
                      'fileAlternativeAxiosSource',
                    )
                  }
                  accept='audio/mp3, audio/m4a'
                  fileList={this.props.labelFileName}
                  onRemove={() => {
                    this.props.changeField(
                      'labelFileName',
                      null,
                      this.props.index,
                    );
                    this.setState({ fileAlternative: [] });
                  }}
                  label={I18n.t('forms.newQuestion.labelAudioContent')}
                  progressIcon='audio'
                  progress={this.state.fileAlternativeProgress}
                />
              </Col>
            );
          }
          break;
        case ALTERNATIVE_TYPE.VIDEO:
          if (!this.props.isEditing) {
            return (
              <Col span={8} className='paddingTop5'>
                <AdvancedDragger
                  customRequest={({ onProgress, onSuccess, onError, file }) =>
                    this.customRequest(
                      onProgress,
                      onSuccess,
                      onError,
                      file,
                      'fileAlternative',
                      'fileAlternativeProgress',
                      'fileAlternativeAxiosSource',
                    )
                  }
                  accept='video/mp4,video/avi'
                  fileList={this.props.labelFileName}
                  onRemove={() => {
                    this.props.changeField(
                      'labelFileName',
                      null,
                      this.props.index,
                    );
                    this.setState({ fileAlternative: [] });
                  }}
                  label={I18n.t('forms.newQuestion.labelVideoContent')}
                  progressIcon='video'
                  progress={this.state.fileAlternativeProgress}
                />
              </Col>
            );
          }
          break;
        case ALTERNATIVE_TYPE.PHOTO:
          if (!this.props.isEditing) {
            return (
              <Col span={8} className='paddingTop5'>
                <AdvancedDragger
                  customRequest={({ onProgress, onSuccess, onError, file }) =>
                    this.customRequest(
                      onProgress,
                      onSuccess,
                      onError,
                      file,
                      'fileAlternative',
                      'fileAlternativeProgress',
                      'fileAlternativeAxiosSource',
                    )
                  }
                  accept='image/png,image/jpeg,image/jpg'
                  fileList={this.props.labelFileName}
                  onRemove={() => {
                    this.props.changeField(
                      'labelFileName',
                      [],
                      this.props.index,
                    );
                    this.setState({ fileAlternative: [] });
                  }}
                  label={I18n.t('forms.newQuestion.labelImageContent')}
                  progressIcon='image'
                  progress={this.state.fileAlternativeProgress}
                />
              </Col>
            );
          }
          break;
        default:
          return <></>;
      }
    };

    const getButtonAddOrRemove = () => {
      if (!this.props.isEditing) {
        if (
          this.props.index === this.props.length - 1 &&
          this.props.length !== 5
        ) {
          return (
            <AdvancedButton
              text={I18n.t('forms.newQuestion.labelAddAlternative')}
              onClick={() => this.props.addAlternative()}
            />
          );
        }
        return (
          <AdvancedButton
            text={I18n.t('forms.newQuestion.labelRemoverAlternative')}
            onClick={() => this.props.removeAlternative(this.props.index)}
          />
        );
      }
    };

    const getLetterAlterantive = () => {
      if (!this.props.isEditing) {
        switch (this.props.index) {
          case 0:
            return 'A.';
          case 1:
            return 'B.';
          case 2:
            return 'C.';
          case 3:
            return 'D.';
          case 4:
            return 'E.';
          default:
            return 'A.';
        }
      }
    };

    return (
      <>
        <Content>
          <Row gutter={[16, 24]} align='middle' style={{ marginTop: 45 }}>
            <Col span={2} />
            {!this.props.isEditing && (
              <Col span={8}>
                <AdvancedRadioGroup
                  value={this.props.labelType}
                  onChange={(val) => {
                    this.props.changeField('label', '', this.props.index);
                    this.props.changeField(
                      'labelFileName',
                      [],
                      this.props.index,
                    );
                    this.props.changeField(
                      'labelFileUrl',
                      [],
                      this.props.index,
                    );
                    this.props.changeField(
                      'labelType',
                      val.target.value,
                      this.props.index,
                    );
                  }}
                  options={[
                    {
                      id: ALTERNATIVE_TYPE.TEXT,
                      name: I18n.t('forms.newQuestion.labelText'),
                    },
                    {
                      id: ALTERNATIVE_TYPE.PHOTO,
                      name: I18n.t('forms.newQuestion.labelImage'),
                    },
                    {
                      id: ALTERNATIVE_TYPE.VIDEO,
                      name: I18n.t('forms.newQuestion.labelVideo'),
                    },
                    {
                      id: ALTERNATIVE_TYPE.AUDIO,
                      name: I18n.t('forms.newQuestion.labelAudio'),
                    },
                  ]}
                  horizontal
                />
              </Col>
            )}
          </Row>
        </Content>
        <Row>
          {(!this.props.isEditing ||
            (this.props.isEditing &&
              this.props.labelType === ALTERNATIVE_TYPE.TEXT)) && (
            <Col
              span={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h6>{getLetterAlterantive()}</h6>
            </Col>
          )}
          {getAlternativeField()}
          <Divider type='vertical' />
          <Col span={4} className='paddingTop5'>
            {getButtonAddOrRemove()}
          </Col>
        </Row>
        <Row>
          <Col span={2} />
          <div className='margin_top'>
            <Checkbox
              disabled={!!this.props.isEditing}
              checked={this.props.isCorrect}
              onChange={(val) =>
                this.props.changeField(
                  'isCorrect',
                  val.target.checked,
                  this.props.index,
                )
              }
            >
              {I18n.t('forms.newQuestion.correctAlternative')}
            </Checkbox>
          </div>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUrlToUploadFile: (fileName, callback) =>
    dispatch(ContentActions.getUrlToUploadFile(fileName, callback)),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) =>
    dispatch(
      ContentActions.uploadFile(
        file,
        url,
        contentType,
        onProgress,
        cancelToken,
        callback,
      ),
    ),
  getUrlTomedia: (name) => dispatch(QuizActions.getUrlPreviewMedia(name)),
});
export default connect(null, mapDispatchToProps)(QuestionAlternative);
