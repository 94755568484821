import { I18n } from 'react-redux-i18n'
import UserPlanOption from '../enum/userPlanOption'

export const returnPlanName = (value) => {

  if(!value) return '--'

  switch (value) {
    case UserPlanOption.BRONZE:
      return I18n.t('userPlanOption.bronze')
    case UserPlanOption.SILVER:
      return I18n.t('userPlanOption.silver')
    case  UserPlanOption.GOLD:
      return I18n.t('userPlanOption.gold')
    case UserPlanOption.BLACK:
      return I18n.t('userPlanOption.black')
    default:
      break;
  }
}
