import React from 'react';
import md5 from 'md5';
import {
  Col,
  Divider, Layout, message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { UserActions } from '../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/user_icon_blue.svg';
import AdministratorForm from '../../../app/forms/administrator/AdministratorForm';
import { ViewType } from '../../../app/enum/viewType';

class AdministratorDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      userDetails: null,
    };
  }

  async componentDidMount () {
    const { getUserDetails, id } = this.props;
    await getUserDetails(id);
    this.setState({ userDetails: this.props.userDetails });

    if (!id) {
      this.setState({
        viewType: ViewType.ADD,
      });
    }
  }

  async updateUser (id, data) {
    const { updateUser, createUser, userDetails } = this.props;

    try {
      if (data.password) {
        data.password = md5(data.password);
      }

      if (data.passwordConfirmation) {
        delete data.passwordConfirmation;
      }

      if (data.profileTypeSelected) {
        data.profileType = data.profileTypeSelected;
        delete data.profileTypeSelected;
      }

      if (this.state.viewType === ViewType.ADD) {
        await createUser(data, () => {
          message.success(I18n.t('routes.panel.administratorDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.administrators.url'));
        });
      } else {
        const { email, name, password, profileType } = data;
        await updateUser(userDetails.id, { email, name, password, profileType }, () => {
          message.success(I18n.t('routes.panel.administratorDetails.messages.success'));
          navigate(I18n.t('routes.panel.administrators.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render () {
    const { Content } = Layout;
    const { viewType, userDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <Content className="panel__layout__content">
        <div className="administrator-details">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <div className="panel__layout__content__title">
              <h2 className="panel__layout__content__title__value">
                <span className="panel__layout__content__title__value__icon">
                  <Icon component={UserIcon} />
                </span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.administratorDetails.pageTitleAdd'
                      : 'routes.panel.administratorDetails.pageTitle',
                  )
                }
              </h2>
              <p className="panel__layout__content__title__value__description">
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.administratorDetails.pageDescriptionAdd'
                      : 'routes.panel.administratorDetails.pageDescription',
                  )
                }
              </p>
            </div>

            <Divider />

            <div>
              <Row>
                <Col span={24}>
                  {(userDetails || viewType === ViewType.ADD) && (
                    <AdministratorForm
                      data={userDetails}
                      submitFunction={(userId, data) => this.updateUser(userId, data)}
                      viewType={viewType}
                      id={id}
                      loading={loading}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: UserSelectors.getUserDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  updateUser: (id, data, callback) => dispatch(UserActions.updateAdmin(id, data, callback)),
  createUser: (data, callback) => dispatch(UserActions.createAdmin(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  AdministratorDetails,
);
