import React from 'react';
import md5 from 'md5';
import {
  Col,
  Divider, Layout, message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { UserActions } from '../../../app/redux/actions';
import { LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/user_icon_blue.svg';
import UserForm from '../../../app/forms/user/UserForm';
import { ViewType } from '../../../app/enum/viewType';
import { removeSpecialChars } from '../../../app/utils/string';

class UserDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      userDetails: null,
    };
  }

  async componentDidMount () {
    const { getUserDetails, id } = this.props;
    await getUserDetails(id);
    this.setState({ userDetails: this.props.userDetails });

    if (!id) {
      this.setState({
        viewType: ViewType.ADD,
      });
    }
  }

  async updateUser (id, data) {
    const { updateUser, createUserDaily, userDetails } = this.props;

    try {
      data = {
        ...data,
        cellphone: `${removeSpecialChars(data.ddi)} ${removeSpecialChars(data.cellphone)}`,
        cep: removeSpecialChars(data.cep),
        cpf: removeSpecialChars(data.cpf),
        planExpiry: data.planExpiry ? new Date(data.planExpiry) : null,
      };

      if (!data.telephone || data.telephone === 0) {
        delete data.telephone;
      }

      if (data.password) {
        data.password = md5(data.password);
      }

      if (data.passwordConfirmation) {
        delete data.passwordConfirmation;
      }

      if (!data.number) {
        return message.error(I18n.t('routes.login.errors.address_number'))
      }

      if (this.state.viewType === ViewType.ADD) {
        await createUserDaily(data, () => {
          message.success(I18n.t('routes.panel.userDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.users.url'));
        });
      } else {
        await updateUser(userDetails.id, data, () => {
          message.success(I18n.t('routes.panel.userDetails.messages.success'));
          navigate(I18n.t('routes.panel.users.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render () {
    const { Content } = Layout;
    const { viewType, userDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <Content className="panel__layout__content">
        <div className="user-details">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <div className="panel__layout__content__title">
              <h2 className="panel__layout__content__title__value">
                <span className="panel__layout__content__title__value__icon">
                  <Icon component={UserIcon} />
                </span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.userDetails.pageTitleAdd'
                      : 'routes.panel.userDetails.pageTitle',
                  )
                }
              </h2>
              <p className="panel__layout__content__title__value__description">
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.userDetails.pageDescriptionAdd'
                      : 'routes.panel.userDetails.pageDescription',
                  )
                }
              </p>
            </div>

            <Divider />

            <div>
              <Row>
                <Col span={24}>
                  {(userDetails || viewType === ViewType.ADD) && (
                    <UserForm
                      data={userDetails}
                      submitFunction={(userId, data) => this.updateUser(userId, data)}
                      viewType={viewType}
                      id={id}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: UserSelectors.getUserDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  createUserDaily: (data, callback) => dispatch(UserActions.createUserDaily(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  UserDetails,
);
