import React from "react";
import { ResponsivePie } from "@nivo/pie";

class PieChart extends React.PureComponent {
  render() {
    const { data } = this.props;

    const marginBottom = data && data.length * 35;
    const height = marginBottom * 3 > 300 ? marginBottom * 3 : 300;

    return (
      <div
        className="pie-chart"
        style={{
          height,
        }}
      >
        <ResponsivePie
          data={data}
          height={height}
          margin={{
            top: 20,
            right: 0,
            bottom: marginBottom,
            left: 0,
          }}
          innerRadius={0.5}
          cornerRadius={3}
          colors={["#0084CF", "#006AB2", "#005095", "#003979", "#00235E", "#002154", "#001741"]}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextColor="#333333"
          radialLabelsLinkColor={{ from: "color" }}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor="#fff"
          legends={[
            {
              anchor: "bottom-left",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: marginBottom,
              itemHeight: 20,
              itemsSpacing: 5,
              symbolSize: 20,
              itemDirection: "left-to-right",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

export default PieChart;
