import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import { ReactComponent as QuestionDatabaseIcon } from '../../../app/assets/img/database_questions_icon.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import moment from 'moment-timezone';

import { CompanyActions, QuestionDatabaseActions } from '../../../app/redux/actions';
import { AuthSelectors, CompanySelectors, LoadingSelectors, QuestionDatabaseSelectors } from '../../../app/redux/reducers';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { getInitials, parsePhone, removeSpecialChars } from '../../../app/utils/string';
import { getPhoneNumber } from '../../../app/utils/masks';
import { UserPlanStatusArray } from '../../../app/enum/userStatusType';
import {QuestionDatabaseOptionsArray} from '../../../app/enum/questionDatabaseOptions';
import { returnPlanName } from '../../../app/utils/returnPlanName';

const { Panel } = Collapse;

class ReleaseNotes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      params: {
        pageSize: 10,
        page: 1,
        orderBy: 'createdAt',
        isDESC: false,
        planOption: '',
        userName: '',
        companyId: '',
        expirationStatus: '',
        email: '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { params } = this.state;
    const { getCompanySelective } = this.props;

    getCompanySelective();
    this.getQuestionDatabase(params)
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getQuestionDatabase({ ...params, offset: 0, page: 1});
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      userName: '',
      companyId: '',
      expirationStatus: '',
      offset: 0,
      page: 1,
      pageSize: 10,
      planOption: '',
      email: '',
    };

    this.getQuestionDatabase(cleanParams);
  };

  getColorDiv = (value) => {
    const atualDate = moment();
    const expiryDate = moment(value);
    const diff = moment(expiryDate).diff(atualDate, 'days', true);

    if (!value) {
      return '--'
    }

    if (diff < 0) {
      return (
        <div className='expiredPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    if (diff >= 0 && diff < 16) {
      return (
        <div className='fifteenDaysPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    return <div className='planOk'>{expiryDate.format('DD/MM/YYYY')}</div>;
  };


  getQuestionDatabase = async (params) => {
    const { getQuestionDatabasePaginated } = this.props;

    this.setState({ params });
    getQuestionDatabasePaginated(params);
  };

  removeUserQuestionDatabase = (id) => {
    const { removeUserQuestionDatabase } = this.props
    removeUserQuestionDatabase(id, () => {
      message.success(I18n.t('routes.panel.questionDatabase.messages.success.deleted'));
      this.dataTableRef.current.reset();
    });
  }

  returnUserId = (id) => {
    const { questionDatabasePaginated } = this.props;
    const userQuestion = questionDatabasePaginated.rows.filter((userQuestionDatabase) => userQuestionDatabase.id === id)
    const userId = userQuestion[0].user.id

    return userId
  }

  userTable = () => {
    const { params } = this.state;
    const { questionDatabasePaginated, loading } = this.props;

    return (
      <div>
        <DataTable
          notGetOnStart
          getMethod={(parameters) => this.getQuestionDatabase(parameters, true)}
          data={questionDatabasePaginated}
          loading={loading > 0}
          ref={this.dataTableRef}
          showSizeChanger
          pageSizeOptions={
            questionDatabasePaginated && questionDatabasePaginated.count && questionDatabasePaginated.count > 100
              ? [ '10', '50', '100', questionDatabasePaginated.count ]
              : [ '10', '20', '50', '100' ]
          }
          params={params}
          columns={[
            {
              width: '50px',
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.photoUrl.key'),
              render: (value, row) =>
                (value && value.photoUrl ? (
                  <Avatar src={value.photoUrl} />
                ) : (
                  <Avatar>{getInitials(row.user.name)}</Avatar>
                )),
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.userName.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.userName.title'),
              render: (value) => value && value.name || '--',
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.userEmail.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.userEmail.title'),
              render: (value) => value && value.email || '--',
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.company.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.company.title'),
              render: (value) => (value.id ? value.name : '--'),
            },
            {
              width: '150px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.cellphone.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.cellphone.title'),
              render: (value) =>
                (value && value.cellphone && getPhoneNumber(value.cellphone) ? (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${removeSpecialChars(
                      parsePhone(value.cellphone),
                    )}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {getPhoneNumber(value.cellphone)}{' '}
                  </a>
                ) : (
                  '--'
                ))
            },
            {
              width: '120px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.planExpiry.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.planExpiry.title'),
              render: (value) => value && this.getColorDiv(value) || '--',
            },
            {
              width: '100px',
              ellipsis: true,
              key: I18n.t('routes.panel.questionDatabase.dataTable.columns.planOption.key'),
              title: I18n.t('routes.panel.questionDatabase.dataTable.columns.planOption.title'),
              render: (value) => returnPlanName(value) || '--',
            },
            {
              width: '140px',
              key: I18n.t('routes.panel.users.dataTable.columns.actions.key'),
              title: '',
              render: (id) => (
                <div className='dataTable__item--right'>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToDetailsText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<IdcardOutlined />}
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.userInfo.url')}${this.returnUserId(id)}`)
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToEditText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `${I18n.t('routes.panel.userDetails.url')}${this.returnUserId(id)}`,
                        )
                      }
                    />
                  </Tooltip>
                  <Popconfirm
                    placement='left'
                    title={I18n.t('shared.confirmTitle')}
                    onConfirm={() => this.removeUserQuestionDatabase(id)}
                    okText={I18n.t('shared.yes')}
                    cancelText={I18n.t('shared.no')}
                  >
                    <Tooltip
                      title={I18n.t(
                        'routes.panel.questionDatabase.unlinkUser',
                      )}
                      overlayStyle={{ fontSize: 11 }}
                    >
                      <Button type='link'
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  };

  render () {
    const { Content } = Layout;
    const { params } = this.state;
    const { companySelectives } = this.props;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.questionDatabase.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.userName}
                      onChange={(val) => this.fieldChange('userName', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.questionDatabase.dataTable.filters.userName')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={companySelectives}
                      label={I18n.t('shared.advancedFilters.form.company')}
                      value={params && params.companyId}
                      onChange={(val) => this.fieldChange('companyId', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={UserPlanStatusArray}
                      label={I18n.t('shared.advancedFilters.form.planStatus')}
                      value={params.expirationStatus}
                      onChange={(val) =>
                        this.fieldChange('expirationStatus', val)
                      }
                      colorDots
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={QuestionDatabaseOptionsArray}
                      label={I18n.t('routes.panel.questionDatabase.assignDetails.form.planOption')}
                      value={params.planOption}
                      onChange={(val) =>
                        this.fieldChange('planOption', val)
                      }
                      colorDots
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.questionDatabase.dataTable.filters.email')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuestionDatabaseIcon} />
                  </span>
                  {I18n.t('routes.panel.questionDatabase.title')}
                </h3>
              </Col>
              <Col className='text-right justify-content-between'>
                <Divider type='vertical' />
                <AdvancedButton
                  text={I18n.t('routes.panel.questionDatabase.assign')}
                  href={I18n.t('routes.panel.questionDatabase.questionDatabaseAssign.url')}
                />
              </Col>
            </Row>
            <Divider />
            {this.userTable()}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  questionDatabasePaginated: QuestionDatabaseSelectors.getQuestionDatabasePaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getQuestionDatabasePaginated: (params) => dispatch(QuestionDatabaseActions.getQuestionDatabasePaginated(params)),
  getCompanySelective: () => dispatch(CompanyActions.getCompanySelectives()),
  removeUserQuestionDatabase: (id, callback) => dispatch(QuestionDatabaseActions.removeUserQuestionDatabase(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
