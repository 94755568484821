import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import { ReactComponent as NotesIcon } from '../../../app/assets/img/notes_icon.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedInput from '../../../components/shared/AdvancedInput';

import { ReleaseNotesActions } from '../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, ReleaseNotesSelectors } from '../../../app/redux/reducers';

const { Panel } = Collapse;

class ReleaseNotes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      params: {
        pageSize: 10,
        page: 1,
        orderBy: 'createdAt',
        isDESC: true,
        title: '',
        version: '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { params } = this.state;

    this.getReleaseNotes(params)
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getReleaseNotes({ ...params, offset: 0, page: 1});
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      title: '',
      version: '',
      offset: 0,
      page: 1,
      pageSize: 10
    };

    this.getReleaseNotes(cleanParams);
  };


  getReleaseNotes = async (params) => {
    const { getReleaseNotePaginated } = this.props;

    this.setState({ params });
    getReleaseNotePaginated(params);
  };

  removeNote = (id) => {
    const { removeReleaseNote } = this.props
    removeReleaseNote(id, () => {
      message.success(I18n.t('routes.panel.releaseNotes.messages.success.deleted'));
      this.dataTableRef.current.reset();
    });
  }

  userTable = () => {
    const { params } = this.state;
    const { notesPaginated, loading } = this.props;

    return (
      <div>
        <DataTable
          notGetOnStart
          getMethod={(parameters) => this.getReleaseNotes(parameters, true)}
          data={notesPaginated}
          loading={loading > 0}
          ref={this.dataTableRef}
          showSizeChanger
          pageSizeOptions={
            notesPaginated && notesPaginated.count && notesPaginated.count > 100
              ? [ '10', '50', '100', notesPaginated.count ]
              : [ '10', '20', '50', '100' ]
          }
          params={params}
          columns={[
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.releaseNotes.dataTable.columns.title.key'),
              title: I18n.t('routes.panel.releaseNotes.dataTable.columns.title.title'),
              render: (value) => value || '--',
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.releaseNotes.dataTable.columns.version.key'),
              title: I18n.t('routes.panel.releaseNotes.dataTable.columns.version.title'),
              render: (value) => value || '--',
            },
            {
              width: '150px',
              ellipsis: true,
              key: I18n.t('routes.panel.releaseNotes.dataTable.columns.description.key'),
              title: I18n.t('routes.panel.releaseNotes.dataTable.columns.description.title'),
              render: (value) => value || '--',
            },
            {
              width: '140px',
              key: I18n.t('routes.panel.users.dataTable.columns.actions.key'),
              title: '',
              render: (id) => (
                <div className='dataTable__item--right'>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToDetailsText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<IdcardOutlined />}
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.releaseNotes.noteDetails.url')}${id}`)
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToEditText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `${I18n.t('routes.panel.releaseNotes.url')}${id}`,
                        )
                      }
                    />
                  </Tooltip>
                  {([
                    ProfileType.ADMIN,
                    ProfileType.QUIZER,
                    ProfileType.TEACHER,
                    ProfileType.UPLOADER,
                  ].includes(this.props.me?.profileType)) && (
                    <Popconfirm
                      placement='left'
                      title={I18n.t('shared.confirmTitle')}
                      onConfirm={() => this.removeNote(id)}
                      okText={I18n.t('shared.yes')}
                      cancelText={I18n.t('shared.no')}
                    >
                      <Tooltip
                        title={I18n.t(
                          'routes.panel.users.dataTable.columns.actions.removeText',
                        )}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button type='link'
                          icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  };

  render () {
    const { Content } = Layout;
    const { params } = this.state;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.releaseNotes.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.releaseNotes.dataTable.filters.title')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.version}
                      onChange={(val) => this.fieldChange('version', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('routes.panel.releaseNotes.dataTable.filters.version')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={NotesIcon} />
                  </span>
                  {I18n.t('routes.panel.releaseNotes.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right justify-content-between'>
                <Divider type='vertical' />
                <AdvancedButton
                  text={I18n.t('routes.panel.releaseNotes.dataTable.addNew')}
                  href={`${I18n.t('routes.panel.releaseNotes.url')}add`}
                />
              </Col>
            </Row>
            <Divider />
            {this.userTable()}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  notesPaginated: ReleaseNotesSelectors.getReleaseNotesPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getReleaseNotePaginated: (params) => dispatch(ReleaseNotesActions.getReleaseNotePaginated(params)),
  removeReleaseNote: (id, callback) => dispatch(ReleaseNotesActions.removeReleaseNote(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseNotes);
