import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import QuestionDatabaseRequests from '../../api/questionDatabase';

export const ACTION_SAVE_QUESTION_DATABASE_PAGINATED = 'ACTION_SAVE_QUESTION_DATABASE_PAGINATED';
export const ACTION_SAVE_USERS_QUESTION_DATABASE = 'ACTION_SAVE_USERS_QUESTION_DATABASE';
export const ACTION_SAVE_CORRECTIONS_QUESTION_DATABASE = 'ACTION_SAVE_CORRECTIONS_QUESTION_DATABASE';
export const ACTION_SAVE_QUESTION_DATABASE_FILTERS = 'ACTION_SAVE_QUESTION_DATABASE_FILTERS';


export const getUsersQuestionDatabase = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const usersPaginated = await QuestionDatabaseRequests.getUsersQuestionDatabase(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_QUESTION_DATABASE,
      payload: usersPaginated,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.request'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuestionDatabasePaginated = (params) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const payload = await QuestionDatabaseRequests.getQuestionDatabasePaginated(params);

    dispatch({
      type: ACTION_SAVE_QUESTION_DATABASE_PAGINATED,
      payload
    });
    return payload;
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.request'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuestionDatabaseCorrectionsPaginated = (params) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const payload = await QuestionDatabaseRequests.getQuestionDatabaseCorrectionsPaginated(params);

    dispatch({
      type: ACTION_SAVE_CORRECTIONS_QUESTION_DATABASE,
      payload
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.requestCorrections'));
  } finally {
    dispatch(removeLoading());
  }
};

export const fixQuestion = (id, status, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuestionDatabaseRequests.fixQuestion(id, status);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.errorCorrectQuestion'));
  } finally {
    dispatch(removeLoading());
  }
};


export const assignUserQuestionDatabase = (params, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuestionDatabaseRequests.create(params);

    if (callback && typeof callback === 'function') {
      callback();
    }

    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.delete'));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeUserQuestionDatabase = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuestionDatabaseRequests.delete(id);

    if (callback && typeof callback === 'function') {
      callback();
    }

    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.questionDatabase.messages.error.delete'));
  } finally {
    dispatch(removeLoading());
  }
};

export const setUsersAttributionFilters = (filters) => async (dispatch) => {
  dispatch({
    type: ACTION_SAVE_QUESTION_DATABASE_FILTERS,
    payload: filters
  });
};