import getInstance from './config';

export default class UserApi {
  static async getUsersPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async getAllUser (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/all', { params });
    return data;
  }

  static async getUsersKanban (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/kanban', { params });
    return data;
  }

  static async getUserDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}`);
    return data;
  }

  static async update (id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/${id}`, userData);
    return data;
  }

  static async createDaily (userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/daily', userData);
    return data;
  }

  static async createAdmin (userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/user/admin', userData);
    return data;
  }

  static async updateAdmin (id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user/admin/${id}`, userData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/user/${id}`);
    return data;
  }

  static async getUserSelectives (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/selectives', { params });
    return data;
  }
}
