import React from 'react';
import {
  Button,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ReactComponent as Arrow } from '../../app/assets/img/down_arrow.svg';

const StatisticCard = (
  {
    percentageValue,
    title,
    onClick,
    style,
    noAction,
    disabled
  },
) => (
  <Button
    className="statistic-card"
    style={{
      width: '100%', whiteSpace: 'normal', backgroundColor: '#f5f5f5', border: 'none', ...style,
    }}
    onClick={onClick || undefined}
    disabled={disabled}
  >
    <div
      className="row"
    >
      <div className="col-3 d-flex justify-content-center align-items-center">
        <Arrow
          width={30}
          height={40}
        />
      </div>
      <div className="col-6 d-flex flex-column">
        <div className="text-left">
          <span style={{ fontSize: 18 }}>{percentageValue}</span>
        </div>
        <div className="text-left">
          <span style={{ fontWeight: 'bold', fontSize: 14 }}>{title}</span>
        </div>
      </div>
      {!noAction && (
      <div className="col-3 d-flex align-items-center justify-content-center">
        <RightOutlined style={{ color: '#0084c8' }} />
      </div>
      )}
    </div>

  </Button>
);

export default StatisticCard;
