import React from 'react'
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';

function ChatFileBar (props) {

  const isImage = (file) => {
    let isImage = false;

    if(
      file === 'image/jpeg' ||
      file === 'image/png' ||
      file === 'application/pdf'
    ) { isImage = true }

    return isImage
  }

  const isAudio = (file) => {
    const isAudio = file.includes('audio/', 0)
    return isAudio
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 45, color: 'white', marginBottom: '15px' }}
    spin />

  return (
    <div className="chat__file-bar">
      <div className="chat__file-bar__container">
        {props.isLoading ? (
          <div className="chat__panel__container__loading">
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <>
            <div className="chat__file-bar__container__top">
              {props.file.type && isImage(props.file.type)  &&
                <img className="chat__file-bar__container__image"
                  src={props.file.base64} />
              }
              {props.file.type && isAudio(props.file.type)  &&
                <audio className="chat__file-bar__container__image"
                  controls="controls"
                  autobuffer="autobuffer">
                  <source src={props.file.base64} />
                </audio>
              }
              {props.file.type && !isAudio(props.file.type) && !isImage(props.file.type)  &&
                <video className="chat__file-bar__container__image"
                  controls
                  src={props.file.base64}>
                </video>
              }
              {props.file.name && props.file.name &&
                <p>
                  <br/>
                  <strong>Deseja mesmo enviar o arquivo: {props.file.name}?</strong>
                </p>
              }
            </div>
            <div className="chat__file-bar__container__buttons">
              <div onClick={() => props.onClose()}
                className="chat__file-bar__container__buttons__close">
                <CloseOutlined />
              </div>
              <div onClick={() => props.onSend()}
                className="chat__file-bar__container__buttons__send">
                <CheckOutlined />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChatFileBar