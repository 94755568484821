import getInstance from './config';

export default class QuizApi {
  static async getQuestionsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/question', { params });
    const  dificulty  = await instance.get('/question/count/by-difficulty', { params });
    return { ...data, dificulties: dificulty.data};
  }

  static async getAllQuestions (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/question/all', { params });
    return data
  }

  static async getQuestionById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/question/${id}`);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/question/${id}`);
    return data;
  }

  static async removeQuiz (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/quiz/${id}`);
    return data;
  }

  static async createQuiz (quiz) {
    const instance = await getInstance();
    const { data } = await instance.post('/quiz', quiz);
    return data;
  }

  static async calculateQuiz (quiz) {
    const instance = await getInstance();
    const {data} = await instance.post('/user-quiz/calculate', quiz)
    return data;
  }

  static async getQuizzesPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/quiz', { params });
    return data;
  }

  static async createQuestion (questionData) {
    const instance = await getInstance();
    const { data } = await instance.post('/question', questionData);
    return data;
  }

  static async createQuestionAlternative (alternative) {
    const instance = await getInstance();
    const { data } = await instance.post('/question-answer', alternative);
    return data;
  }

  static async getUrlMedia (name) {
    const instance = await getInstance();
    const { data } = await instance.get(`/utils/secure-url?fileName=${name}`);
    return data;
  }

  static async getStatistics (id, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user-quiz?quizId=${id}`, { params });
    return data;
  }

  static async getStatisticsById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user-quiz/${id}`);
    return data;
  }

  static async getStatisticsRanking (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user-quiz/statistics', { params });
    return data;
  }

  static async updateQuestion (id, question) {
    const instance = await getInstance();
    const { data } = await instance.put(`/question/${id}`, question);
    return data;
  }

  static async updateQuiz (id, quiz) {
    const instance = await getInstance();
    const { data } = await instance.put(`/quiz/${id}`, quiz);
    return data;
  }

  static async getQuizById (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/quiz/${id}`);
    const  hasResponses  = await instance.get(`/quiz/${id}/verify-quiz-has-response`);
    return {...data, hasResponses: hasResponses.data};
  }

  static async exportQuiz () {
    const instance = await getInstance();
    const { data } = await instance.get('/quiz/export');
    return data;
  }

  static async exportQuestion () {
    const instance = await getInstance();
    const { data } = await instance.get('/question/export');
    return data;
  }

  static async exportQuizStatistics (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user-quiz/export?quizId=${id}`);
    return data;
  }


  static async updateQuizStatistics (id, quiz) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user-question-answer/${id}`, quiz);
    return data;
  }

  static async getUserAnswers (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user-quiz/${id}`);
    return data;
  }

  static async finishQuiz (id, correction) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user-quiz/${id}`, correction);
    return data;
  }

  static async sendCorrections (corrections) {
    const instance = await getInstance();
    const { data } = await instance.put('/user-question-answer/answers', corrections);
    return data;
  }

  static async getQuizSelectives (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/quiz/all', { params });
    return data;
  }

  static async getUsersQuizes (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/quiz', { params });
    return data;
  }
}
