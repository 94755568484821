import React from 'react';
import {
  Input,
} from 'antd';
import InputMask from 'react-input-mask';

const AdvancedMaskedInput = (
  {
    label,
    placeholder,
    onChange,
    type,
    value,
    disabled,
    mask,
  },
) => (
  <div className="advanced-masked-input">
    <label className="advanced-masked-input__label">
      <span className="advanced-masked-input__label__inner">{label}</span>
      <InputMask
        mask={mask}
        value={value}
        onChange={(ev) => onChange(ev.currentTarget.value)}
        disabled={disabled || false}
      >
        {(inputProps) => (
          <Input
            {...inputProps}
            type={type || 'text'}
            placeholder={placeholder}
          />
        )}
      </InputMask>

    </label>
  </div>
);

export default AdvancedMaskedInput;
