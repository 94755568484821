export const HospitalType = {
  PUBLIC: 1,
  PRIVATE: 2,
  SEMI_PUBLIC: 3,
};

export const HospitalTypeArray = [
  { id: HospitalType.PUBLIC, name: 'Público' },
  { id: HospitalType.PRIVATE, name: 'Privado' },
  { id: HospitalType.SEMI_PUBLIC, name: 'Capital Misto' },
];
