import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import {
  FilterActions,
  PushNotificationActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  FilterSelector,
  LoadingSelectors,
  PushNotificationSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as PushNotificationIcon } from '../../../app/assets/img/configuration_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import * as DateUtils from '../../../app/utils/date';
import { ProfileType } from '../../../app/enum/profileType';
import { cleanPagination } from '../../../app/utils/pagination';

const { Panel } = Collapse;

class PushNotifications extends React.Component {
  constructor (props) {
    super(props);
    const { notificationFilters } = props;
    const { notificationListFilters } = notificationFilters;
    this.state = {
      params: {
        ...cleanPagination,
        ...notificationListFilters,
        title: notificationListFilters?.title ?? '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount () {
    this.getPushNotifications(this.state.params)
  }

  getPushNotifications = (params, isFromDataTable, isReset) => {
    const { me, notificationFilters, getPushNotificationsPaginated } =
      this.props;
    const { notificationListFilters } = notificationFilters;

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...notificationListFilters,
        ...params
      };
    }

    this.setState({ params: { ...params } });
    getPushNotificationsPaginated(params, me);
    this.props.setNotificationFilters(params);
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.props.setNotificationFilters({ ...params, ...cleanPagination });
    this.getPushNotifications({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      title: '',
      ...cleanPagination
    };
    this.props.setNotificationFilters(null);
    this.getPushNotifications(cleanParams, true, true);
  };

  removePushNotification (id) {
    this.props.removePushNotification(id, () => {
      message.success(I18n.t('routes.panel.pushNotifications.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render () {
    const { Content } = Layout;
    const { params } = this.state;
    const { pushNotificationsPaginated, loading } = this.props;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pushNotifications.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.title')}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='pushNotifications'>
            <Row>
              <Col span={12}>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={PushNotificationIcon} />
                  </span>
                  {I18n.t('routes.panel.pushNotifications.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right'
                span={12}>
                <AdvancedButton
                  text={I18n.t(
                    'routes.panel.pushNotifications.addNewButtonText',
                  )}
                  href={`${I18n.t(
                    'routes.panel.pushNotificationDetails.url',
                  )}add`}
                />
              </Col>
            </Row>

            <Divider />

            <DataTable
              notGetOnStart
              getMethod={(parameters) =>
                this.getPushNotifications(parameters, true)
              }
              data={pushNotificationsPaginated}
              loading={loading > 0}
              showSizeChanger
              pageSizeOptions={
                pushNotificationsPaginated &&
                  pushNotificationsPaginated.count &&
                  pushNotificationsPaginated.count > 100
                  ? [ '10', '50', '100', pushNotificationsPaginated.count ]
                  : [ '10', '20', '50', '100' ]
              }
              params={params}
              ref={this.dataTableRef}
              columns={[
                {
                  ellipsis: true,
                  key: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.title.key',
                  ),
                  title: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.title.title',
                  ),
                  render: (value) => value || '--',
                },
                {
                  width: '100px',
                  key: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.userCount.key',
                  ),
                  title: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.userCount.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>
                      {value || '--'}
                    </div>
                  ),
                },
                {
                  width: '130px',
                  key: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.scheduleDate.key',
                  ),
                  title: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.scheduleDate.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>
                      {DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')}
                    </div>
                  ),
                },
                {
                  width: '120px',
                  ellipsis: true,
                  key: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.createdAt.key',
                  ),
                  title: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.createdAt.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>
                      {DateUtils.humanizeDateTime(value, 'DD/MM/YYYY')}
                    </div>
                  ),
                },
                {
                  width: '80px',
                  key: I18n.t(
                    'routes.panel.pushNotifications.dataTable.columns.actions.key',
                  ),
                  title: '',
                  render: (id) => (
                    <div className='dataTable__item--right'>
                      {this.props.me?.profileType !== ProfileType.OPERATOR && (
                        <>
                          <Tooltip
                            title={I18n.t(
                              'routes.panel.pushNotifications.dataTable.columns.actions.goToDetailsText',
                            )}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type='link'
                              icon={<EditOutlined />}
                              onClick={() =>
                                navigate(
                                  `${I18n.t(
                                    'routes.panel.pushNotificationDetails.url',
                                  )}${id}`,
                                )
                              }
                            />
                          </Tooltip>
                          <Popconfirm
                            placement='left'
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removePushNotification(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.pushNotifications.dataTable.columns.actions.removeText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button type='link'
                                icon={<DeleteOutlined />} />
                            </Tooltip>
                          </Popconfirm>
                        </>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pushNotificationsPaginated:
    PushNotificationSelectors.getPushNotificationsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  notificationFilters: FilterSelector.getNotificationFilters(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPushNotificationsPaginated: (parameters, me) =>
    dispatch(
      PushNotificationActions.getPushNotificationsPaginated(parameters, me),
    ),
  removePushNotification: (id, callback) =>
    dispatch(PushNotificationActions.removePushNotification(id, callback)),
  setNotificationFilters: (params) =>
    dispatch(FilterActions.setNotificationFilters(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
