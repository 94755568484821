export const StudyContentType = {
  ALL: 1,
  CLINICAL_TRIAL: 2,
  CASE_REPORT: 3,
  CASE_SERIES: 4,
  REVIEW: 5,
};

export const StudyContentTypeArray = [
  { id: StudyContentType.ALL, name: 'Todos' },
  { id: StudyContentType.CLINICAL_TRIAL, name: 'Ensaio Clínico' },
  { id: StudyContentType.CASE_REPORT, name: 'Relato de Caso' },
  { id: StudyContentType.CASE_SERIES, name: 'Série de casos' },
  { id: StudyContentType.REVIEW, name: 'Revisão' },
];
