import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ContentStatus = {
  NOT_STARTED: '1',
  IN_PROGRESS: '2',
  WATCHED: '3',
  NONE: null,
}

export const ContentStatusList = [
  {
    value: ContentStatus.NOT_STARTED,
    label: <Translate value="routes.panel.userStatistics.watched.notStarted" />
  },
  {
    value: ContentStatus.IN_PROGRESS,
    label: <Translate value="routes.panel.userStatistics.watched.inProgress" />
  },
  {
    value: ContentStatus.WATCHED,
    label: <Translate value="routes.panel.userStatistics.watched.watched" />
  },
  {
    value: ContentStatus.NONE,
    label: <Translate value="routes.panel.userStatistics.watched.none" />
  }
]