import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_COURSE_DETAILS,
  ACTION_SAVE_COURSE_GRAPH,
  ACTION_SAVE_COURSE_SEARCH,
  ACTION_SAVE_COURSE_SELECTIVES,
  ACTION_SAVE_COURSES_PAGINATED,
  ACTION_SAVE_MODULES_SEARCH,
} from '../actions/course';

const initialState = Immutable({
  coursesPaginated: null,
  courseDetails: null,
  courseSearch: null,
  courseGraph: null,
  courseSelectives: null,
  modulesSearch: null,
});

export default function course (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_COURSES_PAGINATED:
      state = {
        ...state,
        coursesPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_COURSE_DETAILS:
      state = {
        ...state,
        courseDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_COURSE_SEARCH:
      state = {
        ...state,
        courseSearch: action.payload,
      };
      return state;
    case ACTION_SAVE_COURSE_GRAPH:
      state = {
        ...state,
        courseGraph: action.payload,
      };
      return state;
    case ACTION_SAVE_COURSE_SELECTIVES:
      state = {
        ...state,
        courseSelectives: action.payload,
      };
      return state;
    case ACTION_SAVE_MODULES_SEARCH:
      state = {
        ...state,
        modulesSearch: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getCoursesPaginated (state) {
  return state.course.coursesPaginated;
}

export function getCourseDetails (state) {
  return state.course.courseDetails;
}

export function searchCourses (state) {
  return state.course.courseSearch;
}

export function searchModules (state) {
  return state.course.modulesSearch;
}

export function getCourseGraph (state) {
  return state.course.courseGraph;
}

export function getCourseSelectives (state) {
  return state.course.courseSelectives;
}
