import React from 'react';
import {
  Breadcrumb,
  Col,
  Collapse,
  DatePicker, Divider, Empty, Layout, message, Radio, Row, Select, Spin
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  CompanyActions,
  ContentActions, CourseActions, FilterActions, UserActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors, CompanySelectors, ContentSelectors, CourseSelectors, FilterSelector, LoadingSelectors, UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/dashboard_icon_blue.svg';
import * as DateUtils from '../../../app/utils/date';
import { CourseType } from '../../../app/enum/courseType';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { cleanPagination } from '../../../app/utils/pagination';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import moment from 'moment-timezone';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import ContentRequests from '../../../app/api/content'
import { prepareDataToCsv } from '../../../app/utils/csv';
import { ContentStatusList } from '../../../app/enum/contentStatus';

const { Panel } = Collapse;

class Statistics extends React.Component {
  constructor (props) {
    const { statisticsFilters } = props;
    const { mainStatisticsFilters } = statisticsFilters;
    super(props);
    this.state = {
      csvData: '',
      isFinishedSelected: false,
      isNotFinishedSelected: false,
      params: {
        ...cleanPagination,
        userContentStatus: null,
        companyId: mainStatisticsFilters?.companyId,
        courseId: mainStatisticsFilters?.courseId,
        moduleId: mainStatisticsFilters?.moduleId,
        contentId: mainStatisticsFilters?.contentId,
        startDate: mainStatisticsFilters?.startDate,
        endDate: mainStatisticsFilters?.endDate,
        userId: this.props.id,
        isDESC: true,
      },
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { getUserDetails, id, companySelectives, getCompanySelective } = this.props;
    await getUserDetails(id);

    if(!companySelectives?.length) {
      getCompanySelective();
    }
  }

  csvExport = () => {
    const { contentsHistory } = this.props;

    if (contentsHistory) {
      const csvData = contentsHistory.rows && contentsHistory.rows.map((data) => (
        {
          title: data.title,
          course: this.parseCourses(data.modules),
          modules: this.parseModules(data.modules),
          viewedAt:
            (
              data.userContent
              && data.userContent.viewedAt
              && this.parseviewedAt(data.userContent.viewedAt)
            ) || '--',
        }
      ));
      this.setState({ csvData }, () => {
        if (this.state.csvData) {
          setTimeout(() => {
            this.csvLink.current.link.click();
          });
        } else {
          message.error(I18n.t('shared.awaitData'));
        }
      });
    } else {
      message.error(I18n.t('shared.awaitData'));
    }
  };


  getContents = async (params) => {
    const  newParams = {
      ...this.state.params,
      ...params,
    }
    const { me, id } = this.props;
    const { getContentPaginatedByUserId } = this.props;
    this.setState({ params: newParams });
    getContentPaginatedByUserId({ ...newParams, userId: id }, me);
  };

  searchCourses = async (val) => {
    const { searchCourses } = this.props;
    await searchCourses({ search: val, type: CourseType.COURSE });
  };

  fieldChange = (name, value, callBack) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params }, () => {
      if(callBack) callBack()
    });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.props.setStatisticsMainFilters({...this.props.mainStatisticsFilters , ...params, userContentStatus: null});
    this.getContents({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => {
    this.setState({ isFinishedSelected: false, isNotFinishedSelected: false, params: { ...cleanPagination } });
    this.getContents();
  };

  parseModules = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1 ? `${item.title}, ` : item.title))).join('')
    : '');

  parseCourses = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1 ? `${item.course.title}, ` : item.course.title))).join('')
    : '');

  parseviewedAt = (value) => (value ? DateUtils.humanizeDate(value, 'DD/MM/YYYY') : '');

  async handleCompanySelect (companyId) {
    this.fieldChange('companyId', companyId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          courseId: null,
          moduleId: null,
          contentId: null,
        }
      }))
    });
    await this.props.searchCourses({ type: CourseType.COURSE, parentId: companyId });
  }

  handleChangeSelectedCourse (courseId) {
    this.fieldChange('courseId', courseId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          moduleId: null,
          contentId: null,
        }
      }))
    })
    this.props.getModules({ parentId: courseId })
  }

  handleGetModules (value) {
    this.props.getModules({ parentId: this.state.params?.courseId, title: value })
  }

  handleSelectModule (moduleId) {
    this.fieldChange('moduleId', moduleId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          contentId: null,
        }
      }))
    })
    this.props.getContents({ moduleId })
  }

  handleGetContent (value) {
    this.props.getContents({ moduleId: this.state.params?.moduleId, title: value })
  }

  handleChangeDate = (value) => {
    const { params } = this.state;

    if (value) {
      params.startDate = value[0].startOf('day').toDate();
      params.startPostDate = value[0].startOf('day').toDate();
      params.endDate = value[1].endOf('day').toDate();
      params.endPostDate = value[1].endOf('day').toDate();
    } else {
      params.startDate = null;
      params.startPostDate = null;
      params.endDate = null;
      params.endPostDate = null;
    }

    this.setState({ params });
  };

  async exportPdfData (exportDataFromApi = false) {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.userStatistics.dataTable.columns.modules.titles.course'),
      I18n.t('routes.panel.userStatistics.dataTable.columns.modules.titles.module'),
      I18n.t('routes.panel.userStatistics.dataTable.columns.title.title'),
      I18n.t('routes.panel.userStatistics.dataTable.columns.viewedAt.title'),
      I18n.t('routes.panel.userStatistics.dataTable.columns.viewed.title')
    ]

    const generated = await generateAndDownloadPdf(
      this.props.contentsHistory.rows,
      tableHeadersToPrint,
      `${I18n.t('routes.panel.userStatistics.bottomTitle')} - ${this.props.userDetails.name}`,
      11,
      8,
      (row) => ([
        row?.content?.modules ? this.parseCourses(row?.content?.modules) : '--',
        row.content?.modules ? this.parseModules(row.content?.modules) : '--',
        row.content?.title || '--',
        row.viewedAt ? DateUtils.humanizeDate(row.viewedAt, 'DD/MM/YYYY') : '--',
        row.contentProgress ? `${row.contentProgress}%` : '--'
      ]),
      exportDataFromApi,
      this.props.contentsHistory?.count,
      ContentRequests.getUserContentPaginated,
      this.state.params,
      I18n.t('routes.panel.userStatistics.pdfFileName')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.contentsHistory?.rows,
      (row) => ({
        courses: row?.content?.modules ? this.parseCourses(row?.content?.modules) : '--',
        modules: row.content?.modules ? this.parseModules(row.content?.modules) : '--',
        title: row.content?.title || '--',
        viewedAt: row.viewedAt ? DateUtils.humanizeDate(row.viewedAt, 'DD/MM/YYYY') : '--',
        contentProgress: row.contentProgress ? `${row.contentProgress}%` : '--'
      }),
      exportAllDataFromApi,
      this.props.contentsHistory?.count,
      ContentRequests.getUserContentPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  render () {
    const { RangePicker } = DatePicker;
    const { Content } = Layout;
    const { Option } = Select;
    const {
      params,
    } = this.state;
    const {
      contentsHistory,
      loading,
      userDetails,
      me,
      companySelectives, contentSearched, searchedModules, searchedCourses
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.userStatistics.breadcrumb')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.userStatistics.bottomTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={companySelectives}
                      label={I18n.t('shared.advancedFilters.form.company')}
                      value={params?.companyId}
                      onChange={(val) => this.handleCompanySelect(val)}
                      disableSearch
                    />
                  </Col>
                </Row>
                <Row gutter={15}
                  className='select-relation-container'>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.course')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.courseId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.searchCourses(val)}
                      onChange={(val) => this.handleChangeSelectedCourse(val)}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId}
                    >
                      {searchedCourses &&
                        searchedCourses.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.module')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.moduleId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.handleGetModules(val)}
                      onChange={(val) => this.handleSelectModule(val) }
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId || !params?.courseId}
                    >
                      {searchedModules &&
                        searchedModules.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.content')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.contentId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.handleGetContent(val)}
                      onChange={(val) =>
                        this.setState({ params: { ...params, contentId: val } })
                      }
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId || !params?.courseId || !params?.moduleId}
                    >
                      {contentSearched &&
                        contentSearched.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <div style={{ paddingBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.postDate')}
                    </div>
                    <RangePicker
                      value={
                        params && params.startDate && params.endDate
                          ? [ moment(params.startDate), moment(params.endDate) ]
                          : []
                      }
                      placeholder={[
                        I18n.t('shared.advancedFilters.form.startDate'),
                        I18n.t('shared.advancedFilters.form.endDate'),
                      ]}
                      format='DD/MM/YYYY'
                      onChange={(val) => this.handleChangeDate(val)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('routes.panel.userStatistics.watched.title')}
                    </div>
                    <Radio.Group
                      onChange={(e) => this.fieldChange('userContentStatus', e.target.value)}
                      value={params?.userContentStatus}
                    >
                      {ContentStatusList.map(statusItem => (
                        <Radio
                          key={statusItem.value}
                          value={statusItem.value}>{statusItem.label}</Radio>
                      ))}
                    </Radio.Group>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <Row className="statistics justify-content-between">
            <Col>
              <Row>
                <Col>
                  <h3>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon component={UserIcon} />
                    </span>
                    {I18n.t('routes.panel.userStatistics.bottomTitle')}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>
                    {I18n.t('routes.panel.userStatistics.welcome')},
                    <strong>{this.props.me && this.props.me.name}!</strong>
                  </h6>
                  <h6>
                    {I18n.t('routes.panel.userStatistics.progress')}
                  </h6>
                </Col>
              </Row>
              <Row className='justify-content-between'>
                <Col>
                  <h6>
                    {I18n.t('routes.panel.userStatistics.userTitle')}: <strong>{userDetails && userDetails.name}</strong>
                  </h6>
                </Col>
              </Row>
            </Col>

            <Col className='export-buttons-container'>
              {([
                ProfileType.ADMIN,
              ].includes(me?.profileType)) && (
                <>
                  <AdvancedButton
                    icon={<FilePdfOutlined />}
                    text={I18n.t('shared.exportPdfButton')}
                    onClick={() => this.exportPdfData()}
                    disabled={loading > 0}
                  />
                  <Divider type='vertical' />

                  <AdvancedButton
                    icon={<FilePdfOutlined />}
                    text={I18n.t('shared.exportAllPdfButton')}
                    onClick={() => this.exportPdfData(true)}
                    disabled={this.state.exportingPfdDataLoading || loading > 0}
                    loading={this.state.exportingPfdDataLoading}
                  />
                  <Divider type='vertical' />

                  <AdvancedButton
                    icon={<AppstoreOutlined />}
                    text={I18n.t('shared.exportCsvButtonText')}
                    onClick={() => this.csvExport()}
                    disabled={this.state.exportingDataLoading || loading > 0}
                  />

                  <Divider type='vertical' />
                  <AdvancedButton
                    icon={<AppstoreOutlined />}
                    text={I18n.t('shared.exportAllCsvButtonText')}
                    onClick={() => this.csvExport(true)}
                    disabled={this.state.exportingDataLoading || loading > 0}
                    loading={this.state.exportingDataLoading}
                  />

                  <CSVLink
                    data={this.state.csvData}
                    filename={I18n.t('routes.panel.userStatistics.csvFileName')}
                    ref={this.csvLink}
                    style={{ display: 'none' }}
                  >
                    {I18n.t('shared.exportCsvButtonText')}
                  </CSVLink>
                </>
              )}
            </Col>

            <Divider />

            <DataTable
              params={params}
              getMethod={(parameters) => this.getContents(parameters)}
              data={contentsHistory}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              pageSizeOptions={contentsHistory && contentsHistory.count && contentsHistory.count > 100 ? [ '10', '50', '100', contentsHistory.count.toString() ] : [ '10', '20', '50', '100' ]}
              columns={
                [
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.userStatistics.dataTable.columns.modules.key'),
                    title: I18n.t('routes.panel.userStatistics.dataTable.columns.modules.titles.course'),
                    render: (value) => (value && this.parseCourses(value?.modules)) || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.userStatistics.dataTable.columns.modules.key'),
                    title: I18n.t('routes.panel.userStatistics.dataTable.columns.modules.titles.module'),
                    render: (value) => (value && this.parseModules(value?.modules)) || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.userStatistics.dataTable.columns.title.key'),
                    title: I18n.t('routes.panel.userStatistics.dataTable.columns.title.title'),
                    render: (value) => value?.title || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.userStatistics.dataTable.columns.viewedAt.key'),
                    title: I18n.t('routes.panel.userStatistics.dataTable.columns.viewedAt.title'),
                    render: (value) => (value && DateUtils.humanizeDate(value, 'DD/MM/YYYY')) || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.userStatistics.dataTable.columns.viewed.key'),
                    title: I18n.t('routes.panel.userStatistics.dataTable.columns.viewed.title'),
                    render: (value) => (value ? `${value}%` : '--'),
                  },
                ]
              }
            />
          </Row>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentsHistory: ContentSelectors.getContentPaginatedByUserId(state),
  me: AuthSelectors.getMe(state),
  loading: LoadingSelectors.getLoading(state),
  userDetails: UserSelectors.getUserDetails(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  searchedModules: CourseSelectors.searchModules(state),
  contentSearched: ContentSelectors.getContentSearched(state),
  statisticsFilters: FilterSelector.getStatisticsFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentPaginatedByUserId: (parameters) => dispatch(ContentActions.getContentPaginatedByUserId(parameters)),
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  getContents: (parameters) => dispatch(ContentActions.searchContents(parameters)),
  getModules: (parameters) => dispatch(CourseActions.searchModules(parameters)),
  getCompanySelective: () => dispatch(CompanyActions.getCompanySelectives()),
  setStatisticsMainFilters: (params) => dispatch(FilterActions.setStatisticsMainFilters(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Statistics,
);
