/* eslint-disable max-len */
import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  PieChartOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  CategoryActions,
  ContentActions,
  CourseActions,
  FilterActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CategorySelectors,
  ContentSelectors,
  CourseSelectors,
  FilterSelector,
  LoadingSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as ContentIcon } from '../../../app/assets/img/content_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../app/utils/date';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ProfileType } from '../../../app/enum/profileType';
import { CourseType } from '../../../app/enum/courseType';
import { cleanPagination } from '../../../app/utils/pagination';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import ContentApi from '../../../app/api/content';
import { prepareDataToCsv } from '../../../app/utils/csv';
import { showCompanies } from '../../../app/utils/showCompanies';


const { Panel } = Collapse;

function compare (a, b) {
  if (a.title < b.title) {
    return -1;
  }

  if (a.title > b.title) {
    return 1;
  }

  return 0;
}

class Contents extends React.Component {
  constructor (props) {
    super(props);
    const { coursesFilters } = props;
    const { contentsFilter } = coursesFilters;
    this.state = {
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      course: null,
      optionsPdf: {
        orientation: 'landscape',
      },
      module: null,
      courseOptions: [],
      moduleOptions: [],
      enableModuleOptions: false,
      csvData: '',
      params: {
        isDESC: true,
        ...cleanPagination,
        ...contentsFilter,
        title: contentsFilter?.title ?? null,
        isEnabled: contentsFilter?.isEnabled ?? null,
        startPostDate: contentsFilter?.startPostDate ?? null,
        endPostDate: contentsFilter?.endPostDate ?? null,
        moduleId: contentsFilter?.moduleId ?? null,
        categoryId: contentsFilter?.categoryId ?? null,
        courseId: contentsFilter?.courseId ?? null,
      },
      company: '',
      companyList: [],
    };
    this.pdfLink = React.createRef();
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { contentsFilter } = this.props.coursesFilters;
    await this.props.getCourseSelectives();
    await this.props.searchCourses({ type: CourseType.COURSE });
    this.getCompanyList()
    this.setState({
      courseOptions: this.props.searchedCourses,
      params: {
        ...this.state.params,
        ...contentsFilter,
      },
    });
  }

  csvExport = async (exportAllDataFromApi) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.contentsPaginated?.rows,
      (row) => ({
        title: row.title,
        course: this.parseCourses(row.modules),
        modules: this.parseModules(row.modules),
        postDate: this.parsePostDate(row.modules),
        isEnabled: row.isEnabled,
      }),
      exportAllDataFromApi,
      this.props.contentsPaginated?.count,
      ContentApi.getContentsPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  async exportPdfData (exportAllDataFromApi) {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.contents.dataTable.columns.title.title'),
      I18n.t('routes.panel.contents.dataTable.columns.modules.titles.course'),
      I18n.t('routes.panel.contents.dataTable.columns.modules.titles.module'),
      I18n.t('routes.panel.contents.dataTable.columns.isEnabled.title'),
      I18n.t('routes.panel.contents.dataTable.columns.modules.titles.postDate'),
      I18n.t('routes.panel.contents.dataTable.columns.createdAt.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.contentsPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.contents.sidebarTitle'),
      12,
      12,
      (row) => ([
        row.title || '--',
        row.modules.length ? this.parseCourses(row.modules) : '--',
        row.modules.length ? this.parseModules(row.modules) : '--',
        row.isEnabled ? I18n.t('shared.affirmative.yes') : I18n.t('shared.affirmative.no'),
        row.modules.length > 0 ? this.parsePostDate(row.modules) : '--',
        row.createdAt ? DateUtils.humanizeDateTime(row.createdAt, 'DD/MM/YYYY HH:mm') : '--',
      ]),
      exportAllDataFromApi,
      this.props.contentsPaginated?.count,
      ContentApi.getContentsPaginated,
      this.state.params,
      I18n.t('routes.panel.contents.sidebarTitle')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  updateContent = (id, data) => {
    try {
      data = {
        ...data,
      };
      this.props.updateContent(id, data, () => {
        message.success(I18n.t('routes.panel.contentDetails.messages.success'));
        this.getContents(this.state.params)
      });
    } catch (e) {
      message.error(
        I18n.t('routes.panel.contentDetails.messages.errors.generic'),
      );
    }
  };

  getContents = async (params, isFromDataTable, isReset) => {
    const { me, id, getModuleDetails, getCategorySelective, coursesFilters } =
      this.props;
    const { contentsFilter } = coursesFilters;

    if (id) {
      await getModuleDetails(id);
      getCategorySelective();
      this.fieldChange('moduleId', id);

      this.setState({ module: this.props.moduleDetails }, async () => {
        await getModuleDetails(this.state.module.parentId);
        this.setState({ course: this.props.moduleDetails });
      });
      params.moduleId = this.state.params.moduleId;
    }

    params = {
      ...params,
    };

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...contentsFilter,
        ...params,
      };
    }

    const { getContentsPaginated } = this.props;

    this.setState({ params  });
    getContentsPaginated(params, me);
    this.props.setCoursesContentsFilters({ ...params });
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    let { module } = this.state;

    if (name === 'courseId') {
      module = null;
      params.moduleId = null;
    }

    params[name] = value ? value.toString() : null;
    this.setState({ params, module });
  };

  handleChangeDate = (value) => {
    const { params } = this.state;

    if (value) {
      params.startPostDate = value[0].toDate();
      params.endPostDate = value[1].toDate();
    } else {
      params.startPostDate = null;
      params.endPostDate = null;
    }

    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    const { company } = this.state

    if (company) {
      params.companyId = company;
    }

    this.props.setCoursesContentsFilters({ ...params, ...cleanPagination });
    this.getContents({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => {
    this.props.setCoursesContentsFilters(null);
    this.setState({
      params: {
        title: null,
        isEnabled: null,
        startPostDate: null,
        endPostDate: null,
        moduleId: null,
        categoryId: null,
        courseId: null,
        ...cleanPagination
      },
      course: null,
      module: null,
      company: null,
    });
    this.getContents(
      {
        title: null,
        isEnabled: null,
        startPostDate: null,
        endPostDate: null,
        moduleId: null,
        categoryId: null,
        courseId: null,
        companyId: null,
        ...cleanPagination
      },
      true,
      true,
    );
  };

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  removeContent = (id) => {
    this.props.removeContent(id, () => {
      message.success(I18n.t('routes.panel.contents.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  };

  parseCategories = (categories) => {
    const theCategory = categories.find(
      (category) =>
        category && category.contentCategory && category.contentCategory.isMain,
    );

    return theCategory && theCategory.title;
  };

  parseModules = (value) =>
    (value
      ? value
        .filter(
          (item) => item && item.title
        )
        .map(
          (item, i, self) =>
            (i !== self.length - 1
              ? `${item.title}, `
              : item.title),
        )
        .join('')
      : '');

  parseCourses = (value) =>
    (value
      ? value
        .filter(
          (item) => item && item.course && item.course.title
        )
        .map(
          (item, i, self) =>
            (i !== self.length - 1
              ? `${item.course && item.course.title}, `
              : item.course && item.course.title),
        )
        .join('')
      : '');

  parsePostDate = (value) =>
    (value[0]
      ? DateUtils.humanizeDate(value[0].courseContent.postDate, 'DD/MM/YYYY')
      : '');

  searchModulesFromCourses = async (val) => {
    const { searchCourses } = this.props;
    this.setState({ enableModuleOptions: false }, async () => {
      try {
        await searchCourses({ parentId: val, type: CourseType.MODULE });
        this.setState({
          moduleOptions: this.props.searchedCourses,
          module: '',
        });
      } finally {
        this.setState({ enableModuleOptions: true });
      }
    });
  };

  searchCourses = async (val) => {
    const { searchCourses } = this.props;
    await searchCourses({ search: val, type: CourseType.COURSE });
    this.setState({ courseOptions: this.props.searchedCourses });
  };

  parseCompanies = (item) => {
    const companies = item.length && item.map(returnCompanies => returnCompanies?.course?.company)
    const ArrayCompanies = showCompanies(companies)

    return ArrayCompanies
  }

  searchModules = async (val) => {
    const { searchCourses } = this.props;
    const { course } = this.state;
    await searchCourses({
      search: val,
      type: CourseType.MODULE,
      parentId: course,
    });
    this.setState({ moduleOptions: this.props.searchedCourses });
  };

  render () {
    const { Content } = Layout;
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const {
      params,
      module,
      course,
      courseOptions,
      moduleOptions,
      enableModuleOptions,
      company,
      companyList,
    } = this.state;
    const {
      contentsPaginated,
      loading,
      id: idModule,
      categorySelectives,
      me,
    } = this.props;

    if (course && contentsPaginated && contentsPaginated.rows) {
      contentsPaginated.rows = contentsPaginated.rows.map((content) => ({
        ...content,
        course: course.title,
      }));
    }

    if (categorySelectives && categorySelectives.length > 0) {
      categorySelectives.sort(compare);
    }

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            {idModule && (
              <Breadcrumb.Item>
                <Link to={`${I18n.t('routes.panel.modules.url')}`}>
                  <span>{I18n.t('routes.panel.modules.pageTitle')}</span>
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.contents.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.contentName')}
                    />
                  </Col>
                  {!idModule && (
                    <Col span={8}>
                      <AdvancedSelect
                        options={[
                          { id: 'true', name: I18n.t('shared.yes') },
                          { id: 'false', name: I18n.t('shared.no') },
                        ]}
                        label={I18n.t('shared.advancedFilters.form.isEnabled')}
                        value={params && params.isEnabled}
                        onChange={(val) => this.fieldChange('isEnabled', val)}
                      />
                    </Col>
                  )}
                  {!idModule && (
                    <Col span={8}>
                      <div style={{ paddingBottom: '5px' }}>
                        {I18n.t('shared.advancedFilters.form.postDate')}
                      </div>
                      <RangePicker
                        value={
                          params && params.startPostDate && params.endPostDate
                            ? [
                              moment(params.startPostDate),
                              moment(params.endPostDate),
                            ]
                            : []
                        }
                        placeholder={[
                          I18n.t('shared.advancedFilters.form.startDate'),
                          I18n.t('shared.advancedFilters.form.endDate'),
                        ]}
                        format='DD/MM/YYYY'
                        onChange={(val) => this.handleChangeDate(val)}
                      />
                    </Col>
                  )}
                  {idModule && (
                    <Col span={8}>
                      <AdvancedSelect
                        value={categorySelectives && params.categoryId}
                        onChange={(val) => {
                          this.fieldChange('categoryId', val);
                        }}
                        options={categorySelectives}
                        label={I18n.t(
                          'shared.advancedFilters.form.mainCategory',
                        )}
                      />
                    </Col>
                  )}
                </Row>
                <Row gutter={[ 16, 24 ]}>
                  <Col span={8}>
                    <div className="mb-1">{I18n.t('forms.user.courses.label')}</div>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={I18n.t('shared.selectSomeValue')}
                      showSearch
                      value={params.courseId || undefined}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.searchCourses(val)}
                      onChange={(val) => this.fieldChange('courseId', val)}
                      onSelect={(val) => this.searchModulesFromCourses(val)}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                    >
                      {courseOptions &&
                        courseOptions.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>

                  <Col span={8}>
                    <div className="mb-1">{I18n.t('forms.user.modules.label')}</div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params.moduleId || undefined}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.searchModules(val)}
                      onChange={(val) => this.fieldChange('moduleId', val)}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!enableModuleOptions || !params.courseId}
                    >
                      {moduleOptions &&
                        moduleOptions.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>

                  <Col span={6}>
                    <AdvancedSelect
                      label={I18n.t('routes.panel.questionDatabase.dataTable.filters.company')}
                      value={company}
                      onChange={(val) => this.setState({company: val})}
                      options={companyList}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters(idModule)}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='contents'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={ContentIcon} />
                  </span>
                  {module && module.title}
                  {!idModule && I18n.t('routes.panel.contents.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      disabled={this.state.exportingPfdDataLoading || loading > 0}
                      loading={this.state.exportingPfdDataLoading}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      disabled={this.state.exportingDataLoading || loading > 0}
                      loading={this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.contentDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}

                <Divider type='vertical' />

                {!idModule && (
                  <AdvancedButton
                    text={I18n.t('routes.panel.contents.addNewButtonText')}
                    href={`${I18n.t('routes.panel.contentDetails.url')}add`}
                  />
                )}
              </Col>
            </Row>

            <Divider />
            <div ref={this.pdfLink}>
              <DataTable
                getMethod={(parameters) => this.getContents(parameters, true)}
                data={contentsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                showSizeChanger
                pageSizeOptions={
                  contentsPaginated &&
                    contentsPaginated.count &&
                    contentsPaginated.count > 100
                    ? [ '10', '50', '100', contentsPaginated.count ]
                    : [ '10', '20', '50', '100' ]
                }
                params={params}
                columns={
                  idModule
                    ? [
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.title.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.title.title',
                        ),
                        render: (value) => value || '--',
                      },
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.course.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.course.title',
                        ),
                        render: (value) => value || '--',
                      },
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.categories.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.categories.title',
                        ),
                        render: (value) =>
                          (value && this.parseCategories(value)) || '--',
                      },
                      {
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.actions.key',
                        ),
                        title: '',
                        render: (id) => (
                          <div className='dataTable__item--right'>
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.contents.dataTable.columns.actions.goToEditText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type='link'
                                icon={<EditOutlined />}
                                onClick={() =>
                                  navigate(
                                    `${I18n.t(
                                      'routes.panel.contentDetails.url',
                                    )}${id}`,
                                  )
                                }
                              />
                            </Tooltip>
                            <Popconfirm
                              placement='left'
                              title={I18n.t('shared.confirmTitle')}
                              onConfirm={() => this.removeContent(id)}
                              okText={I18n.t('shared.yes')}
                              cancelText={I18n.t('shared.no')}
                            >
                              <Tooltip
                                title={I18n.t(
                                  'routes.panel.contents.dataTable.columns.actions.removeText',
                                )}
                                overlayStyle={{ fontSize: 11 }}
                              >
                                <Button
                                  type='link'
                                  icon={<DeleteOutlined />}
                                />
                              </Tooltip>
                            </Popconfirm>
                          </div>
                        ),
                      },
                    ]
                    : [
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.title.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.title.title',
                        ),
                        render: (value) => value || '--',
                      },
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.titles.course',
                        ),
                        render: (value) =>
                          (value && this.parseCourses(value)) || '--',
                      },
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.titles.company',
                        ),
                        render: (value) => this.parseCompanies(value),
                      },
                      {
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.titles.module',
                        ),
                        render: (value) =>
                          (value && this.parseModules(value)) || '--',
                      },
                      {
                        width: '85px',
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.isEnabled.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.isEnabled.title',
                        ),
                        render: (value, row) => (
                          <div className='display_flex justify_content'>
                            <Switch
                              checked={value}
                              onChange={() =>
                                this.updateContent(row.id, {
                                  isEnabled: !value,
                                })
                              }
                            />
                          </div>
                        ),
                      },
                      {
                        width: '130px',
                        ellipsis: true,
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.modules.titles.postDate',
                        ),
                        render: (value) =>
                          (value &&
                            value.length > 0 &&
                            this.parsePostDate(value)) ||
                          '--',
                      },
                      {
                        width: '130px',
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.createdAt.key',
                        ),
                        title: I18n.t(
                          'routes.panel.contents.dataTable.columns.createdAt.title',
                        ),
                        render: (createdAt) =>
                          DateUtils.humanizeDateTime(
                            createdAt,
                            'DD/MM/YYYY HH:mm',
                          ),
                      },
                      {
                        width: '110px',
                        key: I18n.t(
                          'routes.panel.contents.dataTable.columns.actions.key',
                        ),
                        title: '',
                        render: (id) => (
                          <div className='dataTable__item--right'>
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.quiz.dataTable.columns.actions.goDuplicate',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type='link'
                                icon={<PlusOutlined />}
                                onClick={() =>
                                  navigate(
                                    `${I18n.t(
                                      'routes.panel.contentDetails.urlDuplicate',
                                    )}${id}`,
                                  )
                                }
                              />
                            </Tooltip>
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.contents.dataTable.columns.actions.goToStatisticsText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <a
                                type='link'
                                href={`${I18n.t(
                                  'routes.panel.contentStatistics.url',
                                )}${id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Button
                                  disabled={
                                    this.props.me.profileType ===
                                    ProfileType.UPLOADER
                                  }
                                  type='link'
                                  icon={<PieChartOutlined />}
                                />
                              </a>
                            </Tooltip>
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.contents.dataTable.columns.actions.goToEditText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type='link'
                                icon={<EditOutlined />}
                                onClick={() =>
                                  navigate(
                                    `${I18n.t(
                                      'routes.panel.contentDetails.url',
                                    )}${id}`,
                                  )
                                }
                              />
                            </Tooltip>
                            {([ ProfileType.ADMIN, ProfileType.TEACHER ]).includes(this.props.me?.profileType) && (
                              <>
                                <Popconfirm
                                  placement='left'
                                  title={I18n.t('shared.confirmTitle')}
                                  onConfirm={() => this.removeContent(id)}
                                  okText={I18n.t('shared.yes')}
                                  cancelText={I18n.t('shared.no')}
                                >
                                  <Tooltip
                                    title={I18n.t(
                                      'routes.panel.contents.dataTable.columns.actions.removeText',
                                    )}
                                    overlayStyle={{ fontSize: 11 }}
                                  >
                                    <Button
                                      type='link'
                                      icon={<DeleteOutlined />}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </>
                            )}
                          </div>
                        ),
                      },
                    ]
                }
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  contentsPaginated: ContentSelectors.getContentsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  moduleDetails: CourseSelectors.getCourseDetails(state),
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  courseSelectives: CourseSelectors.getCourseSelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  coursesFilters: FilterSelector.getCoursesFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCourseSelectives: (params) =>
    dispatch(CourseActions.getCourseSelectivesQuiz(params)),
  getContentsPaginated: (parameters, me) =>
    dispatch(ContentActions.getContentsPaginated(parameters, me)),
  updateContent: (id, data, callback) =>
    dispatch(ContentActions.updateContent(id, data, callback)),
  removeContent: (id, callback) =>
    dispatch(ContentActions.removeContent(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getModuleDetails: (id) => dispatch(CourseActions.getCourseDetails(id)),
  getCategorySelective: () => dispatch(CategoryActions.getCategorySelectives()),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  setCoursesContentsFilters: (params) =>
    dispatch(FilterActions.setCoursesContentsFilters(params)),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contents);
