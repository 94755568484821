import getInstance from './config';

export default class PushNotificationApi {
  static async getUsersForPushPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async send (pushNotificationData) {
    const instance = await getInstance();
    const { data } = await instance.post('/notification/send', pushNotificationData);
    return data;
  }

  static async getPushNotificationsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/notification', { params });
    return data;
  }

  static async getPushNotificationDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/notification/${id}`);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/notification/${id}`);
    return data;
  }

  static async getPermission (params) {
    const instance = await getInstance();
    const { data } = await instance.post('/notification/permission', params);
    return data;
  }
}
