import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  PieChartOutlined,
  PlusOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import Moment from 'moment';
import DataTable from '../../../components/shared/DataTable/DataTable';
import {
  CategoryActions,
  CourseActions,
  FilterActions,
  QuizActions,
  UserActions,
} from '../../../app/redux/actions';

import {
  AuthSelectors,
  CategorySelectors,
  CourseSelectors,
  FilterSelector,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { isEnableEnum } from '../../../app/enum/questionEnum';
import { removeEmptyField } from '../../../app/utils/functionsUtils';
import { ProfileType } from '../../../app/enum/profileType';
import moment from 'moment-timezone';
import { cleanPagination } from '../../../app/utils/pagination';
import QuizApi from '../../../app/api/quiz';
import { CSVLink } from 'react-csv';
import { prepareDataToCsv } from '../../../app/utils/csv';

const { Panel } = Collapse;

class QuizExame extends React.Component {
  constructor (props) {
    super(props);
    const { quizFilters } = props;
    const { simulationFilters } = quizFilters;
    this.state = {
      csvData: '',
      params: {
        ...cleanPagination,
        ...simulationFilters,
        name: simulationFilters?.name ?? '',
        categoriesId: simulationFilters?.categoriesId ?? '',
        isEnabled: simulationFilters?.isEnabled ?? '',
        courseId: simulationFilters?.courseId ?? '',
        startCreatedAt: simulationFilters?.startCreatedAt ?? null,
        endCreatedAt: simulationFilters?.endCreatedAt ?? null,
        expirationAt: simulationFilters?.expirationAt ?? null,
        endExpirationAt: simulationFilters?.endExpirationAt ?? null,
        startExpirationAt: simulationFilters?.startExpirationAt ?? null,
        orderBy: 'createdAt',
        isDESC: true,
      },
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { quizFilters } = this.props;
    const { simulationFilters } = quizFilters;

    await this.props.getQuizzes(this.state.params);
    await this.props.getCategorySelectives();
    await this.props.getCourseSelectives();
    await this.props.getUsersAll();
  }

  getQuizzes = async (parameters, isFromDataTable, isReset) => {
    this.setState({
      params: {
        ...this.state.params,
        ...parameters,
      },
    });

    if (!isReset) {
      parameters = {
        ...this.props.quizFilters.simulationFilters,
        ...parameters,
      };
    }

    let isEnabled;

    if (
      parameters.isEnabled === 1 || parameters.isEnabled === 2
    ) {
      isEnabled = parameters.isEnabled === 2;
    }

    if (parameters.startCreatedAt && parameters.endCreatedAt) {
      parameters = {
        ...parameters,
        startCreatedAt: moment(parameters.startCreatedAt).toISOString(),
        endCreatedAt: moment(parameters.endCreatedAt).toISOString(),
      };
    }

    if (parameters.startExpirationAt && parameters.endExpirationAt) {
      parameters = {
        ...parameters,
        startExpirationAt: moment(parameters.startExpirationAt).toISOString(),
        endExpirationAt: moment(parameters.endExpirationAt).toISOString(),
      };
    }

    await this.props.getQuizzes({...parameters, isEnabled});
    this.props.setQuizSimulationFilters(parameters);
  };

  applyAdvancedFilters = async (event) => {
    event.preventDefault();
    let { params } = this.state;
    const parameter = { ...params, ...cleanPagination };

    await this.getQuizzes(parameter);
  };

  clearFilter = () => {
    const params = {
      name: '',
      categoriesId: '',
      isEnabled: '',
      courseId: '',
      startCreatedAt: null,
      endCreatedAt: null,
      expirationAt: null,
      endExpirationAt: null,
      startExpirationAt: null,
      ...cleanPagination
    };
    // this.setState({ params });
    this.props.setQuizSimulationFilters(null);
    this.getQuizzes(params, true, true);
  };

  updateQuiz = async (id, data) => {
    const { updateQuiz, getQuizzes } = this.props;
    let { params } = this.state;
    params = removeEmptyField(params);
    const parameter = { ...params };

    if (parameter.isEnabled === 2) {
      parameter.isEnabled = true;
    } else if (parameter.isEnabled === 1) {
      parameter.isEnabled = false;
    }

    try {
      await updateQuiz(id, data);
      await getQuizzes(parameter);
      message.success(I18n.t('routes.panel.quiz.messagesUpdateQuiz'));
    } catch (e) {
      message.error(I18n.t('routes.panel.quiz.messagesUpdateQuizFail'));
    }
  };

  exportQuiz = () => {
    this.props.exportQuizzes(() => {
      message.success(I18n.t('routes.panel.quiz.messageExportQuiz'));
    });
  };

  removeQuiz = async (id) => {
    const { removeQuiz } = this.props;

    try {
      await removeQuiz(id);
      this.dataTableRef.current.reset();
    } catch (err) {
      message.error(I18n.t('routes.panel.quiz.labelRemoveErro'));
    }
  };

  changeParams (name, e) {
    const { params } = this.state;
    const paramsAux = params;
    paramsAux[name] = e;
    this.setState({ params: paramsAux });
  }

  getCategory (categories, isMainCategory) {
    if (isMainCategory) {
      return (categories.map(
        (item) => item.quizCategory.isMain === true && item.title,
      )).filter(categoryItem => typeof categoryItem === 'string');
    }

    if (!isMainCategory) {
      let res = '';

      for (let i = 0; i < categories.length; i++) {
        if (res === '' && categories[i].quizCategory?.isMain === false) {
          res += `${categories[i].title}`;
        } else if (categories[i].quizCategory?.isMain === false) {
          res += `, ${categories[i].title}`;
        }
      }

      return res;
    }
  }

  handleChangeDate (value, expiration) {
    const { params } = this.state;

    if (value && !expiration) {
      params.startCreatedAt = value[0].toDate();
      params.endCreatedAt = value[1].toDate();
    } else if (!value && !expiration) {
      params.startCreatedAt = null;
      params.endCreatedAt = null;
    }

    if (value && expiration) {
      params.startExpirationAt = value[0].toDate();
      params.endExpirationAt = value[1].toDate();
    } else if (!value && expiration) {
      params.startExpirationAt = null;
      params.endExpirationAt = null;
    }

    this.setState({ params });
  }

  getCreateBy = (id) => {
    const userName = this.props.usersAll?.find((item) => item?.id === id);
    return userName?.name;
  };

  csvExport = async (exportAllDataFromApi) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.quizPaginated?.rows,
      (row) => ({
        name: row.name || '--',
        numberOfQuestions: row.numberOfQuestions || '--',
        category: row.categories ? this.getCategory(row.categories, true) : '--',
        categorySec: row.categories ? this.getCategory(row.categories) : '--',
        createdAt: row.createdAt ? Moment(row.createdAt).format('DD/MM/YYYY') : '--',
        createBy: row.createBy ? this.getCreateBy(row.createBy) : '--',
        expirationAt: row.expirationAt ? Moment(row.expirationAt).format('DD/MM/YYYY') : '--',
        isEnabled: row.isEnabled ? I18n.t('shared.affirmative.yes') : I18n.t('shared.affirmative.no')
      }),
      exportAllDataFromApi,
      this.props.quizPaginated?.count,
      QuizApi.getQuizzesPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  render () {
    const { Content } = Layout;
    const { params } = this.state;
    const { loading, quizPaginated, categorySelectives, courseSelectives, me } =
      this.props;
    const { RangePicker } = DatePicker;
    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form onSubmit={(event) => this.applyAdvancedFilters(event)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params.name}
                      onChange={(e) => this.changeParams('name', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={categorySelectives}
                      label={I18n.t('shared.advancedFilters.form.category')}
                      value={params.categoriesId}
                      onChange={(e) => this.changeParams('categoriesId', e)}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={isEnableEnum}
                      label={I18n.t('shared.advancedFilters.form.isEnabled')}
                      value={params.isEnabled}
                      onChange={(e) => this.changeParams('isEnabled', e)}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedSelect
                      options={courseSelectives}
                      label={I18n.t('shared.advancedFilters.form.course')}
                      value={params.courseId}
                      onChange={(e) => this.changeParams('courseId', e)}
                    />
                  </Col>
                  <Col span={5}>
                    <div className='quiz-question__filter__date-picker'>
                      {I18n.t('forms.newExame.expirationDate')}
                    </div>
                    <RangePicker
                      value={
                        params &&
                          params.startExpirationAt &&
                          params.endExpirationAt
                          ? [
                            moment(params.startExpirationAt),
                            moment(params.endExpirationAt),
                          ]
                          : []
                      }
                      placeholder={[
                        I18n.t('shared.advancedFilters.form.startDate'),
                        I18n.t('shared.advancedFilters.form.endDate'),
                      ]}
                      format='DD/MM/YYYY'
                      onChange={(val) => this.handleChangeDate(val, true)}
                    />
                  </Col>
                  <Col span={6}>
                    <div className='quiz-question__filter__date-picker'>
                      {I18n.t('shared.advancedFilters.form.createdAt')}
                    </div>
                    <RangePicker
                      value={
                        params && params.startCreatedAt && params.endCreatedAt
                          ? [
                            moment(params.startCreatedAt),
                            moment(params.endCreatedAt),
                          ]
                          : []
                      }
                      placeholder={[
                        I18n.t('shared.advancedFilters.form.startDate'),
                        I18n.t('shared.advancedFilters.form.endDate'),
                      ]}
                      format='DD/MM/YYYY'
                      onChange={(val) => this.handleChangeDate(val)}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.clearFilter()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>
        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuizIcon} />
                  </span>
                  {I18n.t('routes.panel.quiz.pageQuiz')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      disabled={this.state.exportingDataLoading || loading > 0}
                      loading={this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.userDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}
                <Divider type='vertical' />
                <AdvancedButton
                  text={I18n.t('routes.panel.quiz.newExame')}
                  href={`${I18n.t('routes.panel.quiz.urlQuiz')}add`}
                />
              </Col>
            </Row>

            <Divider />
            <DataTable
              notGetOnStart
              getMethod={(parameters) => this.getQuizzes(parameters, true)}
              data={quizPaginated}
              ref={this.dataTableRef}
              loading={loading > 0}
              showSizeChanger
              params={params}
              pageSizeOptions={
                quizPaginated && quizPaginated.count && quizPaginated.count > 100
                  ? [ '10', '50', '100', quizPaginated.count ]
                  : [ '10', '20', '50', '100' ]
              }
              columns={[
                {
                  key: I18n.t('routes.panel.quiz.dataTable.columns.name.key'),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.name.title',
                  ),
                  render: (value) => value,
                },
                {
                  width: '80px',
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.numberOfQuestions.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.numberOfQuestions.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>{value}</div>
                  ),
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.category.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.category.title',
                  ),
                  render: (value) => this.getCategory(value, true),
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.categorySec.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.categorySec.title',
                  ),
                  render: (value) => this.getCategory(value),
                },
                {
                  width: '120px',
                  align: 'center',
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.createdAt.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.createdAt.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>
                      {Moment(value).format('DD/MM/YYYY')}
                    </div>
                  ),
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.createBy.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.createBy.title',
                  ),
                  render: (value) => this.getCreateBy(value),
                },
                {
                  width: '80px',
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.expirationAt.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.expirationAt.title',
                  ),
                  render: (value) => (
                    <div className='display_flex justify_content'>
                      {Moment(value).format('DD/MM/YYYY')}
                    </div>
                  ),
                },
                {
                  width: '90px',
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.isEnabled.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.isEnabled.title',
                  ),
                  render: (value, row) => (
                    <div className='display_flex justify_content'>
                      <Switch
                        disabled={
                          this.props.me.profileType === ProfileType.QUIZER
                        }
                        checked={value}
                        onChange={() =>
                          this.updateQuiz(row.id, { isEnabled: !value })
                        }
                      />
                    </div>
                  ),
                },
                {
                  width: '115px',
                  key: I18n.t(
                    'routes.panel.users.dataTable.columns.actions.key',
                  ),
                  title: '',
                  render: (id) => (
                    <div className='dataTable__item--right'>
                      <Tooltip
                        title={I18n.t(
                          'routes.panel.quiz.dataTable.columns.actions.goAddUsers',
                        )}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<UserAddOutlined />}
                          onClick={() =>
                            navigate(
                              `${I18n.t(
                                'routes.panel.quiz.urlQuizAddUsers',
                              )}${id}`,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        title={I18n.t(
                          'routes.panel.quiz.dataTable.columns.actions.goDuplicate',
                        )}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<PlusOutlined />}
                          onClick={() =>
                            navigate(
                              `${I18n.t(
                                'routes.panel.quiz.urlQuiz',
                              )}duplicate/${id}`,
                            )
                          }
                        />
                      </Tooltip>
                      {this.props.me?.profileType !== ProfileType.OPERATOR && (
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.users.dataTable.columns.actions.goToEditText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t('routes.panel.quiz.urlQuiz')}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title={I18n.t('routes.panel.quiz.seeEstatistics')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<PieChartOutlined />}
                          onClick={() =>
                            navigate(
                              `${I18n.t(
                                'routes.panel.quiz.urlQuizStatistics',
                              )}${id}`,
                            )
                          }
                        />
                      </Tooltip>
                      {([
                        ProfileType.ADMIN,
                        ProfileType.TEACHER,
                      ].includes(this.props.me?.profileType)) && (
                        <Popconfirm
                          placement='left'
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.removeQuiz(id)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t(
                              'routes.panel.users.dataTable.columns.actions.removeText',
                            )}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button type='link'
                              icon={<DeleteOutlined />} />
                          </Tooltip>
                        </Popconfirm>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quizPaginated: QuizSelectors.getQuizzesPaginated(state),
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  courseSelectives: CourseSelectors.getCourseSelectives(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  quizFilters: FilterSelector.getQuizFilters(state),
  usersAll: UserSelectors.getUserAll(state),
});

const mapDispatchToProps = (dispatch) => ({
  getQuizzes: (params) => dispatch(QuizActions.getQuizzes(params)),
  removeQuiz: (id) => dispatch(QuizActions.removeQuiz(id)),
  getCategorySelectives: () =>
    dispatch(CategoryActions.getCategorySelectives()),
  updateQuiz: (id, quiz) => dispatch(QuizActions.updateQuiz(id, quiz)),
  exportQuizzes: (callback) => dispatch(QuizActions.exportQuiz(callback)),
  getCourseSelectives: (params) =>
    dispatch(CourseActions.getCourseSelectivesQuiz(params)),
  setQuizSimulationFilters: (params) =>
    dispatch(FilterActions.setQuizSimulationFilters(params)),
  getUsersAll: (params) => dispatch(UserActions.getAllUser(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(QuizExame);
