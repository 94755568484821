import React from 'react';
import {
  Avatar,
  Empty,
  Select,
} from 'antd';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

function hasValueProp(hasValue, value) {
  let response = null;

  if (hasValue) {
    response = {
      value,
    };
  }

  return response;
}

const AdvancedSelect = (
  {
    value,
    defaultValue,
    options,
    label,
    onChange,
    onSelect,
    disabled,
    placeholder,
    disableSearch,
    onSearch,
    hasValue,
    mode,
    labelStyle,
    photoUrl,
    dropdownClassName,
    optionClassName,
    horizontal,
    colorDots,
  },
) => (
  <div className="advanced-select">
    <label
      className="advanced-select__label"
      style={horizontal ? { display: 'flex' } : {}}
    >
      <span
        style={labelStyle}
        className="advanced-select__label__inner"
      >
        {label}
      </span>
      <Select
        {...hasValueProp(hasValue, value)}
        style={!horizontal ? { width: '100%' } : { width: 'auto' }}
        value={value || undefined}
        defaultValue={defaultValue || undefined}
        onChange={onChange}
        onSelect={onSelect}
        optionFilterProp={disableSearch ? 'children' : undefined}
        disabled={disabled || false}
        placeholder={placeholder || I18n.t('shared.selectSomeValue')}
        showSearch={!disableSearch || false}
        allowClear
        mode={mode || undefined}
        dropdownClassName={dropdownClassName || ''}
        onSearch={onSearch ? (val) => onSearch(val) : undefined}
        notFoundContent={
          (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={I18n.t('shared.notFoundSearch')}
            />
          )
        }
        filterOption={!disableSearch ? (
          input,
          option,
        ) => {
          if (option && option.label) {
            return option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          if (option && option.children) {
            const parsedChildren = option.children.map((o) => o && o.toString().toLowerCase());
            const theOption = parsedChildren && parsedChildren.length > 1 && parsedChildren[1];
            return theOption && theOption.indexOf(input.toLowerCase()) >= 0;
          }
        } : undefined}
      >
        {options && options.length > 0 && options.map((a) => (
          <Option
            key={a.id ? a.id.toString() : (a.name || 'all')}
            value={a.id || a.name}
            label={a.name}
            className={optionClassName || ''}
          >
            {photoUrl
              ? <Avatar src={a.photoUrl} />
              : null}
            {a.name || a.title}
            {colorDots && a && a.color && (
              <span 
                className="advanced-select__option-dot" 
                style={{backgroundColor: a.color}}
              />
            )}
          </Option>
        ))}
      </Select>
    </label>
  </div>
);

export default AdvancedSelect;
