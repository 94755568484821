import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import QuizRequest from '../../api/quiz';

export const ACTION_GET_QUESTIONS = 'ACTION_GET_QUESTIONS';
export const ACTION_GET_USER_MEDIA_ANSWERS = 'ACTION_GET_USER_MEDIA_ANSWERS';
export const ACTION_GET_QUESTION_BY_ID = 'ACTION_GET_QUESTION_BY_ID';
export const ACTION_GET_QUIZ_BY_ID = 'ACTION_GET_QUIZ_BY_ID';
export const ACTION_GET_QUIZZES = 'ACTION_GET_QUIZZES';
export const ACTION_GET_ALL_QUIZZES = 'ACTION_GET_ALL_QUIZZES';
export const ACTION_SET_QUESTION = 'ACTION_GET_QUESTION';
export const ACTION_SET_QUIZZ = 'ACTION_GET_QUESTION';
export const ACTION_GET_QUIZ_STATISTICS = 'ACTION_GET_QUIZ_STATISTICS';
export const ACTION_GET_USER_QUIZ_BY_ID = 'ACTION_GET_USER_QUIZ_BY_ID';
export const ACTION_GET_QUIZ_STATISTICS_RANKING =
  'ACTION_GET_QUIZ_STATISTICS_RANKING';
export const ACTION_GET_USER_QUIZZES = 'ACTION_GET_USER_QUIZZES'

export const setQuestions = (questions) => async (dispatch) => {
  await dispatch({ type: ACTION_GET_QUESTIONS, payload: questions });
};

export const getQuestions = (params, preview) => async (dispatch) => {
  dispatch(addLoading());

  params = {
    orderBy: 'createdAt',
    isDESC: true,
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
  };

  try {
    let questions = null;

    if (!preview) {
      questions = await QuizRequest.getQuestionsPaginated(params);
      dispatch({
        type: ACTION_GET_QUESTIONS,
        payload: questions,
      });
    }

    if (preview) {
      questions = QuizRequest.getAllQuestions(params);
    }

    return questions;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuizzes = (params) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const quizzes = await QuizRequest.getQuizzesPaginated(params);
    dispatch({
      type: ACTION_GET_QUIZZES,
      payload: quizzes,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeQuestion = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createQuestion = (question) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.createQuestion(question);
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createQuestionAlternative = (alternative) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.createQuestionAlternative(alternative);
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const updateQuestion = (id, question, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.updateQuestion(id, question);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuestionById = (id, resturnData) => async (dispatch) => {
  dispatch(addLoading());
  let response = null;

  try {
    response = await QuizRequest.getQuestionById(id);

    if (resturnData) {
      return response;
    }

    dispatch({
      type: ACTION_GET_QUESTION_BY_ID,
      payload: response,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return response;
};

export const getUrlPreviewMedia = (name) => async (dispatch) => {
  dispatch(addLoading());
  let response = null;

  try {
    response = await QuizRequest.getUrlMedia(name);
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }

  return response;
};

export const updateQuiz = (id, quiz) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.updateQuiz(id, quiz);
    message.success(I18n.t('routes.panel.quiz.messagesUpdateQuiz'));
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeQuiz = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.removeQuiz(id);
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createQuiz = (quiz) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.createQuiz(quiz);
    message.success(I18n.t('forms.newExame.sucessExame'));
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const calculateQuiz = (quiz, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.calculateQuiz(quiz);

    if (typeof callback === 'function') {
      callback();
    }

    return response;
  } catch (err) {
    message.error(
      I18n.t('routes.panel.statisticsList.dataTable.columns.actions.calculate'),
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuizById = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.getQuizById(id);
    dispatch({ type: ACTION_GET_QUIZ_BY_ID, payload: response });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuizStatistics = (id, params) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'correctAnswersPercentage',
    isDESC: true,
  };

  try {
    const response = await QuizRequest.getStatistics(id, params);

    response.rows.map((item) => (item.name = item.user.name));

    dispatch({
      type: ACTION_GET_QUIZ_STATISTICS,
      payload: response,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuizStatisticsRanking = (id, params, userId) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'correctAnswersPercentage',
    isDESC: true,
    quizId: id,
    userId,
  };

  try {
    const response = await QuizRequest.getStatisticsRanking(params);

    response.rows.map((item) => (item.name = item.user.name));

    dispatch({
      type: ACTION_GET_QUIZ_STATISTICS_RANKING,
      payload: response,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const exportStatisticsQuiz = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.exportQuizStatistics(id);
    callback();
  } catch (err) {
    //
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getStatisticsById = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.getStatisticsById(id);

    dispatch({
      type: ACTION_GET_USER_QUIZ_BY_ID,
      payload: response,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const updateQuizStatistics =
  (questions, callback) => async (dispatch) => {
    dispatch(addLoading());

    try {
      if(questions) {
        await QuizRequest.updateQuizStatistics(questions.id, {
          status: questions.status,
          correctionAnnotation: questions.correctionAnnotation,
          correctionAnnotationType: questions.correctionAnnotationType,
        });
      }

      if (typeof callback === 'function') {
        callback();
      }
    } catch (err) {
      //
      message.error(
        I18n.t('routes.panel.quiz.dataTable.columns.error.updateQuiz'),
      );
    } finally {
      dispatch(removeLoading());
    }
  };

export const exportQuestion = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.exportQuestion();
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const exportQuiz = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await QuizRequest.exportQuiz();
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserAnswers = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.getUserAnswers(id);
    dispatch({
      type: ACTION_GET_USER_MEDIA_ANSWERS,
      payload: response,
    });
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const sendCorrection = (correction) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.sendCorrections(correction);
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const finishQuizCorrection = (id, correction) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.finishQuiz(id, correction);
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getQuizSelectives = (params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.getQuizSelectives(params);
    dispatch({
      type: ACTION_GET_ALL_QUIZZES,
      payload: response,
    });
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersQuizzes = (params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await QuizRequest.getUsersQuizes(params);
    dispatch({
      type: ACTION_GET_USER_QUIZZES,
      payload: response,
    });
    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
