import React from 'react';
import {
  Card,
  Col,
  Layout,
  message,
  Row,
} from 'antd';
import md5 from 'md5';
import * as queryString from 'query-string';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import AdvancedInput from '../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton/AdvancedButton';
import { AuthActions } from '../../app/redux/actions';
import { LoadingSelectors } from '../../app/redux/reducers';

class PasswordRecovery extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      recoveryToken: '',
      step: 0,
    };
  }

  componentDidMount () {
    const queryParams = queryString.parse(window.location.search);

    if (queryParams.code && queryParams.email) {
      this.setState({ step: 1, email: queryParams.email, recoveryToken: queryParams.code });
    }
  }

  forgotPassword (e) {
    e.preventDefault();

    const { forgotPassword } = this.props;
    const { email } = this.state;

    if (!email) {
      return message.error(I18n.t('routes.passwordRecovery.errors.fields'));
    }

    forgotPassword(email, () => {
      message.success(I18n.t('routes.passwordRecovery.messages.recoverySent'));
      this.setState({ email: '' });
    });
  }

  recoveryPassword (e) {
    e.preventDefault();

    const { recoveryPassword } = this.props;
    const {
      email, password, confirmPassword, recoveryToken,
    } = this.state;

    if (!email || !password || !recoveryToken) {
      return message.error(I18n.t('routes.passwordRecovery.errors.fields'));
    }

    if (password !== confirmPassword) {
      return message.error(I18n.t('routes.passwordRecovery.errors.invalid_confirm'));
    }

    recoveryPassword({ email, password: md5(password), recoveryToken }, () => {
      message.success(I18n.t('routes.passwordRecovery.messages.success'));
    });
  }

  render () {
    const { Content } = Layout;
    const {
      email,
      password,
      confirmPassword,
      step,
    } = this.state;
    const {
      loading,
    } = this.props;
    return (
      <Content className="login__container">
        <Row
          type="flex"
          justify="center"
        >
          <Col
            lg={8}
            md={16}
          >
            <Row className="login__logo">
              <Col>
                <img
                  alt="login logo"
                  className="login__logo__img"
                  src="/assets/img/logo.svg"
                />
              </Col>
            </Row>
            <Card>
              <Row>
                <Col>
                  <p><strong>{I18n.t('routes.passwordRecovery.pageTitle')}</strong></p>
                </Col>
              </Row>


              {step <= 0 && (
                <>
                  <Row className="login__row">
                    <Col>
                      <p>{I18n.t('routes.passwordRecovery.content.step1')}</p>
                    </Col>
                  </Row>
                  <form onSubmit={(e) => this.forgotPassword(e)}>
                    <Row className="login__input">
                      <Col span={24}>
                        <AdvancedInput
                          label={I18n.t('routes.passwordRecovery.content.email')}
                          placeholder={I18n.t('routes.passwordRecovery.content.emailPlaceholder')}
                          type="email"
                          value={email}
                          onChange={(value) => this.setState({ email: value })}
                          disabled={loading && loading > 0}
                        />
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      justify="end"
                      className="login__input login__input__button"
                    >
                      <Col span={24}>
                        <AdvancedButton
                          text={I18n.t('routes.passwordRecovery.content.continueButton')}
                          loading={loading && loading > 0}
                          htmlType="submit"
                          long
                        />
                      </Col>
                    </Row>
                  </form>
                </>
              )}
              {step >= 1 && (
                <>
                  <Row className="login__row">
                    <Col>
                      <p>{I18n.t('routes.passwordRecovery.content.step2')}</p>
                    </Col>
                  </Row>
                  <form onSubmit={(e) => this.recoveryPassword(e)}>
                    <Row className="login__input">
                      <Col span={24}>
                        <AdvancedInput
                          label={I18n.t('routes.passwordRecovery.content.password')}
                          placeholder={I18n.t('routes.passwordRecovery.content.passwordPlaceholder')}
                          type="password"
                          value={password}
                          onChange={(value) => this.setState({ password: value })}
                          disabled={loading && loading > 0}
                          isPassword
                        />
                      </Col>
                    </Row>
                    <Row className="login__input">
                      <Col span={24}>
                        <AdvancedInput
                          label={I18n.t('routes.passwordRecovery.content.confirmPassword')}
                          placeholder={I18n.t('routes.passwordRecovery.content.confirmPasswordPlaceholder')}
                          type="password"
                          value={confirmPassword}
                          onChange={(value) => this.setState({ confirmPassword: value })}
                          disabled={loading && loading > 0}
                          isPassword
                        />
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      justify="end"
                      className="login__input login__input__button"
                    >
                      <Col span={24}>
                        <AdvancedButton
                          text={I18n.t('routes.passwordRecovery.content.finish')}
                          loading={loading && loading > 0}
                          htmlType="submit"
                          long
                        />
                      </Col>
                    </Row>
                  </form>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  recoveryPassword: (
    { email, recoveryToken, password }, callback,
  ) => dispatch(AuthActions.recoveryPassword({ email, recoveryToken, password }, callback)),
  forgotPassword: (email, callback) => dispatch(AuthActions.forgotPassword(email, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  PasswordRecovery,
);
