import React from 'react';
import {
  Col,
  Layout, message, Row,
  Spin,
  Divider,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { ContentActions } from '../../../app/redux/actions';
import { LoadingSelectors, ContentSelectors } from '../../../app/redux/reducers';
import { ReactComponent as ContentIcon } from '../../../app/assets/img/content_icon_blue.svg';
import ContentForm from '../../../app/forms/content/ContentForm';
import { ViewType } from '../../../app/enum/viewType';

class ContentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      contentDetailsUpdated: null,
    };
  }

  async componentDidMount() {
    const { getContentDetails, id, isDuplicating } = this.props;
    await getContentDetails(id);
    this.setState({ contentDetailsUpdated: this.props.contentDetails });
    if (isDuplicating) {
      this.setState({
        viewType: ViewType.DUPLICATE
      })
    }
    if (!id) {
      this.setState({
        viewType: ViewType.ADD,
      });
    }
  }

  updateContent(id, data) {
    const { updateContent, createContent, contentDetails } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD || this.state.viewType === ViewType.DUPLICATE) {
        createContent(data, () => {
          message.success(I18n.t('routes.panel.contentDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.contents.url'));
        });
      } else {
        updateContent(contentDetails.id, data, () => {
          message.success(I18n.t('routes.panel.contentDetails.messages.success'));
          navigate(I18n.t('routes.panel.contents.url'));
        });
      }
    } catch (e) {
      message.error(I18n.t('routes.panel.contentDetails.messages.errors.generic'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, contentDetailsUpdated } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <Content className="panel__layout__content">
        <div className="content-details">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <div className="panel__layout__content__title">
              <h2 className="panel__layout__content__title__value">
                <span className="panel__layout__content__title__value__icon">
                  <Icon component={ContentIcon} />
                </span>
                {
                  I18n.t(
                    viewType === ViewType.ADD || viewType === ViewType.DUPLICATE
                      ? 'routes.panel.contentDetails.pageTitleAdd'
                      : 'routes.panel.contentDetails.pageTitle',
                  )
                }
              </h2>
              <p className="panel__layout__content__title__value__description">
                {
                  I18n.t(
                    viewType === ViewType.ADD || viewType === ViewType.DUPLICATE
                      ? 'routes.panel.contentDetails.pageDescriptionAdd'
                      : 'routes.panel.contentDetails.pageDescription',
                  )
                }
              </p>
            </div>

            <Divider />

            <div>
              <Row>
                <Col span={24}>
                  {(contentDetailsUpdated || viewType === ViewType.ADD || viewType === ViewType.DUPLICATE) && (
                    <ContentForm
                      data={contentDetailsUpdated}
                      submitFunction={(contentId, data) => this.updateContent(contentId, data)}
                      viewType={viewType}
                      id={id}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  contentDetails: ContentSelectors.getContentDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentDetails: (id) => dispatch(ContentActions.getContentDetails(id)),
  updateContent: (id, data, callback) => dispatch(ContentActions.updateContent(id, data, callback)),
  createContent: (data, callback) => dispatch(ContentActions.createContent(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ContentDetails,
);
