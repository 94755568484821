import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col, Collapse, DatePicker, Divider, Empty, Layout, Popconfirm, Row, Select, Spin, Tooltip,
} from 'antd';
import moment from 'moment-timezone';
import { navigate } from '@reach/router';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined, PieChartOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import DataTable from '../../../components/shared/DataTable';
import {
  ContentActions, HospitalActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors, ContentSelectors, HospitalSelectors, LoadingSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/dashboard_icon_blue.svg';
import { ReactComponent as StatisticContentIcon } from '../../../app/assets/img/statisticContent_icon_blue.svg';
import StatisticCard from '../../../components/StatisticCard/StatisticCard';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import PieChart from '../../../components/PieChart';
import { getInitials } from '../../../app/utils/string';
import { getSpecializationTitleById } from '../../../app/enum/specializationType';
import { cleanPagination } from '../../../app/utils/pagination';
import { ProfileType } from '../../../app/enum/profileType';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class ContentStatistics extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      params: {
        ...cleanPagination,
        hospitalId: null,
        state: null,
        city: null,
        startDate: null,
        endDate: null,
      },
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  componentDidMount () {
    const {
      id,
      getDashboardSelectives,
      getContentStatisticsData,
    } = this.props;

    const {
      params,
    } = this.state;

    getDashboardSelectives(id);
    getContentStatisticsData(id, params);
  }

  csvExport = () => {
    const {
      id,
      exportUsers,
    } = this.props;

    const {
      params,
    } = this.state;

    exportUsers(id, params);
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  };

  applyAdvancedFilters = (e) => {
    e.preventDefault();

    const {
      id,
      getContentStatisticsData,
    } = this.props;

    const {
      params,
    } = this.state;

    getContentStatisticsData(id, params);
  };

  cleanAdvancedFilters = () => {
    window.location.reload();
  };

  handleChangeDate = (value, startDateName, endDateName) => {
    const { params } = this.state;

    if (value) {
      value[0] = value[0] && value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      value[1] = value[1] && value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      params[startDateName] = value[0].toDate();
      params[endDateName] = value[1].toDate();
    } else {
      params[startDateName] = null;
      params[endDateName] = null;
    }

    this.setState({ params });
  };

  parseSpecializationsForChart = (specializations) => (
    specializations.map((specialization) => ({
      id: getSpecializationTitleById(specialization.specializationId),
      label: getSpecializationTitleById(specialization.specializationId),
      value: parseInt(specialization.total, 10),
    }))
  );

  parseStatesForChart = (states) => (
    states.map((state) => ({
      id: state.stateName || I18n.t('routes.panel.contentStatistics.undefinedData'),
      label: state.stateName || I18n.t('routes.panel.contentStatistics.undefinedData'),
      value: state.total,
    }))
  );

  cleanEmptyData = (arr) => arr.filter((o) => o.name && o.name !== '');

  render () {
    const { Content } = Layout;
    const { Option } = Select;

    const {
      params,
    } = this.state;

    const {
      loading,
      contentStatisticsData,
      dashboardSelectives,
      me,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.contentStatistics.breadcrumb')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event)}>
                <Row gutter={16}>
                  <Col span={6}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.hospital')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={(params && params.hospitalId) || undefined}
                      showArrow
                      filterOption={false}
                      onChange={(val) => this.setState({ params: { ...params, hospitalId: val } })}
                      notFoundContent={(
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      )}
                      allowClear
                    >
                      {dashboardSelectives
                      && this.cleanEmptyData(dashboardSelectives.hospitals).map((d) =>
                        <Option key={d.id}>{d.name}</Option>)}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.state')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={(params && params.state) || undefined}
                      showArrow
                      filterOption={false}
                      onChange={(val) => this.setState({ params: { ...params, state: val } })}
                      notFoundContent={(
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      )}
                      allowClear
                    >
                      {dashboardSelectives
                      && this.cleanEmptyData(dashboardSelectives.states).map((d) =>
                        <Option key={d.id}>{d.name}</Option>)}
                    </Select>
                  </Col>
                  <Col span={6}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.city')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={(params && params.city) || undefined}
                      showArrow
                      filterOption={false}
                      onChange={(val) => this.setState({ params: { ...params, city: val } })}
                      notFoundContent={(
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      )}
                      allowClear
                    >
                      {dashboardSelectives
                      && this.cleanEmptyData(dashboardSelectives.cities).map((d) =>
                        <Option key={d.id}>{d.name}</Option>)}
                    </Select>
                  </Col>

                  <Col span={6}>
                    <div style={{ paddingBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.created')}
                    </div>
                    <RangePicker
                      style={{ width: '100%' }}
                      value={
                        params && params.startDate && params.endDate
                          ? [ moment(params.startDate), moment(params.endDate) ]
                          : []
                      }
                      placeholder={
                        [
                          I18n.t('shared.advancedFilters.form.startDate'),
                          I18n.t('shared.advancedFilters.form.endDate'),
                        ]
                      }
                      format="DD/MM/YYYY"
                      onChange={(val) => this.handleChangeDate(val, 'startDate', 'endDate')}
                    />
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="statistics">
            <Row>
              <Col span={12}>
                <h3>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={UserIcon} />
                  </span>
                  {I18n.t('routes.panel.userStatistics.pageTitle')}
                </h3>
              </Col>
            </Row>

            <Row gutter={[ 8, 16 ]}>
              <Col span={12}>
                <h6>
                  {I18n.t('routes.panel.userStatistics.bottomTitle')}
                </h6>
              </Col>
            </Row>

            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <Row gutter={[ 8, 48 ]}>
                <Col span={8}>
                  <StatisticCard
                    percentageValue={contentStatisticsData && contentStatisticsData.totalViewed}
                    title={I18n.t('routes.panel.userStatistics.cards.viewedContent')}
                  />
                </Col>
                <Col span={8}>
                  <StatisticCard
                    percentageValue={contentStatisticsData && contentStatisticsData.totalContents}
                    title={I18n.t('routes.panel.userStatistics.cards.consumedContent')}
                  />
                </Col>
              </Row>
            </Spin>

            <Row gutter={16}>
              {contentStatisticsData
              && contentStatisticsData.specializations
              && contentStatisticsData.specializations.length > 0
              && (
                <Col span={12}>
                  <PieChart data={this.parseSpecializationsForChart(contentStatisticsData.specializations)} />
                </Col>
              )}


              {contentStatisticsData
              && contentStatisticsData.states
              && contentStatisticsData.states.length > 0
              && (
                <Col span={12}>
                  <PieChart data={this.parseStatesForChart(contentStatisticsData.states)} />
                </Col>
              )}
            </Row>

            <br />
            <br />
            <br />

            <Row gutter={16}>
              <Col span={12}>
                <h6>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={StatisticContentIcon} />
                  </span>
                  {I18n.t('routes.panel.contentStatistics.pageSubtitleConsumedUsers')}
                </h6>
              </Col>

              <Col
                className="text-right"
                span={12}
              >
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <AdvancedButton
                    text={I18n.t('shared.exportCsvButtonText')}
                    onClick={() => this.csvExport()}
                  />
                )}

              </Col>
            </Row>

            <Divider />

            <DataTable
              params={params}
              notGetOnStart
              data={contentStatisticsData && { rows: contentStatisticsData.userList }}
              ref={this.dataTableRef}
              disablePagination
              columns={
                [
                  {
                    key: I18n.t('routes.panel.contentStatistics.dataTable.columns.photoUrl.key'),
                    render: (value, row) => (
                      value
                        ? <Avatar src={value} />
                        : (
                          <Avatar>
                            {getInitials(row.name)}
                          </Avatar>
                        )
                    ),
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.contentStatistics.dataTable.columns.name.key'),
                    title: I18n.t('routes.panel.contentStatistics.dataTable.columns.name.title'),
                    render: (value) => value || '--',
                  },
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.contentStatistics.dataTable.columns.email.key'),
                    title: I18n.t('routes.panel.contentStatistics.dataTable.columns.email.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.contentStatistics.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">
                        <Tooltip
                          title={I18n.t('routes.panel.contentStatistics.dataTable.columns.actions.goToDetailsText')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<IdcardOutlined />}
                            onClick={() => navigate(`${I18n.t('routes.panel.userInfo.url')}${id}`)}
                            target="_blank"
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t('routes.panel.contentStatistics.dataTable.columns.actions.goToEditText')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${I18n.t('routes.panel.userDetails.url')}${id}`)}
                            target="_blank"
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t('routes.panel.contentStatistics.dataTable.columns.actions.goToStatisticsText')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <a
                            type="link"
                            href={
                              `${I18n.t('routes.panel.userStatistics.url')}${id}`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              type="link"
                              icon={<PieChartOutlined />}
                            />
                          </a>
                        </Tooltip>
                        <Popconfirm
                          placement="left"
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.removeUser(id)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t('routes.panel.contentStatistics.dataTable.columns.actions.removeText')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]
              }
            />
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  me: AuthSelectors.getMe(state),
  loading: LoadingSelectors.getLoading(state),

  contentStatisticsData: ContentSelectors.getContentStatisticsData(state),
  dashboardSelectives: ContentSelectors.getDashboardSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentStatisticsData: (contentId, parameters) => dispatch(
    ContentActions.getContentStatisticsData(contentId, parameters),
  ),
  getDashboardSelectives: (contentId) => dispatch(ContentActions.getDashboardSelectives(contentId)),
  exportUsers: (contentId, params) => dispatch(ContentActions.exportUsers(contentId, params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentStatistics);
