import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from 'react-redux-i18n';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Moment from 'moment';
import AdvancedButton from '../shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../shared/AdvancedInput/AdvancedInput';
import AdvancedDragger from '../shared/AdvancedDragger/AdvancedDragger';
const years = [];
for (let i = Moment().year(); i >= 1980; i--) {
  years.push({ id: i.toString(), name: i });
}

class QuestionJustifications extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openedVideo: false,
      videoJustificationProgress: 0,
      videoJustificationAxiosSource: null,
      openedImage: false,
      imageJustificationProgress: 0,
      imageJustificationAxiosSource: null,
      openedAudio: false,
      audioJustificationProgress: 0,
      audioJustificationAxiosSource: null,
    };
  }

  render() {
    const {
      fieldChange,
      justification,
      justificationImageUrl,
      justificationVideoUrl,
      audioJustification,
      setQuestionState,
      customRequest,
    } = this.props;
    const {
      openedVideo,
      videoJustificationProgress,
      videoJustificationAxiosSource,
      openedImage,
      imageJustificationProgress,
      imageJustificationAxiosSource,
      openedAudio,
      audioJustificationProgress,
      audioJustificationAxiosSource,
    } = this.state;

    const fieldsList = [
      {
        visible: openedImage,
        switchVisibility: () => this.setState({ openedImage: !openedImage }),
        title: I18n.t('forms.newQuestion.labelImage'),
        accept: 'image/png,image/jpeg,image/jpg',
        label: I18n.t('forms.newQuestion.labelImageContent'),
        progressIcon: 'image',
        progress: imageJustificationProgress,
        fileList: justificationImageUrl,
        onRemove: () => {
          fieldChange('justificationImage', null);
          setQuestionState({ justificationImageUrl: [] });
        },
        customRequest: (onProgress, onSuccess, onError, file) =>
          customRequest(
            onProgress,
            onSuccess,
            onError,
            file,
            'justificationImageUrl',
            'imageJustificationProgress',
            'imageJustificationAxiosSource',
          ),
      },
      {
        visible: openedVideo,
        switchVisibility: () => this.setState({ openedVideo: !openedVideo }),
        title: I18n.t('forms.newQuestion.labelVideo'),
        accept: 'video/mp4,video/avi',
        label: I18n.t('forms.newQuestion.labelVideoContent'),
        progressIcon: 'video',
        progress: videoJustificationProgress,
        fileList: justificationVideoUrl,
        onRemove: () => {
          fieldChange('justificationVideo', null);
          setQuestionState({ justificationVideoUrl: [] });
        },
        customRequest: (onProgress, onSuccess, onError, file) =>
          customRequest(
            onProgress,
            onSuccess,
            onError,
            file,
            'justificationVideoUrl',
            'videoJustificatioProgress',
            'videoJustificatioAxiosSource',
          ),
      },
      {
        visible: openedAudio,
        switchVisibility: () => this.setState({ openedAudio: !openedAudio }),
        title: I18n.t('forms.newQuestion.labelAudioPodcast'),
        accept: 'audio/mp3, audio/m4a',
        label: I18n.t('forms.newQuestion.labelAudioContent'),
        progressIcon: 'audio',
        progress: audioJustificationProgress,
        fileList: audioJustification,
        onRemove: () => {
          fieldChange('justificationAudio', null);
          setQuestionState({ audioJustification: [] });
        },
        customRequest: (onProgress, onSuccess, onError, file) =>
          customRequest(
            onProgress,
            onSuccess,
            onError,
            file,
            'audioJustification',
            'audioJustificationProgress',
            'audioJustificationAxiosSource',
          ),
      },
    ];

    return (
      <div>
        <Row>
          <Col span={12}>
            <AdvancedInput
              label={I18n.t('forms.newQuestion.justification')}
              value={justification}
              onChange={(val) => fieldChange('justification', val)}
              isTextarea
            />
          </Col>
        </Row>
        <>
          {fieldsList.map((field) => (
            <>
              <Row>
                <Col span={10}>
                  {field.visible ? (
                    <MinusCircleOutlined
                      onClick={() => field.switchVisibility()}
                    />
                  ) : (
                    <PlusCircleOutlined
                      onClick={() => field.switchVisibility()}
                    />
                  )}
                  <AdvancedButton
                    type='link'
                    text={field.title}
                    onClick={() => field.switchVisibility()}
                  />
                </Col>
              </Row>
              {field.visible && (
                <Row>
                  <Col span={12}>
                    <AdvancedDragger
                      title={field.title}
                      customRequest={({
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                      }) =>
                        field.customRequest(
                          onProgress,
                          onSuccess,
                          onError,
                          file,
                        )
                      }
                      accept={field.accept}
                      fileList={field.fileList}
                      onRemove={() => {
                        field.onRemove();
                      }}
                      label={field.label}
                      progressIcon={field.progressIcon}
                      progress={field.progress}
                    />
                  </Col>
                </Row>
              )}
            </>
          ))}
        </>
      </div>
    );
  }
}

export default QuestionJustifications;
