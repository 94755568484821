export const StatisticCardType = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  USERS: 4,
  POSTED_CONTENT: 5,
  VIEWED_CONTENT: 6,
};

export const StatisticCardTypeArray = [
  { id: StatisticCardType.DAY, name: 'Acessos hoje' },
  { id: StatisticCardType.WEEK, name: 'Acessos na semana' },
  { id: StatisticCardType.MONTH, name: 'Acessos no mês' },
  { id: StatisticCardType.USERS, name: 'Acessos' },
  { id: StatisticCardType.POSTED_CONTENT, name: 'Conteúdos postados' },
  { id: StatisticCardType.VIEWED_CONTENT, name: 'Conteúdos acessados' },
];

export const GetStatisticCardType = (value) => {
  const type = StatisticCardTypeArray.find((o) => o.id === value);
  return type ? type.name : '';
};
