import React from 'react';
import {
  Col,
  Divider, Layout, message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { CourseActions } from '../../../app/redux/actions';
import { CourseSelectors, LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as CourseIcon } from '../../../app/assets/img/course_icon_blue.svg';
import ModuleForm from '../../../app/forms/module/ModuleForm';
import { ViewType } from '../../../app/enum/viewType';
import Modules from '../modules/Modules';

class ModuleDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      moduleDetails: null,
    };
  }

  async componentDidMount () {
    const { getModuleDetails, id, isDuplicating } = this.props;

    try {
      await getModuleDetails(id);
      this.setState({ moduleDetails: this.props.moduleDetails });

      if (isDuplicating) {
        this.setState({
          viewType: ViewType.DUPLICATE,
        });
      }

      if (!id) {
        this.setState({
          viewType: ViewType.ADD,
        });
      }
    } catch (err) {
      message.error(I18n.t('routes.panel.moduleDetails.messages.errors.generic'));
      navigate(I18n.t('routes.panel.modules.url'));
    }
  }

  async updateModule (id, data) {
    const { updateModule, createModule, moduleDetails } = this.props;
    let iscreateModule = false;

    try {
      if (this.state.viewType === ViewType.ADD || this.state.viewType === ViewType.DUPLICATE) {
        await createModule(data, () => {
          message.success(I18n.t('routes.panel.moduleDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.modules.url'));
        });
        iscreateModule = true;
      } else if (this.state.viewType === ViewType.EDIT) {
        await updateModule(moduleDetails.id, data, () => {
          message.success(I18n.t('routes.panel.moduleDetails.messages.success'));
          navigate(I18n.t('routes.panel.modules.url'));
        });
      }

      this.modulesList.getModules({
        pageSize: 10,
        total: 0,
        offset: 0,
        page: 1,
        data: null,
      });
      return iscreateModule;
    } catch (e) {
      message.error(I18n.t(`routes.panel.courseDetails.messages.errors.${e.message}`));
    }
  }

  render () {
    const { Content } = Layout;
    const { viewType, moduleDetails } = this.state;
    const {
      loading,
      id,
      isDuplicating,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content">
          <div className="module-details">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={CourseIcon} />
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD || viewType === ViewType.DUPLICATE
                        ? 'routes.panel.moduleDetails.pageTitleAdd'
                        : 'routes.panel.moduleDetails.pageTitle',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD || viewType === ViewType.DUPLICATE
                        ? 'routes.panel.moduleDetails.pageDescriptionAdd'
                        : 'routes.panel.moduleDetails.pageDescription',
                    )
                  }
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col span={24}>
                    {(moduleDetails || viewType === ViewType.ADD || viewType === ViewType.DUPLICATE) && (
                      <ModuleForm
                        data={moduleDetails}
                        submitFunction={(moduleId, data) => this.updateModule(moduleId, data)}
                        viewType={viewType}
                        id={id}
                        isDuplicating={isDuplicating}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} />
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
        <Modules
          isCreating
          ref={(ref) => { this.modulesList = ref; }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  moduleDetails: CourseSelectors.getCourseDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getModuleDetails: (id) => dispatch(CourseActions.getCourseDetails(id)),
  updateModule: (id, data, callback) => dispatch(CourseActions.updateCourse(id, data, callback)),
  createModule: (data, callback) => dispatch(CourseActions.createModule(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ModuleDetails,
);
