import getInstance from './config';

export default class QuestionDatabaseApi {
  static async getQuestionDatabasePaginated (params) {
    const instance = await getInstance(params);
    const { data } = await instance.get('/question-database/users', { params });
    return data;
  }

  static async getUsersQuestionDatabase (params) {
    const instance = await getInstance(params);
    const { data } = await instance.get('/user', { params });
    return data;
  }

  static async getQuestionDatabaseCorrectionsPaginated (params) {
    const instance = await getInstance(params);
    const { data } = await instance.get('/user-question-database-answer/historic', { params });
    return data;
  }

  static async create (params) {
    const instance = await getInstance();
    const { data } = await instance.post('/question-database/', params);
    return data;
  }

  static async fixQuestion (id, status) {
    const instance = await getInstance();
    const { data } = await instance.put(`/user-question-database-answer/${id}`, { status });
    return data;
  }

  static async delete (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/question-database/${id}`);
    return data;
  }
}