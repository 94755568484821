import getInstance from './config';

export default class CompanyApi {
  static async getCompaniesPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/company', { params });
    return data;
  }

  static async getCompanyDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/company/${id}`);
    return data;
  }

  static async update (id, companyData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/company/${id}`, companyData);
    return data;
  }

  static async createCompany (companyData) {
    const instance = await getInstance();
    const { data } = await instance.post('/company', companyData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/company/${id}`);
    return data;
  }

  static async getCompanySelectives () {
    const instance = await getInstance();
    const { data } = await instance.get('/company/selectives');
    return data;
  }
}
