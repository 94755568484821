import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import AssignStudentsRequests from '../../api/assignStudents';

export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const GET_STUDENTS_BY_QUIZ = 'GET_STUDENTS_BY_QUIZ';
export const GET_SELECTED_STUDENTS = 'GET_SELECTED_STUDENTS';

export const getAllStudents = (students) => async (dispatch) => {
  let allStudents;
  dispatch(addLoading())
  try {
    if(!students) {
      allStudents = await AssignStudentsRequests.getAllStudents();
    }
    dispatch({
      type: GET_ALL_STUDENTS,
      payload: allStudents || students,
    });
  } catch (err) {
    message.error(err)
  } finally {
    dispatch(removeLoading())
  }
};

export const getSelectedStudents = (params) => async (dispatch) => {
  dispatch(addLoading())

  try {
    const selectedStudents = await AssignStudentsRequests.getSelectedStudents(params);

    if(selectedStudents) {
      dispatch({
        type: GET_SELECTED_STUDENTS,
        payload: selectedStudents,
      });
    }
  } catch (err) {
    message.error(err)
  } finally {
    dispatch(removeLoading())
  }
};

export const getStudentsByQuiz = (params) => async (dispatch) => {
  let studentsByQuiz = []
  dispatch(addLoading())
  try {
    studentsByQuiz = await AssignStudentsRequests.getStudentsByQuiz(params);
    if(studentsByQuiz) {
      dispatch({
        type: GET_STUDENTS_BY_QUIZ,
        payload: studentsByQuiz,
      });
    }
  } catch (err) {
    message.error(err)
  } finally {
    dispatch(removeLoading())
    return studentsByQuiz
  }
};


export const updateSelectedStudents = (paramsToInclude) => async (dispatch) => {
  dispatch(addLoading())

  try {
    await AssignStudentsRequests.updateSelectedStudents(paramsToInclude);

    message.success(I18n.t('routes.panel.assignStudents.sectionAddOrRemoveStudentsByCourses.success'));
  } catch (err) {
    message.error(err)
  } finally {
    dispatch(removeLoading())
  }
};




