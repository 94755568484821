import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Row,
} from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import DataTable from '../../../components/shared/DataTable/DataTable';
import {
  CompanySelectors, CourseSelectors, LoadingSelectors, QuestionDatabaseSelectors
} from '../../../app/redux/reducers';
import {
  CompanyActions,
  CourseActions,
  QuestionDatabaseActions,
  UtilsActions,
} from '../../../app/redux/actions';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { getInitials, parsePhone, removeSpecialChars } from '../../../app/utils/string';
import { cleanPagination } from '../../../app/utils/pagination';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import UserPlanOption from '../../../app/enum/userPlanOption';
import * as DateUtils from '../../../app/utils/date';
import { getPhoneNumber } from '../../../app/utils/masks';
import { CourseType } from '../../../app/enum/courseType';

class PushNotificationDetails extends React.Component {
  constructor (props) {
    super(props);
    this.dataTableRef = React.createRef();
    this.state = {
      sendToAll: false,
      cityList: [],
      params: {
        name: '',
        companyId: '',
        email: '',
        courseId: '',
        companyIdNotInclude: undefined,
        plansNotInclude: undefined,
        ...this.props.questionDatabaseFilters,
        ...cleanPagination
      },
      form: {
        userIdList: null,
        endAt: '',
        startAt: '',
        planOption: this.props.questionDatabaseFilters?.plansNotInclude,
        companyId: this.props.questionDatabaseFilters?.companyIdNotInclude,
      },
      modalVisible: false,
      formToModal: {},
      courseList: this.props.courseList,
      company: '',
      companyList: [],
    };
    this.loadingUser = false;
    this.loadingSend = false;
  }

  async componentDidMount () {
    const {
      getCompanySelective,
      searchCourses,
    } = this.props;
    getCompanySelective();
    this.getCompanyList();
    await searchCourses({ type: CourseType.COURSE });
    this.props.getCompanySelective();
  }

  async onSubmit (e) {
    e.preventDefault();
    const { form, sendToAll } = this.state;
    const { assignUserQuestionDatabase, questionDatabasePaginated  } = this.props;
    const allUsers = questionDatabasePaginated && questionDatabasePaginated.rows.map(users => users.id)

    const isInvalidPeriod = DateUtils.validatePeriod(form.startAt, form.endAt, true)

    if ((!form.userIdList || form.userIdList.length === 0) && !sendToAll) {
      return message.error(I18n.t('routes.panel.questionDatabase.messages.error.invalidUsers'));
    }

    if (isInvalidPeriod) {
      return message.error(isInvalidPeriod)
    }

    if (form.planOption === '') {
      return message.error(I18n.t('routes.panel.questionDatabase.messages.error.invalidPlan'));
    }

    const params = {
      userIdList: sendToAll ? allUsers : form.userIdList,
      startAt: moment(form.startAt, 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      endAt: moment(form.endAt, 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      planOption: form.planOption,
      companyId: form.companyId,
    }

    try {
      await assignUserQuestionDatabase(params, () => {
        message.success(I18n.t('routes.panel.questionDatabase.messages.success.assigned'));
        navigate(I18n.t('routes.panel.questionDatabase.url'));
      })

    } catch (err) {
      return message.error(I18n.t('routes.panel.questionDatabase.messages.error.assigned'));
    }
  }

  fieldChange (name, value, callback) {
    const { params } = this.state;

    params[name] = value;
    this.setState({ params }, () => {
      if(callback) callback()
    });
  }

  fieldChangeForm (name, value) {
    const { form } = this.state;

    form[name] = value;
    this.setState({ form });
  }

  getUsers = async (params) => {
    params = {
      ...this.state.params,
      ...params,
      profileType: ProfileType.USER,
    };

    const { getUsersQuestionDatabasePaginated } = this.props;

    this.setState({ params });

    try {
      this.loadingUser = true;
      await getUsersQuestionDatabasePaginated(params);
    } finally {
      this.loadingUser = false;
      this.props.setUsersAttributionFilters(params)
    }
  }

  cleanAdvancedFilters = async () => {
    const params = {
      name: '',
      email: '',
      courseId: '',
      companyId: '',
      specializationId: null,
      state: null,
      city: '',
      hospitalId: '',
      contentId: '',
      quizId: '',
      course: '',
      company: '',
      ...cleanPagination
    };
    const form = {
      planOption: undefined,
      companyId: undefined,
      userIdList: null,
    }
    await this.getUsers(params);
    this.setState({ params, form });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    const {company} = this.state

    if (company) {
      params.companyId = company;
    }

    this.setState({params: {
      ...params,
      ...cleanPagination
    }})
    this.getUsers(params);
  };

  parseSpecialization = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1
      ? `${item.title}, `
      : item.title))).join('')
    : '');

  getColumns = (params) => params.map((o) => (
    {
      ...o,
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }
  ))

  onSelectRows = (rowsKeys) => {
    const { id } = this.props;

    if (!id) {
      const { form } = this.state;
      form.userIdList = rowsKeys;
      this.setState({ form });
    }
  }

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  onSelectCompany = async (val) => {
    this.setState({ company: val})

    this.getCourseByCompany(val);
  }

  getCourseByCompany = async (value) => {
    const { getCourseByCompany } = this.props;
    await getCourseByCompany(value, (data) => {
      this.setState({ courseList: data });
    });
  };

  getColorDiv = (value) => {
    const atualDate = moment();
    const expiryDate = moment(value);
    const diff = moment(expiryDate).diff(atualDate, 'days', true);

    if (diff < 0) {
      return (<div className="expiredPlan">{expiryDate.format('DD/MM/YYYY')}</div>);
    }

    if (diff >= 0 && diff < 16) {
      return (<div className="fifteenDaysPlan">{expiryDate.format('DD/MM/YYYY')}</div>);
    }

    return (<div className="planOk">{expiryDate.format('DD/MM/YYYY')}</div>);
  }

  handleChangeAtributionInfos = () => {
    const {companyIdNotInclude, plansNotInclude} = this.state.params

    this.setState({
      form: {
        ...this.state.form,
        userIdList: null,
      }
    })

    if (companyIdNotInclude && plansNotInclude) {
      this.getUsers(this.state.params)
    }
  }

  render () {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const {
      form, params, courseList, company, companyList
    } = this.state;

    const {
      questionDatabasePaginated,
      loading,
      id,
    } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-lg-12">
            <Content className="panel__layout__content panel__layout__content--breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <span>{I18n.t('routes.panel.pageTitle')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{I18n.t('routes.panel.questionDatabase.pageTitle')}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Content>

            <Content className="panel__layout__content panel__layout__content--advanced-filter">
              <Collapse
                className="advanced-filter"
              >
                <Panel
                  header={I18n.t('shared.advancedFilters.title')}
                  key="1"
                >
                  <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.name}
                          onChange={(val) => this.fieldChange('name', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.name')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.email}
                          onChange={(val) => this.fieldChange('email', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.email')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          disableSearch
                          options={companyList}
                          value={company}
                          onChange={(val) => this.onSelectCompany(val)}
                          onSelect={(val) => this.getCourseByCompany(val)}
                          label={I18n.t('shared.advancedFilters.form.company')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          value={params && params.courseId}
                          onChange={(val) => {
                            this.fieldChange('courseId', val);
                          }}
                          options={courseList}
                          label={I18n.t('shared.advancedFilters.form.parentId')}
                          disabled={!company}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="advanced-filter__search-button text-right"
                      >
                        <AdvancedButton
                          type="link"
                          text={I18n.t('shared.advancedFilters.clearButtonText')}
                          onClick={() => this.cleanAdvancedFilters()}
                        />
                        <AdvancedButton
                          htmlType="submit"
                          text={I18n.t('shared.advancedFilters.filterButtonText')}
                          icon={<SearchOutlined />}
                        />
                      </Col>
                    </Row>
                  </form>
                </Panel>
              </Collapse>
            </Content>

            <Content className="panel__layout__content panel__layout__content--advanced-filter">
              <Collapse
                className="advanced-filter"
                activeKey={1}
              >
                <Panel
                  header={I18n.t('routes.panel.questionDatabase.assignDetails.title')}
                  key="1"
                >
                  <form onSubmit={(event) => this.onSubmit(event)}>
                    <Row gutter={16}>
                      <Col span={7}>
                        <AdvancedMaskedInput
                          label={I18n.t('forms.user.startAt.label')}
                          value={form && form.startAt}
                          onChange={(val) => this.fieldChangeForm('startAt', val)}
                          mask='99/99/9999'
                          type='tel'
                        />
                      </Col>
                      <Col span={7}>
                        <AdvancedMaskedInput
                          label={I18n.t('forms.user.endAt.label')}
                          value={form && form.endAt}
                          onChange={(val) => this.fieldChangeForm('endAt', val)}
                          mask='99/99/9999'
                          type='tel'
                        />
                      </Col>
                      <Col span={7}>
                        <AdvancedSelect
                          value={form && form.planOption}
                          onChange={(val) => {
                            this.fieldChangeForm('planOption', val);
                            this.fieldChange('plansNotInclude', val, () => this.handleChangeAtributionInfos());
                          }}
                          options={[
                            {
                              id: UserPlanOption.BRONZE,
                              title: I18n.t('userPlanOption.bronze'),
                            },
                            {
                              id: UserPlanOption.SILVER,
                              title: I18n.t('userPlanOption.silver'),
                            },
                            {
                              id: UserPlanOption.GOLD,
                              title: I18n.t('userPlanOption.gold'),
                            },
                            {
                              id: UserPlanOption.BLACK,
                              title: I18n.t('userPlanOption.black'),
                            },
                          ]}
                          label={`${I18n.t('forms.user.plan.label')}`}
                        />
                      </Col>
                      <Col span={7}>
                        <AdvancedSelect
                          value={form && form.companyId}
                          onChange={(val) => {
                            this.fieldChangeForm('companyId', val);
                            this.fieldChange('companyIdNotInclude', val, () => this.handleChangeAtributionInfos());
                          }}
                          options={companyList}
                          label={`${I18n.t('forms.user.compay.label')}`}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="advanced-filter__search-button text-right"
                      >
                        <AdvancedButton
                          htmlType="submit"
                          text={I18n.t('routes.panel.questionDatabase.assignDetails.assign')}
                          disabled={!form.userIdList && !questionDatabasePaginated}
                        />
                      </Col>
                    </Row>
                  </form>
                </Panel>
              </Collapse>
            </Content>
          </div>
        </div>

        <form
          className="push-notification__form"
          onSubmit={(ev) => this.onSubmit(ev)}
        >
          <div className="row">
            <div className="col-lg-12">

              {!this.state.sendToAll && (
                <Content className="panel__layout__content">
                  <div className="push-notification">

                    <Row>
                      <Col span={12}>
                        <h3>
                          <span className="panel__layout__content__title__value__icon">
                            <UserAddOutlined />
                          </span>
                          {I18n.t('routes.panel.quiz.AssignStudents')}
                        </h3>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          getMethod={(parameters) => this.getUsers(parameters, true)}
                          data={questionDatabasePaginated}
                          loading={loading > 0 && this.loadingUser}
                          ref={this.dataTableRef}
                          params={params}
                          notGetOnStart
                          columns={this.getColumns(
                            [
                              {
                                width: '60px',
                                key: I18n.t('routes.panel.users.dataTable.columns.photoUrl.key'),
                                render: (value, row) => (
                                  value
                                    ? <Avatar src={value} />
                                    : (
                                      <Avatar>
                                        {getInitials(row.name)}
                                      </Avatar>
                                    )
                                ),
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.name.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.name.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.specializations.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.specializations.title'),
                                render: (value) => (value && value.length > 0 && this.parseSpecialization(value)) || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.cellphone.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.cellphone.title'),
                                render: (value) =>
                                  (value && getPhoneNumber(value) ? (
                                    <a
                                      href={`https://api.whatsapp.com/send?phone=${removeSpecialChars(
                                        parsePhone(value),
                                      )}`}
                                      target='_blank'
                                      rel='noreferrer noopener'
                                    >
                                      {getPhoneNumber(value)}{' '}
                                    </a>
                                  ) : (
                                    '--'
                                  ))
                              },
                              {
                                key: I18n.t('routes.panel.users.dataTable.columns.email.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.email.title'),
                                render: (value) => value || '--',
                              },
                              {
                                key: I18n.t('routes.panel.users.dataTable.columns.city.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.city.title'),
                                render: (value) => value || '--',
                              },
                            ],
                          )}
                          pagination={false}
                          bordered={false}
                          size="middle"
                          locale={{
                            emptyText: I18n.t('shared.nothingToShow'),
                          }}
                          selectable={!id || id === 'add'}
                          selectedRowKeys={form.userIdList}
                          rowSelection={form.userIdList}
                          onSelectRow={(rows) => this.onSelectRows(rows)}
                        />
                      </div>
                      <Col span={24}
                        align='right'>
                        <AdvancedButton
                          type="link"
                          href={`${I18n.t('routes.panel.questionDatabase.url')}`}
                          text={I18n.t('forms.goBackButtonText')}
                        />
                        <Divider type="vertical" />
                        <AdvancedButton
                          text={I18n.t('routes.panel.questionDatabase.assignDetails.assign')}
                          htmlType="submit"
                          disabled={!form.userIdList && !questionDatabasePaginated}
                        />
                      </Col>
                    </div>
                  </div>
                </Content>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  questionDatabasePaginated: QuestionDatabaseSelectors.getUserQuestionDatabase(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  loading: LoadingSelectors.getLoading(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  courseList: CourseSelectors.getCourseSelectives(state),
  questionDatabaseFilters: QuestionDatabaseSelectors.getQuestionDatabaseFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
  getCourseByCompany: (params, callback) => dispatch(UtilsActions.getCourseByCompany(params, callback)),
  getUsersQuestionDatabasePaginated: (params) => dispatch(QuestionDatabaseActions.getUsersQuestionDatabase(params)),
  setUsersAttributionFilters: (params) => dispatch(QuestionDatabaseActions.setUsersAttributionFilters(params)),
  getCompanySelective: () => dispatch(
    CompanyActions.getCompanySelectives(),
  ),
  assignUserQuestionDatabase: (id, callback) => dispatch(QuestionDatabaseActions.assignUserQuestionDatabase(id, callback)),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotificationDetails);
