import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import chatReducer from './reducers/chat';
import contentReducer from './reducers/content';
import categoryReducer from './reducers/category';
import companyReducer from './reducers/company';
import hospitalReducer from './reducers/hospital';
import statisticReducer from './reducers/statistic';
import courseReducer from './reducers/course';
import loadingReducer from './reducers/loading';
import pushNotificationReducer from './reducers/push-notification';
import quizReducer from './reducers/quiz';
import assignStudentsReducer from './reducers/assignStudents';
import filtersReducer from './reducers/filters';
import releaseNoteReducer from './reducers/releaseNote';
import questionDatabaseReducer from './reducers/questionDatabase';

export default () => {
  const store = createStore(
    combineReducers({
      i18n: i18nReducer,
      auth: authReducer,
      loading: loadingReducer,
      user: userReducer,
      content: contentReducer,
      category: categoryReducer,
      pushNotification: pushNotificationReducer,
      company: companyReducer,
      course: courseReducer,
      hospital: hospitalReducer,
      statistic: statisticReducer,
      quiz: quizReducer,
      filters: filtersReducer,
      chat: chatReducer,
      assignStudents: assignStudentsReducer,
      releaseNote: releaseNoteReducer,
      questionDatabase: questionDatabaseReducer,
    }),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );

  return store;
};
