import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Spin,
  Steps,
  Switch,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FileSearchOutlined,
  LeftOutlined,
  MessageOutlined,
  PlusSquareFilled,
  RightOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import axios from 'axios';
import Pdf from 'react-to-pdf';
import moment from 'moment';
import { CourseType } from '../../../app/enum/courseType';
import {
  AuthSelectors,
  CategorySelectors,
  CourseSelectors,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import {
  CategoryActions,
  ContentActions,
  CourseActions,
  QuizActions,
  UserActions,
} from '../../../app/redux/actions';
import { SpecializationList } from '../../../app/enum/specializationType';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import {
  Alternative_Answer,
  ALTERNATIVE_TYPE,
  DifficultyType,
  QuestionType,
  QuizType,
  QuizTypeEnum,
  shareType,
} from '../../../app/enum/questionEnum';
import DataTable from '../../../components/shared/DataTable/DataTable';
import {
  getSubStringTitle,
  joinElements,
  removeEmptyArray,
  removeEmptyField,
} from '../../../app/utils/functionsUtils';
import DragComponent from '../../../components/dnd-vertical-list/DndVericalList';
import PreviewQuestion from '../../../components/preview-question/PreviewQuestion';
import { getInitials, removeSpecialChars } from '../../../app/utils/string';
import { ProfileType } from '../../../app/enum/profileType';
import Logo from '../../../app/assets/img/ic_logo.svg';
import { cleanPagination } from '../../../app/utils/pagination';
import { generateAndDownloadExamePdf } from '../../../app/utils/pdf/quizPdf';

class NewExame extends React.Component {
  constructor (props) {
    super(props);
    this.myRef = React.createRef();
    this.usersPanelDataTableRef = React.createRef();
    this.state = {
      quizCreatedId: '',
      optionsPdf: {
        orientation: 'portrait',
        format: [ 1000, 250 ],
      },
      studendsFilterOpen: false,
      modalOpenAdd: false,
      modalOpenRemove: false,
      modalOpenRemoveAll: false,
      exporWithJustifications: false,
      exporWithAnswers: false,
      form: {
        name: '',
        isEnabled: true,
        type: QuizType.SIMULATION,
        description: '',
        note: '',
        coverImageName: '',
        iconImageName: '',
        duration: null,
        noDuration: '',
        numberOfQuestions: 0,
        exhibitionAt: '',
        expirationAt: '',
        questions: [],
        categories: [],
        users: [],
        inOrder: true,
        courses: [],
      },
      imageCoverUrl: [],
      imageFileProgress: 0,
      imageFileAxiosSource: null,
      mainCategory: '',
      secondaryCategory: [],
      userParams: {
        name: '',
        email: '',
        specializationId: null,
        city: '',
        state: '',
      },
      params: {
        categoriesId: [],
        type: '',
        answerType: '',
        difficulty: '',
        location: '',
        initialYear: '',
        finalYear: '',
        description: '',
        descriptionTwo: '',
        ...cleanPagination
      },
      parameters: {},
      question: [],
      selected: [],
      allSelected: [],
      insertQuestion: [],
      modalQuestion: false,
      modalVisible: false,
      modalSendQuizVisible: false,
      individualModalVisible: false,
      type: shareType.GROUP,
      url: '',
      easy: 0,
      medium: 0,
      hard: 0,
      users: [],
      addedUsers: [],
      removedUsers: [],
      removedCourses: [],
      courses: [],
      addedCourses: [],
      previewQuestions: [],
      caractersName: 0,
      current: 0,
      selectedRowKeys: [],
      dataTableUsersPage: 1,
      lastDataTableSelected: [],
      selectedUsersParams: [],
      selectedCourses: [],
      selectedCoursesParams: [],
      hasResponses: false,
      initialCourses: [],
    };
    this.pdfLink = React.createRef();
    this.dataTableRefUsers = React.createRef();
    this.dataTableRefUsersEdit = React.createRef();
    this.dataTableRefCourses = React.createRef();
    this.dataTableRefCoursesEdit = React.createRef();
  }

  async componentDidMount () {
    await this.props.getCategorySelectives();

    if (this.props.isDuplicating) {
      const { type, allSelected, params, secondaryCategory } = this.state;
      let {
        form,
        insertQuestion,
        easy,
        medium,
        hard,
        imageCoverUrl,
        mainCategory,
      } = this.state;
      await this.props.getQuizById(this.props.id);
      form = { ...this.props.quizEditing };

      if (form.note) {
        form.note = '';
      }

      insertQuestion = this.props.quizEditing.questions;
      this.props.quizEditing.questions.forEach((item) => {
        switch (item.difficulty) {

          case DifficultyType.EASY:
            easy++;
            break;
          case DifficultyType.MEDIUM:
            medium++;
            break;
          case DifficultyType.HARD:
            hard++;
            break;
          default:
            break;

        }

        allSelected.push(item.id);
      });

      if (this.props.quizEditing.coverImageName) {
        imageCoverUrl = [
          { name: this.props.quizEditing.coverImageName, uid: 70 },
        ];
      }

      mainCategory = this.props.quizEditing.categories.filter(
        (item) => !item.isMain,
      );

      if (mainCategory.length > 0) {
        mainCategory = mainCategory[0].id;
      }

      let secondaryCategoryAuxiliar = [];
      secondaryCategoryAuxiliar = this.props.quizEditing.categories.filter(
        (item) => !item.isMain,
      );

      if (secondaryCategoryAuxiliar.length > 0) {
        secondaryCategoryAuxiliar.forEach((item) =>
          secondaryCategory.push(item.id),
        );
      }

      this.setState({
        form,
        insertQuestion,
        easy,
        medium,
        hard,
        type,
        allSelected,
        imageCoverUrl,
        mainCategory,
        secondaryCategory,
      });
      await this.props.getQuestion({
        ...params,
        excludeQuestions: allSelected.join(),
      });
    }

    if (this.props.isEditing) {
      const {
        addedUsers,
        addedCourses,
        type,
        allSelected,
        params,
        secondaryCategory,
        parameters,
      } = this.state;
      let {
        form,
        insertQuestion,
        easy,
        medium,
        hard,
        imageCoverUrl,
        mainCategory,
      } = this.state;
      await this.props.getQuizById(this.props.id);
      this.setState({
        hasResponses: this.props.quizEditing.hasResponses.hasResponses,
      });
      form = { ...this.props.quizEditing };

      if (!form.note) {
        form.note = '';
      }

      insertQuestion = this.props.quizEditing.questions;
      this.props.quizEditing.questions.forEach((item) => {
        switch (item.difficulty) {

          case DifficultyType.EASY:
            easy++;
            break;
          case DifficultyType.MEDIUM:
            medium++;
            break;
          case DifficultyType.HARD:
            hard++;
            break;
          default:
            break;

        }

        allSelected.push(item.id);
      });

      if (
        this.props.quizEditing.courses &&
        this.props.quizEditing.courses.length > 0
      ) {
        this.props.quizEditing.courses.map((item) =>
          addedCourses.push(item.id),
        );
        await this.props.getCourseSelectives();

        if (addedCourses.length > 0) {
          await this.props.getCourses({
            ...parameters,
            includeCourses: addedCourses.join(),
          });
        }
      } else {
        await this.props.getCourseSelectives();
      }

      if (
        this.props.quizEditing.users &&
        this.props.quizEditing.users.length > 0
      ) {
        this.props.quizEditing.users.map((item) => addedUsers.push(item.id));

        if (addedUsers.length > 0) {
          await this.props.getUsers(
            { ...parameters, includeUsers: addedUsers.join() },
            ProfileType.ADMIN,
            true,
          );
        }
      }

      if (this.props.quizEditing.coverImageName) {
        imageCoverUrl = [
          { name: this.props.quizEditing.coverImageName, uid: 70 },
        ];
      }

      mainCategory = this.props.quizEditing.categories.filter(
        (item) => !item.isMain,
      );

      if (mainCategory.length > 0) {
        mainCategory = mainCategory[0].id;
      }

      let secondaryCategoryAuxiliar = [];
      secondaryCategoryAuxiliar = this.props.quizEditing.categories.filter(
        (item) => item.quizCategory.isMain !== true,
      );
      secondaryCategoryAuxiliar.forEach((item) =>
        secondaryCategory.push(item.id),
      );

      const courses = [];
      this.props.quizEditing.courses.forEach((item) => {
        courses.push(item.id);
      });

      this.setState({
        initialCourses: courses,
        form,
        insertQuestion,
        easy,
        medium,
        hard,
        addedUsers,
        addedCourses,
        type,
        allSelected,
        imageCoverUrl,
        mainCategory,
        secondaryCategory,
        courses,
      });

      if (
        this.props.quizEditing.courses &&
        this.props.quizEditing.courses.length > 0
      ) {
        const coursesAux = this.props.quizEditing.courses.map(
          (item) => item.id,
        );
        await this.props.getUsers(
          {
            ...parameters,
            pageSize: 50000,
            /* includeUsers: addedUsers.join() */ selectedCourses:
              coursesAux.join(),
          },
          ProfileType.ADMIN,
          true,
        );
      } else {
        await this.props.getUsers(
          { ...parameters },
          ProfileType.ADMIN,
          true,
          true,
        );
        await this.props.getCourses(
          { ...parameters, includeCourses: undefined },
          undefined,
          CourseType.COURSE,
          true,
        );
      }

      await this.props.getQuestion({
        ...params,
        excludeQuestions: allSelected.join(),
      });
    } else {
      await this.props.getCourseSelectives();
    }

    if (!this.props.isEditing) {
      const emptyParams = [];
      await this.props.getCourses(
        { ...emptyParams, includeCourses: undefined },
        undefined,
        CourseType.COURSE,
        true,
      );
      await this.props.getUsers(
        { ...emptyParams, pageSize: 50000, includeUsers: undefined },
        ProfileType.ADMIN,
        true,
        true,
      );
    }
  }

  setMainCategory (value) {
    this.setState({ mainCategory: value });
  }

  setSecundaryCategory (value) {
    this.setState({ secondaryCategory: value });
  }

  filterQuestions = (question) => {
    let response = { rows: [], count: 0 };
    const { insertQuestion } = this.state;

    if (question && question.rows.length > 0) {
      response = { ...question };

      for (let i = 0; i < insertQuestion.length; i++) {
        response.rows = response.rows.filter(
          (item) => insertQuestion[i].id !== item.id,
        );
      }
    }

    return response;
  };

  customRequest = async (
    onProgress,
    onSuccess,
    onError,
    file,
    type,
    progress,
    axiosSource,
    multiple = false,
  ) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const { form } = this.state;
    let { url } = this.state;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
        isPublic: true,
      });
      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });
      await uploadFile(
        newFile,
        fileNameAndUrl.url,
        newFile.type,
        onUploadProgress,
        this.state[axiosSource].cancelToken,
        () => {
          message.success(
            I18n.t('routes.panel.courseDetails.messages.fileSuccess'),
          );
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        const shortFileUrl =
          fileNameAndUrl.url.split(fileNameAndUrl.fileName)[0] +
          fileNameAndUrl.fileName;
        form.coverImageName = fileNameAndUrl.fileName;
        url = shortFileUrl;
        this.setState({ form, url });
        this.fieldChange(type, shortFileUrl);
      } else {
        this.handleMultFileChange(type, {
          fileName: fileNameAndUrl.fileName,
          title: file.name,
        });
      }
    } catch (error) {
      //
    }
  };

  verifyQuiz = (form) => {
    const { insertQuestion, mainCategory } = this.state;
    let validated = true;

    if (form.name === '') {
      validated = false;
      message.error(I18n.t('forms.newExame.messagesVerify.withoutName'));
    } else if (!form.noDuration && form.duration === '') {
      validated = false;
      message.error(I18n.t('forms.newExame.messagesVerify.withoutDuration'));
    } else if (
      form.categories &&
      (mainCategory === '' || mainCategory === [] || !mainCategory)
    ) {
      message.error(
        I18n.t('forms.newExame.messagesVerify.withoutMainCategory'),
      );
      validated = false;
    } else if (form.description === '') {
      validated = false;
      message.error(I18n.t('forms.newExame.messagesVerify.withoutDescription'));
    } else if (insertQuestion.length === 0) {
      message.error(I18n.t('forms.newExame.messagesVerify.withoutQuestions'));
      validated = false;
    } else if (form.exhibitionAt === '') {
      message.error(
        I18n.t('forms.newExame.messagesVerify.withoutExhibitionDate'),
      );
      validated = false;
    } else if (form.expirationAt === '') {
      message.error(
        I18n.t('forms.newExame.messagesVerify.withoutExpirationDate'),
      );
      validated = false;
    } else if (!moment(form.exhibitionAt).isBefore(form.expirationAt)) {
      message.error(
        I18n.t('forms.newExame.messagesVerify.durationDatesIncorrect'),
      );
      validated = false;
    } else if (
      (!form.duration || form.duration === '') &&
      !form.noDuration &&
      form.noDuration === ''
    ) {
      message.error(I18n.t('forms.newExame.messagesVerify.withoutDuration'));
      validated = false;
    }

    return validated;
  };

  sendQuiz = async () => {
    const {
      form,
      mainCategory,
      secondaryCategory,
      insertQuestion,
      addedUsers,
      users,
      courses,
      selectedRowKeys,
      selectedCourses,
      removedUsers,
      removedCourses,
      initialCourses,
    } = this.state;
    const { isEditing } = this.props;
    const categories = [];

    if (mainCategory && mainCategory !== '') {
      categories.push({ id: mainCategory, isMain: true });
    }

    if (secondaryCategory && secondaryCategory.length > 0) {
      secondaryCategory.forEach((item) =>
        categories.push({ id: item, isMain: false }),
      );
    }

    if (!isEditing) {
      delete form.users;
      delete form.courses;
      form.users = selectedRowKeys;
      form.courses = selectedCourses;
    }

    let quiz = form;
    const questions = [];
    const auxCourses = [];
    const auxUsers = [];

    if (this.verifyQuiz(quiz)) {
      if (!quiz.noDuration && typeof quiz.duration !== 'number') {
        const index = quiz.duration.indexOf(',');

        if (index !== -1) {
          quiz.duration = quiz.duration.replace(',', '.');
        }

        quiz.duration = parseFloat(quiz.duration);
      }

      if (quiz.noDuration || quiz.duration > 0) {
        insertQuestion.forEach((item, index) =>
          questions.push({ id: item.id, questionNumber: index + 1 }),
        );
        quiz.questions = questions;
        quiz.categories = categories;
        quiz.users = [];

        if (initialCourses && isEditing) {
          quiz.courses = courses.map((item) => ({ id: item }));
          quiz.removedCourses = initialCourses
            .filter((item) => !courses.includes(item))
            .map((o) => ({ id: o }));
        } else if (form.courses && form.courses.length > 0 && !isEditing) {
          selectedCourses.map((item) => auxCourses.push({ id: item }));
          quiz.courses = auxCourses;
        } else {
          quiz.courses = [];
        }

        if (this.props.isEditing) {
          try {
            await this.props.updateQuizById(this.props.id, {
              ...quiz,
              numberOfQuestions: parseInt(this.state.form.numberOfQuestions),
            });
            navigate(`${I18n.t('routes.panel.quiz.urlQuiz')}`);
          } catch (err) {
            //
          }
        } else {
          quiz = removeEmptyField(quiz);

          if (form.inOrder === false) {
            quiz.inOrder = false;
          }

          try {
            const quizCreated = await this.props.createQuiz({
              ...quiz,
              numberOfQuestions: parseInt(this.state.form.numberOfQuestions),
              isEnabled: true,
            });
            this.setState({
              quizCreatedId: quizCreated.id,
              modalSendQuizVisible: true,
            });
          } catch (err) {
            //
          }
        }
      } else {
        message.error(
          I18n.t('forms.newExame.messagesVerify.durationBellowZero'),
        );
      }
    }
  };

  addFilter = (field, value) => {
    const { params } = this.state;
    params[field] = value;
    this.setState({ params });
  };

  applyFilter = async (event, parameters) => {
    if (event) {
      event.preventDefault();
    }

    let params = { ...parameters, ...cleanPagination };

    if (
      params.categoriesId &&
      (params.categoriesId === '' || params.categoriesId.length === 0)
    ) {
      delete params.categoriesId;
    }

    if (
      params.initialYear &&
      params.initialYear !== '' &&
      params.initialYear &&
      params.finalYear !== ''
    ) {
      params.yearRange = `${params.initialYear},${params.finalYear}`;
    } else if (params.initialYear && params.finalYear !== '') {
      params.yearRange = `${params.initialYear}`;
    } else if (params.initialYear === '' && params.finalYear === '') {
      delete params.yearRange;
    }

    if (params.description) params = removeEmptyField(params);

    params = joinElements(params);
    this.setState({ params: { ...this.state.params, ...params }})
    const response = await this.props.getQuestion({
      ...params,
      description: params.description || params.descriptionTwo,
      excludeQuestions: this.state.allSelected.join(),
    });
    this.filterQuestions(response);
  };

  getQuestionsPaginated = async (params) => {
    params = removeEmptyField(params);
    params = joinElements(params);
    await this.props.getQuestion({
      yearRange:
        params.initialYear && params.finalYear
          ? `${params.initialYear},${params.finalYear}`
          : null,
      excludeQuestions: this.state.allSelected.join(),
      ...this.state.params,
      offset: params.offset || 0,
      ...params,
      description: params.description || params.descriptionTwo,
    });
    this.setState({ params: { ...this.state.params, ...params } })
  };

  getColorDiv = (value) => {
    switch (value) {

      case DifficultyType.EASY:
        return <div className='side-bar-difficulty easy' />;
      case DifficultyType.MEDIUM:
        return <div className='side-bar-difficulty medium' />;
      case DifficultyType.HARD:
        return <div className='side-bar-difficulty hard' />;
      default:
        return <div className='side-bar-difficulty' />;

    }
  };

  orderQuestions = (parametro) => {
    this.setState({ insertQuestion: parametro });
  };

  removeItem = (id) => {
    let { insertQuestion } = this.state;
    insertQuestion = insertQuestion.filter((item) => item.id !== id);
    this.setState({ insertQuestion });
  };

  removeQuestions = async () => {
    const { insertQuestion, params } = this.state;
    let parameters = { ...params };
    let { easy, medium, hard, allSelected } = this.state;
    const aux = insertQuestion.filter((item) => item.checked !== true);
    const questionsSelected = insertQuestion.filter(
      (item) => item.checked
    );
    const questions = this.props.question;
    questionsSelected.forEach((item) => {
      questions.rows.push(item);

      if (!this.props.isEditing) {
        allSelected = allSelected.filter((id) => item.id !== id);
      }
    });

    const AllQuestionsWithoutSelected = questionsSelected.filter(
      (o) => !aux.includes(o),
    );

    if (this.props.isEditing) {
      AllQuestionsWithoutSelected.forEach((item) => {
        allSelected = allSelected.filter((id) => item.id !== id);
      });
    }

    AllQuestionsWithoutSelected.forEach((item) => {
      if (item.difficulty === DifficultyType.EASY) {
        easy--;
      } else if (item.difficulty === DifficultyType.MEDIUM) {
        medium--;
      } else if (item.difficulty === DifficultyType.HARD) {
        hard--;
      }
    });
    parameters = removeEmptyField(parameters);
    parameters = joinElements(parameters);
    parameters = removeEmptyArray(parameters);
    await this.props.getQuestion({
      ...parameters,
      excludeQuestions: allSelected.join(),
    });
    this.setState({
      insertQuestion: aux,
      hard,
      medium,
      easy,
      allSelected,
    });
  };

  removeAllQuestions = async () => {
    const { params } = this.state;

    let { easy, medium, hard } = this.state;
    let parameters = { ...params };
    parameters = removeEmptyField(parameters);
    parameters = removeEmptyArray(parameters);
    parameters = joinElements(parameters);
    easy = 0;
    medium = 0;
    hard = 0;
    await this.props.getQuestion(parameters);
    this.setState({
      insertQuestion: [],
      hard,
      medium,
      easy,
      allSelected: [],
    });
  };

  addQuestions = async () => {
    const { selected, insertQuestion, params, allSelected } = this.state;
    let { easy, medium, hard } = this.state;
    let selectedAux = selected;
    const aux = insertQuestion;
    let response;

    for (let i = 0; i < insertQuestion.length; i++) {
      const indice = selectedAux.indexOf(insertQuestion[i].id);

      if (indice !== -1) {
        selectedAux = selectedAux.filter((item, index) => indice !== index);
      }
    }

    for (let i = 0; i < selectedAux.length; i++) {
      response = this.props.question.rows.filter(
        (item) => item.id === selectedAux[i],
      );
      // eslint-disable-next-line prefer-destructuring
      response = response[0];
      response.checked = false;

      if (response.difficulty === DifficultyType.EASY) {
        easy++;
      } else if (response.difficulty === DifficultyType.MEDIUM) {
        medium++;
      } else if (response.difficulty === DifficultyType.HARD) {
        hard++;
      }

      aux.push(response);
      allSelected.push(selectedAux[i]);
    }

    this.setState({
      insertQuestion: aux,
      selected: [],
      allSelected,
      hard,
      medium,
      easy,
    });
    this.applyFilter(null, { ...params, excludeQuestions: allSelected.join() });
  };

  onSelectRows = async (rowsKeys) => {
    let { selected } = this.state;
    selected = rowsKeys;
    this.setState({ selected });
  };

  onSelectUserRows = async (rowsKeys) => {
    let { users } = this.state;
    users = rowsKeys;
    this.setState({ users });
  };

  onSelectCoursesRows = async (rowsKeys, isAdd) => {
    if (isAdd) {
      let { selectedCourses } = this.state;
      selectedCourses = rowsKeys;
      const courses = rowsKeys;
      this.setState({ selectedCourses });
      await this.getDataUsersTable({}, false, false, courses);
      await this.getAddedCourses();
    } else {
      let { courses } = this.state;
      courses = rowsKeys;
      this.setState({ courses });
      await this.getDataUsersTable({}, false, false, courses);
      await this.getAddedCourses();
    }
  };

  setChecked = (isChecked, index) => {
    const { insertQuestion } = this.state;
    insertQuestion[index].checked = isChecked;
    this.setState({ insertQuestion });
  };

  cancelUploadFile = (axiosSource) => {
    this.state[axiosSource].cancel();
  };

  showModal = async () => {
    const { allSelected, params, imageCoverUrl } = this.state;
    let { url } = this.state;
    let parameters = { ...params };
    parameters = removeEmptyField(parameters);
    parameters = removeEmptyArray(parameters);
    parameters = joinElements(parameters);
    let response = [];

    if (allSelected.length > 0) {
      try {
        response = await this.props.getQuestion(
          {
            orderBy: 'number',
            isDesc: false,
            includeQuestions: allSelected.join(),
          },
          true,
        );

        if (imageCoverUrl) {
          const fileUrl = await this.props.getUrlToMedia(imageCoverUrl[0].name);
          url = fileUrl.url;
        }
      } catch (err) {
        //
      }
    }

    this.setState({
      modalVisible: true,
      url,
      previewQuestions: response,
    });
  };

  handleCancelModal = async () => {
    this.setState({
      modalVisible: false,
    });
  };

  getAddedCourses = async (parameters) => {
    const { selectedCourses, courses } = this.state;

    if (selectedCourses.length > 0 && courses.length === 0) {
      await this.props.getCourses({
        ...parameters,
        includeCourses: selectedCourses.join(),
      });
    } else if (courses.length > 0 && selectedCourses.length === 0) {
      await this.props.getCourses({
        ...parameters,
        includeCourses: courses.join(),
      });
    }

    if (parameters) {
      this.setState({ selectedCoursesParams: parameters });
    }
  };

  removeAddedCourses = async (val, isEditing) => {
    const { selectedCourses, selectedCoursesParams, courses, removedCourses } =
      this.state;

    if (!isEditing) {
      const i = selectedCourses.indexOf(val.id);
      let courseList = null;

      if (i === -1) {
        courseList = selectedCourses;
      } else {
        courseList = selectedCourses.filter((item) => {
          return item !== val.id;
        });
      }

      if (courseList.length > 0) {
        await this.props.getCourses({
          ...selectedCoursesParams,
          includeCourses: courseList.join(),
        });
      } else {
        await this.props.getCourses(
          {
            ...selectedCoursesParams,
            includeCourses: undefined,
          },
          undefined,
          CourseType.COURSE,
          true,
        );
      }

      await this.getDataUsersTable({}, false, false, courseList);
      this.setState({ selectedCourses: courseList });
    } else {
      const i = courses.indexOf(val.id);
      let courseList = null;

      if (i === -1) {
        courseList = courses;
      } else {
        courseList = courses.filter((item) => {
          return item !== val.id;
        });
      }

      if (courseList.length > 0) {
        await this.props.getCourses({
          ...selectedCoursesParams,
          includeCourses: courseList.join(),
        });
      } else {
        await this.props.getCourses(
          {
            ...selectedCoursesParams,
            includeCourses: undefined,
          },
          undefined,
          CourseType.COURSE,
          true,
        );
      }

      await this.getDataUsersTable({}, false, false, courseList);
      this.setState({ courses: courseList });
    }
  };

  showModalQuestion = async (id) => {
    const response = await this.props.getQuestionById(id, true);
    this.setState({
      modalQuestion: true,
      question: response,
    });
  };

  handleCancelModalQuestion = () => {
    this.setState({
      modalQuestion: false,
    });
  };

  showindividualModal = async () => {
    this.setState({
      individualModalVisible: true,
    });
  };

  handleCancelindividualModal = async () => {
    await this.getDataUsersTable({}, true);
    this.setState({
      individualModalVisible: false,
    });
  };

  handleOkModalSendQuiz = async () => {
    this.setState({
      modalSendQuizVisible: false,
    });
    navigate(
      `${I18n.t('routes.panel.quiz.urlQuizAddUsers')}${
        this.state.quizCreatedId
      }`,
    );
  };

  handleCancelModalSendQuiz = async () => {
    this.setState({
      modalSendQuizVisible: false,
    });
    navigate(`${I18n.t('routes.panel.quiz.urlQuiz')}`);
  };

  changeTypeShare = (value) => {
    const { form } = this.state;
    let { courses, users } = this.state;

    if (value.target.value === shareType.INDIVIDUAL) {
      this.showindividualModal();
    }

    form.courses = [];
    form.users = [];
    courses = [];
    users = [];
    this.setState({
      courses,
      users,
      type: value.target.value,
      form,
    });
  };

  getUsers = async (params) => {
    const { addedUsers } = this.state;

    if (this.props.isEditing && addedUsers.length > 0) {
      params = { ...params, excludeUsers: addedUsers.join() };
    }

    this.setState({ params: { ...params } });
    this.props.getUsers({
      ...params,
      pageSize: 50000,
      profileType: ProfileType.USER,
    });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getUsers({ ...params, offset: 0 });
  };

  parseSpecialization = (value) =>
    (value
      ? value
        .map((item, i, self) =>
          (i !== self.length - 1 ? `${item.title}, ` : item.title),
        )
        .join('')
      : '');

  onChangeNotes = (value) => {
    const { form } = this.state;

    if (form.note.length + value.length < 1500) {
      if (form.note[form.note.length - 1] === '\n') {
        form.note += `${value}\n`;
      } else if (form.note.length === 0) {
        form.note += `${value}\n`;
      } else {
        form.note += `\n${value}\n`;
      }

      this.setState({ form, notesAux: value });
    }
  };

  onChangeTextArea = (value) => {
    const { form } = this.state;

    if (value[value.length - 1] === '\n' && value.length > form.note.length) {
      value += '• ';
      form.note = value;
    } else if (form.note.length === 0) {
      value = `• ${value}`;
      form.note = value;
    } else {
      form.note = value;
    }

    this.setState({ form });
  };

  getDataCourseTable = async (parameters) => {
    const { addedCourses } = this.state;

    if (addedCourses.length > 0) {
      await this.props.getCourses({
        ...parameters,
        includeCourses: addedCourses.join(),
      });
    }
  };

  getDataUsersTable = async (parameters, addUsers, removeUsers, courses) => {
    if (courses && courses.length > 0) {
      await this.props.getUsers(
        {
          ...parameters,
          pageSize: 50000,
          /* includeUsers: allUsers.join(), */ selectedCourses:
            courses.join() /* excludeUsers: removedUsers.join() */,
        },
        ProfileType.ADMIN,
        true,
      );
    } else if (courses && courses.length === 0) {
      await this.props.getUsers(
        {
          ...parameters,
          /* includeUsers: allUsers.join(), */ selectedCourses:
            undefined /* excludeUsers: removedUsers.join() */,
        },
        ProfileType.ADMIN,
        true,
        true,
      );
    }
  };

  returnData = () => {
    const { type } = this.state;

    if (type === shareType.INDIVIDUAL) {
      return this.props.usersPaginatedSecondary;
    }

    return this.props.coursesPaginated;
  };

  getStyle = () => {
    if (this.state.url) {
      return {
        backgroundImage: `url(${this.state.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        width: '100%',
        height: '10%',
      };
    }
  };

  getOptions = () => {
    const { form } = this.state;
    const options = [
      {
        id: QuestionType.MULTIPLEANSWER,
        name: I18n.t('forms.newQuestion.labelQuestionMultipleAnswer'),
      },
    ];

    if (form.type === QuizType.SIMULATION) {
      return options.concat({
        id: QuestionType.OTHER,
        name: I18n.t('forms.newQuestion.labelQuestionOther'),
      });
    }

    return options;
  };

  changeStep = async (current) => {
    this.setState({ current });
    this.myRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  onSelectChange = (selectedRows, page) => {
    const { selectedRowKeys, dataTableUsersPage, lastDataTableSelected } =
      this.state;
    const selected = [ ...selectedRows, ...selectedRowKeys ];

    if (page === dataTableUsersPage) {
      if (selectedRows.length < lastDataTableSelected.length) {
        const difference = lastDataTableSelected.filter(
          (x) => selectedRows.indexOf(x) === -1,
        );

        const i = selected.indexOf(...difference);
        selected.splice(i, 1);
      }
    }

    const selectedId = [];
    selected.forEach((c) => {
      if (!selectedId.includes(c)) {
        selectedId.push(c);
      }
    });
    this.setState({
      selectedRowKeys: selectedId,
      dataTableUsersPage: page,
      lastDataTableSelected: selectedRows,
    });
  };

  changeUserParams (name, val) {
    const { userParams } = this.state;
    userParams[name] = val;
    this.setState({ userParams });
  }

  changeStudendsFilterOpen () {
    const { studendsFilterOpen } = this.state;
    this.setState({ studendsFilterOpen: !studendsFilterOpen });
  }

  fieldChange (name, value) {
    const { form, params } = this.state;
    let { caractersName } = this.state;

    if (
      name === 'name' &&
      (value.length <= 40 || form.name.length > value.length)
    ) {
      caractersName = value.length;
      form[name] = value;
    } else if (name !== 'name') {
      form[name] = value;
    }

    if (name === 'type' && value === QuizType.TEST) {
      form.noDuration = true;
      form.duration = null;
      params.type = QuestionType.MULTIPLEANSWER;
      this.applyFilter();
    } else if (name === 'type' && value !== QuizType.TEST) {
      params.type = '';
      this.applyFilter();
    }

    if (name === 'noDuration') {
      form.duration = null;
    }

    this.setState({ form, caractersName, params });
  }

  parsePhone (value) {
    return (
      (value &&
        value.slice(0, 2) === '55' &&
        value.length === 14 &&
        `${value.slice(0, 2)} (${value.slice(3, 5)}) ${value.slice(
          5,
          10,
        )}-${value.slice(10)}`) ||
      (value &&
        value.split(' ').length > 1 &&
        `${value.split(' ')[0]} - ${value.split(' ')[1]}`) ||
      value
    );
  }

  cleanUserFilter () {
    const userParams = {
      name: '',
      email: '',
      specializationId: null,
      city: '',
      state: '',
    };

    this.setState({ userParams });
  }

  clearFilter = async () => {
    const params = {
      categoriesId: [],
      type: '',
      answerType: [],
      difficulty: '',
      location: '',
      initialYear: '',
      finalYear: '',
      description: '',
      ...cleanPagination
    };

    this.setState({ params });

    let { easy, medium, hard } = this.state;
    const parameters = { ...params };
    easy = 0;
    medium = 0;
    hard = 0;
    await this.props.getQuestion(parameters);
  };

  handleOkHasResponseAlertModal = () => {
    if (this.state.modalOpenRemoveAll) {
      this.removeAllQuestions();
    }

    if (this.state.modalOpenRemove) {
      this.removeQuestions();
    }

    if (this.state.modalOpenAdd) {
      this.addQuestions();
    }

    this.setState({
      modalOpenRemoveAll: false,
      modalOpenRemove: false,
      modalOpenAdd: false,
    });
  };

  handleCancelHasResponseAlertModal = () => {
    this.setState({
      modalOpenRemoveAll: false,
      modalOpenRemove: false,
      modalOpenAdd: false,
    });
  };

  removeUser (user, isEditing) {
    const { addedUsers, selectedUsersParams, removedUsers, selectedRowKeys } =
      this.state;

    if (isEditing) {
      const i = addedUsers.indexOf(user.id);
      let userList = null;

      if (i === -1) {
        userList = addedUsers;
      } else {
        userList = addedUsers.filter((item) => {
          return item !== user.id;
        });
      }

      const users = {
        userList,
        removedUsers: [ ...removedUsers, user.id ],
      };
      this.setState({ addedUsers: userList });
      this.setState({ removedUsers: users.removedUsers });
      this.getDataUsersTable(selectedUsersParams, false, users);
    } else {
      const i = selectedRowKeys.indexOf(user.id);
      let userList = null;

      if (i === -1) {
        userList = selectedRowKeys;
      } else {
        userList = selectedRowKeys.filter((item) => {
          return item !== user.id;
        });
      }

      const users = {
        userList,
        removedUsers: [ ...removedUsers, user.id ],
      };
      this.setState({ selectedRowKeys: userList });
      this.setState({ removedUsers: users.removedUsers });
      this.getDataUsersTable(selectedUsersParams, false, users);
    }
  }

  handleExportPdfButtonClick = async () => {
    let coverImageUrl = undefined
    if (this.state.imageCoverUrl && this.state.imageCoverUrl.length > 0) {
      const fileUrl = await this.props.getUrlToMedia(this.state.imageCoverUrl[0].name);
      coverImageUrl = fileUrl.url;
    }

    generateAndDownloadExamePdf(
      I18n.t('forms.newQuestion.exportPdf.fileName'),
      I18n.t('forms.newQuestion.labelPreviewQuestion'),
      this.state.form.name,
      this.state.form.description,
      this.state.previewQuestions,
      coverImageUrl,
      this.state.exporWithJustifications,
      this.state.exporWithAnswers
    )
  }

  render () {
    const { Content } = Layout;
    const {
      form,
      mainCategory,
      secondaryCategory,
      params,
      modalVisible,
      modalQuestion,
      caractersName,
      studendsFilterOpen,
      selectedRowKeys,
    } = this.state;

    const { TextArea } = Input;
    const { Step } = Steps;
    const {
      categorySelectives,
      loading,
      usersPaginated,
      isEditing,
    } = this.props;
    const modalPreviewQuestion = (item) => (
      <Modal
        visible={modalQuestion}
        onOk={this.handleCancelModalQuestion}
        onCancel={this.handleCancelModalQuestion}
      >
        <div className='header_modal'>
          <div className='water_marc_logo'>
            <img src={Logo}
              width='100%'
              height='100%'
              alt='' />
          </div>
          <div className='title_header'>
            {I18n.t('forms.newQuestion.labelPreviewQuestion')}
          </div>
        </div>
        <PreviewQuestion
          key={item.id}
          location={item?.location}
          year={item?.year}
          description={item?.description}
          imageUrl={item?.imageFileUrl}
          audioUrl={item?.audioFileUrl}
          videoUrl={item?.videoFileUrl}
          answers={item?.answers}
          item={item}
          isEditing={this.props.editingQuestion}
          justification={item.justification}
          justificationImage={item.justificationImageUrl}
          justificationVideo={item.justificationVideoUrl}
          justificationAudio={item.justificationAudioUrl}
          essayOptions={
            item?.type === Alternative_Answer.DISSERTATION &&
            item?.answers.map((value) => value.labelType)
          }
        />
      </Modal>
    );

    const modalPreviewExame = (questionsToPreview) => {
      let notes = form.note;

      if (notes && notes !== '') {
        notes = notes.split('\n');
        notes = notes.filter((item) => item !== '');

        if (notes.length === 0) {
          notes = null;
        }
      } else {
        notes = null;
      }

      return (
        <Modal
          visible={modalVisible}
          onOk={this.handleCancelModal}
          onCancel={this.handleCancelModal}
          width='70%'
          footer={[
            <>
              <div>
                <span className='mr-1'>{I18n.t('forms.newQuestion.exportPdf.incluse.justifications')}</span>
                <Checkbox onChange={(e) => this.setState({exporWithJustifications: e.target.checked})}/>
              </div>
              <div>
                <span className='mr-1'>{I18n.t('forms.newQuestion.exportPdf.incluse.answers')}</span>
                <Checkbox onChange={(e) => this.setState({exporWithAnswers: e.target.checked})}/>
              </div>
              <AdvancedButton
                text={I18n.t('shared.exportPdfButton')}
                onClick={this.handleExportPdfButtonClick}
              />
            </>
          ]}
        >
          <div className='modal_margin'
            ref={this.pdfLink}>
            <div className='header_modal'>
              <div className='water_marc_logo'>
                <img src={Logo}
                  width='100%'
                  height='100%'
                  alt='' />
              </div>
              <div className='title_header'>
                {I18n.t('forms.newQuestion.labelPreviewQuestion')}
              </div>
            </div>
            <div style={this.getStyle()}
              className='margin_top margin_bottom'>
              <div
                style={{ background: 'rgba(0, 89, 166, 0.53)' }}
                className='margin_top margin_bottom padding_horizontal_3 padding_vertical_3'
              >
                <Row>
                  <Col span={24}>
                    <h6 className='color_white'>{form.name}</h6>
                  </Col>
                </Row>
                {notes?.map((item) => (
                  <Row key={item}>
                    <Col span={24}>
                      <div className='color_white'>{item}</div>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span={24}>
                    <h6 className='color_white margin_top margin_Bottom'>
                      {I18n.t('forms.newExame.exameDescript')}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className='color_white'>{form.description}</p>
                  </Col>
                </Row>
              </div>
            </div>
            {questionsToPreview?.length > 0 &&
              questionsToPreview?.map((item, index) => (
                <div key={item.id}>
                  <h6>{index + 1}.</h6>
                  <PreviewQuestion
                    location={item?.location}
                    year={item?.year}
                    description={item?.description}
                    imageUrl={item?.imageFileUrl}
                    audioUrl={item?.audioFileUrl}
                    videoUrl={item?.videoFileUrl}
                    answers={item?.answers}
                    justification={item.justification}
                    justificationImage={item.justificationImageUrl}
                    justificationVideo={item.justificationVideoUrl}
                    justificationAudio={item.justificationAudioUrl}
                    isEditing={this.props.editingQuestion}
                    essayOptions={
                      item?.type === Alternative_Answer.DISSERTATION &&
                      item?.answers.map((value) => value.labelType)
                    }
                  />
                </div>
              ))}
          </div>
        </Modal>
      );
    };

    const modalSendQuiz = () => {
      return (
        <Modal
          visible={this.state.modalSendQuizVisible}
          onOk={this.handleOkModalSendQuiz}
          onCancel={this.handleCancelModalSendQuiz}
          okText={I18n.t('routes.panel.companies.sendQuizModal.onText')}
          cancelText={I18n.t('routes.panel.companies.sendQuizModal.cancelText')}
          width='30%'
        >
          <div className='modal_margin'>
            <div style={this.getStyle()}
              className='margin_top'>
              <Row>
                <Col span={24}>
                  <p className='text-center'>
                    {I18n.t('routes.panel.companies.sendQuizModal.content.one')}
                    <br />
                    <br />
                    {I18n.t('routes.panel.companies.sendQuizModal.content.two')}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      );
    };

    const individualModal = () => (
      <Modal
        title={I18n.t('forms.newExame.lableInidividualModal')}
        visible={this.state.individualModalVisible}
        onOk={this.handleCancelindividualModal}
        onCancel={this.handleCancelindividualModal}
        width='90%'
        className='modal_top_px'
      >
        <Col>
          <form
            onSubmit={(event) =>
              this.applyAdvancedFilters(event, this.state.userParams)
            }
          >
            <Row gutter={24}>
              <Col span={8}>
                <AdvancedInput
                  value={this.state.userParams.name}
                  onChange={(e) => this.changeUserParams('name', e)}
                  placeholder={I18n.t('shared.type')}
                  label={I18n.t('shared.advancedFilters.form.name')}
                />
              </Col>
              <Col span={8}>
                <AdvancedInput
                  value={this.state.userParams.email}
                  onChange={(e) => this.changeUserParams('email', e)}
                  placeholder={I18n.t('shared.type')}
                  label={I18n.t('shared.advancedFilters.form.email')}
                />
              </Col>
              <Col span={8}>
                <AdvancedSelect
                  options={SpecializationList}
                  label={I18n.t('shared.advancedFilters.form.specialization')}
                  value={
                    this.state.userParams &&
                    this.state.userParams.specializationId
                  }
                  onChange={(val) =>
                    this.changeUserParams('specializationId', val)
                  }
                />
              </Col>
              <Col span={8}>
                <AdvancedInput
                  value={this.state.userParams && this.state.userParams.city}
                  onChange={(val) => this.changeUserParams('city', val)}
                  placeholder={I18n.t('shared.type')}
                  label={I18n.t('shared.advancedFilters.form.city')}
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col
                span={24}
                className='advanced-filter__search-button text-right'
              >
                <AdvancedButton
                  type='link'
                  text={I18n.t('shared.advancedFilters.clearButtonText')}
                  onClick={() => this.cleanUserFilter()}
                />
                <AdvancedButton
                  htmlType='submit'
                  text={I18n.t('shared.advancedFilters.filterButtonText')}
                  icon={<SearchOutlined />}
                />
              </Col>
            </Row>
          </form>
        </Col>
        <Row>
          <DataTable
            getMethod={(p) => this.getUsers(p)}
            data={usersPaginated}
            loading={loading > 0}
            ref={this.usersPanelDataTableRef}
            selectable
            selectedRowKeys={selectedRowKeys}
            onSelectRow={this.onSelectChange}
            showSizeChanger
            pageSizeOptions={
              usersPaginated &&
              usersPaginated.count &&
              usersPaginated.count > 100
                ? [ '10', '50', '100', usersPaginated.count ]
                : [ '10', '20', '50', '100' ]
            }
            params={this.state.userParams}
            columns={[
              {
                key: I18n.t(
                  'routes.panel.users.dataTable.columns.photoUrl.key',
                ),
                render: (value, row) =>
                  (value ? (
                    <Avatar src={value} />
                  ) : (
                    <Avatar>{getInitials(row.name)}</Avatar>
                  )),
              },
              {
                ellipsis: true,
                key: I18n.t('routes.panel.users.dataTable.columns.name.key'),
                title: I18n.t(
                  'routes.panel.users.dataTable.columns.name.title',
                ),
                render: (value) => value || '--',
              },
              {
                ellipsis: true,
                key: I18n.t('routes.panel.users.dataTable.columns.email.key'),
                title: I18n.t(
                  'routes.panel.users.dataTable.columns.email.title',
                ),
                render: (value) => value || '--',
              },
              {
                ellipsis: true,
                key: I18n.t(
                  'routes.panel.users.dataTable.columns.cellphone.key',
                ),
                title: I18n.t(
                  'routes.panel.users.dataTable.columns.cellphone.title',
                ),
                render: (value) =>
                  (this.parsePhone(value) && value !== 'null null' ? (
                    <a
                      href={`https://api.whatsapp.com/send?phone=${removeSpecialChars(
                        this.parsePhone(value),
                      )}`}
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      {this.parsePhone(value)}{' '}
                      <MessageOutlined className='icon_new_exame' />
                    </a>
                  ) : (
                    '--'
                  )),
              },
              {
                ellipsis: true,
                key: I18n.t(
                  'routes.panel.users.dataTable.columns.specializations.key',
                ),
                title: I18n.t(
                  'routes.panel.users.dataTable.columns.specializations.title',
                ),
                render: (value) =>
                  (value &&
                    value.length > 0 &&
                    this.parseSpecialization(value)) ||
                  '--',
              },
              {
                ellipsis: true,
                key: I18n.t('routes.panel.users.dataTable.columns.city.key'),
                title: I18n.t(
                  'routes.panel.users.dataTable.columns.city.title',
                ),
                render: (value) => value || '--',
              },
            ]}
          />
        </Row>
      </Modal>
    );

    const hasResponseAlertModal = () => {
      return (
        <Modal
          visible={
            this.state.modalOpenRemoveAll ||
            this.state.modalOpenRemove ||
            this.state.modalOpenAdd
          }
          onOk={this.handleOkHasResponseAlertModal}
          onCancel={this.handleCancelHasResponseAlertModal}
          width='90%'
          style={{ textAlign: 'center' }}
          className='modal_top_px'
        >
          <Col>
            <Row>
              <h5 style={{ marginTop: '30px' }}>
                {I18n.t('forms.newExame.hasResponseAlertModal')}
              </h5>
            </Row>
          </Col>
        </Modal>
      );
    };

    return (
      <>
        {modalSendQuiz()}
        {modalPreviewExame(this.state.previewQuestions)}
        {individualModal()}
        {modalPreviewQuestion(this.state.question)}
        {hasResponseAlertModal()}
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.quiz.newExame')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Spin spinning={loading > 0}
          tip={I18n.t('shared.loading')}>
          <Content className='panel__layout__content'>
            <div className='users'>
              <Row>
                <Col span={12}>
                  <h3>
                    <div ref={this.myRef} />
                    <span className='panel__layout__content__title__value__icon'>
                      <Icon component={QuizIcon} />
                    </span>
                    {this.props.isEditing
                      ? I18n.t('routes.panel.quiz.pageEditingExame')
                      : I18n.t('routes.panel.quiz.pageNewExame')}
                  </h3>
                </Col>
              </Row>
              <Row>
                <h6>{I18n.t('forms.newExame.description')}</h6>
              </Row>
              <Divider />
              <Row gutter={24}>
                <Col span={10}
                  className='user-form-col'>
                  <Steps
                    current={this.state.current}
                    onChange={this.changeStep}
                    className='user-form-steps'
                  >
                    <Step
                      title={I18n.t('routes.panel.quiz.addNewQuiz')}
                      className='user-form-step'
                    />
                    <Step
                      title={I18n.t('routes.panel.quiz.addQuestions')}
                      className='user-form-step'
                    />
                  </Steps>
                </Col>
              </Row>
              <Divider />
              {this.state.current === 0 && (
                <>
                  <Row>
                    <Col span={20}>
                      <h6>
                        {`${I18n.t('forms.newExame.labelTile')}${
                          caractersName === 0 ? '' : `( ${caractersName} / 40)`
                        }`}
                      </h6>
                      <AdvancedInput
                        value={form.name}
                        onChange={(val) => this.fieldChange('name', val)}
                      />
                    </Col>
                  </Row>
                  {!isEditing && (
                    <Row>
                      <Col span={8}
                        className='margin_top'>
                        <h6>{I18n.t('forms.newExame.selectTypeOfQuiz')}</h6>
                        <AdvancedRadioGroup
                          value={form?.type}
                          onChange={(val) =>
                            this.fieldChange('type', val.target.value)
                          }
                          options={QuizTypeEnum}
                          horizontal
                        />
                      </Col>
                    </Row>
                  )}
                  {!isEditing && form.type === QuizType.SIMULATION && (
                    <>
                      <Row>
                        <Col span={20}>
                          <h6>{I18n.t('forms.newExame.inOrder')}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ marginRight: 15 }}>
                          <h6>{I18n.t('forms.newExame.no')}</h6>
                        </Col>
                        <Col style={{ marginRight: 15 }}>
                          <Switch
                            checked={form.inOrder}
                            onChange={(val) => this.fieldChange('inOrder', val)}
                          />
                        </Col>
                        <Col>
                          <h6>{I18n.t('forms.newExame.yes')}</h6>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col span={12}
                      className='margin_top'>
                      <AdvancedDragger
                        title={I18n.t('forms.newExame.labelImage')}
                        customRequest={({
                          onProgress,
                          onSuccess,
                          onError,
                          file,
                        }) =>
                          this.customRequest(
                            onProgress,
                            onSuccess,
                            onError,
                            file,
                            'imageCoverUrl',
                            'imageFileProgress',
                            'imageFileAxiosSource',
                          )
                        }
                        accept='image/png,image/jpeg,image/jpg,image/gif'
                        fileList={this.state.imageCoverUrl}
                        onRemove={() => {
                          this.fieldChange('imageCoverUrl', null);
                          this.setState({ imageCoverUrl: [] });
                        }}
                        label={I18n.t('forms.newQuestion.labelImageContent')}
                        progressIcon='image'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      {form.note ? (
                        <h6>
                          {I18n.t('forms.newExame.generalOrientation')}{' '}
                          {`(${form.note.length}/1500)`}
                        </h6>
                      ) : (
                        <h6>
                          {I18n.t('forms.newExame.generalOrientation')} (0/1500)
                        </h6>
                      )}
                      <TextArea
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        value={form.note}
                        onChange={(event) =>
                          this.onChangeTextArea(event.target.value)
                        }
                        maxLength={1500}
                      />
                    </Col>
                    <Divider type='vertical' />
                    <Col span={10}>
                      <h6>
                        {I18n.t('forms.newExame.pickSomeMockOrientation')}
                      </h6>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.firstOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.firstOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.secondOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.secondOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.thirdOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.thirdOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.fourthOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.fourthOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.fifthOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.fifthOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdvancedButton
                            type='link'
                            icon={<PlusSquareFilled />}
                            onClick={() =>
                              this.onChangeNotes(
                                `• ${I18n.t(
                                  'forms.newExame.orientations.sixthOrientation',
                                )}`,
                              )
                            }
                          />
                          <span>
                            {I18n.t(
                              'forms.newExame.orientations.sixthOrientation',
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}
                      className='margin_top'>
                      <h6>{I18n.t('forms.newExame.exameDescript')}</h6>
                      <TextArea
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        value={form.description}
                        onChange={(val) =>
                          this.fieldChange('description', val.target.value)
                        }
                        maxLength={255}
                      />
                    </Col>
                  </Row>
                  <>
                    <Row>
                      <Divider type='vertical' />
                      <Col span={4}
                        className='margin_top'>
                        <h6>{I18n.t('forms.newExame.questionsNum')}</h6>
                        <AdvancedInput
                          value={form.numberOfQuestions}
                          type='number'
                          onChange={(val) =>
                            this.fieldChange('numberOfQuestions', val)
                          }
                        />
                      </Col>
                      <Divider type='vertical' />
                      <Col span={4}
                        className='margin_top'>
                        <h6>{I18n.t('forms.newExame.spanTime')}</h6>
                        <AdvancedInput
                          value={form.duration}
                          placeholder={I18n.t('forms.newExame.exameDuration')}
                          onChange={(val) => this.fieldChange('duration', val)}
                          type='number'
                          disabled={this.state.form.noDuration}
                        />
                        <Checkbox
                          checked={this.state.form.noDuration}
                          onChange={(val) =>
                            this.fieldChange('noDuration', val.target.checked)
                          }
                        >
                          {I18n.t('forms.newExame.noTime')}
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <h6>{I18n.t('forms.newQuestion.labelMainCategory')}</h6>
                        <AdvancedSelect
                          value={categorySelectives && mainCategory}
                          onChange={(val) => this.setMainCategory(val)}
                          dropdownClassName='category-select'
                          options={categorySelectives}
                          optionClassName='category-options'
                          photoUrl
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <h6>{I18n.t('forms.content.category.label')}</h6>
                        <AdvancedSelect
                          mode='multiple'
                          value={categorySelectives && secondaryCategory}
                          onChange={(val) => this.setSecundaryCategory(val)}
                          dropdownClassName='category-select'
                          options={categorySelectives}
                          optionClassName='category-options'
                          photoUrl
                        />
                      </Col>
                    </Row>
                  </>
                </>
              )}
              {this.state.current === 1 && (
                <>
                  <>
                    <div
                      onClick={() => this.changeStudendsFilterOpen()}
                      className='question__category__title'
                    >
                      <h6 className='question__category__title__text'>
                        {I18n.t('forms.newExame.questionFilter')}
                      </h6>
                      <RightOutlined
                        className={`${studendsFilterOpen && 'rotate'}`}
                      />
                    </div>
                    {studendsFilterOpen && (
                      <>
                        <br />
                        <form
                          className='form-filter-question margin_bottom'
                          onSubmit={(event) => this.applyFilter(event, params)}
                        >
                          <div className='form-question-list'>
                            <Checkbox.Group
                              value={params.categoriesId}
                              onChange={(value) =>
                                this.addFilter('categoriesId', value)
                              }
                            >
                              <div className='form-question-wrapper'>
                                {categorySelectives?.map((item) => (
                                  <Col key={item.id}>
                                    <Checkbox value={item.id}>
                                      {item.title}
                                    </Checkbox>
                                  </Col>
                                ))}
                              </div>
                            </Checkbox.Group>
                          </div>
                          <Divider />
                          <Row>
                            <Col span={8}>
                              <h6>
                                {I18n.t('routes.panel.quiz.typeQuestion')}
                              </h6>
                              <AdvancedRadioGroup
                                value={params?.type}
                                onChange={(val) =>
                                  this.addFilter('type', val.target.value)
                                }
                                options={[
                                  {
                                    id: QuestionType.MULTIPLEANSWER,
                                    name: I18n.t(
                                      'forms.newQuestion.labelQuestionMultipleAnswer',
                                    ),
                                  },
                                  {
                                    id: QuestionType.DISSERTATION,
                                    name: I18n.t(
                                      'forms.newQuestion.labelDissertation',
                                    ),
                                  },
                                ]}
                                horizontal
                              />
                            </Col>
                            <Col span={6}>
                              <h6>
                                {I18n.t(
                                  'forms.newQuestion.labelQuestionDifficulty',
                                )}
                              </h6>
                              <Checkbox.Group
                                value={params.difficulty}
                                options={[
                                  {
                                    label: I18n.t(
                                      'forms.newQuestion.labelQuestionEasy',
                                    ),
                                    value: DifficultyType.EASY,
                                  },
                                  {
                                    label: I18n.t(
                                      'forms.newQuestion.labelQuestionMedium',
                                    ),
                                    value: DifficultyType.MEDIUM,
                                  },
                                  {
                                    label: I18n.t(
                                      'forms.newQuestion.labelQuestionHard',
                                    ),
                                    value: DifficultyType.HARD,
                                  },
                                ]}
                                onChange={(val) =>
                                  this.addFilter('difficulty', val)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className='row-align-center'>
                            <Col span={8}>
                              <h6>{I18n.t('forms.newQuestion.labelLocal')}</h6>
                              <AdvancedInput
                                placeholder={I18n.t(
                                  'forms.newExame.placeholderLocation',
                                )}
                                value={params?.location}
                                onChange={(val) =>
                                  this.addFilter('location', val)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className='row-align-center'>
                            <Col span={8}>
                              <h6>
                                {I18n.t('forms.newExame.questionAnswerType')}
                              </h6>
                              <Checkbox.Group
                                value={params.answerType}
                                onChange={(val) => {
                                  this.addFilter('answerType', val);
                                }}
                                options={[
                                  {
                                    value: ALTERNATIVE_TYPE.TEXT,
                                    label: I18n.t(
                                      'forms.newQuestion.labelText',
                                    ),
                                  },
                                  {
                                    value: ALTERNATIVE_TYPE.PHOTO,
                                    label: I18n.t(
                                      'forms.newQuestion.labelImage',
                                    ),
                                  },
                                  {
                                    value: ALTERNATIVE_TYPE.VIDEO,
                                    label: I18n.t(
                                      'forms.newQuestion.labelVideo',
                                    ),
                                  },
                                  {
                                    value: ALTERNATIVE_TYPE.AUDIO,
                                    label: I18n.t(
                                      'forms.newQuestion.labelAudio',
                                    ),
                                  },
                                ]}
                              />
                            </Col>
                            <div>
                              <div>
                                <h6>{I18n.t('forms.newExame.year')}</h6>
                              </div>
                              <div className='display_flex'>
                                <Col span={6}>
                                  <AdvancedInput
                                    placeholder={I18n.t(
                                      'forms.newExame.placeholderInitialYear',
                                    )}
                                    value={params?.initialYear}
                                    onChange={(val) =>
                                      this.addFilter('initialYear', val)
                                    }
                                  />
                                </Col>
                                <div className='display_flex width_justify'>
                                  <span className='align_self_center'>
                                    {I18n.t('forms.newExame.until')}
                                  </span>
                                </div>
                                <Col span={6}>
                                  <AdvancedInput
                                    placeholder={I18n.t(
                                      'forms.newExame.placeholderFinalYear',
                                    )}
                                    value={params?.finalYear}
                                    onChange={(val) =>
                                      this.addFilter('finalYear', val)
                                    }
                                  />
                                </Col>
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <Col span={8}
                              className='position_relative'>
                              <div>
                                <h6>{I18n.t('forms.newExame.labelSearch')}</h6>
                              </div>
                              <AdvancedInput
                                placeholder={I18n.t(
                                  'forms.newExame.labelSearch',
                                )}
                                value={params.description}
                                onChange={(val) =>
                                  this.addFilter('description', val)
                                }
                              />
                            </Col>
                          </Row>
                          <Row gutte={16}>
                            <Col
                              span={24}
                              className='advanced-filter__search-button text-right padding_zero'
                            >
                              <AdvancedButton
                                type='large'
                                text={I18n.t(
                                  'shared.advancedFilters.clearButtonText',
                                )}
                                onClick={() => this.clearFilter()}
                              />
                              <Divider type='vertical' />
                              <AdvancedButton
                                htmlType='submit'
                                text={I18n.t('forms.newExame.labelFilter')}
                              />
                            </Col>
                          </Row>
                        </form>
                      </>
                    )}
                  </>
                  <Divider />
                  <Row className='padding_vertical_3'>
                    <Col>
                      <h6>{I18n.t('forms.newExame.labelOrientation')}</h6>
                      <h6>
                        {I18n.t('forms.newExame.labelObservationQuestions')}
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Row>
                        <span>
                          {I18n.t('forms.newExame.insertedQuestion')}
                          <strong> ({this.state.insertQuestion.length})</strong>
                        </span>
                      </Row>
                      <Row className='margin_top'>
                        <span className='count_questions_level'>
                          <span>
                            {I18n.t('forms.newExame.easies')}
                            <span className='text-color-easy'>
                              (
                              {this.filterQuestions(this.props.question)
                                .dificulties &&
                                this.filterQuestions(this.props.question)
                                  .dificulties.easy}
                              )
                            </span>
                          </span>
                          <span>
                            {I18n.t('forms.newExame.mediuns')}
                            <span className='text-color-medium'>
                              (
                              {this.filterQuestions(this.props.question)
                                .dificulties &&
                                this.filterQuestions(this.props.question)
                                  .dificulties.medium}
                              )
                            </span>
                          </span>
                          <span>
                            {I18n.t('forms.newExame.hards')}
                            <span className='text-color-hard'>
                              (
                              {this.filterQuestions(this.props.question)
                                .dificulties &&
                                this.filterQuestions(this.props.question)
                                  .dificulties.hard}
                              )
                            </span>
                          </span>
                        </span>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}
                      className='position_relative'>
                      <div>
                        <h6>{I18n.t('forms.newExame.labelSearch')}</h6>
                      </div>
                      <AdvancedInput
                        placeholder={I18n.t('forms.newExame.labelSearch')}
                        value={params.descriptionTwo}
                        onChange={(val) =>
                          this.addFilter('descriptionTwo', val)
                        }
                      />
                      <AdvancedButton
                        onClick={() => this.applyFilter(false, params)}
                        text={I18n.t('forms.newExame.labelFilter')}
                      />
                      <Divider type='vertical' />
                      <AdvancedButton
                        type='large'
                        text={I18n.t('shared.advancedFilters.clearButtonText')}
                        onClick={() => this.clearFilter()}
                      />
                    </Col>
                  </Row>
                  <Divider type='vertical' />
                  <Row>
                    <Col span={10}>
                      <DataTable
                        getMethod={(par) => this.getQuestionsPaginated(par)}
                        data={this.filterQuestions(this.props.question)}
                        ref={this.dataTableRef}
                        loading={loading > 0}
                        showSizeChanger
                        params={params}
                        columns={[
                          {
                            key: I18n.t(
                              'routes.panel.quiz.dataTable.columns.difficulty.key',
                            ),
                            title: '',
                            render: (value) => this.getColorDiv(value),
                          },
                          {
                            key: I18n.t(
                              'routes.panel.quiz.dataTable.columns.description.key',
                            ),
                            title: '',
                            render: (value) => getSubStringTitle(value, 0, 80),
                          },
                          {
                            key: I18n.t(
                              'routes.panel.users.dataTable.columns.actions.key',
                            ),
                            title: '',
                            render: (id) => (
                              <Button
                                type='link'
                                icon={<FileSearchOutlined />}
                                onClick={() => this.showModalQuestion(id)}
                              />
                            ),
                          },
                        ]}
                        selectable
                        selectedRowKeys={this.state.selected}
                        rowSelection={this.state.selected}
                        onSelectRow={(rows) => this.onSelectRows(rows)}
                      />
                    </Col>
                    <Col span={4}>
                      <span className='column-add-remove-button'>
                        <Row>
                          <Col span={24}>
                            {!this.state.hasResponses ? (
                              <>
                                <span
                                  className='button-new-exame'
                                  onClick={() => this.addQuestions()}
                                >
                                  <div className='button-question'>
                                    <RightOutlined style={{ color: '#fff' }} />
                                  </div>
                                </span>
                                <span
                                  className='button-new-exame'
                                  onClick={() => this.removeQuestions()}
                                >
                                  <div className='button-question'>
                                    <LeftOutlined style={{ color: '#fff' }} />
                                  </div>
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className='button-new-exame'
                                  onClick={() =>
                                    this.setState({ modalOpenAdd: true })
                                  }
                                >
                                  <div className='button-question'>
                                    <RightOutlined style={{ color: '#fff' }} />
                                  </div>
                                </span>
                                <span
                                  className='button-new-exame'
                                  onClick={() =>
                                    this.setState({ modalOpenRemove: true })
                                  }
                                >
                                  <div className='button-question'>
                                    <LeftOutlined style={{ color: '#fff' }} />
                                  </div>
                                </span>
                              </>
                            )}
                          </Col>
                        </Row>
                      </span>
                    </Col>
                    <Col span={10}>
                      <Row>
                        <span className='count_questions_level'>
                          <span>
                            {I18n.t('forms.newExame.easies')}
                            <span className='text-color-easy'>
                              ({this.state.easy})
                            </span>
                          </span>
                          <span>
                            {I18n.t('forms.newExame.mediuns')}
                            <span className='text-color-medium'>
                              ({this.state.medium})
                            </span>
                          </span>
                          <span>
                            {I18n.t('forms.newExame.hards')}
                            <span className='text-color-hard'>
                              ({this.state.hard})
                            </span>
                          </span>
                          <span>
                            {this.state.hasResponses ? (
                              <AdvancedButton
                                text={I18n.t(
                                  'forms.newExame.cleanAllQuestions',
                                )}
                                onClick={() =>
                                  this.setState({ modalOpenRemoveAll: true })
                                }
                              />
                            ) : (
                              <AdvancedButton
                                text={I18n.t(
                                  'forms.newExame.cleanAllQuestions',
                                )}
                                onClick={() => this.removeAllQuestions()}
                              />
                            )}
                          </span>
                        </span>
                      </Row>
                      <Row>
                        <DragComponent
                          items={this.state.insertQuestion}
                          orderContent={this.orderQuestions}
                          removeitem={this.removeItem}
                          colorItem={this.getColorDiv}
                          functionCheck={this.setChecked}
                          showModalQuestion={this.showModalQuestion}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row className='margin_top'>
                    <Col span={4}>
                      <h6>{I18n.t('forms.newExame.exptionDate')}</h6>
                      <DatePicker
                        value={
                          form && form.exhibitionAt && moment(form.exhibitionAt)
                        }
                        placeholder=''
                        showTime={{ format: 'HH:mm' }}
                        format='DD/MM/YYYY HH:mm'
                        onChange={(val) =>
                          this.fieldChange('exhibitionAt', val)
                        }
                      />
                    </Col>
                    <Divider type='vertical' />
                    <Col span={4}>
                      <h6>{I18n.t('forms.newExame.expirationDate')}</h6>
                      <DatePicker
                        value={
                          form && form.expirationAt && moment(form.expirationAt)
                        }
                        placeholder=''
                        showTime={{ format: 'HH:mm' }}
                        format='DD/MM/YYYY HH:mm'
                        onChange={(val) =>
                          this.fieldChange('expirationAt', val)
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Divider />
              <Row gutter={[ 16, 24 ]}>
                <Col span={24}
                  className='text-right'>
                  <AdvancedButton
                    type='link'
                    href={I18n.t('routes.panel.quiz.urlQuiz')}
                    text={I18n.t('forms.goBackButtonText')}
                  />
                  <Divider type='vertical' />
                  <AdvancedButton
                    type='large'
                    htmlType='submit'
                    text={I18n.t('forms.newQuestion.labelPreviewQuestion')}
                    onClick={() => this.showModal()}
                  />
                  <Divider type='vertical' />
                  {this.state.current === 1 && (
                    <>
                      <AdvancedButton
                        text={I18n.t('shared.back')}
                        icon={<DoubleLeftOutlined />}
                        onClick={() => this.changeStep(0)}
                      />
                      <Divider type='vertical' />
                      <AdvancedButton
                        htmlType='submit'
                        text={I18n.t('shared.save')}
                        icon={<SaveOutlined />}
                        onClick={() => this.sendQuiz()}
                      />
                    </>
                  )}
                  {this.state.current === 0 && (
                    <AdvancedButton
                      text={I18n.t('shared.next')}
                      icon={<DoubleRightOutlined />}
                      onClick={() => this.changeStep(1)}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Content>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  courseSelectives: CourseSelectors.getCourseSelectives(state),
  coursesPaginated: CourseSelectors.getCoursesPaginated(state),
  question: QuizSelectors.getQuestionsPaginated(state),
  usersPaginated: UserSelectors.getUsersPaginated(state),
  usersPaginatedSecondary: UserSelectors.getUsersPaginatedSecondary(state),
  quizEditing: QuizSelectors.getSpecificQuiz(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});
const mapDispatchToProps = (dispatch) => ({
  getUrlToUploadFile: (fileName, callback) =>
    dispatch(ContentActions.getUrlToUploadFile(fileName, callback)),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) =>
    dispatch(
      ContentActions.uploadFile(
        file,
        url,
        contentType,
        onProgress,
        cancelToken,
        callback,
      ),
    ),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  getCategorySelectives: () =>
    dispatch(CategoryActions.getCategorySelectives()),
  getCourseSelectives: (params) =>
    dispatch(CourseActions.getCourseSelectivesQuiz(params)),
  getUrlToMedia: (name) => dispatch(QuizActions.getUrlPreviewMedia(name)),
  getQuestion: (params, preview) =>
    dispatch(QuizActions.getQuestions(params, preview)),
  getQuestionById: (id, resturnData) =>
    dispatch(QuizActions.getQuestionById(id, resturnData)),
  setQuestions: (questions) => dispatch(QuizActions.setQuestions(questions)),
  getUsers: (params, me, secondary, clear) =>
    dispatch(UserActions.getUsersPaginated(params, me, secondary, clear)),
  createQuiz: (quiz) => dispatch(QuizActions.createQuiz(quiz)),
  getQuizById: (id) => dispatch(QuizActions.getQuizById(id)),
  updateQuizById: (id, quiz) => dispatch(QuizActions.updateQuiz(id, quiz)),
  getCourses: (params, me, type, clear) =>
    dispatch(CourseActions.getCoursesPaginated(params, me, type, clear)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewExame);
