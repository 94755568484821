import Immutable from 'seamless-immutable';
import * as QuizActions from '../actions/quiz';

const initialState = Immutable({
  questionsPaginated: null,
  quizPaginated: null,
  specificQuestion: null,
  quizStatistics: null,
  specificQuiz: null,
  userAnswers: null,
  quizSelective: null,
  userQuizById: null,
  userQuizzes: null,
});

export default function quiz (
  state = initialState,
  action,
) {
  switch (action.type) {
    case QuizActions.ACTION_GET_QUESTIONS:
      return ({
        ...state,
        questionsPaginated: action.payload,
      });
    case QuizActions.ACTION_GET_QUIZZES:
      return ({
        ...state,
        quizPaginated: action.payload,
      });
    case QuizActions.ACTION_GET_USER_QUIZ_BY_ID:
      return ({
        ...state,
        userQuizById: action.payload,
      });
    case QuizActions.ACTION_GET_QUESTION_BY_ID:
      return ({
        ...state,
        specificQuestion: action.payload,
      });
    case QuizActions.ACTION_GET_QUIZ_STATISTICS:
      return ({
        ...state,
        quizStatistics: action.payload,
      });
    case QuizActions.ACTION_GET_QUIZ_BY_ID:
      return ({
        ...state,
        specificQuiz: action.payload,
      });
    case QuizActions.ACTION_GET_USER_MEDIA_ANSWERS:
      return ({
        ...state,
        userAnswers: action.payload,
      });
    case QuizActions.ACTION_GET_ALL_QUIZZES:
      return ({
        ...state,
        quizSelective: action.payload,
      });
    case QuizActions.ACTION_GET_QUIZ_STATISTICS_RANKING:
      return ({
        ...state,
        quizStatisticsRanking: action.payload,
      });
    case QuizActions.ACTION_GET_USER_QUIZZES:
      return ({
        ...state,
        userQuizzes: action.payload,
      });
    default:
      return state;
  }
}

export function getQuestionsPaginated (state) {
  return state.quiz.questionsPaginated;
}

export function getUserQuizById (state) {
  return state.quiz.userQuizById;
}

export function getQuizzesPaginated (state) {
  return state.quiz.quizPaginated;
}

export function getSpecificQuestion (state) {
  return state.quiz.specificQuestion;
}

export function getStatisticsQuiz (state) {
  return state.quiz.quizStatistics;
}

export function getStatisticsQuizRanking (state) {
  return state.quiz.quizStatisticsRanking;
}

export function getSpecificQuiz (state) {
  return state.quiz.specificQuiz;
}

export function getUserAnswers (state) {
  return state.quiz.userAnswers;
}

export function getQuizSelectives (state) {
  return state.quiz.quizSelective;
}

export function getUserQuizzes (state) {
  return state.quiz.userQuizzes;
}
