export const Specialization = {
  ACUPUNCTURE: 1,
  ALLERGY_AND_IMMUNOLOGY: 2,
  ANESTHESIOLOGY: 3,
  ANGIOLOGY: 4,
  CLINICAL_CANCEROLOGY_AND_SURGICAL_CANCEROLOGY: 5,
  CARDIOLOGY: 6,
  CARDIOVASCULAR_SURGERY: 7,
  HAND_SURGERY: 8,
  HEAD_AND_NECK_SURGERY: 9,
  DIGESTIVE_TRACT_SURGERY: 10,
  GENERAL_SURGERY: 11,
  PEDIATRIC_SURGERY: 12,
  PLASTIC_SURGERY: 13,
  THORACIC_SURGERY: 14,
  VASCULAR_SURGERY: 15,
  MEDICAL_CLINIC: 16,
  COLOPROCTOLOGY: 17,
  DERMATOLOGY: 18,
  ENDOCRINOLOGY_AND_METABOLISM: 19,
  ENDOSCOPY: 20,
  GASTROENTEROLOGY: 21,
  MEDICAL_GENETICS: 22,
  GERIATRICS: 23,
  GYNECOLOGY_AND_OBSTETRICS: 24,
  HOMEOPATHY: 25,
  INFECTOLOGY: 26,
  MASTOLOGY: 27,
  EMERGENCY_MEDICINE: 28,
  FAMILY_AND_COMMUNITY_MEDICINE: 29,
  TRAFFIC_MEDICINE: 30,
  OCCUPATIONAL_MEDICINE: 31,
  SPORTS_MEDICINE: 32,
  PHYSICAL_MEDICINE_AND_REHABILITATION: 33,
  INTENSIVE_MEDICINE: 34,
  LEGAL_MEDICINE_AND_MEDICAL_EXPERTISE: 35,
  NUCLEAR_MEDICINE: 36,
  PREVENTIVE_AND_SOCIAL_MEDICINE: 37,
  NEPHROLOGY: 38,
  NEUROSURGERY: 39,
  NEUROLOGY: 40,
  NUTROLOGY: 41,
  OPHTHALMOLOGY: 42,
  ORTHOPEDICS_AND_TRAUMATOLOGY: 43,
  OTORHINOLARYNGOLOGY: 44,
  PATHOLOGY: 45,
  CLINICAL_PATHOLOGY_LABORATORY_MEDICINE: 46,
  PEDIATRICS: 47,
  PNEUMOLOGY: 48,
  PSYCHIATRY: 49,
  RADIOLOGY_AND_DIAGNOSTIC_IMAGING: 50,
  RADIOTHERAPY: 51,
  RHEUMATOLOGY: 52,
  HEMATOLOGY_AND_HEMOTHERAPY: 53,
  UROLOGY: 54,
  OTHERS: 55,
};

export const SpecializationList = [
  {
    id: Specialization.ACUPUNCTURE,
    title: 'Acupuntura',
  },
  {
    id: Specialization.ALLERGY_AND_IMMUNOLOGY,
    title: 'Alergia e imunologia',
  },
  {
    id: Specialization.ANESTHESIOLOGY,
    title: 'Anestesiologia',
  },
  {
    id: Specialization.ANGIOLOGY,
    title: 'Angiologia',
  },
  {
    id: Specialization.CLINICAL_CANCEROLOGY_AND_SURGICAL_CANCEROLOGY,
    title: 'Cancerologia Clínica e Cancerologia Cirúrgica',
  },
  {
    id: Specialization.CARDIOLOGY,
    title: 'Cardiologia',
  },
  {
    id: Specialization.CARDIOVASCULAR_SURGERY,
    title: 'Cirurgia cardiovascular',
  },
  {
    id: Specialization.HAND_SURGERY,
    title: 'Cirurgia da mão',
  },
  {
    id: Specialization.HEAD_AND_NECK_SURGERY,
    title: 'Cirurgia de cabeça e pescoço',
  },
  {
    id: Specialization.DIGESTIVE_TRACT_SURGERY,
    title: 'Cirurgia do aparelho digestivo',
  },
  {
    id: Specialization.GENERAL_SURGERY,
    title: 'Cirurgia geral',
  },
  {
    id: Specialization.PEDIATRIC_SURGERY,
    title: 'Cirurgia pediátrica',
  },
  {
    id: Specialization.PLASTIC_SURGERY,
    title: 'Cirurgia plástica',
  },
  {
    id: Specialization.THORACIC_SURGERY,
    title: 'Cirurgia torácica',
  },
  {
    id: Specialization.VASCULAR_SURGERY,
    title: 'Cirurgia vascular',
  },
  {
    id: Specialization.MEDICAL_CLINIC,
    title: 'Clínica médica',
  },
  {
    id: Specialization.COLOPROCTOLOGY,
    title: 'Coloproctologia',
  },
  {
    id: Specialization.DERMATOLOGY,
    title: 'Dermatologia',
  },
  {
    id: Specialization.ENDOCRINOLOGY_AND_METABOLISM,
    title: 'Endocrinologia e metabologia',
  },
  {
    id: Specialization.ENDOSCOPY,
    title: 'Endoscopia',
  },
  {
    id: Specialization.GASTROENTEROLOGY,
    title: 'Gastroenterologia',
  },
  {
    id: Specialization.MEDICAL_GENETICS,
    title: 'Genética médica',
  },
  {
    id: Specialization.GERIATRICS,
    title: 'Geriatria',
  },
  {
    id: Specialization.GYNECOLOGY_AND_OBSTETRICS,
    title: 'Ginecologia e obstetrícia',
  },
  {
    id: Specialization.HOMEOPATHY,
    title: 'Homeopatia',
  },
  {
    id: Specialization.INFECTOLOGY,
    title: 'Infectologia',
  },
  {
    id: Specialization.MASTOLOGY,
    title: 'Mastologia',
  },
  {
    id: Specialization.EMERGENCY_MEDICINE,
    title: 'Medicina de emergência',
  },
  {
    id: Specialization.FAMILY_AND_COMMUNITY_MEDICINE,
    title: 'Medicina de família e comunidade',
  },
  {
    id: Specialization.TRAFFIC_MEDICINE,
    title: 'Medicina de tráfego',
  },
  {
    id: Specialization.OCCUPATIONAL_MEDICINE,
    title: 'Medicina do trabalho',
  },
  {
    id: Specialization.SPORTS_MEDICINE,
    title: 'Medicina esportiva',
  },
  {
    id: Specialization.PHYSICAL_MEDICINE_AND_REHABILITATION,
    title: 'Medicina física e reabilitação',
  },
  {
    id: Specialization.INTENSIVE_MEDICINE,
    title: 'Medicina intensiva',
  },
  {
    id: Specialization.LEGAL_MEDICINE_AND_MEDICAL_EXPERTISE,
    title: 'Medicina legal e perícia médica',
  },
  {
    id: Specialization.NUCLEAR_MEDICINE,
    title: 'Medicina nuclear',
  },
  {
    id: Specialization.PREVENTIVE_AND_SOCIAL_MEDICINE,
    title: 'Medicina preventiva e social',
  },
  {
    id: Specialization.NEPHROLOGY,
    title: 'Nefrologia',
  },
  {
    id: Specialization.NEUROSURGERY,
    title: 'Neurocirurgia',
  },
  {
    id: Specialization.NEUROLOGY,
    title: 'Neurologia',
  },
  {
    id: Specialization.NUTROLOGY,
    title: 'Nutrologia',
  },
  {
    id: Specialization.OPHTHALMOLOGY,
    title: 'Oftalmologia',
  },
  {
    id: Specialization.ORTHOPEDICS_AND_TRAUMATOLOGY,
    title: 'Ortopedia e traumatologia',
  },
  {
    id: Specialization.OTORHINOLARYNGOLOGY,
    title: 'Otorrinolaringologia',
  },
  {
    id: Specialization.PATHOLOGY,
    title: 'Patologia',
  },
  {
    id: Specialization.CLINICAL_PATHOLOGY_LABORATORY_MEDICINE,
    title: 'Patologia clínica/medicina laboratorial',
  },
  {
    id: Specialization.PEDIATRICS,
    title: 'Pediatria',
  },
  {
    id: Specialization.PNEUMOLOGY,
    title: 'Pneumologia',
  },
  {
    id: Specialization.PSYCHIATRY,
    title: 'Psiquiatria',
  },
  {
    id: Specialization.RADIOLOGY_AND_DIAGNOSTIC_IMAGING,
    title: 'Radiologia e diagnóstico por imagem',
  },
  {
    id: Specialization.RADIOTHERAPY,
    title: 'Radioterapia',
  },
  {
    id: Specialization.RHEUMATOLOGY,
    title: 'Reumatologia',
  },
  {
    id: Specialization.HEMATOLOGY_AND_HEMOTHERAPY,
    title: 'Hematologia e hemoterapia',
  },
  {
    id: Specialization.UROLOGY,
    title: 'Urologia',
  },
  {
    id: Specialization.OTHERS,
    title: 'Outros',
  },
];

export function getSpecializationTitleById (id) {
  const response = SpecializationList.find((o) => o.id === id);

  if (response) {
    return response.title;
  }

  return null;
}

export default Specialization;
