import React from 'react';
import {
  Collapse,
  Divider,
  Layout,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {InfoCircleOutlined, MenuOutlined} from '@ant-design/icons';
import { CourseActions } from '../../../app/redux/actions';
import { LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as CourseIcon } from '../../../app/assets/img/course_icon_blue.svg';
import { SimpleVerticalDragDrop } from '../../../components/SimpleVerticalDragDrop/SimpleVerticalDragDrop';
import { reorderList } from '../../../app/utils/order';
import { FakeTableHeader } from '../../../components/FakeTableHeader/FakeTableHeader';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { navigate } from '@reach/router';
import Switch from 'react-switch';
import { LazyInput } from '../../../components/LazyInput/LazyInput';


const ORDER_TYPES = {
  CONTENTS: 'CONTENTS',
  MODULES: 'MODULES',
}

class OrderContents extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      items: [],
      title: '',
      orderType: ORDER_TYPES.CONTENTS,
    };
  }

  async findContents () {
    const courseId = this.props.id

    if (!courseId) return;

    const allContents = await this.props.getAllContents(courseId)

    this.setState({items: allContents.contents.map(item => ({...item, id: item.moduleId})), title: allContents.title})
  }

  componentDidMount () {
    this.findContents()
  }

  onDragEnd = (result, moduleIndex) => {
    const { items } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    items[moduleIndex].contents = reorderList(
      items[moduleIndex].contents,
      result.source.index,
      result.destination.index,
    );

    this.setState({ items });
  }

  handleCollapse = (indexToHandle) => {
    this.setState({
      items: this.state.items.map((item, index) => ({
        ...item,
        open: indexToHandle === index ? !item.open : item.open
      }))
    })
  }

  handleChangeOrderType = () => {
    this.setState({
      orderType: this.state.orderType === ORDER_TYPES.CONTENTS ? ORDER_TYPES.MODULES : ORDER_TYPES.CONTENTS
    })
  }

  onDragModulesEnd = (result) => {
    let { items } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    items = reorderList(
      items,
      result.source.index,
      result.destination.index,
    );

    this.setState({ items });
  }

  handleChangeContentTitle = ({moduleId, contentId, value}) => {
    this.setState(prev => ({
      items: prev.items.map(module => ({
        ...module,
        contents: module.contents.map(content => ({
          ...content,
          title: (moduleId === module.id && contentId === content.id) ? value : content.title,
          titleChanged: (moduleId === module.id && contentId === content.id) ? true : content.titleChanged
        }))
      }))
    }));
  }

  handleChangeModuleTitle = (id, value) => {
    this.setState(prev => ({
      items: prev.items.map(item => ({
        ...item,
        title: id === item.id ? value : item.title,
        titleChanged: id === item.id ? true : item.titleChanged
      }))
    }))
  }

  handleSaveOrderButtonClick = () => {
    const formatedOrder = this.state.items.map(({id: moduleId, title: moduleTitle, titleChanged: moduleTitleChanged, contents}, moduleOrder) => ({
      moduleId,
      order: moduleOrder,
      title: moduleTitleChanged ? moduleTitle : undefined,
      contents: contents.map(({courseContentId, id: contentId, title, titleChanged: contentTitleChanged}, contentOrder) => ({
        courseContentId,
        id: contentId,
        order: contentOrder,
        title: contentTitleChanged ? title : undefined,
      }))
    }))

    this.props.saveOrder(this.props.id, formatedOrder, () => {
      navigate(I18n.t('routes.panel.courses.url'));
    })
  };

  render () {
    const {
      loading,
    } = this.props;
    const { items, title } = this.state

    return (
      <>
        <Layout.Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={CourseIcon}/>
                  </span>
                  Curso: {title}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t('routes.panel.orderContents.description')}
                </p>

                <Divider />

                <Row className='switch-order-type'>
                  <Tooltip title={I18n.t('routes.panel.orderContents.orderTypesTooltip.module')}>
                    <div
                      className='order-type-indicator-content'
                      onClick={() => this.setState({orderType: ORDER_TYPES.MODULES})}
                    >
                      <b className={this.state.orderType === ORDER_TYPES.MODULES && 'active-order-mode'}>
                        {I18n.t('routes.panel.orderContents.modulesMode')} <InfoCircleOutlined />
                      </b>
                    </div>
                  </Tooltip>
                  <Switch
                    uncheckedIcon={''}
                    checkedIcon={''}
                    offColor='#0084CF'
                    onColor='#0084CF'
                    onChange={this.handleChangeOrderType}
                    checked={this.state.orderType === ORDER_TYPES.CONTENTS} />
                  <Tooltip title={I18n.t('routes.panel.orderContents.orderTypesTooltip.content')}>
                    <div
                      className='order-type-indicator-content'
                      onClick={() => this.setState({orderType: ORDER_TYPES.CONTENTS})}
                    >
                      <b className={this.state.orderType === ORDER_TYPES.CONTENTS && 'active-order-mode'}>
                        {I18n.t('routes.panel.orderContents.contentsMode')} <InfoCircleOutlined />
                      </b>
                    </div>
                  </Tooltip>
                </Row>
              </div>
            </Spin>

            {
              this.state.orderType === ORDER_TYPES.CONTENTS ? (
                <Collapse>
                  {items.map((module, moduleIndex) => (
                    <Collapse.Panel key={moduleIndex}
                      className='custom-dd-panel'
                      header={<h5>Modulo: {module.title}</h5>}>
                      <div>
                        <FakeTableHeader
                          fields={[
                            {title: '', className: 'dd-hold'},
                            {title: I18n.t('routes.panel.orderContents.fields.order'), className: 'dd-index'},
                            {title: I18n.t('routes.panel.orderContents.fields.title'), className: 'dd-title'},
                            {title: I18n.t('routes.panel.orderContents.fields.year'), className: 'dd-year'},
                            {title: 'Descrição', className: 'dd-categories'},
                            {title: I18n.t('routes.panel.orderContents.fields.date'), className: 'dd-date'},
                          ]}
                        />

                        <SimpleVerticalDragDrop
                          items={module.contents}
                          onDragEnd={(dragResponse) => this.onDragEnd(dragResponse, moduleIndex)}
                          renderItem={(item, rowIndex) => (
                            <div key={item.id}
                              className='contents-drag-drop-item'>
                              <div className='dd-hold'>
                                <MenuOutlined />
                              </div>
                              <div className='dd-index'>{rowIndex + 1}</div>
                              <div className='dd-title'>
                                <LazyInput
                                  handleSendValue={this.handleChangeContentTitle}
                                  renderInput={(onChange) => (
                                    <textarea rows={1}
                                      cols={1}
                                      className='dd-new-content-title-input'
                                      defaultValue={item.title}
                                      onChange={(v) => onChange({moduleId: module.id, contentId: item.id, value: v.target.value})}/>
                                  )}
                                />
                              </div>
                              <div className='dd-year'>{item.year}</div>
                              <div className='dd-categories'>{item.description}</div>
                              <div className='dd-date'>{new Date(item.createdAt).toLocaleDateString()}</div>
                            </div>
                          )}
                        />
                      </div>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              ) : (
                <SimpleVerticalDragDrop
                  items={this.state.items}
                  onDragEnd={this.onDragModulesEnd}
                  renderItem={(item, rowIndex) => (
                    <div className={`dd-module-order-item ${rowIndex + 1 === this.state.items.length && 'last-dd-module-order-item'}`}>
                      <MenuOutlined />
                      <b>
                        {rowIndex + 1}. <LazyInput
                          handleSendValue={this.handleChangeModuleTitle}
                          renderInput={(onChange) => (
                            <input
                              defaultValue={item.title}
                              onChange={(e) => onChange(item.id, e.target.value)}/>
                          )}
                        />
                      </b>
                    </div>
                  )}
                />
              )
            }

            <Row justify='end'
              className='save-button-container'>
              <AdvancedButton
                type="link"
                text={I18n.t('forms.goBackButtonText')}
                href={I18n.t('routes.panel.courses.url')}
              />
              <AdvancedButton
                text={I18n.t('routes.panel.orderContents.buttons.save')}
                onClick={this.handleSaveOrderButtonClick}
              />
            </Row>

          </div>
        </Layout.Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllContents: (courseId) => dispatch(CourseActions.getOrderedContentsByCourse(courseId)),
  saveOrder: async (courseId, order, callback) => dispatch(CourseActions.saveContentsOrder(courseId, order, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  OrderContents,
);
