import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout,
  message,
  Row,
} from 'antd';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, { AppstoreOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthSelectors, ContentSelectors, LoadingSelectors, UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as DashboardIcon } from '../../../app/assets/img/dashboard_icon_blue.svg';
import * as DateUtils from '../../../app/utils/date';
import { ContentActions, UserActions } from '../../../app/redux/actions';
import { GetStatisticCardType, StatisticCardType } from '../../../app/enum/statisticCardType';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import ContentRequests from '../../../app/api/content'
import UserRequests from '../../../app/api/user';
import { ProfileType } from '../../../app/enum/profileType';

class StatisticLists extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      loadingExternalCsv: false,
      csvData: '',
      params: this.props.location && this.props.location.state && this.props.location.state.params,
      type: this.props.location && this.props.location.state && this.props.location.state.type,
      paginatedData: null,
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  getPropsForSearch = () => {
    const { params } = this.state;

    switch (this.state.type) {
      case StatisticCardType.POSTED_CONTENT:
        return params;
      case StatisticCardType.VIEWED_CONTENT:
        return { ...params, hasAccess: true };
      case StatisticCardType.USERS:
        return {
          ...params,
          startAccessDate: params.startPostDate,
          endAccessDate: params.endPostDate,
          orderBy: 'createdAt',
          hasAccess: true,
          profileType: ProfileType.USER
        };
      case StatisticCardType.DAY:
        return {
          ...params,
          startAccessDate: moment().startOf('d').toDate(),
          endAccessDate: moment().endOf('d').toDate(),
          hasAccess: true
        };
      case StatisticCardType.WEEK:
        return {
          ...params,
          startAccessDate: moment().subtract(1, 'w').startOf('week').toDate(),
          endAccessDate: moment().subtract(1, 'w').endOf('week').toDate(),
          hasAccess: true
        };
      case StatisticCardType.MONTH:
        return {
          ...params,
          startAccessDate: moment().subtract(1, 'M').startOf('M').toDate(),
          endAccessDate: moment().subtract(1, 'M').endOf('M').toDate(),
          hasAccess: true
        };
    }
  }

  requestApiDataToUseOnFullCsv = async (requesFromApiFunction, totalDataAmount) => {
    let apiResult = null

    try{
      this.setState({loadingExternalCsv: true})
      const propsForSearch = this.getPropsForSearch()
      apiResult = await requesFromApiFunction({
        limit: totalDataAmount,
        offset: 0,
        isDESC: true,
        orderBy: 'accessCount',
        ...propsForSearch
      })
      this.setState({loadingExternalCsv: false})
    } catch (err) {
      this.setState({loadingExternalCsv: false})
    }

    return apiResult
  }

  csvExport = async (exportAllData = false) => {
    let csvContent = null;

    if (this.state.type === StatisticCardType.POSTED_CONTENT || this.state.type === StatisticCardType.VIEWED_CONTENT) {
      const { contentsPaginated } = this.props;
      csvContent = contentsPaginated;

      if(exportAllData) {
        const result = await this.requestApiDataToUseOnFullCsv(
          ContentRequests.getContentsPaginated,
          contentsPaginated.count
        )

        if(result) {
          csvContent = result
        }
      }

      if (csvContent) {
        const csvData = this.csvExportContent(csvContent);
        this.generateCsvLink(csvData);
      } else {
        message.error(I18n.t('shared.awaitData'));
      }
    } else {
      const { usersPaginated } = this.props;
      csvContent = usersPaginated;

      if(exportAllData) {
        const result = await this.requestApiDataToUseOnFullCsv(
          UserRequests.getUsersPaginated,
          usersPaginated.count,
        )

        if(result) {
          csvContent = result
        }
      }

      if (csvContent) {
        const csvData = this.csvExportUsers(csvContent);
        this.generateCsvLink(csvData);
      } else {
        message.error(I18n.t('shared.awaitData'));
      }
    }
  }

  generateCsvLink = (csvData) => {
    this.setState({ csvData }, () => {
      if (this.state.csvData) {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      } else {
        message.error(I18n.t('shared.awaitData'));
      }
    });
  }

  csvExportContent = (csvContent) => csvContent.rows && csvContent.rows.map((data) => ({
    title: data.title,
    course: this.parseCourses(data.modules),
    modules: this.parseModules(data.modules),
    postDate: this.parsePostDate(data.modules),
  }));

  csvExportUsers = (csvContent) => csvContent.rows && csvContent.rows.map((data) => ({
    name: data.name,
    company: (data.companies[0] && data.companies[0].name) || '--',
    accessCount: data.accessCount,
  }));

  getPaginatedList = async (params) => {
    params = {
      ...this.state.params,
      ...params,
    };
    const { me } = this.props;
    this.setState({ params: { ...params } });

    if (this.state.type === StatisticCardType.POSTED_CONTENT) {
      const { getContentsPaginated } = this.props;
      await getContentsPaginated({
        ...params,
        hasAccess: true
      }, me);
      this.setState({ paginatedData: this.props.contentsPaginated });
    } else if (this.state.type === StatisticCardType.VIEWED_CONTENT) {
      const { getContentsPaginated } = this.props;
      await getContentsPaginated({ ...params, hasAccess: true }, me);
      this.setState({ paginatedData: this.props.contentsPaginated });
    } else if (this.state.type === StatisticCardType.USERS) {
      const { getUsersPaginated } = this.props;
      await getUsersPaginated(
        {
          ...params,
          startAccessDate: params.startPostDate,
          endAccessDate: params.endPostDate,
          hasAccess: true,
          profileType: ProfileType.USER
        }, me,
      );
      this.setState({ paginatedData: this.props.usersPaginated });
    } else if (this.state.type === StatisticCardType.DAY) {
      const { getUsersPaginated } = this.props;
      await getUsersPaginated(
        {
          ...params,
          startAccessDate: moment().startOf('d').toDate(),
          endAccessDate: moment().endOf('d').toDate(),
          hasAccess: true
        }, me,
      );
      this.setState({ paginatedData: this.props.usersPaginated });
    } else if (this.state.type === StatisticCardType.WEEK) {
      const { getUsersPaginated } = this.props;
      await getUsersPaginated(
        {
          ...params,
          startAccessDate: moment().subtract(1, 'w').startOf('week').toDate(),
          endAccessDate: moment().subtract(1, 'w').endOf('week').toDate(),
          hasAccess: true,
          profileType: ProfileType.USER
        }, me,
      );
      this.setState({ paginatedData: this.props.usersPaginated });
    } else if (this.state.type === StatisticCardType.MONTH) {
      const { getUsersPaginated } = this.props;
      await getUsersPaginated(
        {
          ...params,
          startAccessDate: moment().subtract(1, 'M').startOf('M').toDate(),
          endAccessDate: moment().subtract(1, 'M').endOf('M').toDate(),
          hasAccess: true,
          profileType: ProfileType.USER
        }, me,
      );
      this.setState({ paginatedData: this.props.usersPaginated });
    }
  }

  parseModules = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1 ? `${item.title}, ` : item.title))).join('')
    : '');

  parseCourses = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1 ? `${item.course.title}, ` : item.course.title))).join('')
    : '');

  parsePostDate = (value) => (value[0]
    ? DateUtils.humanizeDate(value[0].courseContent.postDate, 'DD/MM/YYYY')
    : '');

  render () {
    const { Content } = Layout;
    const {
      loading,
      me,
    } = this.props;
    const { paginatedData, type, params } = this.state;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.statistics.url')}`}>
                <span>{I18n.t('routes.panel.statisticsList.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{GetStatisticCardType(type)}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="statisticsList">

            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={DashboardIcon} />
                  </span>
                  {I18n.t('routes.panel.statisticsList.pageTitle')}
                </h3>
              </Col>

              <Col className="text-right">
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.statisticsList.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      disabled={this.state.loadingExternalCsv}
                      loading={this.state.loadingExternalCsv}
                    />
                  </>
                )}

                <Divider type='vertical' />

              </Col>
            </Row>

            <Divider />

            <DataTable
              params={params}
              getMethod={(parameters) => this.getPaginatedList(parameters)}
              data={paginatedData}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              pageSizeOptions={paginatedData && paginatedData.count > 100
                ? [ '10', '50', '100', paginatedData.count.toString() ] : [ '10', '20', '50', '100' ]}
              columns={
                type === StatisticCardType.DAY
                  || type === StatisticCardType.WEEK
                  || type === StatisticCardType.MONTH
                  || type === StatisticCardType.USERS
                  ? ([
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.company.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.company.title'),
                      render: (value) => (value && value[0] && value[0].name) || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.accessCount.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.accessCount.title'),
                      render: (value) => value || '--',
                    } ]
                  ) : (
                    [ {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.title.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.title.title'),
                      render: (value) => value || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.titles.course'),
                      render: (value) => (value && this.parseCourses(value)) || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.titles.module'),
                      render: (value) => (value && this.parseModules(value)) || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.modules.titles.postDate'),
                      render: (value) => (value && value.length > 0 && this.parsePostDate(value)) || '--',
                    },
                    {
                      ellipsis: true,
                      key: I18n.t('routes.panel.statisticsList.dataTable.columns.accessCount.key'),
                      title: I18n.t('routes.panel.statisticsList.dataTable.columns.accessCount.title'),
                      render: (value) => value || '--',
                    } ]
                  )
              }
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  contentsPaginated: ContentSelectors.getContentsPaginated(state),
  usersPaginated: UserSelectors.getUsersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentsPaginated: (parameters, me) => dispatch(
    ContentActions.getContentsPaginated({ ...parameters, orderBy: 'accessCount' }, me),
  ),
  getUsersPaginated: (parameters, me) => dispatch(UserActions.getUsersPaginated(parameters, me)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  StatisticLists,
);
