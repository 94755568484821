import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs

function getGraphicBars (rows, graphicAmountKey) {
  const higherBarNumber = rows.reduce((higger, next) => (
    next[graphicAmountKey] > higger ? Number(next[graphicAmountKey]) : higger
  ), [ 0 ])

  return rows.map(currentRow => {
    const currentRowAmount = Number(currentRow[graphicAmountKey])
    const barWidth = (currentRowAmount * 515) / higherBarNumber
    const barRestWidth = 515 - barWidth

    return [
      {
        text: `${currentRow.title}: ${currentRowAmount}`
      },
      {
        columns: [
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: barWidth,
                h: 10,
                color: '#1F77B4',
              },
            ],
            width: barWidth
          },
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: barRestWidth,
                h: 10,
                color: '#e0e0e0',
              },
            ]
          }
        ],
        margin: [ 0, 0, 0, 10 ],
      },
    ]
  })
}

export function exportPdfFromGraphic (title, rows, graphicAmountKey, fileName) {
  const graphicBars = getGraphicBars(rows, graphicAmountKey)

  const pdfDefinitions = {
    content: [
      {
        text: title,
        bold: true,
        margin: [ 0, 0, 0, 20 ],
        style: 'title'
      },
      ...graphicBars
    ],
    styles: {
      title: {
        bold: true,
        fontSize: 15
      }
    }
  }

  pdfMake.createPdf(pdfDefinitions).download(fileName || 'file');
}