/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Layout,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, { AppstoreOutlined, BarChartOutlined, FilePdfOutlined, ProfileOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import {
  CompanyActions,
  ContentActions,
  CourseActions,
  FilterActions,
  StatisticActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CompanySelectors,
  ContentSelectors,
  CourseSelectors,
  FilterSelector,
  LoadingSelectors,
  StatisticSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as DashboardIcon } from '../../../app/assets/img/dashboard_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import StatisticCard from '../../../components/StatisticCard/StatisticCard';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { CourseType } from '../../../app/enum/courseType';
import { StatisticCardType } from '../../../app/enum/statisticCardType';
import DataTable from '../../../components/shared/DataTable/DataTable';
// eslint-disable-next-line no-duplicate-imports
import { navigate } from '@reach/router';
import { parseCompanies } from '../../../app/utils/showCompanies';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { CSVLink } from 'react-csv';
import { ProfileType } from '../../../app/enum/profileType';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { getPhoneNumber } from '../../../app/utils/masks';
import StatisticRequests from '../../../app/api/statistic';
import { prepareDataToCsv } from '../../../app/utils/csv';

const { Panel } = Collapse;

class Statistics extends React.Component {
  constructor (props) {
    super(props);
    const { statisticsFilters } = props;
    const { mainStatisticsFilters } = statisticsFilters;
    this.state = {
      cityList: [],
      stateList: [],
      params: mainStatisticsFilters,
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      csvData: ''
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { getCompanySelective } = this.props;
    const { params } = this.state
    getCompanySelective();
    this.getStatistics(params)
    this.getDataForTable()
  }

  getStatistics = (params, me, isFromDataTable, isReset) => {
    const { getStatisticsDashboard, statisticsFilters } = this.props;
    const { mainStatisticsFilters } = statisticsFilters;

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...mainStatisticsFilters,
        ...params,
      };
    }

    getStatisticsDashboard(params, me);
  };

  searchCourses = async (val) => {
    const { searchCourses } = this.props;
    await searchCourses({ search: val, type: CourseType.COURSE });
  };

  handleChangeDate = (value) => {
    const { params } = this.state;

    if (value) {
      params.startDate = value[0].startOf('day').toDate();
      params.startPostDate = value[0].startOf('day').toDate();
      params.endDate = value[1].endOf('day').toDate();
      params.endPostDate = value[1].endOf('day').toDate();
    } else {
      params.startDate = null;
      params.startPostDate = null;
      params.endDate = null;
      params.endPostDate = null;
    }

    this.setState({ params });
  };

  fieldChange = (name, value, callBack) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params }, () => {
      if(callBack) callBack()
    });
  };

  applyAdvancedFilters = (event, params) => {
    const props = {
      ...params,
      page: 1,
      offset: 0
    }
    event.preventDefault();
    this.props.setStatisticsMainFilters(props);
    this.getStatistics(props);
    this.props.getUsersForStatisticsPaginated(props)
    this.setState({ params: props })
  };

  cleanAdvancedFilters = () => {
    this.setState({
      params: {
        companyId: null,
        courseId: null,
        moduleId: null,
        contentId: null,
        startPostDate: null,
        endPostDate: null,
        startDate: null,
        endDate: null,
      },
    });
    this.getStatistics(
      {
        companyId: null,
        courseId: null,
        moduleId: null,
        contentId: null,
        startPostDate: null,
        endPostDate: null,
        startDate: null,
        endDate: null,
      },
      undefined,
      true,
      true,
    );
    this.props.getUsersForStatisticsPaginated(null)
    this.props.setStatisticsMainFilters(null);
  };

  removeStatistic (id) {
    this.props.removeStatistic(id, () => {
      message.success(I18n.t('routes.panel.statistics.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  async handleCompanySelect (companyId) {
    this.fieldChange('companyId', companyId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          courseId: null,
          moduleId: null,
          contentId: null,
        }
      }))
    });
    await this.props.searchCourses({ type: CourseType.COURSE, parentId: companyId });
  }

  getSelectiveById (id, selective) {
    const response = selective.find((o) => o.id === id);

    if (response) {
      return response.name || response.title;
    }

    return null;
  }

  handleChangeSelectedCourse (courseId) {
    this.fieldChange('courseId', courseId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          moduleId: null,
          contentId: null,
        }
      }))
    })
    this.props.getModules({ parentId: courseId })
  }

  handleGetModules (value) {
    this.props.getModules({ parentId: this.state.params?.courseId, title: value })
  }

  handleSelectModule (moduleId) {
    this.fieldChange('moduleId', moduleId, () => {
      this.setState((state) => ({
        params: {
          ...state.params,
          contentId: null,
        }
      }))
    })
    this.props.getContents({ moduleId })
  }

  handleGetContent (value) {
    this.props.getContents({ moduleId: this.state.params?.moduleId, title: value })
  }

  getDataForTable (params) {
    this.props.getUsersForStatisticsPaginated({ ...this.state.params, ...params})
    this.setState({
      params: { ...this.state.params, ...params}
    })
    this.props.setStatisticsMainFilters({ ...this.state.params, ...params});
  }

  async exportPdfData (exportDataFromApi = false) {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.statistics.studentsTable.columns.name.title'),
      I18n.t('routes.panel.statistics.studentsTable.columns.email.title'),
      I18n.t('routes.panel.statistics.studentsTable.columns.cellphone.title'),
      I18n.t('routes.panel.statistics.studentsTable.columns.company.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.usersForStatistics.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.statistics.userInStatistics'),
      11,
      8,
      (row) => ([
        row.name || '--',
        row.email || '--',
        row.cellphone ? getPhoneNumber(row.cellphone) : '--',
        parseCompanies(row.companies) || '--',
      ]),
      exportDataFromApi,
      this.props.usersForStatistics?.count,
      StatisticRequests.getUsersForStatisticsPaginated,
      this.state.params,
      I18n.t('routes.panel.statisticsList.type.users')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.usersForStatistics?.rows,
      (row) => ({
        name: row.name || '--',
        email: row.email || '--',
        cellphone: row.cellphone ? getPhoneNumber(row.cellphone) : '--',
        companies: row.companies ? parseCompanies(row.companies) : '--',
      }),
      exportAllDataFromApi,
      this.props.usersForStatistics?.count,
      StatisticRequests.getUsersForStatisticsPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  render () {
    const { Content } = Layout;
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const { params } = this.state;
    const {
      statisticsDashboard,
      loading,
      companySelectives,
      searchedCourses,
      searchedModules,
      contentSearched,
      usersForStatistics,
      me,
    } = this.props;

    return (
      <div>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.statistics.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={companySelectives}
                      label={I18n.t('shared.advancedFilters.form.company')}
                      value={params?.companyId}
                      onChange={(val) => this.handleCompanySelect(val)}
                      disableSearch
                    />
                  </Col>
                </Row>
                <Row gutter={15}
                  className='select-relation-container'>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.course')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.courseId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.searchCourses(val)}
                      onChange={(val) => this.handleChangeSelectedCourse(val)}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId}
                    >
                      {searchedCourses &&
                        searchedCourses.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.module')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.moduleId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.handleGetModules(val)}
                      onChange={(val) => this.handleSelectModule(val) }
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId || !params?.courseId}
                    >
                      {searchedModules &&
                        searchedModules.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.content')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={params?.contentId}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.handleGetContent(val)}
                      onChange={(val) =>
                        this.setState({ params: { ...params, contentId: val } })
                      }
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                      disabled={!params?.companyId || !params?.courseId || !params?.moduleId}
                    >
                      {contentSearched &&
                        contentSearched.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.email')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <div style={{ paddingBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.postDate')}
                    </div>
                    <RangePicker
                      value={
                        params && params.startDate && params.endDate
                          ? [ moment(params.startDate), moment(params.endDate) ]
                          : []
                      }
                      placeholder={[
                        I18n.t('shared.advancedFilters.form.startDate'),
                        I18n.t('shared.advancedFilters.form.endDate'),
                      ]}
                      format='DD/MM/YYYY'
                      onChange={(val) => this.handleChangeDate(val)}
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='statistics'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={DashboardIcon} />
                  </span>
                  {I18n.t('routes.panel.statistics.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right justify-content-between export-buttons-container'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      disabled={this.state.exportingPfdDataLoading || loading > 0}
                      loading={this.state.exportingPfdDataLoading}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={this.state.exportingDataLoading || loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      disabled={this.state.exportingDataLoading || loading > 0}
                      loading={this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.statisticsList.type.users')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}
              </Col>
            </Row>

            <Row gutter={[ 48, 48 ]}>
              <Col span={12}>
                <h6>
                  {I18n.t('routes.panel.statistics.welcome')},{' '}
                  <strong>{this.props.me && this.props.me.name}!</strong>
                </h6>
                <h6>{I18n.t('routes.panel.statistics.progress')}</h6>
                {statisticsDashboard?.userCount && (
                  <h6>{I18n.t('routes.panel.statistics.currentUsers') + statisticsDashboard.userCount}</h6>
                )}
              </Col>
            </Row>

            <Spin spinning={loading > 0}
              tip={I18n.t('shared.loading')}>
              <Row gutter={[ 8, 48 ]}>
                <Col span={8}>
                  <div>
                    <StatisticCard
                      percentageValue={statisticsDashboard?.postedContentCount}
                      title={I18n.t(
                        'routes.panel.statistics.cards.postedContentCount',
                      )}
                      noAction
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <StatisticCard
                      percentageValue={statisticsDashboard?.viewedContentCount || 0}
                      title={I18n.t(
                        'routes.panel.statistics.cards.viewedContentCount',
                      )}
                      noAction
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <StatisticCard
                      percentageValue={statisticsDashboard?.totalUsersWhoAccessCount || 0}
                      title={I18n.t(
                        'routes.panel.statistics.cards.totalUsersWhoAccessCount',
                      )}
                      noAction
                    />
                  </div>
                </Col>
              </Row>
            </Spin>
          </div>
        </Content>

        <Content className='panel__layout__content'>
          <DataTable
            params={params}
            getMethod={(props) => this.getDataForTable(props)}
            data={usersForStatistics}
            loading={loading}
            notGetOnStart
            dontShowIndex
            showSizeChanger
            pageSizeOptions={[ '10' ]}
            columns={
              [
                {
                  ellipsis: true,
                  key: I18n.t('routes.panel.statistics.studentsTable.columns.name.key'),
                  title: I18n.t('routes.panel.statistics.studentsTable.columns.name.title'),
                  render: (value) => value || '--',
                },
                {
                  ellipsis: true,
                  key: I18n.t('routes.panel.statistics.studentsTable.columns.email.key'),
                  title: I18n.t('routes.panel.statistics.studentsTable.columns.email.title'),
                  render: (value) => value || '--',
                },
                {
                  ellipsis: true,
                  key: I18n.t('routes.panel.statistics.studentsTable.columns.cellphone.key'),
                  title: I18n.t('routes.panel.statistics.studentsTable.columns.cellphone.title'),
                  render: (value) => value || '--',
                },
                {
                  ellipsis: true,
                  key: I18n.t('routes.panel.statistics.studentsTable.columns.company.key'),
                  title: I18n.t('routes.panel.statistics.studentsTable.columns.company.title'),
                  render: (value) => (value ? parseCompanies(value) : '--'),
                },
                {
                  ellipsis: true,
                  key: I18n.t('routes.panel.statistics.studentsTable.columns.actions.key'),
                  title: I18n.t('routes.panel.statistics.studentsTable.columns.actions.title'),
                  render: (id) => (
                    <>
                      <Tooltip
                        title={I18n.t('routes.panel.statistics.studentsTable.columns.actions.buttons.statistics')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<BarChartOutlined />}
                          onClick={() => navigate(`${I18n.t('routes.panel.userStatistics.url')}${id}`)} />
                      </Tooltip>
                      <Tooltip
                        title={I18n.t('routes.panel.statistics.studentsTable.columns.actions.buttons.quiz')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<ProfileOutlined />}
                          onClick={() => navigate(
                            I18n.t('routes.panel.userQuiz.url', {userId: id})
                          )} />
                      </Tooltip>
                    </>
                  ),
                },
              ]
            }
          />
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statisticsDashboard: StatisticSelectors.getStatisticsDashboard(state),
  me: AuthSelectors.getMe(state),
  loading: LoadingSelectors.getLoading(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  searchedModules: CourseSelectors.searchModules(state),
  statisticsFilters: FilterSelector.getStatisticsFilters(state),
  contentSearched: ContentSelectors.getContentSearched(state),
  usersForStatistics: StatisticSelectors.getUserForStatistics(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticsDashboard: (parameters, me) =>
    dispatch(StatisticActions.getStatisticsDashboard(parameters, me)),
  getCompanySelective: () => dispatch(CompanyActions.getCompanySelectives()),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  setStatisticsMainFilters: (params) =>
    dispatch(FilterActions.setStatisticsMainFilters(params)),
  getModules: (parameters) =>
    dispatch(CourseActions.searchModules(parameters)),
  getContents: (parameters) =>
    dispatch(ContentActions.searchContents(parameters)),
  getUsersForStatisticsPaginated: (parameters) =>
    dispatch(StatisticActions.getUsersForStatisticsPaginated(parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
