import './styles/less/index.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';

import { ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale-provider/pt_BR';
import enUs from 'antd/lib/locale-provider/en_US';
import Config from './app/config/app.config';
import AuthServices from './app/services/auth';
import i18nDictionary from './i18n';
import * as AuthActions from './app/redux/actions/auth';
import initalizeRedux from './app/redux';
import Routes from './app/routes';

const redux = initalizeRedux();

export function axiosHandleUnatorizedErr () {
  redux.dispatch(AuthActions.cleanAuth());
}

async function authenticated (store) {
  if (AuthServices.isAuthenticated()) {
    const auth = AuthServices.get();
    await store.dispatch(AuthActions.saveAuthentication(auth));
  }
}

try {
  const tz = jstz.determine();
  const timezone = tz.name() || Config.timezone;
  let { language } = Config.language;

  if (!i18nDictionary[language]) ({ language } = Config);

  // initialize react-redux-i18n
  syncTranslationWithStore(redux);
  redux.dispatch(loadTranslations(i18nDictionary));
  redux.dispatch(setLocale(language));

  moment.tz.setDefault(timezone);
  moment.locale(language);

  if (moment.locale() === 'pt-br') {
    moment.updateLocale('pt-br', {
      weekdaysMin: [ 'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb' ],
    });
  }

  authenticated(redux);
} catch (err) {
  // Redirect to error page
} finally {
  // const launchLoader = document.getElementById('launch');
  // launchLoader.classList.add('hide');
}

ReactDOM.render(
  <Provider store={redux}>
    <ConfigProvider locale={moment.locale() === 'pt-br' ? ptBr : enUs}>
      <Routes />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
