export async function getBase64Image (url) {
  return new Promise((resolve) => {
    fetch(url, {cache: 'no-cache'}).then(r => r.blob()).then(blob => {
      const reader = new FileReader();

      reader.onload = function () {
        const b64 = reader.result;
        resolve(b64);
      };

      reader.readAsDataURL(blob);
    });
  })
}