export const removeEmptyField = (filterObj, ignore = []) => {
  Object.keys(filterObj).forEach((i) => {
    if ((!filterObj[i] || filterObj[i] === '' || filterObj[i] === null) && !ignore.includes(i)) {
      delete filterObj[i];
    }
  });
  return filterObj;
};

export const removeEmptyArray = (filterObj) => {
  Object.keys(filterObj).forEach((i) => {
    if (typeof filterObj[i] === 'object' && filterObj[i].length === 0) {
      delete filterObj[i];
    }
  });
  return filterObj;
};

export const joinElements = (filterObj) => {
  Object.keys(filterObj).forEach((i) => {
    if (typeof filterObj[i] === 'object') {
      filterObj[i] = filterObj[i].join();
    }
  });
  return filterObj;
};

export const getSubStringTitle = (value, start, end) => {
  let res = '';

  if (value.length > 80) {
    res = value.substring(start, end);
    return `${res}...`;
  }

  return value;
};

export const cpfValidation = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf === '') return false;

  if (cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999') { return false; }

  let add = 0;

  for (let i = 0; i < 9; i++) { add += parseInt(cpf.charAt(i), 10) * (10 - i); }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) { rev = 0; }

  if (rev !== parseInt(cpf.charAt(9), 10)) { return false; }

  add = 0;

  for (let i = 0; i < 10; i++) { add += parseInt(cpf.charAt(i), 10) * (11 - i); }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) { rev = 0; }

  if (rev !== parseInt(cpf.charAt(10), 10)) { return false; }

  return true;
};
