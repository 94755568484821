import {
  ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
  ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED,
  ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
} from '../actions/push-notification';

const initialState = {
  usersForPushNotificationPaginated: null,
  pushNotificationsPaginated: null,
  pushNotificationDetails: null,
};

export default function pushNotification (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_USERS_FOR_PUSH_PAGINATED:
      state = {
        ...state,
        usersForPushNotificationPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED:
      state = {
        ...state,
        pushNotificationsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_PUSH_NOTIFICATION_DETAILS:
      state = {
        ...state,
        pushNotificationDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getUsersForPushNotificationPaginated (state) {
  return state.pushNotification.usersForPushNotificationPaginated;
}

export function getPushNotificationsPaginated (state) {
  return state.pushNotification.pushNotificationsPaginated;
}

export function getPushNotificationDetails (state) {
  return state.pushNotification.pushNotificationDetails;
}
