import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import ContentRequests from '../../api/content';
import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_CONTENTS_PAGINATED = 'ACTION_SAVE_CONTENTS_PAGINATED';
export const ACTION_SAVE_CONTENTS_HISTORY = 'ACTION_SAVE_CONTENTS_HISTORY';
export const ACTION_SAVE_CONTENTS_USER_ID = 'ACTION_SAVE_CONTENTS_USER_ID';
export const ACTION_SAVE_CONTENTS_CALENDAR = 'ACTION_SAVE_CONTENTS_CALENDAR';
export const ACTION_SAVE_CONTENT_DETAILS = 'ACTION_SAVE_CONTENT_DETAILS';
export const ACTION_SAVE_CONTENT_SELECTIVES = 'ACTION_SAVE_CONTENT_SELECTIVES';

export const ACTION_SAVE_CONTENT_STATISTICS_DATA = 'ACTION_SAVE_CONTENT_STATISTICS_DATA';
export const ACTION_SAVE_CONTENT_DASHBOARD_SELECTIVES = 'ACTION_SAVE_CONTENT_DASHBOARD_SELECTIVES';
export const ACTION_SAVE_CONTENT_SEARCH = 'ACTION_SAVE_CONTENT_SEARCH'

export const ACTION_CLEAN_CONTENT = 'CLEAN_CONTENT';

export const cleanContent = () => ({
  type: ACTION_CLEAN_CONTENT,
  content: null,
});

export const getContentsPaginated = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
    showUnpublished: true,
  };

  if (me && me.profileType !== ProfileType.ADMIN) {
    parameters = {
      ...parameters,
    };
  }

  try {
    const contentsPaginated = await ContentRequests.getContentsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CONTENTS_PAGINATED,
      payload: contentsPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getContentsHistory = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    // orderBy: (parameters && parameters.orderBy) || 'modules.courseContent.postDate',
    isDESC: true,
  };

  try {
    const contents = await ContentRequests.getContentsHistory(parameters);
    dispatch({
      type: ACTION_SAVE_CONTENTS_HISTORY,
      payload: contents,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getContentPaginatedByUserId = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    // orderBy: (parameters && parameters.orderBy) || 'modules.courseContent.postDate',
    isDESC: true,
  };

  try {
    const contents = await ContentRequests.getUserContentPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CONTENTS_USER_ID,
      payload: contents,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getContentsCalendar = (parameters) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const contentsCalendar = await ContentRequests.getContentsCalendar(parameters);
    dispatch({
      type: ACTION_SAVE_CONTENTS_CALENDAR,
      payload: contentsCalendar,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getContentDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await ContentRequests.getContentDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_CONTENT_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getContentSelectives = (me, searchInput) => async (dispatch) => {
  try {
    let params = {
      searchInput,
    };

    if (me && me.profileType !== ProfileType.ADMIN) {
      params = {
        ...params,
      };
    }

    const contentDetails = await ContentRequests.getContentSelectives(params);
    dispatch({
      type: ACTION_SAVE_CONTENT_SELECTIVES,
      payload: contentDetails,
    });
  } catch (err) {
    //
  }
};

export const updateContent = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await ContentRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.contents.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createContent = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    data = {
      ...data,
      profileType: ProfileType.CONTENT,
    };
    await ContentRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.contentDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeContent = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await ContentRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.contents.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadFile = (file, url, contentType, onProgress, cancelToken, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const fileUpdated = await ContentRequests.importFile(file, url, contentType, onProgress, cancelToken);
    callback();
    return fileUpdated;
  } catch (err) {
    message.error(I18n.t(`routes.panel.contentDetails.messages.fileError.${err.message}`));
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }

};

export const getUrlToUploadFile = (fileOriginalName) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return ContentRequests.getUrlToUploadFile(fileOriginalName);
  } finally {
    dispatch(removeLoading());
  }
};

// statistics
export const getContentStatisticsData = (contentId, parameters) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const payload = await ContentRequests.getContentStatisticsData(contentId, parameters);
    dispatch({
      type: ACTION_SAVE_CONTENT_STATISTICS_DATA,
      payload,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getDashboardSelectives = (contentId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const payload = await ContentRequests.getDashboardSelectives(contentId);
    dispatch({
      type: ACTION_SAVE_CONTENT_DASHBOARD_SELECTIVES,
      payload,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const exportUsers = (contentId, params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    params = {
      ...params,
      contentId,
    };
    await ContentRequests.exportUsers(params);
    message.success(I18n.t('routes.panel.contentStatistics.messages.exportSuccess'));
  } catch (err) {
    message.error(I18n.t('routes.panel.contentStatistics.messages.exportError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const searchContents = (props) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const contents = await ContentRequests.getContentsPaginated(props);
    dispatch({
      type: ACTION_SAVE_CONTENT_SEARCH,
      payload: contents.rows,
    });
  } catch (err) {
    //
  }

  dispatch(removeLoading());
};
