import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { compressImages } from '../../../app/utils/imageUtils';
import { getBase64 } from '../../../app/utils/string';
import { message } from 'antd';

const Uploader = ({
  disabled,
  children,
  multiple,
  className,
  uploadClassName,
  type = 'image',
  onChange,
  value,
}) => {

  const [file, setFile] = useState(value);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (value !== null) {
      setFile(value);
    }
  }, [value]);

  const onFileChange = async ({ file }) => {
    if (file) {
      const validFile = 
        file.type === 'image/jpeg' || 
        file.type === 'image/png' ||
        file.type === 'application/pdf' ||
        file.type === 'audio/wav' || 
        file.type === 'audio/mp3' ||
        file.type === 'video/mp4' ||
        file.type === 'video/flv' ||
        file.type === 'video/wmv';

      if(!validFile && type !== 'image'){
        return message.error('Arquivo invalido');
      }

      if (['image/jpeg', 'image/png'].includes(file.type)){
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);
      toggleFile(file ? file.base64 : value || null);

    } else {
      onChange(null);
    }
  };

  const toggleFile = (file) => {
    setFile(file);
  };


  return (
    <div className={className || 'advanced-file-upload'}>
      <Upload
        className={uploadClassName || 'advanced-file-upload__upload'}
        customRequest={onFileChange}
        disabled={disabled}
        multiple={multiple}
        onChange={onChange}
        onPreview={() => true}
      >
        {children}
      </Upload>
    </div>
  )
}

export default Uploader