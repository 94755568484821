import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';

function humanizeDate (date, format) {
  try {
    if (date) {
      if (moment(date, 'YYYY-MM-DD').isValid()) {
        date = moment(date, 'YYYY-MM-DD').format(format);
      }
    } else {
      date = '--';
    }

    return date;
  } catch (err) {
    //
  }
}

function humanizeDateTime (date, format) {
  try {
    if (date) {
      if (moment(date, 'YYYY-MM-DD HH:mm').isValid()) {
        date = moment.utc(date).local().format(format);
        // date = moment(date, 'YYYY-MM-DD HH:mm').format(format);
      }
    } else {
      date = '--';
    }

    return date;
  } catch (err) {
    //
  }
}

function parseDate (date, fromFormat) {
  try {
    if (date) {
      if (moment(date, fromFormat).isValid()) {
        date = moment(date, fromFormat).format('YYYY-MM-DD');
      }
    }

    return date;
  } catch (err) {
    //
  }
}

function isToday (date) {
  const today = new Date()
  date = new Date(date)
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

function hourAndMinute (date) {
  date = new Date(date)
  const hour = date.getHours()
  const minute = date.getMinutes()
  return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`
}

function getDuration (startTime, end) {
  try {
    const duration = moment.duration(moment(end).diff(moment(startTime)));
    return duration.asMinutes();
  } catch (err) {
   //
  }
}

function validatePeriod (startAt, endAt) {
  const validStartAt = moment(startAt, 'DD/MM/YYYY')
  const validEndAt = moment(endAt, 'DD/MM/YYYY')
  const presentDay = moment().format('DD/MM/YYYY')

  if(moment(validStartAt, 'DD/MM/YYYY').isBefore(moment(presentDay, 'DD/MM/YYYY'))) {
    return (I18n.t('routes.panel.userDetails.messages.errors.startAt_invalid'))
  }

  if(!validStartAt.isValid() || !validEndAt.isValid()) {
    return (I18n.t('routes.panel.userDetails.messages.errors.invalid_date'))
  }

  if(!moment(validStartAt).isBefore(moment(validEndAt))) {
    return (I18n.t('routes.panel.userDetails.messages.errors.before_date'))
  }
}

export {
  humanizeDate,
  humanizeDateTime,
  parseDate,
  getDuration,
  isToday,
  hourAndMinute,
  validatePeriod,
};
