import React from 'react';
import {
  Layout,
  Breadcrumb, Calendar, Badge, Select, Typography, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import { Link } from '@reach/router';
import { ContentActions } from '../../../app/redux/actions';
import { LoadingSelectors, ContentSelectors } from '../../../app/redux/reducers';

class ContentCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
    };
  }

  async componentDidMount() {
    await this.initializeCalendar();
  }

  initializeCalendar = async () => {
    const { getContentsCalendar, id } = this.props;
    const { selectedMonth, selectedYear } = this.state;

    if (id) {
      await getContentsCalendar({ courseId: id, month: selectedMonth, year: selectedYear });
    }
  }

  getListData = (value) => {
    const listData = [];
    const { contentsCalendar } = this.props;
    const newArray = (contentsCalendar && contentsCalendar.filter((el) => el.courseContent && el.courseContent.postDate
      && moment(el.courseContent.postDate).date() === value.date()
      && moment(el.courseContent.postDate).month() === value.month()
      && moment(el.courseContent.postDate).year() === value.year())) || [];
    newArray.forEach((element) => {
      listData.push({
        type: 'blue',
        content: element.title,
      });
    });
    return listData;
  }

  dateCellOverrideRender = (value) => {
    if (value.month() !== this.state.selectedMonth) {
      return;
    }
    const listData = this.getListData(value);
    return (
      <div className={`day-container ${
        value.date() === moment().date()
          && value.month() === moment().month()
          && value.year() === moment().year() ? 'today' : ''}`}
      >
        <div className="day-value">
          {value.date()}
        </div>
        <div className="day-content">
          <ul className="events">
            {listData.map((item) => (
              <li key={item.content}>
                <Badge
                  status={item.type}
                  text={item.content}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              status={item.type}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  }

  updateYearAndMonth = (dateMoment) => {
    const { selectedMonth, selectedYear } = this.state;
    if (dateMoment.year() === selectedYear && dateMoment.year() === selectedMonth) {
      return;
    }
    this.setState({ selectedMonth: dateMoment.month(), selectedYear: dateMoment.year() }, async () => {
      await this.initializeCalendar();
    });
  }

  render() {
    const { Content } = Layout;
    const {
      loading,
    } = this.props;

    return (
      <div className="content-calendar">
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.courses.url')}`}>
                <span>{I18n.t('routes.panel.courses.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.contentCalendar.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>


        <Content className="panel__layout__content">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <Calendar
              onPanelChange={(e) => this.setState({ selectedMonth: e.month() })}
              dateFullCellRender={this.dateCellOverrideRender}
              monthFullCellRender={this.monthCellRender}
              onChange={(e) => this.updateYearAndMonth(e)}
              headerRender={({
                value, onChange,
              }) => {
                const start = 0;
                const end = 12;
                const monthOptions = [];

                const current = value.clone();
                const localeData = value.localeData();
                const months = [];
                for (let i = 0; i < 12; i++) {
                  current.month(i);
                  months.push(localeData.monthsShort(current));
                }

                for (let index = start; index < end; index++) {
                  monthOptions.push(
                    <Select.Option
                      key={`${index}`}
                    >
                      {months[index]}
                    </Select.Option>,
                  );
                }
                const month = value.month();

                const year = value.year();
                const options = [];
                for (let i = year - 5; i <= year + 5; i += 1) {
                  options.push(
                    <Select.Option
                      key={i}
                      value={i}
                    >
                      {i}
                    </Select.Option>,
                  );
                }
                return (
                  <div style={{ padding: 8 }}>
                    <div className="row">
                      <div className="col">
                        <Typography.Title level={4}>
                          {`${moment().month(month).format('MMMM').toUpperCase()} ${String(year)}`}
                        </Typography.Title>
                      </div>
                      <div className="col-auto">
                        <div className="row">
                          <div className="col px-1">
                            <Select
                              dropdownMatchSelectWidth={false}
                              onChange={(newYear) => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                              }}
                              value={String(year)}
                            >
                              {options}
                            </Select>
                          </div>
                          <div className="col px-1">
                            <Select
                              dropdownMatchSelectWidth={false}
                              value={String(month)}
                              onChange={(selectedMonth) => {
                                const newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                              }}
                            >
                              {monthOptions}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </Spin>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentsCalendar: ContentSelectors.getContentCalendar(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getContentsCalendar: (parameters) => dispatch(ContentActions.getContentsCalendar(parameters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ContentCalendar,
);
