/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

export const LazyInput = ({renderInput, handleSendValue, delayTime}) => {

  const [ inputValue, setInputValue ] = useState();

  useEffect(() => {
    if (inputValue === undefined) return;

    const timeout = setTimeout(() => {
      handleSendValue(...inputValue);
    }, delayTime || 800);

    return () => {
      clearTimeout(timeout);
    };
  }, [ inputValue ]);

  const onChange = useCallback((...value) => {
    setInputValue(value)
  }, [])

  return renderInput(onChange)
}