import React, { useState } from 'react';
import {
  Avatar,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row
} from 'antd';
import {
  MoreOutlined,
} from '@ant-design/icons';
import { getInitials } from '../../app/utils/string';
import PreviewQuestion from '../preview-question/PreviewQuestion';
import Logo from '../../app/assets/img/ic_logo.svg';
import { Alternative_Answer } from '../../app/enum/questionEnum';
import { I18n } from 'react-redux-i18n';


export const ChatHeader = (props) => {
  const [ studentModalVisible, setStudentModalVisible ] = useState(false);
  const [ questionModalVisible, setQuestionModalVisible ] = useState(false);
  const [ contentModalVisible, setContentModalVisible ] = useState(false);
  // eslint-disable-next-line prefer-destructuring
  const contentDetail = props.contentInfo.rows[0]

  const showModal = (type) => {
    if (type === 'question') {
      setQuestionModalVisible(true)
    } else if (type === 'student') {
      setStudentModalVisible(true);
    } else {
      setContentModalVisible(true);
    }
  };

  const handleOk = () => {
    setStudentModalVisible(false);
    setQuestionModalVisible(false);
    setContentModalVisible(false);
  };

  const handleCancel = () => {
    setStudentModalVisible(false);
    setQuestionModalVisible(false);
    setContentModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={() => showModal('student')}>
          {I18n.t('routes.modalDetails.userDetail.title')}
        </a>
      </Menu.Item>
      {props.question ? (
        <Menu.Item>
          <a onClick={() => showModal('question')}>
            {I18n.t('routes.modalDetails.questionDetail.title')}
          </a>
        </Menu.Item>
      ) :
        <Menu.Item>
          <a onClick={() => showModal('content')}>
            {I18n.t('routes.modalDetails.contentDetail.title')}
          </a>
        </Menu.Item>
      }

    </Menu>
  );

  const studentModal = () => {
    return (
      <Modal title="Dados do usuário"
        visible={studentModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.name')}</strong>{props.user.name || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.cpf')}</strong>{props.user.cpf || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.phoneNumber')}</strong>{props.user.cellphone || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.profission')}</strong>{props.user.profession || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.email')}</strong>{props.user.email || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.city')}</strong>{props.user.city || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.state')}</strong>{props.user.state || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.address')}</strong>{props.user.address || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.userDetail.cep')}</strong>{props.user.cep || '--'}
            </p>
          </Col>
        </Row>
      </Modal>
    )
  }

  const contentModal = () => {
    return (
      <Modal title="Dados do usuário"
        visible={contentModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.contentDetail.courseTitle')}</strong>
              {contentDetail?.userContent?.content.courseContent.course.course.title || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.contentDetail.module')}
              </strong>{contentDetail?.userContent?.content.courseContent.course.title || '--'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>{I18n.t('routes.modalDetails.contentDetail.content')}</strong>{contentDetail?.userContent?.content && contentDetail?.userContent?.content?.title || '--'}
            </p>
          </Col>
        </Row>
      </Modal>
    )
  }

  const questionModal = () => {
    return (
      <Modal title="Detalhes da questão"
        visible={questionModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className="modal_margin">
          <div className="header_modal">
            <div className="water_marc_logo">
              <img
                src={Logo}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <PreviewQuestion
            location={props.question.location}
            year={props.question.year}
            description={props.question.description}
            justification={props.question.justification}
            justificationImage={props.question.justificationImageUrl}
            justificationVideo={props.question.justificationVideoUrl}
            justificationAudio={props.question.justificationAudioUrl}
            answers={props.question.answers}
            isEditing={false}
            onOpen={(url) => window.open(url, '_blank').focus()}
            userAnswer={props.questionAnswer && props.questionAnswer.length > 0 && props.questionAnswer[0].answer}
            essayOptions={props.question.type === Alternative_Answer.DISSERTATION && props.question.answers.map((item) => item.labelType)}
          />
        </div>
      </Modal>
    )
  }


  return (
    <div className="chat__header">
      <>
        {studentModalVisible && studentModal()}
        {questionModalVisible && questionModal()}
        {contentModalVisible && contentModal()}
      </>
      <div className="chat__header__left">
        {props.user && props.user.photoUrl && props.loadedChat ? (
          <div className="chat__header__left__img">
            <Avatar src={props.user.photoUrl} />
          </div>
        ) : (
          <div className="chat__header__left__img">
            <Avatar>
              {props.user && props.user.name && props.loadedChat ? getInitials(props.user.name) : ''}
            </Avatar>
          </div>
        )}
        <div className="chat__header__left__name">
          {props.user && props.loadedChat ? props.user.name : 'Carregando...'}
        </div>
      </div>
      
      {
        (props.user && (props.question || props.contentInfo)) && (
          <div className="chat__header__right">
            <Dropdown overlay={menu}
              placement="bottomRight">
              <div className="chat__header__right__item">
                <MoreOutlined />
              </div>
            </Dropdown>
          </div>
        )
      }
    </div>
  )
}

export default ChatHeader
