import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  DatePicker,
  Layout,
  message,
  Modal,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { navigate } from '@reach/router';
import { AuthActions, UserActions, UtilsActions } from '../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../../app/redux/reducers';
import { GetUserStatus, UserStatusType, UserStatusTypeArray } from '../../../app/enum/userStatusType';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { removeSpecialChars } from '../../../app/utils/string';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { SpecializationList } from '../../../app/enum/specializationType';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import * as DateUtils from '../../../app/utils/date';

class UserRequests extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      cityList: [],
      stateList: [],
      cardIdsLoading: [],
      modalId: '',
      planExpiry: null,
      params: {
        name: null,
        profileType: ProfileType.USER,
        startCreatedAt: null,
        endCreatedAt: null,
        specializationId: null,
        state: '',
        city: null,
      },
      columnsFromBackend:
      {
        [UserStatusTypeArray[UserStatusType.REQUESTED - 1].name]: {
          id: UserStatusType.REQUESTED,
          name: UserStatusTypeArray[UserStatusType.REQUESTED - 1].name,
          items: [],
        },
        [UserStatusTypeArray[UserStatusType.AWAITING_PAYMENT - 1].name]: {
          id: UserStatusType.AWAITING_PAYMENT,
          name: UserStatusTypeArray[UserStatusType.AWAITING_PAYMENT - 1].name,
          items: [],
        },
        [UserStatusTypeArray[UserStatusType.APPROVED - 1].name]: {
          id: UserStatusType.APPROVED,
          name: UserStatusTypeArray[UserStatusType.APPROVED - 1].name,
          items: [],
        },
        [UserStatusTypeArray[UserStatusType.INACTIVE - 1].name]: {
          id: UserStatusType.INACTIVE,
          name: UserStatusTypeArray[UserStatusType.INACTIVE - 1].name,
          items: [],
        },
      },
    };
  }

  async componentDidMount () {
    const { getStates } = this.props;
    const states = await getStates();
    await this.setInicialStates();
    this.setState({ stateList: this.parseStateList(states) });
  }

  handleConfirmModal = () => {
    if (this.state.modalId) {
      this.updateUserRequest(
        this.state.modalId,
        {
          status: UserStatusType.APPROVED,
        },
      );
    } else {
      message.error(I18n.t('routes.panel.userRequestDetails.messages.errors.date'));
    }
  };

  handleCancelModal = () => {
    this.setInicialStates();
  };

  getCityByState = async (value) => {
    const { getCityByState } = this.props;
    const cityListByState = await getCityByState(value);
    const { params } = this.state;
    params.city = null;
    this.setState({ cityList: this.parseCityList(cityListByState), params });
  }

  parseCityList = (list) => list.map((cityItem) => ({ id: cityItem.city, name: cityItem.city }));

  parseStateList = (list) => list.map((stateItem) => ({ id: stateItem.state, name: stateItem.state }));

  setInicialStates = async (params = { profileType: ProfileType.USER }) => {
    const { me } = this.props;
    this.setState({ params: { ...params } });
    await this.props.getUsersKanban(params, me);

    this.setState((state) => ({
      columnsFromBackend: {
        ...state.columnsFromBackend,
        [UserStatusTypeArray[UserStatusType.REQUESTED - 1].name]: {
          ...state.columnsFromBackend[UserStatusTypeArray[UserStatusType.REQUESTED - 1].name],
          items: this.props.usersKanban
            && this.props.usersKanban
              .filter((item) => item.status === UserStatusType.REQUESTED),
        },
        [UserStatusTypeArray[UserStatusType.AWAITING_PAYMENT - 1].name]: {
          ...state.columnsFromBackend[UserStatusTypeArray[UserStatusType.AWAITING_PAYMENT - 1].name],
          items: this.props.usersKanban
            && this.props.usersKanban
              .filter((item) => item.status === UserStatusType.AWAITING_PAYMENT),
        },
        [UserStatusTypeArray[UserStatusType.APPROVED - 1].name]: {
          ...state.columnsFromBackend[UserStatusTypeArray[UserStatusType.APPROVED - 1].name],
          items: this.props.usersKanban
            && this.props.usersKanban
              .filter((item) => item.status === UserStatusType.APPROVED),
        },
        [UserStatusTypeArray[UserStatusType.INACTIVE - 1].name]: {
          ...state.columnsFromBackend[UserStatusTypeArray[UserStatusType.INACTIVE - 1].name],
          items: this.props.usersKanban
            && this.props.usersKanban
              .filter((item) => item.status === UserStatusType.INACTIVE),
        },
      },
    }));
  }

  onDragEnd = (result, columns) => {
    if (!result.destination) return undefined;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [ ...sourceColumn.items ];
      const destItems = [ ...destColumn.items ];
      const [ removed ] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      this.setState(() => ({
        columnsFromBackend: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        },
      }));

      this.updateUserRequest(removed.id, { status: destColumn.id });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [ ...column.items ];
      const [ removed ] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      this.setState(() => ({
        columnsFromBackend: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        },
      }));
    }
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  handleSpecialization = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  handleChangeDate = (value, startDateName, endDateName) => {
    const { params } = this.state;

    if (value) {
      params[startDateName] = value[0].toDate();
      params[endDateName] = value[1].toDate();
    } else {
      params[startDateName] = null;
      params[endDateName] = null;
    }

    this.setState({ params });
  }

  updateUserRequest = (id, data) => {
    const { updateUser } = this.props;
    this.setState((state) => ({
      cardIdsLoading: [ ...state.cardIdsLoading, id ],
    }), async () => {
      try {
        await updateUser(id, data, (success) => {
          if(success) {
            message.success(I18n.t('routes.panel.userRequestDetails.messages.success'));
          } else {
            this.setInicialStates();
          }
        });
      } catch (e) {
        this.setInicialStates();
      } finally {
        this.setState((state) => ({
          cardIdsLoading: [ ...state.cardIdsLoading ].filter((item) => item !== id),
        }));
      }
    });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.setInicialStates(params);
  };

  cleanAdvancedFilters = () => this.setInicialStates();

  render () {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const { RangePicker } = DatePicker;
    const {
      params, columnsFromBackend, cardIdsLoading, cityList, stateList,
    } = this.state;
    const {
      loading,
    } = this.props;
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.userRequests.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={SpecializationList}
                      label={I18n.t('shared.advancedFilters.form.specialization')}
                      value={params && params.specializationId}
                      onChange={(val) => this.handleSpecialization('specializationId', val)}
                    />
                  </Col>
                  <Col span={6}>
                    <div style={{ paddingBottom: '5px' }}>
                      {I18n.t('shared.advancedFilters.form.created')}
                    </div>
                    <RangePicker
                      style={{ width: '100%' }}
                      value={
                        params && params.startCreatedAt && params.endCreatedAt
                          ? [ moment(params.startCreatedAt), moment(params.endCreatedAt) ]
                          : []
                      }
                      placeholder={
                        [
                          I18n.t('shared.advancedFilters.form.startDate'),
                          I18n.t('shared.advancedFilters.form.endDate'),
                        ]
                      }
                      format="DD/MM/YYYY"
                      onChange={(val) => this.handleChangeDate(val, 'startCreatedAt', 'endCreatedAt')}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedSelect
                      options={stateList}
                      label={I18n.t('shared.advancedFilters.form.state')}
                      value={params && params.state}
                      onChange={(val) => {
                        this.fieldChange('state', val);
                        this.fieldChange('cityList', null);
                      }}
                      onSelect={(val) => this.getCityByState(val)}
                      disableSearch
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedSelect
                      options={cityList}
                      value={params && params.state && params.city}
                      onChange={(val) => this.fieldChange('city', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.city')}
                      disableSearch
                      disabled={!(params && params.state)}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Spin
          spinning={loading > 0 && cardIdsLoading.length === 0}
          tip={I18n.t('shared.loading')}
        >
          <Content className="panel__layout__content">
            <div
              className="userRequests"
              style={{
                display: 'flex', justifyContent: 'space-between',
              }}
            >
              <DragDropContext onDragEnd={(result) => this.onDragEnd(result, columnsFromBackend)}>
                {columnsFromBackend && Object.entries(columnsFromBackend).map(([ id, column ]) => (
                  <div
                    key={id}
                    style={{
                      width: '24%',
                    }}
                  >
                    <Droppable
                      droppableId={id}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="droppable"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver ? 'rgba(0, 150, 255, 0.1)' : '#ebebeb',
                            padding: 10,
                          }}
                        >
                          <div style={{ marginBottom: 20 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div className={`outer ${removeSpecialChars(column.name.toLowerCase())}`}>
                                <div className="inner">&nbsp;</div>
                              </div>
                              <span style={{ fontSize: 14, fontWeight: 'bold' }}>{column.name}</span>
                            </div>
                            <span style={{ color: '#bbbbbb', marginLeft: 18 }}>
                              {column.items && column.items.length}{' '}
                              {I18n.t('routes.panel.userRequests.kanban.registrationsMade')}
                            </span>
                          </div>
                          {column.items && column.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(providedDraggable, snapshotDraggable) => (
                                <div
                                  className="draggable"
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.dragHandleProps}
                                  {...providedDraggable.draggableProps}
                                  style={{
                                    userSelect: 'none',
                                    backgroundColor: snapshotDraggable.isDragging ? '#263b4e' : '#fdfdfd',
                                    color: snapshotDraggable.isDragging ? 'white' : '#424242',
                                    border: snapshotDraggable.isDragging ? 'none' : '1px solid #cfcfcf',
                                    ...providedDraggable.draggableProps.style,
                                  }}
                                >
                                  <Spin
                                    spinning={loading > 0 && cardIdsLoading.includes(item.id)}
                                    tip={I18n.t('shared.loading')}
                                  >
                                    <Row className="align_items_center">
                                      <Col span={20}>
                                        <div style={{ fontWeight: 'bold' }}>
                                          {item.name}
                                        </div>
                                      </Col>
                                    </Row>
                                    <div style={{ fontSize: 12, marginTop: '5px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {I18n.t('routes.panel.userRequests.kanban.email')}
                                      </span>
                                      <span style={{ overflowWrap: 'break-word' }}>
                                        {item.email || '--'}
                                      </span>
                                    </div>
                                    <div style={{ fontSize: 12, marginTop: '5px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {I18n.t('routes.panel.userRequests.kanban.specialization')}
                                      </span>
                                      {(
                                        item.specializations
                                        && item.specializations.map((spc, i, self) => (i !== self.length - 1
                                          ? `${spc.title}, `
                                          : spc.title)))
                                        || ' --'}
                                    </div>
                                    <div style={{ fontSize: 12, marginTop: '5px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {I18n.t('routes.panel.userRequests.kanban.TEMI')}
                                      </span>
                                      {item.hasTemi ? 'Sim' : 'Não'}
                                    </div>
                                    <div style={{ fontSize: 12, marginTop: '5px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {I18n.t('routes.panel.userRequests.kanban.UTI_AMIB')}
                                      </span>
                                      {item.hasUtiExpertise ? 'Sim' : 'Não'}
                                    </div>
                                    <div style={{ fontSize: 12, marginTop: '5px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        {I18n.t('routes.panel.userRequests.kanban.location')}
                                      </span>
                                      {item.city || ' --'}
                                    </div>
                                    <div style={{ fontSize: 12, marginTop: '5px', textAlign: 'right' }}>
                                      <span
                                        style={{ fontWeight: 'bold', cursor: 'pointer', textDecoration: 'underline', color:'blue' }}
                                        onClick={() => navigate(`${I18n.t('routes.panel.userInfo.url')}${item.id}`)}
                                      >
                                        {I18n.t('routes.panel.userRequests.kanban.seeMore')}
                                      </span>
                                    </div>
                                  </Spin>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                ))}
              </DragDropContext>

            </div>
          </Content>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersKanban: UserSelectors.getUsersKanban(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersKanban: (parameters, me) => dispatch(UserActions.getUsersKanban(parameters, me)),
  updateUser: (id, data, callback) => dispatch(UserActions.updateUser(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCityByState: (state) => dispatch(UtilsActions.getCityByState(state)),
  getStates: (state) => dispatch(UtilsActions.getStates(state)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  UserRequests,
);
