import React, { useState, useEffect, useRef } from 'react'
import useRecorder from "../../app/hooks/useRecorder";
import {
  CheckOutlined,
  CloseOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import {ReactComponent as AudioIcon } from '../../app/assets/img/recording.svg'
import { Spin } from 'antd';
import {
  message,
} from 'antd';

function RecordAudio(props) {

  let [audioFile, isRecording, startRecording, stopRecording, audioURL] = useRecorder();
  const playerRef = useRef();

  const handleRecord = async () => {
    if(!isRecording) return startRecording()
    stopRecording()
  }

  const handleSend = () => {
    if(!audioURL || !audioFile) return message.error('Grave um audio antes de enviar!');
    props.onSend(audioFile, 'audio/mpeg-3')
    props.onClose()
  }

  return (
    <div className="record-audio">
      <div className="record-audio__container">
        <div onClick={() => handleRecord()} className={`record-audio__container__controls ${isRecording && 'record-audio__container__controls--active'}`}>
          <AudioIcon />
          {isRecording ? 'Gravando...' : '   Clique no botão para gravar!'}
        </div>
        <audio src={audioURL} controls ref={playerRef} />   
        <div className="record-audio__container__buttons">
          <div onClick={() => props.onClose()} className="record-audio__container__buttons__close">
            <CloseOutlined />
          </div>
          <div onClick={() => handleSend()} className="record-audio__container__buttons__send">
            <CheckOutlined />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecordAudio