import Axios from 'axios';
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import AuthServices from '../services/auth';
import ApiUtil, { handleAxiosError } from '../utils/api';
import { axiosHandleUnatorizedErr } from '../../index';
import redux from '../redux/index';
import { AuthActions } from '../redux/actions';

function getInstance ({ isAuth } = {}, { contentType } = {}) {
  const auth = AuthServices.get();
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': ApiUtil.getApiKey(),
    'Authorization': auth && auth.token ? `Bearer ${auth.token}` : undefined,
  };

  if (contentType) {
    headers['Content-Type'] = contentType;
    delete headers.Authorization;
  }

  const axiosInstance = Axios.create({
    baseURL: isAuth ? 'https://auth.ccdensino.com.br/api' : ApiUtil.getBaseUrl(),
    timeout: 9000000,
    headers,
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      if (err && err.response && err.response.status === 401) {
        try {
          const store = redux();
          const payload = AuthServices.get();
          await store.dispatch(AuthActions.refreshToken(payload));
          window.location.reload();
        } catch {
          message.error(I18n.t('routes.login.errors.user_invalid_token'));
          axiosHandleUnatorizedErr();
        }
      }

      if (err && err.response && err.response.status === 403) {
        axiosHandleUnatorizedErr();
      }

      return Promise.reject(handleAxiosError(err));
    });

  return axiosInstance;
}

export default getInstance;
