import { ProfileType } from '../enum/profileType';

const appRoutes = [
  {
    url: '/painel/administradores/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/administradores/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/administradores/detalhe/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/usuarios/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/usuarios/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/usuario/detalhe/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/conteudo/',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/conteudo/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/cursos/',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR
    ],
  },
  {
    url: '/painel/curso/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/modulos/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.UPLOADER,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.UPLOADER,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/modulo/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.UPLOADER,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.UPLOADER,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/agenda/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/solicitacoes/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.KANBAN_MANAGER,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.KANBAN_MANAGER,
    ],
  },
  {
    url: '/painel/dashboard/estatisticas/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/dashboard/estatisticas/usuario/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/dashboard/estatisticas/conteudo/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/dashboard/estatisticas/lista/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/configuracoes/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/configuracoes/categorias/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/categorias/add',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/hospitais/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/hospitais/add',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/empresas/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/empresas/add',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/notificacoes/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/notificacoes/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/usuarios/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/usuarios/curso',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/usuarios/empresa',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/administradores/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/conteudo/',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
  },
  {
    url: '/painel/curso/',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
  },
  {
    url: '/painel/modulo/',
    view: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.UPLOADER,
      ProfileType.MARKETING
    ],
  },
  {
    url: '/painel/configuracoes/hospitais/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/empresas/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/configuracoes/categorias/',
    view: [
      ProfileType.ADMIN,
    ],
    write: [
      ProfileType.ADMIN,
    ],
  },
  {
    url: '/painel/notificaoes/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/push-notification/',
    view: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.MARKETING,
    ],
  },
  {
    url: '/painel/provas/',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/provas/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/provas/estatisticas/',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
    ],
  },
  {
    url: '/painel/provas/estatisticas/correcao/',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
    ],
  },
  {
    url: '/painel/questoes/',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
  {
    url: '/painel/questoes/add',
    view: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
    write: [
      ProfileType.ADMIN,
      ProfileType.USER,
      ProfileType.TEACHER,
      ProfileType.QUIZER,
      ProfileType.UPLOADER,
      ProfileType.MARKETING,
      ProfileType.OPERATOR,
    ],
  },
];

// exemplo: src/containers/panel/users/Users.js linha 394
export const hasPermission = (url, permission, profileType) => {
  const cleanUrl = url.replace(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g, '');
  const routePermission = appRoutes.find((o) => o.url === cleanUrl);

  if (!routePermission) {
    return false;
  }

  return !!routePermission[permission].find((o) => o === profileType);
};
