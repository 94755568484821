import getInstance from './config';

export default class AssignStudentsApi {

  static async getAllStudents (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/all', { params });
    return data;
  }

  static async getStudentsByQuiz (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user-course/users', { params });
    return data;
  }

  static async getSelectedStudents (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user-quiz/quiz/users', { params });
    return data;
  }

  static async updateSelectedStudents (payload) {
    const instance = await getInstance();
    const { data } = await instance.put('/user-quiz/user-list', payload);
    return data;
  }

  static async deleteSelectedStudents (params) {
    const instance = await getInstance();
    const { data } = await instance.delete('/user-quiz/user-list', { params });
    return data;
  }

}
