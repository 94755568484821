import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import ReleaseNoteRequests from '../../api/releaseNote';

export const ACTION_SAVE_RELEASE_NOTE_PAGINATED = 'ACTION_SAVE_RELEASE_NOTE_PAGINATED';
export const ACTION_SAVE_RELEASE_NOTE_DETAILS = 'ACTION_SAVE_RELEASE_NOTE_DETAILS';


export const getReleaseNotePaginated = (params) => async (dispatch) => {
  dispatch(addLoading());
  params = {
    ...params,
    limit: (params && params.pageSize) || 10,
    offset: (params && params.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const payload = await ReleaseNoteRequests.getReleaseNotePaginated(params);

    dispatch({
      type: ACTION_SAVE_RELEASE_NOTE_PAGINATED,
      payload
    });
    return payload;
  } catch (err) {
    message.error(I18n.t('routes.panel.releaseNotes.messages.error.request'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createReleaseNote = (releaseNote, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await ReleaseNoteRequests.create(releaseNote);

    if (callback && typeof callback === 'function') {
      callback();
    }

    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.releaseNotes.messages.error.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeReleaseNote = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await ReleaseNoteRequests.delete(id);

    if (callback && typeof callback === 'function') {
      callback();
    }

    return response;
  } catch (err) {
    message.error(I18n.t('routes.panel.releaseNotes.messages.error.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getNotesDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  let payload = null;


  try {

    if (id) {
      payload = await ReleaseNoteRequests.getReleaseNoteDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_RELEASE_NOTE_DETAILS,
      payload,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.releaseNotes.messages.error.details'));
  } finally {
    dispatch(removeLoading());
  }

  return payload

};

export const updateNote = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await ReleaseNoteRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(true);
    }
  } catch (err) {
    message.error(I18n.t('routes.panel.releaseNotes.messages.error.editNote'));
    callback(false)
  } finally {
    dispatch(removeLoading());
  }
};