import React from 'react';
import {
  Col,
  Divider, Layout, message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { HospitalActions } from '../../../app/redux/actions';
import { HospitalSelectors, LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as HospitalIcon } from '../../../app/assets/img/configuration_icon_blue.svg';
import HospitalForm from '../../../app/forms/hospital/HospitalForm';
import { ViewType } from '../../../app/enum/viewType';
import { removeSpecialChars } from '../../../app/utils/string';

class HospitalDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      hospitalDetails: null,
    };
  }

  async componentDidMount () {
    const { getHospitalDetails, id } = this.props;
    await getHospitalDetails(id);
    this.setState({ hospitalDetails: this.props.hospitalDetails });

    if (!id) {
      this.setState({
        viewType: ViewType.ADD,
      });
    }
  }

  async updateHospital (id, data) {
    const { updateHospital, createHospital, hospitalDetails } = this.props;

    try {
      data = {
        ...data,
        phone: removeSpecialChars(data.phone),
        cep: removeSpecialChars(data.cep),
        cnpj: removeSpecialChars(data.cnpj),
      };

      if (!data.phone || data.phone === 0) {
        delete data.phone;
      }

      if (this.state.viewType === ViewType.ADD) {
        await createHospital(data, () => {
          message.success(I18n.t('routes.panel.hospitalDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.hospitals.url'));
        });
      } else {
        await updateHospital(hospitalDetails.id, data, () => {
          message.success(I18n.t('routes.panel.hospitalDetails.messages.success'));
          navigate(I18n.t('routes.panel.hospitals.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render () {
    const { Content } = Layout;
    const { viewType, hospitalDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <Content className="panel__layout__content">
        <div className="hospital-details">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <div className="panel__layout__content__title">
              <h2 className="panel__layout__content__title__value">
                <span className="panel__layout__content__title__value__icon">
                  <Icon component={HospitalIcon} />
                </span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.hospitalDetails.pageTitleAdd'
                      : 'routes.panel.hospitalDetails.pageTitle',
                  )
                }
              </h2>
              <p className="panel__layout__content__title__value__description">
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.hospitalDetails.pageDescriptionAdd'
                      : 'routes.panel.hospitalDetails.pageDescription',
                  )
                }
              </p>
            </div>

            <Divider />

            <div>
              <Row>
                <Col span={24}>
                  {(hospitalDetails || viewType === ViewType.ADD) && (
                    <HospitalForm
                      data={hospitalDetails}
                      submitFunction={(hospitalId, data) => this.updateHospital(hospitalId, data)}
                      viewType={viewType}
                      id={id}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  hospitalDetails: HospitalSelectors.getHospitalDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getHospitalDetails: (id) => dispatch(HospitalActions.getHospitalDetails(id)),
  updateHospital: (id, data, callback) => dispatch(HospitalActions.updateHospital(id, data, callback)),
  createHospital: (data, callback) => dispatch(HospitalActions.createHospital(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  HospitalDetails,
);
