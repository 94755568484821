import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import CourseRequests from '../../api/course';
import { ProfileType } from '../../enum/profileType';
import { CourseType } from '../../enum/courseType';

export const ACTION_SAVE_COURSES_PAGINATED = 'ACTION_SAVE_COURSES_PAGINATED';
export const ACTION_SAVE_COURSE_DETAILS = 'ACTION_SAVE_COURSE_DETAILS';
export const ACTION_SAVE_COURSE_SELECTIVES = 'ACTION_SAVE_COURSE_SELECTIVES';
export const ACTION_SAVE_COURSE_SEARCH = 'ACTION_SAVE_COURSE_SEARCH';
export const ACTION_SAVE_COURSE_GRAPH = 'ACTION_SAVE_COURSE_GRAPH';
export const ACTION_SAVE_MODULES_SEARCH = 'ACTION_SAVE_SEARCH_MODULES'

export const ACTION_CLEAN_COURSE = 'CLEAN_COURSE';

export const cleanCourse = () => ({
  type: ACTION_CLEAN_COURSE,
  course: null,
});

export const getCoursesPaginated = (parameters, me, type = CourseType.COURSE, clear) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    type,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  if (me && me.profileType !== ProfileType.ADMIN) {
    parameters = {
      ...parameters,
    };
  }

  try {
    const coursesPaginated = await CourseRequests.getCoursesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_COURSES_PAGINATED,
      payload: clear ? {rows:[], count:0} : coursesPaginated ,
    });
    return coursesPaginated;
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const searchCourses = (searchInput) => async (dispatch) => {
  try {
    const courseSearch = await CourseRequests.searchCourses(searchInput);
    dispatch({
      type: ACTION_SAVE_COURSE_SEARCH,
      payload: courseSearch,
    });
  } catch (err) {
    //
  }
};

export const getCourseDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await CourseRequests.getCourseDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_COURSE_DETAILS,
      payload: data,
    });
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCourseSelectives = (me, searchInput) => async (dispatch) => {
  try {
    let params = {
      searchInput,
    };

    if (me && me.profileType !== ProfileType.ADMIN) {
      params = {
        ...params,
      };
    }

    const courseDetails = await CourseRequests.getCourseSelectives(params);
    dispatch({
      type: ACTION_SAVE_COURSE_SELECTIVES,
      payload: courseDetails,
    });
  } catch (err) {
    //
  }
};

export const getCourseSelectivesQuiz = (params) => async (dispatch) => {
  params = {
    ...params,
    type:1
  }

  try {
    const courseDetails = await CourseRequests.getCourseSelectives(params);
    dispatch({
      type: ACTION_SAVE_COURSE_SELECTIVES,
      payload: courseDetails,
    });
  } catch (err) {
    //
  }
};

export const updateCourse = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    if (err.message.includes('can_only_exist_one_trial')) {
      message.error(`${I18n.t('routes.panel.courseDetails.messages.errors.can_only_exist_one_trial')} ${err.message.split(':')[1]}`);
    } else {
      message.error(I18n.t('routes.panel.courses.errors.generic'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const createCourse = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.courseDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const duplicateCourse = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.duplicate(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.courseDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const createModule = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const removeCourse = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.courses.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadFile = (file, url, contentType, onProgress, cancelToken, callback) => async (dispatch) => {
  try {
    const fileUpdated = await CourseRequests.importFile(file, url, contentType, onProgress, cancelToken);
    callback();
    return fileUpdated;
  } catch (err) {
    message.error(I18n.t(`routes.panel.courseDetails.messages.fileError.${err.message}`));
    throw new Error();
  }
};

export const getUrlToUploadFile = (fileOriginalName) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return CourseRequests.getUrlToUploadFile(fileOriginalName);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCourseGraph = (params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const payload = await CourseRequests.getCourseGraph(params);
    dispatch({
      type: ACTION_SAVE_COURSE_GRAPH,
      payload,
    });
  } finally {
    dispatch(removeLoading());
  }
};

export const searchModules = (params) => async (dispatch) => {
  dispatch(addLoading());
  const parameters = {
    ...params,
    type: CourseType.MODULE,
    limit: 50,
    orderBy: 'createdAt',
    isDESC: true
  }

  try {
    const payload = await CourseRequests.getCoursesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_MODULES_SEARCH,
      payload: payload.rows,
    });
  } finally {
    dispatch(removeLoading());
  }
};

export const getOrderedContentsByCourse = (courseId) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await CourseRequests.getOrderedContentsByCourse(courseId);

    return {title: response.title, contents: response.modules}
  } finally {
    dispatch(removeLoading());
  }
};

export const saveContentsOrder = (courseId, order, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CourseRequests.saveOrder(courseId, order)
    message.success(I18n.t('routes.panel.orderContents.errors.saveSuccess'));

    if(callback) callback()
  } catch (err) {
    message.error(I18n.t('routes.panel.orderContents.errors.saveFail'));
  } finally {
    dispatch(removeLoading());
  }

};
