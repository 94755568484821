import React from 'react';
import {
  Breadcrumb,
  Col,
  Collapse,
  Divider,
  Input, Layout, message, Row, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, { SaveOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { QuizActions } from '../../../app/redux/actions';
import {
  LoadingSelectors, QuizSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { removeEmptyField } from '../../../app/utils/functionsUtils';
import {
  CorrectionAnswerArray, QuestionAnswerType, QuestionType, UserQuizStatus,
} from '../../../app/enum/questionEnum';

const { Panel } = Collapse;

class QuizExame extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      correction: [],
      params: {
        name: '',
        categoriesId: '',
        isEnabled: '',
      },
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    let { correction } = this.state;
    await this.props.getUserAnswers(this.props.id);
    const { questions } = this.props.userAnswers.quiz;
    correction = questions;
    this.setState({ correction });
  }

  updateQuiz = async (id, data) => {
    const { updateQuiz, getQueizzes } = this.props;
    let { params } = this.state;
    params = removeEmptyField(params);
    const parameter = { ...params };

    if (parameter.isEnabled === 2) {
      parameter.isEnabled = true;
    } else if (parameter.isEnabled === 1) {
      parameter.isEnabled = false;
    }

    try {
      await updateQuiz(id, data);
      await getQueizzes(parameter);
      message.success(I18n.t('routes.panel.quiz.messagesUpdateQuiz'));
    } catch (e) {
      message.error(I18n.t('routes.panel.quiz.messagesUpdateQuizFail'));
    }
  };

  addCorrection = (value, userAnswersIndex, questionIndex) => {
    const { correction } = this.state;
    correction[questionIndex].userAnswers[userAnswersIndex].correctionAnnotation = value;
    this.setState({ correction });
  }

  addCorrectionTag = (value, userAnswersIndex, questionIndex) => {
    const { correction } = this.state;
    correction[questionIndex].userAnswers[userAnswersIndex].status = value;
    this.setState({ correction });
  }

  sendCorrection = async () => {
    const { correction } = this.state;
    const payload = [];

    try {
      for (let i = 0; i < correction.length; i++) {
        for (let j = 0; j < correction[i].userAnswers.length; j++) {
          payload.push({
            id: correction[i].userAnswers[j].id,
            status: correction[i].userAnswers[j].status,
            correctionAnnotation: correction[i].userAnswers[j].correctionAnnotation ? correction[i].userAnswers[j].correctionAnnotation : '',
          });
        }
      }

      await this.props.sendCorrection(payload);
      message.success(I18n.t('forms.userMediaAnswer.correctionSavedButQuizNotFinished'));
    } catch (err) {
      //
    }
  }

  getLabelStatus = (status) => {
    switch (status) {

      case UserQuizStatus.WAITING:
        return 'Aguardando Correção';
      case UserQuizStatus.DONE:
        return 'Corrigido';
      case UserQuizStatus.NOT_DONE:
        return 'Não feito';
      case UserQuizStatus.PENDING:
        return 'Pendente';
      default:
        return 'Indefinido';

    }
  }

  finishCorrection = async () => {
    try {
      await this.props.finishQuizCorrection(this.props.id, { status: UserQuizStatus.DONE });
      navigate(`${I18n.t('routes.panel.quiz.urlQuizStatistics')}${this.props.userAnswers.quiz.id}`);
    } catch (err) {
      //
    }
  }

  render () {
    const { Content } = Layout;
    const { TextArea } = Input;
    const { correction } = this.state;
    const { loading } = this.props;
    const getVideo = (item) => (
      <>
        <Row>
          <Col span={15}>
            <video
              width="100%"
              height="100%"
              controls
              src={item}
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
    const getPic = (image) => (
      <>
        <Row>
          <Col span={15}>
            <img
              src={image}
              width="100%"
              height="100%"
            />
          </Col>
        </Row>
        <Divider />
      </>
    );
    const getText = (text) => (
      <>
        <Row>
          <Col span={15}>
            <span><strong>{I18n.t('forms.userMediaAnswer.textAnswer')}</strong></span>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <span>{text}</span>
          </Col>
        </Row>
        <Divider />
      </>
    );
    const getAudio = (audio) => (
      <audio controls>
        <source
          src={audio}
          type="audio/mp3"
        />
      </audio>
    );

    const displayMediaQuestions = (item, userAnswersIndex, questionIndex) => {
      if (item.medias.length > 0 || item.value) {
        return (
          <>
            {item.answer.type === QuestionAnswerType.VIDEO && (getVideo(item.medias[0].mediaFileUrl))}
            {item.answer.type === QuestionAnswerType.PHOTO && item.medias.map((e) => getPic(e.mediaFileUrl))}
            {item.answer.type === QuestionAnswerType.TEXT && getText(item.value)}
            {item.answer.type === QuestionAnswerType.AUDIO && getAudio(item.medias[0].mediaFileUrl)}
            <Row>
              <Col span={10}>
                <AdvancedSelect
                  options={CorrectionAnswerArray}
                  label={I18n.t('forms.userMediaAnswer.correctionTag')}
                  value={item?.status}
                  onChange={(val) => this.addCorrectionTag(val, userAnswersIndex, questionIndex)}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={15}>
                <p>{I18n.t('forms.userMediaAnswer.correctionComment')}</p>
              </Col>
            </Row>
            <Row>
              <Col span={15}>
                <TextArea
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  value={item.correctionAnnotation}
                  onChange={(event) => this.addCorrection(event.target.value, userAnswersIndex, questionIndex)}
                  maxLength={255}
                />
              </Col>
            </Row>
            <Divider />
          </>
        );
      }
    };

    const displayQuestions = (question, questionIndex) => (
      <>
        <div key={question.id}>
          <Row>
            <Col span={24}>
              <span>
                <strong>{`${question.quizQuestion.questionNumber}. `}</strong>
                <span>{`${question.location} - ${question.year}`}</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span>
                <strong>{question.description}</strong>
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>{I18n.t('forms.userMediaAnswer.description')}</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>{question.description}</span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              <h6>{I18n.t('forms.userMediaAnswer.userAnswer')}</h6>
            </Col>
          </Row>
          {question.userAnswers.map((mediaItem, index) => (
            <Row key={mediaItem.id}>
              <Col span={24}>
                {displayMediaQuestions(mediaItem, index, questionIndex)}
              </Col>
            </Row>
          ))}
        </div>
      </>
    );
    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuizStatistics')}${this.props.userAnswers?.quiz.id}`}>
                <span>{I18n.t('routes.panel.quiz.pageStatistics')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.quiz.pageCorrections')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className="panel__layout__content">
          <div className="users">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <Row>
                <Col span={12}>
                  <h3>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon component={QuizIcon} />
                    </span>
                    {this.props.userAnswers?.quiz.name} - {I18n.t('forms.userMediaAnswer.correction')}
                  </h3>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col>
                  <h4>{I18n.t('forms.userMediaAnswer.user')}:</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span><strong>{I18n.t('forms.userMediaAnswer.name')}:</strong> {this.props.userAnswers?.user.name}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span>
                    <strong>
                      {I18n.t('forms.userMediaAnswer.status')}:
                    </strong>
                    {this.getLabelStatus(this.props.userAnswers?.status)}
                  </span>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col><h5>{I18n.t('forms.userMediaAnswer.answers')}</h5></Col>
              </Row>
              {correction.map((item, index) => {
                if (item.type === QuestionType.OTHER) {
                  return (
                    <>
                      <Row key={item.id}>
                        <Col span={24}>
                          {displayQuestions(item, index)}
                        </Col>
                      </Row>
                    </>
                  );
                }
              })}
              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                    onClick={() => this.sendCorrection()}
                  />
                  <Divider type="vertical" />
                  <AdvancedButton
                    text={I18n.t('forms.userMediaAnswer.finishCorrection')}
                    onClick={() => this.finishCorrection()}
                  />

                </Col>
              </Row>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userAnswers: QuizSelectors.getUserAnswers(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserAnswers: (id) => dispatch(QuizActions.getUserAnswers(id)),
  sendCorrection: (correction) => dispatch(QuizActions.sendCorrection(correction)),
  finishQuizCorrection: (id, correction) => dispatch(QuizActions.finishQuizCorrection(id, correction)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  QuizExame,
);
