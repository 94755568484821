import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import HospitalRequests from '../../api/hospital';

export const ACTION_SAVE_HOSPITAL_SELECTIVES = 'ACTION_SAVE_HOSPITAL_SELECTIVES';
export const ACTION_SAVE_HOSPITALS_PAGINATED = 'ACTION_SAVE_HOSPITALS_PAGINATED';
export const ACTION_SAVE_HOSPITAL_DETAILS = 'ACTION_SAVE_HOSPITAL_DETAILS';

export const ACTION_CLEAN_HOSPITAL = 'CLEAN_HOSPITAL';

export const cleanHospital = () => ({
  type: ACTION_CLEAN_HOSPITAL,
  hospital: null,
});

export const getHospitalsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const hospitalsPaginated = await HospitalRequests.getHospitalsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_HOSPITALS_PAGINATED,
      payload: hospitalsPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getHospitalSelectives = (contentId) => async (dispatch) => {
  try {
    const hospitalSelectives = await HospitalRequests.getHospitalSelectives(contentId);
    dispatch({
      type: ACTION_SAVE_HOSPITAL_SELECTIVES,
      payload: hospitalSelectives,
    });
  } catch (err) {
    //
  }
};

export const getHospitalDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await HospitalRequests.getHospitalDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_HOSPITAL_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const updateHospital = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await HospitalRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.hospitalDetails.messages.errors.${err.message}`));
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};


export const createHospital = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
    };
    await HospitalRequests.createHospital(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.hospitalDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeHospital = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await HospitalRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.hospitals.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
