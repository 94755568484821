import React from 'react';
import { Row, Col, Radio, Divider, Button } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  ALTERNATIVE_TYPE,
  QuestionType,
  Alternative_Answer,
} from '../../app/enum/questionEnum';

const SEND_MEDIA = {
  PICTURES: 2,
  AUDIO: 3,
  VIDEO: 4,
  TEXT: 5,
};
class PreviewQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showJustification: false,
    };
  }

  render() {
    const {
      location,
      year,
      description,
      answers,
      onOpen,
      userAnswer,
      justification,
      justificationImage,
      justificationAudio,
      justificationVideo,
      essayOptions,
    } = this.props;

    const { showJustification } = this.state;
    const getLetterAlterantive = (index) => {
      switch (index) {
        case 0:
          return 'A.';
        case 1:
          return 'B.';
        case 2:
          return 'C.';
        case 3:
          return 'D.';
        case 4:
          return 'E.';
        default:
          return 'A.';
      }
    };
    const getAudio = (url) => {
      if (url !== '' && url && typeof url !== 'object') {
        return (
          <span className='block_media margin_bottom'>
            <Row>
              <div className='margin_top'>
                <Col span={8}>
                  <audio controls>
                    <source
                      src={url}
                      type='audio/mp3'
                      onDoubleClick={() => onOpen(url)}
                    />
                  </audio>
                  <span className='margin_top'>
                    {I18n.t('forms.newQuestion.previewAudioNewTab')}
                  </span>
                </Col>
              </div>
            </Row>
          </span>
        );
      }
    };
    const getVideo = (url, answer) => {
      if (url !== '' && url && typeof url !== 'object') {
        return (
          <span className='block_media margin_bottom'>
            <Row justify={answer ? 'center' : ''}>
              <Col span={8}>
                <div className='block_media margin_top'>
                  <video
                    width='100%'
                    height='100%'
                    controls
                    src={url}
                    onDoubleClick={() => onOpen(url)}
                  />
                </div>
                <span className='margin_top'>
                  {I18n.t('forms.newQuestion.previewVideoNewTab')}
                </span>
              </Col>
            </Row>
          </span>
        );
      }
    };
    const getImage = (url) => {
      if (url !== '' && url && typeof url !== 'object') {
        return (
          <span className='block_media margin_bottom'>
            <Row>
              <Col span={10}>
                <div className='margin_top section_photo'>
                  <img
                    width='100%'
                    height='100%'
                    alt='Imagem da alternativa'
                    src={url}
                    onClick={() => onOpen(url)}
                  />
                </div>
                <span className='margin_top'>
                  {I18n.t('forms.newQuestion.previewImageNewTab')}
                </span>
              </Col>
            </Row>
          </span>
        );
      }
    };
    const getDescription = () => {
      let questioDescription = [];
      if (description) {
        questioDescription = description.split('\n');
        return questioDescription?.map((item) => (
          <Row key={item}>
            <Col span={24}>
              <div>{item}</div>
            </Col>
          </Row>
        ));
      }
    };

    return (
      <div className='margin_bottom' ref={this.props.ref}>
        <Row>
          <Col span={24}>
            <h6>
              {I18n.t('forms.newQuestion.labelLocal')} -{' '}
              {I18n.t('forms.newQuestion.labelYear')}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {(location || year) && (
              <p>
                {location}-{year}
              </p>
            )}
          </Col>
        </Row>
        <Divider type='horizontal' />
        <Row>
          <Col span={24}>
            <h6>{I18n.t('forms.newQuestion.questionBodyPreview')}</h6>
          </Col>
        </Row>
        {getDescription()}
        <Divider type='horizontal' />
        <Row>
          <Col span={24}>
            {getImage(this.props.imageUrl)}
            {getAudio(this.props.audioUrl)}
            {getVideo(this.props.videoUrl, true)}
          </Col>
        </Row>
        {(justification ||
          justificationImage ||
          justificationAudio ||
          justificationVideo) && (
          <>
            <Row>
              <Col span={24}>
                <Button
                  type={'primary'}
                  onClick={() =>
                    this.setState({ showJustification: !showJustification })
                  }
                  htmlType={'button'}
                >
                  <h6>{I18n.t('forms.newQuestion.showJustification')}</h6>
                </Button>
              </Col>
            </Row>
            <Divider type='horizontal' />
            {showJustification && (
              <>
                <Row>
                  <Col span={24}>
                    <h6>{I18n.t('forms.newQuestion.justification')}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className='preview-question__justification' span={24}>
                    {justification}
                    {getImage(justificationImage)}
                    {getAudio(justificationAudio)}
                    {getVideo(justificationVideo, true)}
                  </Col>
                </Row>
                <Divider type='horizontal' />
              </>
            )}
          </>
        )}
        <Row>
          <Col span={24}>
            <h6>
              {essayOptions && essayOptions.length > 0
                ? I18n.t('forms.newQuestion.labelEditDissertative')
                : I18n.t('forms.newQuestion.labelEditAlternative')}
            </h6>
          </Col>
        </Row>
        {essayOptions &&
          essayOptions.length > 0 &&
          essayOptions.map((item) => {
            switch (item) {
              case ALTERNATIVE_TYPE.TEXT:
                return (
                  <p>{`${'-'} ${I18n.t('forms.notifications.labelText')}`}</p>
                );
              case ALTERNATIVE_TYPE.AUDIO:
                return (
                  <p>{`${'-'} ${I18n.t('forms.newQuestion.labelAudio')}`}</p>
                );
              case ALTERNATIVE_TYPE.PHOTO:
                return (
                  <p>{`${'-'} ${I18n.t('forms.newQuestion.labelImage')}`}</p>
                );
              case ALTERNATIVE_TYPE.VIDEO:
                return (
                  <p>{`${'-'} ${I18n.t('forms.newQuestion.labelVideo')}`}</p>
                );
            }
          })}
        {!essayOptions &&
          answers.map((item, index) => {
            if (item.type === String(QuestionType.MULTIPLEANSWER)) {
              switch (item.labelType) {
                case ALTERNATIVE_TYPE.AUDIO:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <Radio
                              checked={
                                (userAnswer && item.id === userAnswer.id) ||
                                (item && item.isCorrect)
                              }
                              disabled
                            />
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            {getAudio(item.labelFileUrl)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case ALTERNATIVE_TYPE.VIDEO:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <Radio
                              checked={
                                (userAnswer && item.id === userAnswer.id) ||
                                (item && item.isCorrect)
                              }
                              disabled
                            />
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            {getVideo(item.labelFileUrl)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case ALTERNATIVE_TYPE.PHOTO:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <Radio
                              disabled
                              checked={
                                (userAnswer && item.id === userAnswer.id) ||
                                (item && item.isCorrect)
                              }
                            />
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            {getImage(item.labelFileUrl)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case ALTERNATIVE_TYPE.TEXT:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <Radio
                              disabled
                              checked={
                                (userAnswer && item.id === userAnswer.id) ||
                                (item && item.isCorrect)
                              }
                            />
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            <span>{item.label}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                default:
                  return <></>;
              }
            } else {
              switch (item.type) {
                case SEND_MEDIA.PICTURES:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            <span>Envio de Imagem </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case SEND_MEDIA.VIDEO:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            <span>Envio de Vídeo</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case SEND_MEDIA.TEXT:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            <span>Envio de Texto</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                case SEND_MEDIA.AUDIO:
                  return (
                    <div key={item.id} className='margin_top'>
                      <Row>
                        <Col span={24}>
                          <div className='preview_question_answer'>
                            <h6 className='margin_letter_answer'>
                              {getLetterAlterantive(index)}
                            </h6>
                            <span>Envio de Áudio</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                default:
                  return <></>;
              }
            }
          })}
      </div>
    );
  }
}

export default PreviewQuestion;
