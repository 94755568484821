import React, { useEffect, useRef, useState } from 'react'
import {
  Layout,
} from 'antd';
import { connect } from 'react-redux';
import {
  ChatActions,
  QuizActions,
  UserActions
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  ChatSelectors,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors
} from '../../../app/redux/reducers';
import ChatHeader from '../../../components/chat-components/ChatHeader';
import ChatPanel from '../../../components/chat-components/ChatPanel';
import ChatFooter from '../../../components/chat-components/ChatFooter';
import FileType from '../../../app/enum/messageFileType';
import MessageType from '../../../app/enum/messageType';
import { CHAT_TYPE } from '../../../app/enum/questionEnum';

const { Content } = Layout

const ChatPage = (props) => {
  const [ timeOut, setTimeOut ] = useState(0);
  const [ chatRecived, setChatRecived ] = useState();
  const [ chatInfo, setChatInfo ] = useState();
  const [ isLoadedChat, setIsLoadedChat ] = useState(false);

  useEffect(() => {
    setIsLoadedChat(false);
    getUserMessages();

    return () => {
      props.clearUserMessages()
    }
  }, []);

  useEffect(() => {
    if (props.messages && props.messages.length) {
      getChatPaginated({type: props.messages[0].typeChat})

      const chatId = props && props.messages && props.messages.length && props.messages[0].chatId;
      const chatInfoDetails = props.chatPaginated && props.chatPaginated.count > 0 && props.chatPaginated.rows.find((item) => item.id === chatId);

      if (chatInfoDetails && chatInfoDetails.id === chatId) {
        setChatInfo(chatInfoDetails);
      }

      if (chatInfo && chatInfo.questionId) {
        props.getUserDetails(chatInfo.user.id)
        props.getQuestion(chatInfo.questionId)
        setChatRecived(chatInfo)
      } else {
        props.getUserDetails(chatInfo?.user?.id)
        setChatRecived(chatInfo)
      }
    }
  }, [ props.messages ])


  useEffect(() => {
    setTimeout(() => {
      setTimeOut((state) => {
        return state + 1
      });
      getUserMessages();
      setIsLoadedChat(true);
    }, 5000)
  }, [ timeOut ])

  const getFileType = (fileType) => {
    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/png' ||
      fileType === 'application/pdf'
    ) { return FileType.IMAGE }

    if (fileType.includes('audio/', 0)) {
      return FileType.AUDIO
    }

    return FileType.VIDEO
  }

  const sendMessage = async (message, fileType) => {
    let payload;
    let messageType;

    switch (props.messages[0].typeChat) {
      case CHAT_TYPE.MESSAGE:
        messageType = MessageType.QUESTION
        break
      case CHAT_TYPE.CONTENT:
        messageType = MessageType.CONTENT
        break
      case CHAT_TYPE.QUESTION_DATABASE:
        messageType = MessageType.QUESTION_DATABASE
        break
      default:
        break;
    }

    if (!fileType) {
      payload = {
        chatId: props.id,
        message,
        chatType: messageType,
      }
    } else {
      payload = {
        chatId: props.id,
        fileName: message,
        fileType: getFileType(fileType),
        chatType: messageType,
      }
    }

    await props.sendMessage(payload)
    setTimeout(() => {
      getUserMessages();
    }, 500)
  }

  const getUserMessages = async () => {
    await props.getMessages(props.id)
  }

  const getChatPaginated = async (type) => {
    await props.getChatPaginated(type)
  }

  return (
    <Content>
      <div className="chat">
        <ChatHeader
          user={props && props.user}
          question={props && props.question}
          questionAnswer={chatRecived && chatRecived.userQuiz && chatRecived.userQuiz.userAnswers}
          contentInfo={props.chatPaginated}
          loadedChat={isLoadedChat}
        />
        <ChatPanel
          messages={props && props.messages}
          whoSent={props && props.user}
          loadedChat={isLoadedChat}
        />
        <ChatFooter sendMessage={sendMessage} />
      </div>
    </Content>
  )
}

const mapStateToProps = (state) => ({
  question: QuizSelectors.getSpecificQuestion(state),
  chatPaginated: ChatSelectors.getChatPaginated(state),
  me: AuthSelectors.getMe(state),
  messages: ChatSelectors.getMessages(state),
  loading: LoadingSelectors.getLoading(state),
  user: UserSelectors.getUserDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getChatPaginated: (parameters) => dispatch(ChatActions.getChatPaginated(parameters)),
  getMessages: (id) => dispatch(ChatActions.getMessages(id)),
  sendMessage: (params) => dispatch(ChatActions.sendMessage(params)),
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
  clearUserMessages: () => dispatch(ChatActions.getMessages(null, true)),
  getQuestion: (id) => dispatch(QuizActions.getQuestionById(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ChatPage,
);
