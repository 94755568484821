import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import { ProfileType } from '../../enum/profileType';
import PushNotificationRequests from '../../api/push-notification';

export const ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED = 'ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED';
export const ACTION_SAVE_PUSH_NOTIFICATION_DETAILS = 'ACTION_SAVE_PUSH_NOTIFICATION_DETAILS';

export const ACTION_SAVE_USERS_FOR_PUSH_PAGINATED = 'ACTION_SAVE_USERS_FOR_PUSH_PAGINATED';
export const ACTION_CLEAN_PUSH_NOTIFICATION = 'CLEAN_PUSH_NOTIFICATION';

export const cleanPushNotification = () => ({
  type: ACTION_CLEAN_PUSH_NOTIFICATION,
  pushNotification: null,
});

export const getUsersForPushNotification = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  if (parameters.profileType) {
    parameters = {
      ...parameters,
      profileType: parameters.profileType,
    };
  } else if (parameters.profileTypeList) {
    parameters = {
      ...parameters,
      profileTypeList: parameters.profileTypeList,
    };
  }

  if (me && me.profileType !== ProfileType.ADMIN) {
    parameters = {
      ...parameters,
    };
  }

  try {
    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: null,
    });
    const usersPaginated = await PushNotificationRequests.getUsersForPushPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const sendPushNotification = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await PushNotificationRequests.send(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`pushNotification.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const getPushNotificationsPaginated = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const pushNotificationsPaginated = await PushNotificationRequests.getPushNotificationsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_PUSH_NOTIFICATIONS_PAGINATED,
      payload: pushNotificationsPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getPushNotificationDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await PushNotificationRequests.getPushNotificationDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const removePushNotification = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await PushNotificationRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.pushNotifications.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getPermissionNotification = (params) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await PushNotificationRequests.getPermission(params);
  } catch (err) {
    message.error(I18n.t('routes.panel.pushNotifications.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
