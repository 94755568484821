import React from 'react';
import {
  Col,
  Divider, Layout, message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { CategoryActions } from '../../../app/redux/actions';
import { CategorySelectors, LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as CategoryIcon } from '../../../app/assets/img/configuration_icon_blue.svg';
import CategoryForm from '../../../app/forms/category/CategoryForm';
import { ViewType } from '../../../app/enum/viewType';

class CategoryDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      categoryDetails: null,
    };
  }

  async componentDidMount () {
    const { getCategoryDetails, id } = this.props;
    await getCategoryDetails(id);
    this.setState({ categoryDetails: this.props.categoryDetails });

    if (!id) {
      this.setState({
        viewType: ViewType.ADD,
      });
    }
  }

  async updateCategory (id, data) {
    const { updateCategory, createCategory, categoryDetails } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        await createCategory(data, () => {
          message.success(I18n.t('routes.panel.categoryDetails.messages.successCreate'));
          navigate(I18n.t('routes.panel.categories.url'));
        });
      } else {
        await updateCategory(categoryDetails.id, data, () => {
          message.success(I18n.t('routes.panel.categoryDetails.messages.success'));
          navigate(I18n.t('routes.panel.categories.url'));
        });
      }
    } catch (e) {
      //
    }
  }

  render () {
    const { Content } = Layout;
    const { viewType, categoryDetails } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <Content className="panel__layout__content">
        <div className="category-details">
          <Spin
            spinning={loading > 0}
            tip={I18n.t('shared.loading')}
          >
            <div className="panel__layout__content__title">
              <h2 className="panel__layout__content__title__value">
                <span className="panel__layout__content__title__value__icon">
                  <Icon component={CategoryIcon} />
                </span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.categoryDetails.pageTitleAdd'
                      : 'routes.panel.categoryDetails.pageTitle',
                  )
                }
              </h2>
              <p className="panel__layout__content__title__value__description">
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.categoryDetails.pageDescriptionAdd'
                      : 'routes.panel.categoryDetails.pageDescription',
                  )
                }
              </p>
            </div>

            <Divider />

            <div>
              <Row>
                <Col span={24}>
                  {(categoryDetails || viewType === ViewType.ADD) && (
                    <CategoryForm
                      data={categoryDetails}
                      submitFunction={(categoryId, data) => this.updateCategory(categoryId, data)}
                      viewType={viewType}
                      id={id}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Spin>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryDetails: CategorySelectors.getCategoryDetails(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCategoryDetails: (id) => dispatch(CategoryActions.getCategoryDetails(id)),
  updateCategory: (id, data, callback) => dispatch(CategoryActions.updateCategory(id, data, callback)),
  createCategory: (data, callback) => dispatch(CategoryActions.createCategory(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CategoryDetails,
);
