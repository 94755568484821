import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import CompanyRequests from '../../api/company';

export const ACTION_SAVE_COMPANY_SELECTIVES = 'ACTION_SAVE_COMPANY_SELECTIVES';
export const ACTION_SAVE_COMPANIES_PAGINATED = 'ACTION_SAVE_COMPANIES_PAGINATED';
export const ACTION_SAVE_COMPANY_DETAILS = 'ACTION_SAVE_COMPANY_DETAILS';

export const ACTION_CLEAN_COMPANY = 'CLEAN_COMPANY';

export const cleanCompany = () => ({
  type: ACTION_CLEAN_COMPANY,
  company: null,
});

export const getCompaniesPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const companiesPaginated = await CompanyRequests.getCompaniesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_COMPANIES_PAGINATED,
      payload: companiesPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getCompanySelectives = () => async (dispatch) => {
  try {
    const companySelectives = await CompanyRequests.getCompanySelectives();
    dispatch({
      type: ACTION_SAVE_COMPANY_SELECTIVES,
      payload: companySelectives,
    });
  } catch (err) {
    //
  }
};

export const getCompanyDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await CompanyRequests.getCompanyDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_COMPANY_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCompany = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CompanyRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.companyDetails.messages.errors.${err.message}`));
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};


export const createCompany = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
    };
    await CompanyRequests.createCompany(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.companyDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeCompany = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CompanyRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.companies.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
