import React from 'react';
import { Translate } from 'react-redux-i18n';

const UserPlanOption = {
  BRONZE: 1,
  SILVER: 2,
  GOLD: 3,
  BLACK: 4,
};

export const UserPlanOptionMap = new Map([
  [ UserPlanOption.BRONZE, 'Bronze' ],
  [ UserPlanOption.SILVER, 'Prata' ],
  [ UserPlanOption.GOLD, 'Ouro' ],
  [ UserPlanOption.BLACK, 'Black' ],
]);

export const UserPlanOptionsArray = [
  { id: UserPlanOption.BRONZE, name: <Translate value="userPlanOption.bronze" />},
  { id: UserPlanOption.SILVER, name: <Translate value="userPlanOption.silver" />},
  { id: UserPlanOption.GOLD, name: <Translate value="userPlanOption.gold" />},
  { id: UserPlanOption.BLACK, name: <Translate value="userPlanOption.black" /> },
];



export const UserPlanOptionScoreWeight = new Map([
  [ UserPlanOption.BRONZE, 1 ],
  [ UserPlanOption.SILVER, 1.5 ],
  [ UserPlanOption.GOLD, 2 ],
  [ UserPlanOption.BLACK, 3 ],
]);

export default UserPlanOption;