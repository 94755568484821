import { useEffect, useState } from 'react';

const useRecorder = () => {
  const [ audioURL, setAudioURL ] = useState('');
  const [ audioFile, setAudioFile ] = useState(null);
  const [ isRecording, setIsRecording ] = useState(false);
  const [ recorder, setRecorder ] = useState(null);

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }

      return;
    }

    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    const handleData = e => {
      try {
        setAudioURL(URL.createObjectURL(e.data))
        const blob = new Blob([ e.data ], {type: 'audio/mpeg-3'})
        const audioFile = new File([ blob ], `ccd-audio-${Date.now()}.mp3`);
        setAudioFile(audioFile)
      } catch (e) {
        setAudioFile(null)
        setAudioURL('')
      }
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [ recorder, isRecording ]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [ audioFile, isRecording, startRecording, stopRecording, audioURL ];
};

async function requestRecorder () {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}

export default useRecorder;
