import React from 'react'
import * as DateUtils from '../../app/utils/date';
import FileType from '../../app/enum/messageFileType';

function ChatMessage ({message, mine, file}) {
  return (
    <>
      <div className={`chat__panel__container__message ${mine ? 'chat__panel__container__message--me' : 'chat__panel__container__message--user' }`}>
        <div className={`chat__panel__container__message__content ${mine ? 'chat__panel__container__message__content--me' : 'chat__panel__container__message__content--user' }`}>
          {file && file === FileType.AUDIO && (
            <audio className="chat__file-bar__container__image"
              controls="controls"
              autobuffer="autobuffer">
              <source src={message.fileUrl} />
            </audio>
          )}
          {file && file === FileType.IMAGE && (<img src={message.fileUrl}
            className="chat__panel__container__message__content__image"/>)}
          {file && file === FileType.VIDEO && (
            <a href={message.fileUrl}
              className="chat__file-bar__container__video"
              target="_blank"
              rel="noreferrer">
                Clique aqui para abrir o video
            </a>
          )}
          {!file && message.message}
          {DateUtils.isToday(message.sentAt) ?
            <span className="chat__panel__container__message__content__time">{DateUtils.hourAndMinute(message.sentAt)}</span>
            :
            <span className="chat__panel__container__message__content__time">{DateUtils.humanizeDate(message.sentAt, 'DD/MM/YYYY')}</span>
          }
        </div>
      </div>
    </>
  )
}

export default ChatMessage