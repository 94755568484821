import React from 'react';
import {
  Col,
  Layout,
  Row,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { Link } from '@reach/router';

class PasswordChanged extends React.PureComponent {
  render () {
    const { Content } = Layout;
    return (
      <Content className="login__container">
        <Row
          type="flex"
          justify="center"
        >
          <Col
            span={24}
          >
            <Row className="login__logo">
              <Col>
                <img
                  alt="login logo"
                  className="login__logo__img"
                  src="/assets/img/logo.svg"
                />
              </Col>
            </Row>
            <Row className="login__logo">
              <Col>
                <h3 style={{ color: 'white' }}>
                  {I18n.t('routes.passwordChanged.content.title')}
                </h3>
                <Link to={I18n.t('routes.login.url')}>
                  <span style={{ color: 'white' }}>
                    {I18n.t('routes.passwordChanged.content.backToLogin')}
                  </span>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default PasswordChanged;
