import getInstance from './config';

export default class HospitalApi {
  static async getHospitalsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/hospital', { params });
    return data;
  }

  static async getHospitalDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/hospital/${id}`);
    return data;
  }

  static async update (id, hospitalData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/hospital/${id}`, hospitalData);
    return data;
  }

  static async createHospital (hospitalData) {
    const instance = await getInstance();
    const { data } = await instance.post('/hospital', hospitalData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/hospital/${id}`);
    return data;
  }

  static async getHospitalSelectives (contentId) {
    const instance = await getInstance();
    const { data } = await instance.get(`/hospital/selectives?contentId=${contentId}`);
    return data;
  }
}
