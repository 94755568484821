import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs

export function getPdfDefinitionsToPrintTable (title, headers, rows, fontSize, headerFontSize) {
  return {
    content: [
      { text: title, style: 'globalHeader' },
      {
        style: 'tableContent',
        table: {
          body: [
            headers.map(headerItem => ({ text: headerItem, style: 'tableHeaderItem' })),
            ...rows
          ]
        }
      }
    ],
    styles: {
      tableContent: {
        fontSize,
      },
      tableHeaderItem: {
        bold: true,
        fontSize: headerFontSize,
      },
      globalHeader: {
        bold: true,
        fontSize: 18,
        margin: [ 5, 0, 0, 15 ]
      }
    },
  }
}


export async function generateAndDownloadPdf (
  localData,
  headers,
  title,
  tableHeaderFontSize,
  tableRowsFontSize,
  formatRowFunction,
  useDataFromApi,
  rowsAmountToFetchFromApi,
  fetchApiHandlerFunction,
  fetchApiParams,
  fileName
) {
  let dataToExport = localData

  if (useDataFromApi && rowsAmountToFetchFromApi && fetchApiHandlerFunction && fetchApiParams) {
    try {
      const apiResponse = await fetchApiHandlerFunction({
        ...fetchApiParams,
        limit: rowsAmountToFetchFromApi,
        offset: null,
        page: null,
        pageSize: null
      })

      if (apiResponse?.rows) {
        dataToExport = apiResponse.rows
      }
    } catch {
      return
    }
  }

  const tableRowsToPrint =
    dataToExport ?
      dataToExport.map(formatRowFunction) : [];
  const pdfDefinitions = getPdfDefinitionsToPrintTable(
    title,
    headers,
    tableRowsToPrint,
    tableRowsFontSize,
    tableHeaderFontSize
  )
  pdfMake.createPdf(pdfDefinitions).download(fileName || 'file');
  return true
}