import getInstance from './config';

export default class AuthApi {
  static async auth (parameters) {
    const instance = await getInstance({ isAuth: true });
    parameters = {
      ...parameters,
      grantType: 'password',
    };
    const { data } = await instance.post('/auth', parameters);
    return data;
  }

  static async forgotPassword (parameters) {
    const instance = await getInstance({ isAuth: true });
    parameters = {
      ...parameters,
      sendTo: 'email',
    };
    const { data } = await instance.post('/user/password-recovery', parameters);
    return data;
  }

  static async recoveryPassword (parameters) {
    const instance = await getInstance({ isAuth: true });
    const { data } = await instance.put('/user/password-recovery', parameters);
    return data;
  }

  static async me () {
    const instance = await getInstance();
    const { data } = await instance.get('/user/me');
    return data;
  }

  static async refreshToken (user) {
    const instance = getInstance({ isAuth: true });
    const { data } = await instance.post('/auth/refresh', user);

    return data;
  }
}
