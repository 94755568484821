import React from 'react';
import {
  Breadcrumb,
  Checkbox,
  Col,
  Divider,
  Layout,
  message,
  Modal,
  Row,
  Spin,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  RightOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import axios from 'axios';
import Moment from 'moment';
import Pdf from 'react-to-pdf';

import {
  CategoryActions,
  ContentActions,
  CourseActions,
  QuizActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  CategorySelectors,
  LoadingSelectors,
  QuizSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import {
  Alternative_Answer,
  ALTERNATIVE_TYPE,
  DifficultyType,
  QuestionAnswerType,
  QuestionType,
} from '../../../app/enum/questionEnum';
import QuestionAlternative from '../../../components/question-alternative/QuestionAlterantive';
import QuestionJustifications from '../../../components/Justifications/QuestionJustifications';

import { removeEmptyField } from '../../../app/utils/functionsUtils';
import PreviewQuestion from '../../../components/preview-question/PreviewQuestion';
import Logo from '../../../app/assets/img/ic_logo.svg';
import { YearsType } from '../../../app/enum/years';
const years = [];

for (let i = YearsType.TWO_THOUSAND_AND_THIRTY; i >= 1980; i--) {
  years.push({ id: i.toString(), name: i });
}

class NewQuestion extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      questionId: '',
      optionsPdf: {
        orientation: 'portrait',
        format: [ 1000, 250 ],
      },
      form: {
        isEnabled: true,
        location: '',
        year: '',
        company: '',
        answers: [
          {
            id: null,
            label: '',
            isCorrect: false,
            type: String(QuestionAnswerType.MULTIPLE_ANSWER),
            labelFileName: '',
            labelFileUrl: '',
            labelFileNameString: '',
            labelType: ALTERNATIVE_TYPE.TEXT,
            isEnabled: true,
          },
        ],
        description: '',
        justification: '',
        categories: [],
        type: QuestionType.MULTIPLEANSWER,
        difficulty: DifficultyType.EASY,
        imageFileName: '',
        imageFileUrl: '',
        audioFileName: '',
        audioFileUrl: '',
        videoFileName: '',
        videoFileUrl: '',
        justificationImage: '',
        justificationImageUrl: '',
        justificationAudio: '',
        justificationAudioUrl: '',
        justificationVideo: '',
        justificationVideoUrl: '',
        allowAttachment: true,
      },
      videoCoverUrl: [],
      videoFileProgress: 0,
      videoFileAxiosSource: null,
      imageCoverUrl: [],
      imageFileProgress: 0,
      imageFileAxiosSource: null,
      audio: [],
      audioProgress: 0,
      audioAxiosSource: null,
      justificationVideoUrl: [],
      videoJustificationProgress: 0,
      videoJustificationAxiosSource: null,
      justificationImageUrl: [],
      imageJustificationProgress: 0,
      imageJustificationAxiosSource: null,
      audioJustification: [],
      audioJustificationProgress: 0,
      audioJustificationAxiosSource: null,
      modalVisible: false,
      mainCategory: '',
      secondaryCategory: [],
      answerCategory: [],
      caractersLocation: 0,
      maxPhoto: 1,
      minPhoto: 1,
      openedVideo: false,
      openedAudio: false,
      openedImage: false,
      openedMainCategory: false,
      openedAlternativeCategory: false,
      openedSecondaryCategory: false,
      openedEssayCategory: false,
      typeAlternative: QuestionType.MULTIPLEANSWER,
      companyList: [],
      companyIds: [],
      lastInsertedCompanies: []
    };
    this.pdfLink = React.createRef();
  }

  async componentDidMount () {
    let {
      videoCoverUrl,
      audio,
      imageCoverUrl,
      mainCategory,
      secondaryCategory,
      answerCategory,
      justificationImageUrl,
      justificationVideoUrl,
      audioJustification,
      categoriesAnswer,
      form,
    } = this.state;
    this.props.getCategorySelectives();
    this.getCompanyList();
    const { editingQuestion, id } = this.props;

    if(!form.year) {
      form.year = Moment().year().toString();
    }

    if (editingQuestion) {
      const response = await this.props.getQuestion(id);
      const { answers } = response;
      answers.forEach((item) => {
        if (item.labelFileName) {
          item.labelFileName = [ { name: item.labelFileName, uid: 94 } ];
        }
      });
      response.answers = answers;
      mainCategory = response.categories.filter(
        (item) => item.questionCategory.isMain,
      );

      if (mainCategory.length > 0) {
        mainCategory = mainCategory[0].id;
      }

      let companyIds
      let lastInsertedCompanies

      if(response.companies?.length) {
        companyIds = response.companies.map(company => company.id)
        lastInsertedCompanies = [ ...companyIds ]
      }

      const getSecondaryCategory = response.categories.filter(
        (item) => !item.questionCategory.isMain,
      );

      if (getSecondaryCategory.length > 0) {
        getSecondaryCategory.forEach((item) => {
          secondaryCategory.push(item.id);
        });
      }

      if (
        response.answers[0].type === String(QuestionAnswerType.DISSERTATION)
      ) {
        response.answers.forEach((item) => {
          answerCategory.push(item.labelType);
        });
      }

      if (response.videoFileName) {
        videoCoverUrl = [ { name: response.videoFileName, uid: 91 } ];
      }

      if (response.imageFileName) {
        imageCoverUrl = [ { name: response.imageFileName, uid: 93 } ];
      }

      if (response.audioFileName) {
        audio = [ { name: response.audioFileName, uid: 92 } ];
      }

      if (response.justificationImage) {
        justificationImageUrl = [
          { name: response.justificationImage, uid: 92 },
        ];
      }

      if (response.justificationAudio) {
        audioJustification = [ { name: response.justificationAudio, uid: 92 } ];
      }

      if (response.justificationVideo) {
        justificationVideoUrl = [
          { name: response.justificationVideo, uid: 92 },
        ];
      }

      if (response.answers.length < 1) {
        response.answers = [
          {
            id: null,
            label: '',
            isCorrect: false,
            type: String(QuestionAnswerType.MULTIPLE_ANSWER),
            labelFileName: '',
            labelFileUrl: '',
            labelType: ALTERNATIVE_TYPE.TEXT,
          },
        ];
      }

      this.setState({
        questionId: response.id,
        form: response,
        audio,
        videoCoverUrl,
        imageCoverUrl,
        mainCategory,
        secondaryCategory,
        justificationImageUrl,
        justificationVideoUrl,
        audioJustification,
        categoriesAnswer,
        companyIds,
        lastInsertedCompanies
      });
    }
  }

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  setMainCategory (value) {
    this.setState({ mainCategory: value });
  }

  setSecondaryCategory (value) {
    this.setState({ secondaryCategory: value });
  }

  setCategoriesAnswer (value) {
    this.setState({ categoriesAnswer: value });
  }

  setAnswerCategory (value) {
    this.setState({ answerCategory: value });
  }

  customRequest = async (
    onProgress,
    onSuccess,
    onError,
    file,
    type,
    progress,
    axiosSource,
    multiple = false,
  ) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
      });
      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });

      await uploadFile(
        newFile,
        fileNameAndUrl.url,
        newFile.type,
        onUploadProgress,
        this.state[axiosSource].cancelToken,
        () => {
          message.success(
            I18n.t('routes.panel.contentDetails.messages.fileSuccess'),
          );
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        const fileUrl = await this.props.getUrlToMedia(fileNameAndUrl.fileName);

        if (type === 'imageCoverUrl') {
          this.fieldChange('imageFileName', fileNameAndUrl.fileName);
          this.fieldChange('imageFileUrl', fileUrl.url);
        } else if (type === 'audio') {
          this.fieldChange('audioFileName', fileNameAndUrl.fileName);
          this.fieldChange('audioFileUrl', fileUrl.url);
        } else if (type === 'videoCoverUrl') {
          this.fieldChange('videoFileName', fileNameAndUrl.fileName);
          this.fieldChange('videoFileUrl', fileUrl.url);
        } else if (type === 'justificationImageUrl') {
          this.fieldChange('justificationImage', fileNameAndUrl.fileName);
          this.fieldChange('justificationImageUrl', fileUrl.url);
        } else if (type === 'audioJustification') {
          this.fieldChange('justificationAudio', fileNameAndUrl.fileName);
          this.fieldChange('justificationAudioUrl', fileUrl.url);
        } else if (type === 'justificationVideoUrl') {
          this.fieldChange('justificationVideo', fileNameAndUrl.fileName);
          this.fieldChange('justificationVideoUrl', fileUrl.url);
        }
      } else {
        this.handleMultFileChange(type, {
          fileName: fileNameAndUrl.fileName,
          title: file.name,
        });
      }
    } catch (error) {
      //
    }
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleCancelModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  verifyFields = () => {
    const { form } = this.state;
    let verified = true;

    if (form.location === '') {
      message.error(I18n.t('forms.newQuestion.messagesVerify.withoutLocation'));
      verified = false;
    } else if (form.year === '') {
      message.error(I18n.t('forms.newQuestion.messagesVerify.withoutYear'));
      verified = false;
    } else if (form.description === '') {
      message.error(
        I18n.t('forms.newQuestion.messagesVerify.withoutDescription'),
      );
      verified = false;
    } else if (form.type === QuestionType.MULTIPLEANSWER) {
      let isOneCorrect = 0;
      let blankAnswer = 0;
      form.answers.forEach((i) => {
        if (i.isCorrect) {
          isOneCorrect += 1;
        }

        if (i.labelType && i.labelType === ALTERNATIVE_TYPE.TEXT && !i.label) {
          blankAnswer++;
        } else if (i.labelType !== ALTERNATIVE_TYPE.TEXT && !i.labelFileName) {
          blankAnswer++;
        }
      });

      if (this.state.openedAlternativeCategory) {
        if (isOneCorrect < 1 || blankAnswer !== 0) {
          message.error(I18n.t('forms.newQuestion.atLeastOneCorrect'));
          verified = false;
        }
      } else if (form.type === QuestionType.OTHER && form.answers.length < 0) {
        message.error(
          I18n.t('forms.newQuestion.messagesVerify.withoutOptionAnswer'),
        );
        verified = false;
      }

      return verified;
    }
  };

  saveQuestion = async () => {
    const {
      questionId,
      form,
      mainCategory,
      secondaryCategory,
      answerCategory,
      companyIds,
    } = this.state;

    const typeAnswer = [
      {
        id: ALTERNATIVE_TYPE.TEXT,
        name: 'Texto',
      },
      {
        id: ALTERNATIVE_TYPE.PHOTO,
        name: 'Imagem',
      },
      {
        id: ALTERNATIVE_TYPE.VIDEO,
        name: 'Video',
      },
      {
        id: ALTERNATIVE_TYPE.AUDIO,
        name: 'Áudio',
      },
    ];
    const { editingQuestion, id } = this.props;

    if(!companyIds.length) {
      return message.error(I18n.t('routes.panel.questionDatabase.messages.error.selectCompany'));
    }

    if (this.verifyFields() || editingQuestion) {
      const formToSend = removeEmptyField(form, [ 'justification' ]);
      formToSend.year = formToSend.year.toString();
      const formAux = formToSend;

      if (
        formAux.answers &&
        formAux.answers.length > 0 &&
        formToSend.answers.length > 0
      ) {
        formToSend.answers.forEach((item, index) => {
          if (item.labelFileName === [] || item.labelFileName === '') {
            delete formAux.answers[index].labelFileName;
          }

          if (!item.labelFileUrl || !item.labelFileUrl.length) {
            delete formAux.answers[index].labelFileUrl;
          }

          if (!item.label || !item.label.length) {
            delete formAux.answers[index].label;
          }

          if (!item.id) {
            delete formAux.answers[index].id;
          }

          formAux.answers[index].number = index + 1;
        });
      }

      formAux.categories = [];

      formAux.categories.push({ id: mainCategory, isMain: true });

      if (secondaryCategory && secondaryCategory.length > 0) {
        secondaryCategory.map((item) => {
          formAux.categories.push({
            id: item,
            isMain: false,
          });
        });
      }

      if (answerCategory && answerCategory.length > 0 && !editingQuestion) {
        formAux.answers = [];
        typeAnswer.map((item) => {
          formAux.answers.push({
            isCorrect: false,
            isEnabled: true,
            labelFileNameString: '',
            labelType: item.id,
            type: String(QuestionAnswerType.DISSERTATION),
            hasRemoved: !answerCategory.includes(item.id),
          });
        });
      }

      if (answerCategory && answerCategory.length > 0 && editingQuestion) {
        const response = await this.props.getQuestion(id);
        const { answers } = response;
        formAux.answers = [];
        typeAnswer.map((item) => {
          formAux.answers.push({
            id: answers.find((answer) => answer.labelType === item.id)?.id,
            isCorrect: false,
            isEnabled: true,
            label: null,
            max: 1,
            min: 1,
            number: 1,
            labelFileNameString: '',
            labelType: item.id,
            type: String(QuestionAnswerType.DISSERTATION),
            hasRemoved: !answerCategory.includes(item.id),
          });
        });
      }

      if (editingQuestion) {
        formAux.answers = formAux.answers.filter(
          (item) => item.id || !item.hasRemoved,
        );

        const formAuxLabel = formAux.answers.filter((item) => item.label);

        const addCompanies = this.state.companyIds.filter(companyId => !this.state.lastInsertedCompanies?.includes(companyId))
        const removeCompanies = this.state.lastInsertedCompanies.filter(companyId => !this.state.companyIds.includes(companyId))

        if(addCompanies.length) {
          formAux.addCompanies = addCompanies
        }

        if(removeCompanies.length) {
          formAux.removeCompanies = removeCompanies
        }

        if (
          (formAuxLabel.length === formAux.answers.length &&
            formAux.type === Alternative_Answer.MULTIPLEANSWER) ||
          formAux.type === Alternative_Answer.DISSERTATION
        ) {
          try {
            this.props.updateQuestion(questionId, formAux);
            message.success(I18n.t('routes.panel.quiz.updateQuestion'));
            navigate(I18n.t('routes.panel.quiz.urlQuestion'));
          } catch (err) {
            message.error(I18n.t('pushNotification.messages.errors.generic'));
          }
        } else {
          message.error(I18n.t('forms.newQuestion.atLeastOneCorrect'));
        }
      } else if (!editingQuestion && mainCategory && mainCategory !== '') {
        if (
          this.state.openedAlternativeCategory ||
          (this.state.openedEssayCategory &&
            formAux.answers[0].type === String(QuestionAnswerType.DISSERTATION))
        ) {
          try {
            await this.props.postQuestion({
              ...formAux,
              type: this.state.openedAlternativeCategory
                ? Alternative_Answer.MULTIPLEANSWER
                : Alternative_Answer.DISSERTATION,
              companyIds,
            });
            message.success(I18n.t('forms.newQuestion.createWithSucess'));
            navigate(I18n.t('routes.panel.quiz.urlQuestion'));
          } catch (err) {
            message.error(I18n.t('pushNotification.messages.errors.generic'));
          }
        } else {
          message.error(I18n.t('routes.panel.quiz.notHaveAlternative'));
        }
      } else {
        message.error(
          I18n.t('forms.newQuestion.messagesVerify.withoutMainCategory'),
        );
      }
    }
  };

  cancelUploadFile = (axiosSource) => {
    this.state[axiosSource].cancel();
  };

  addAlternative = () => {
    const alterantive = {
      id: null,
      label: '',
      isCorrect: false,
      type: String(Alternative_Answer.MULTIPLEANSWER),
      labelFileName: '',
      labelFileUrl: '',
      labelType: ALTERNATIVE_TYPE.TEXT,
      min: 1,
      max: 1,
      isEnabled: true,
    };
    const { form } = this.state;
    const aux = form.answers;

    if (aux.length < 5) {
      aux.push(alterantive);
    }

    form.answers = aux;
    this.setState({ form });
  };

  removeAlternative = (indexItem) => {
    const { form } = this.state;
    let aux = form.answers;

    if (aux.length > 1 && !this.props.editingQuestion) {
      aux = aux.filter((item, index) => index !== indexItem);
    } else {
      const itemRemoved = aux.find((item, index) => index === indexItem);
      itemRemoved.hasRemoved = true;
    }

    form.answers = aux;

    this.setState({ form });
  };

  onSelectCompany = async (val) => {
    this.setState({ companyIds: val })
  }

  changeInfoAnswer = (name, value, index) => {
    const { form } = this.state;
    const aux = form.answers;
    const item = aux[index];
    item[name] = value;
    aux[index] = item;

    if (name === 'isCorrect' && value === true) {
      aux.forEach((item, i) => {
        if (i !== index) {
          item.isCorrect = false;
        }
      });
    }

    form.answers = aux;
    this.setState({ form });
  };

  changeMediaAnswer = (value) => {
    const { form } = this.state;
    const aux = [];
    value.map((item) => {
      switch (item) {
        case QuestionAnswerType.VIDEO:
          aux.push({
            label: '-',
            isCorrect: false,
            type: QuestionAnswerType.VIDEO,
            number: 1,
            labelType: ALTERNATIVE_TYPE.NONE,
          });
          break;
        case QuestionAnswerType.TEXT:
          aux.push({
            label: '-',
            isCorrect: false,
            type: QuestionAnswerType.TEXT,
            number: 1,
            labelType: ALTERNATIVE_TYPE.NONE,
          });
          break;
        case QuestionAnswerType.PHOTO:
          aux.push({
            label: '*',
            isCorrect: false,
            type: QuestionAnswerType.PHOTO,
            number: 1,
            labelType: ALTERNATIVE_TYPE.NONE,
            max: 1,
            min: 1,
          });
          break;
        case QuestionAnswerType.AUDIO:
          aux.push({
            label: '*',
            isCorrect: false,
            type: QuestionAnswerType.AUDIO,
            number: 1,
            labelType: ALTERNATIVE_TYPE.NONE,
          });
          break;
        default:
          break;
      }
    });
    form.answers = aux;
    this.setState({ form });
  };

  changeMinPic = (num) => {
    const { form, maxPhoto } = this.state;
    let { minPhoto } = this.state;
    const index = form.answers.findIndex(
      (item) => item.type === QuestionAnswerType.PHOTO,
    );

    if (num > 0 && num <= maxPhoto) {
      form.answers[index].min = num;
      minPhoto = num;
    }

    this.setState({ form, minPhoto });
  };

  changeMaxPic = (num) => {
    const { form, minPhoto } = this.state;
    let { maxPhoto } = this.state;
    const index = form.answers.findIndex(
      (item) => item.type === QuestionAnswerType.PHOTO,
    );

    if (num > 0 && num >= minPhoto) {
      form.answers[index].max = num;
      maxPhoto = num;
    }

    this.setState({ form, maxPhoto });
  };

  fieldChange (name, value) {
    let { form, caractersLocation } = this.state;

    if (
      name === 'location' &&
      (value.length <= 40 || caractersLocation.length > value.length)
    ) {
      form[name] = value;
      caractersLocation = value.length;
    } else if (name === 'type' && value === QuestionType.MULTIPLEANSWER) {
      form.answers = [
        {
          id: null,
          label: '-',
          isCorrect: false,
          type: String(QuestionAnswerType.MULTIPLE_ANSWER),
          labelFileName: '',
          labelFileUrl: '',
          labelType: ALTERNATIVE_TYPE.TEXT,
          isEnabled: true,
        },
      ];
      form[name] = value;
    } else if (name === 'type' && value === Alternative_Answer.DISSERTATION) {
      form.answers = [];
    } else if (name === 'type' && value === QuestionType.OTHER) {
      form[name] = value;
    } else if (name !== 'location') {
      form[name] = value;
    }

    this.setState({ form, caractersLocation });
  }

  getcheckBox = (answerCategory) => {
    const answers = answerCategory.filter((item) => !item.hasRemoved);
    return answers.map((item) => item.id);
  };

  render () {
    const { Content } = Layout;
    const {
      form,
      modalVisible,
      mainCategory,
      secondaryCategory,
      imageFileProgress,
      videoFileProgress,
      audioProgress,
      caractersLocation,
      openedImage,
      openedVideo,
      openedAudio,
      imageJustificationProgress,
      videoJustificationProgress,
      audioJustificationProgress,
      openedSecondaryCategory,
      openedMainCategory,
      openedAlternativeCategory,
      openedEssayCategory,
      answerCategory,
      companyList,
      companyIds
    } = this.state;
    const { categorySelectives, editingQuestion } = this.props;

    const modalDateInput = (
      <Modal
        visible={modalVisible}
        onOk={this.handleCancelModal}
        onCancel={this.handleCancelModal}
        width='70%'
        footer={[
          <Pdf
            targetRef={this.pdfLink}
            filename='previa_questoes.pdf'
            options={this.state.optionsPdf}
            x={0.9}
            y={0.9}
            scale={0.9}
          >
            {({ toPdf }) => (
              <AdvancedButton
                text={I18n.t('shared.exportPdfButton')}
                onClick={toPdf}
              />
            )}
          </Pdf>,
        ]}
      >
        <div className='modal_margin'
          ref={this.pdfLink}>
          <div className='header_modal'>
            <div className='water_marc_logo'>
              <img src={Logo}
                width='100%'
                height='100%' />
            </div>
            <div className='title_header'>
              {I18n.t('forms.newQuestion.labelPreviewQuestion')}
            </div>
          </div>
          <PreviewQuestion
            location={form?.location}
            year={form?.year}
            description={form?.description}
            justification={form.justification}
            justificationImage={form.justificationImageUrl}
            justificationVideo={form.justificationVideoUrl}
            justificationAudio={form.justificationAudioUrl}
            imageUrl={form?.imageFileUrl}
            audioUrl={form?.audioFileUrl}
            videoUrl={form?.videoFileUrl}
            answers={form?.answers}
            isEditing={this.props.editingQuestion}
            onOpen={(url) => window.open(url, '_blank').focus()}
            essayOptions={
              (form.type === Alternative_Answer.DISSERTATION ||
                this.state.openedEssayCategory) &&
              this.state.answerCategory
            }
          />
        </div>
      </Modal>
    );

    const answerHasRemoved = form.answers.filter((item) => !item.hasRemoved);

    const typeAnswer = [
      {
        id: ALTERNATIVE_TYPE.TEXT,
        name: 'Texto',
      },
      {
        id: ALTERNATIVE_TYPE.PHOTO,
        name: 'Imagem',
      },
      {
        id: ALTERNATIVE_TYPE.VIDEO,
        name: 'Video',
      },
      {
        id: ALTERNATIVE_TYPE.AUDIO,
        name: 'Áudio',
      },
    ];

    const labelType = form.answers.map((item) => {
      return item.labelType;
    });

    return (
      <>
        {modalDateInput}
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuestion')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuestion')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuestion')}add`}>
                <span>{I18n.t('routes.panel.quiz.newQuestion')}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Spin spinning={this.props.loading > 0}
          tip={I18n.t('shared.loading')}>
          <Content className='panel__layout__content'>
            <div className='users'>
              <Row>
                <Col span={12}>
                  <h3>
                    <span className='panel__layout__content__title__value__icon'>
                      <Icon component={QuizIcon} />
                    </span>
                    {this.props.editingQuestion
                      ? I18n.t('routes.panel.quiz.pageEditingQuestion')
                      : I18n.t('routes.panel.quiz.pageNewQuestion')}
                  </h3>
                </Col>
              </Row>
              <h6>{I18n.t('forms.newQuestion.description')}</h6>
              <Divider />
              <Row>
                <Col span={7}>
                  <AdvancedInput
                    label={`${I18n.t('forms.newQuestion.labelLocal')}${
                      caractersLocation === 0
                        ? ''
                        : `( ${caractersLocation} / 40)`
                    }`}
                    value={form?.location}
                    onChange={(val) => this.fieldChange('location', val)}
                  />
                </Col>
                <Divider type='vertical' />
                <Col span={4}>
                  <AdvancedSelect
                    options={years}
                    label={I18n.t('forms.content.year.label')}
                    value={form?.year || Moment().year().toString()}
                    onChange={(val) => this.fieldChange('year', val)}
                  />
                </Col>
                <Divider type='vertical' />
                <Col span={6}>
                  <AdvancedSelect
                    options={companyList}
                    label={I18n.t('forms.user.company.label')}
                    value={companyIds}
                    onChange={(val) => this.onSelectCompany(val)}
                    mode='multiple'
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  span={12}>
                  <AdvancedInput
                    label={I18n.t('forms.newQuestion.questionBody')}
                    value={form?.description}
                    onChange={(val) => this.fieldChange('description', val)}
                    isTextarea
                    style={{padding: 0}}
                    allowClear={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <AdvancedDragger
                    title={I18n.t('forms.newQuestion.labelImage')}
                    customRequest={({ onProgress, onSuccess, onError, file }) =>
                      this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'imageCoverUrl',
                        'imageFileProgress',
                        'imageFileAxiosSource',
                      )
                    }
                    accept='image/png,image/jpeg,image/jpg'
                    fileList={this.state.imageCoverUrl}
                    onRemove={() => {
                      this.fieldChange('imageFileName', null);
                      this.setState({ imageCoverUrl: [] });
                    }}
                    label={I18n.t('forms.newQuestion.labelImageContent')}
                    progressIcon='image'
                    progress={imageFileProgress}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <AdvancedDragger
                    title={I18n.t('forms.newQuestion.labelVideo')}
                    customRequest={({ onProgress, onSuccess, onError, file }) =>
                      this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'videoCoverUrl',
                        'videoFileProgress',
                        'videoFileAxiosSource',
                      )
                    }
                    accept='video/mp4,video/avi'
                    fileList={this.state.videoCoverUrl}
                    onRemove={() => {
                      this.fieldChange('videoFileName', null);
                      this.setState({ videoCoverUrl: [] });
                    }}
                    label={I18n.t('forms.newQuestion.labelVideoContent')}
                    progressIcon='video'
                    progress={videoFileProgress}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <AdvancedDragger
                    title={I18n.t('forms.newQuestion.labelAudioPodcast')}
                    customRequest={({ onProgress, onSuccess, onError, file }) =>
                      this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'audio',
                        'audioProgress',
                        'audioAxiosSource',
                      )
                    }
                    accept='audio/mp3, audio/m4a'
                    fileList={this.state.audio}
                    onRemove={() => {
                      this.fieldChange('audioFileName', null);
                      this.setState({ audio: [] });
                    }}
                    label={I18n.t('forms.newQuestion.labelAudioContent')}
                    progressIcon='audio'
                    progress={audioProgress}
                  />
                </Col>
              </Row>
              <Divider type='horizontal' />
              <Row>
                <div className='question__category'>
                  <div
                    onClick={() =>
                      this.setState({ openedMainCategory: !openedMainCategory })
                    }
                    className='question__category__title'
                  >
                    <h6 className='question__category__title__text'>
                      {I18n.t('shared.advancedFilters.form.mainCategory')}
                    </h6>
                    <RightOutlined
                      className={`${openedMainCategory && 'rotate'}`}
                    />
                  </div>
                  <p
                    className={`question__category__subtitle ${
                      openedMainCategory && 'margin'
                    }`}
                  >
                    {I18n.t('forms.newQuestion.mainCategorySubtitle')}
                  </p>
                </div>

                {openedMainCategory && (
                  <>
                    <Row>
                      {categorySelectives?.map((item) => (
                        <Col key={item.id}
                          span={6}>
                          <Checkbox
                            onChange={(e) =>
                              this.setMainCategory(e.target.value)
                            }
                            value={item.id}
                            checked={item.id === this.state.mainCategory}
                          >
                            {item.title}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </Row>
              <Divider type='horizontal' />
              <Row>
                <div className='question__category'>
                  <div
                    onClick={() =>
                      this.setState({
                        openedSecondaryCategory: !openedSecondaryCategory,
                      })
                    }
                    className='question__category__title'
                  >
                    <h6 className='question__category__title__text'>
                      {I18n.t('forms.content.category.label')}
                    </h6>
                    <RightOutlined
                      className={`${openedSecondaryCategory && 'rotate'}`}
                    />
                  </div>
                  <p
                    className={`question__category__subtitle ${
                      openedSecondaryCategory && 'margin'
                    }`}
                  >
                    {I18n.t('forms.newQuestion.secondaryCategorySubtitle')}
                  </p>
                </div>
                {openedSecondaryCategory && (
                  <Row>
                    <Checkbox.Group
                      value={secondaryCategory}
                      onChange={(e) => this.setSecondaryCategory(e)}
                    >
                      <Row>
                        {categorySelectives?.map((item) => (
                          <Col key={item.id}
                            span={6}>
                            <Checkbox value={item.id}>{item.title}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Row>
                )}
              </Row>
              <Divider type='horizontal' />
              <Row>
                <div className='question__category'>
                  <Tooltip
                    title={
                      (form.answers[0].type === '6' ||
                        this.state.openedEssayCategory) &&
                      I18n.t('forms.newExame.tooltipAlternative')
                    }
                  >
                    <div
                      onClick={() => {
                        this.setState({
                          openedAlternativeCategory: !openedAlternativeCategory,
                          justificationImageUrl: [],
                          justificationVideoUrl: [],
                          audioJustification: [],
                          form: {
                            ...form,
                            justification: '',
                            justificationImage: null,
                            justificationVideo: null,
                            justificationAudio: null,
                          },
                        });
                      }}
                      className='question__category__title'
                      style={
                        (editingQuestion &&
                          form.answers[0].type ===
                            String(Alternative_Answer.MULTIPLEANSWER)) ||
                        form.answers[0].type === '6' ||
                        this.state.openedEssayCategory
                          ? { pointerEvents: 'none' }
                          : { pointerEvents: 'auto' }
                      }
                    >
                      <h6 className='question__category__title__text'>
                        {I18n.t(
                          'forms.newQuestion.labelQuestionMultipleAnswer',
                        )}
                      </h6>
                      <RightOutlined
                        className={`${openedAlternativeCategory && 'rotate'}`}
                      />
                    </div>
                    <p
                      className={`question__category__subtitle ${
                        openedAlternativeCategory && 'margin'
                      }`}
                    >
                      {I18n.t('forms.newQuestion.mainCategorySubtitle')}
                    </p>
                  </Tooltip>
                </div>
              </Row>
              {((editingQuestion &&
                form.type === QuestionType.MULTIPLEANSWER) ||
                openedAlternativeCategory) && (
                <>
                  {!editingQuestion && (
                    <>
                      <Row>
                        <Col span={12}>
                          <h6>
                            {I18n.t('forms.newQuestion.labelInsertAlternative')}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <span>
                            {I18n.t(
                              'forms.newQuestion.labelCorrectAlternativa',
                            )}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                  {!this.props.editingQuestion &&
                    form.type === QuestionType.MULTIPLEANSWER &&
                    form?.answers.map(
                      (item, index) =>
                        !item.hasRemoved && (
                          <QuestionAlternative
                            key={item.id}
                            index={index}
                            label={item.label}
                            isCorrect={item.isCorrect}
                            length={form?.answers.length}
                            labelType={item.labelType}
                            labelFileName={item.labelFileName}
                            labelFileUrl={item.labelFileUrl}
                            addAlternative={this.addAlternative}
                            removeAlternative={this.removeAlternative}
                            changeField={this.changeInfoAnswer}
                            questionType={this.state.typeAlternative}
                          />
                        ),
                    )}
                  {this.props.editingQuestion &&
                    form.type === QuestionType.MULTIPLEANSWER &&
                    answerHasRemoved.map((item, index) => (
                      <QuestionAlternative
                        key={item.id}
                        index={index}
                        label={item.label}
                        isCorrect={item.isCorrect}
                        length={form?.answers.length}
                        labelType={item.labelType}
                        labelFileName={item.labelFileName}
                        labelFileUrl={item.labelFileUrl}
                        addAlternative={this.addAlternative}
                        removeAlternative={this.removeAlternative}
                        changeField={this.changeInfoAnswer}
                        questionType={this.state.typeAlternative}
                      />
                    ))}
                  <QuestionJustifications
                    fieldChange={(value, name) => this.fieldChange(value, name)}
                    justification={form?.justification}
                    justificationImageUrl={this.state.justificationImageUrl}
                    justificationVideoUrl={this.state.justificationVideoUrl}
                    audioJustification={this.state.audioJustification}
                    setQuestionState={(state) => this.setState(state)}
                    customRequest={this.customRequest}
                  />
                </>
              )}
              <Divider type='horizontal' />
              <Row>
                <div className='question__category'>
                  <Tooltip
                    title={
                      (this.state.openedAlternativeCategory ||
                        (editingQuestion && form.answers[0].type === '1')) &&
                      I18n.t('forms.newExame.tooltipEssay')
                    }
                  >
                    <div
                      onClick={() =>
                        this.setState({
                          openedEssayCategory: !openedEssayCategory,
                        })
                      }
                      className='question__category__title'
                      style={
                        (editingQuestion && form.answers[0].type === '1') ||
                        form.answers[0].type === '6' ||
                        this.state.openedAlternativeCategory
                          ? { pointerEvents: 'none' }
                          : { pointerEvents: 'auto' }
                      }
                    >
                      <h6 className='question__category__title__text'>
                        {I18n.t('forms.newQuestion.labelQuesTionEssay')}
                      </h6>
                      <RightOutlined
                        className={`${openedEssayCategory && 'rotate'}`}
                      />
                    </div>
                    <p
                      className={`question__category__subtitle ${
                        openedEssayCategory && 'margin'
                      }`}
                    >
                      {I18n.t('forms.newQuestion.secondaryCategorySubtitle')}
                    </p>
                  </Tooltip>
                </div>
              </Row>
              {((editingQuestion &&
                form.answers[0].type ===
                  String(QuestionAnswerType.DISSERTATION)) ||
                openedEssayCategory) && (
                <>
                  <Row>
                    <Col span={12}>
                      <h6>{I18n.t('forms.newQuestion.essayAnswer')}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox.Group
                      value={answerCategory}
                      onChange={(e) => this.setAnswerCategory(e)}
                    >
                      <Row>
                        {typeAnswer.map((item) => {
                          return (
                            <Col key={item.id}>
                              <Checkbox value={item.id}>{item.name}</Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </Row>
                  <QuestionJustifications
                    fieldChange={(value, name) => this.fieldChange(value, name)}
                    justification={form?.justification}
                    justificationImageUrl={this.state.justificationImageUrl}
                    justificationVideoUrl={this.state.justificationVideoUrl}
                    audioJustification={this.state.audioJustification}
                    setQuestionState={(state) => this.setState(state)}
                    customRequest={this.customRequest}
                  />
                </>
              )}
              <Divider type='horizontal' />
              <Row gutter={[ 16, 24 ]}>
                <Col span={6}>
                  <AdvancedRadioGroup
                    value={form?.difficulty}
                    label={I18n.t('forms.newQuestion.labelQuestionDifficulty')}
                    onChange={(val) =>
                      this.fieldChange('difficulty', val.target.value)
                    }
                    options={[
                      {
                        id: DifficultyType.EASY,
                        name: I18n.t('forms.newQuestion.labelQuestionEasy'),
                      },
                      {
                        id: DifficultyType.MEDIUM,
                        name: I18n.t('forms.newQuestion.labelQuestionMedium'),
                      },
                      {
                        id: DifficultyType.HARD,
                        name: I18n.t('forms.newQuestion.labelQuestionHard'),
                      },
                    ]}
                    horizontal
                  />
                </Col>
              </Row>

              <Row gutter={[ 16, 24 ]}>
                <Col span={24}
                  className='text-right'>
                  <AdvancedButton
                    type='link'
                    href={I18n.t('routes.panel.quiz.urlQuestion')}
                    text={I18n.t('forms.goBackButtonText')}
                  />
                  <Divider type='vertical' />
                  <AdvancedButton
                    type='large'
                    htmlType='submit'
                    text={I18n.t('forms.newQuestion.labelPreviewQuestion')}
                    onClick={() => this.showModal()}
                  />
                  <Divider type='vertical' />
                  <AdvancedButton
                    htmlType='submit'
                    text={I18n.t('shared.save')}
                    icon={<SaveOutlined />}
                    onClick={() => this.saveQuestion()}
                  />
                </Col>
              </Row>
            </div>
          </Content>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  question: QuizSelectors.getSpecificQuestion(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUrlToUploadFile: (fileName, callback) =>
    dispatch(ContentActions.getUrlToUploadFile(fileName, callback)),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) =>
    dispatch(
      ContentActions.uploadFile(
        file,
        url,
        contentType,
        onProgress,
        cancelToken,
        callback,
      ),
    ),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  getCategorySelectives: () =>
    dispatch(CategoryActions.getCategorySelectives()),
  postQuestion: (question) => dispatch(QuizActions.createQuestion(question)),
  getUrlToMedia: (name) => dispatch(QuizActions.getUrlPreviewMedia(name)),
  getQuestion: (id) => dispatch(QuizActions.getQuestionById(id)),
  updateQuestion: (id, question) =>
    dispatch(QuizActions.updateQuestion(id, question)),
  postAlternative: (alterantive) =>
    dispatch(QuizActions.createQuestionAlternative(alterantive)),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewQuestion);
