import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout,
  message,
  Row,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import {
  QuizActions,
  UserActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CategorySelectors,
  FilterSelector,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import {
  getSubStringTitle,
} from '../../../app/utils/functionsUtils';
import { ProfileType } from '../../../app/enum/profileType';
import { CSVLink } from 'react-csv';
import QuizRequest from '../../../app/api/quiz'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { prepareDataToCsv } from '../../../app/utils/csv';
import { UserQuizStatusArray } from '../../../app/enum/questionEnum';
import { humanizeDate } from '../../../app/utils/date';
pdfMake.vfs = pdfFonts.pdfMake.vfs

class UserQuizzes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      exportingPfdDataLoading: false,
      exportingDataLoading: false,
      csvData: '',
      params: {
        isDesc: true,
        userId: this.props.id
      },
    };
    this.dataTableRef = React.createRef();
    this.csvLink = React.createRef();
  }

  async componentDidMount () {
    this.props.getUserDetails(this.props.id)
  }

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.userQuizzes?.rows,
      (row) => ({
        name: row.name || '-',
        execution_date: row.users[0]?.userQuiz?.createdAt ? humanizeDate(row.users[0]?.userQuiz?.createdAt, 'DD/MM/YYYY') : '--',
        status: row.users[0]?.userQuiz?.status ? UserQuizStatusArray.find(({id}) => id === row.users[0]?.userQuiz?.status)?.name : '--',
        hitPercentage: row.users[0]?.userQuiz?.correctAnswersPercentage ? `${row.users[0]?.userQuiz?.correctAnswersPercentage}%` : '--'
      }),
      exportAllDataFromApi,
      this.props.userQuizzes?.count,
      QuizRequest.getUsersQuizes,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  async exportPdfData (exportAllDataFromApi = false) {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.userQuiz.dataTable.columns.name.title'),
      I18n.t('routes.panel.userQuiz.dataTable.columns.executionDate.title'),
      I18n.t('routes.panel.userQuiz.dataTable.columns.status.title'),
      I18n.t('routes.panel.userQuiz.dataTable.columns.hitPercentage.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.userQuizzes.rows,
      tableHeadersToPrint,
      `${I18n.t('routes.panel.quiz.pageQuiz')} - ${this.props.userDetails?.name}`,
      8,
      11,
      (row) => ([
        row.name || '-',
        row.users[0]?.userQuiz?.createdAt ? humanizeDate(row.users[0]?.userQuiz?.createdAt, 'DD/MM/YYYY') : '--',
        row.users[0]?.userQuiz?.status ? UserQuizStatusArray.find(({id}) => id === row.users[0]?.userQuiz?.status)?.name : '--',
        row.users[0]?.userQuiz?.correctAnswersPercentage ? `${row.users[0]?.userQuiz?.correctAnswersPercentage}%` : '--'
      ]),
      exportAllDataFromApi,
      this.props.userQuizzes?.count,
      QuizRequest.getUsersQuizes,
      this.state.params,
      I18n.t('routes.panel.quiz.pageQuiz')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  getUsersQuizzes (params) {
    this.props.getUsersQuizzes({...params, userId: this.props.id || null})
  }

  render () {
    const { Content } = Layout;
    const { params } = this.state;
    const { loading, me, userQuizzes, id, userDetails } = this.props;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.statistics.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.userQuiz.url', {userId: id})}>
                <span>{I18n.t('routes.panel.userQuiz.pageName')}</span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuizIcon} />
                  </span>
                  {I18n.t('routes.panel.userQuiz.pageName')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      loading={this.state.exportingDataLoading}
                      disabled={this.state.exportingDataLoading || loading > 0}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                    />
                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.userDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>

                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      loading={this.state.exportingPfdDataLoading}
                      disabled={this.state.exportingPfdDataLoading || loading > 0}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                    />
                  </>
                )}

                <Divider type='vertical' />

              </Col>
            </Row>
            <Row>
              <h5>{I18n.t('routes.panel.userQuiz.student')}: {userDetails?.name}</h5>
            </Row>
            <Divider />
            <div className='quizQuestionList'>
              <DataTable
                dontShowIndex
                getMethod={(params) => this.getUsersQuizzes(params)}
                data={userQuizzes}
                ref={this.dataTableRef}
                loading={loading > 0}
                showSizeChanger
                pageSizeOptions={[ '10', '20', '50', '100' ]}
                params={params}
                columns={[
                  {
                    key: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.name.key',
                    ),
                    title: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.name.title',
                    ),
                    render: (value) => value,
                  },
                  {
                    key: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.executionDate.key',
                    ),
                    title: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.executionDate.title',
                    ),
                    render: (value) => (value ? humanizeDate(value[0]?.userQuiz?.createdAt, 'DD/MM/YYYY') : '--'),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.status.key',
                    ),
                    title: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.status.title',
                    ),
                    render: (value) => (value
                      ? UserQuizStatusArray.find(({id}) => id === value[0]?.userQuiz?.status)?.name || '--'
                      : '--'),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.hitPercentage.key',
                    ),
                    title: I18n.t(
                      'routes.panel.userQuiz.dataTable.columns.hitPercentage.title',
                    ),
                    render: (value) => (value ? `${value[0]?.userQuiz?.correctAnswersPercentage}%` : '--')
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userQuizzes: QuizSelectors.getUserQuizzes(state),

  questionsPaginated: QuizSelectors.getQuestionsPaginated(state),
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  quizFilters: FilterSelector.getQuizFilters(state),
  userDetails: UserSelectors.getUserDetails(state),
  usersPaginated: UserSelectors.getUsersPaginated(state),
  usersAll: UserSelectors.getUserAll(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersQuizzes: (params) => dispatch(QuizActions.getUsersQuizzes(params)),
  getUserDetails: (id) => dispatch(UserActions.getUserDetails(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserQuizzes);
