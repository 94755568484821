import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Steps,
  Upload,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import {
  SaveOutlined, UploadOutlined,
} from '@ant-design/icons';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors, LoadingSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';

class CategoryForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      current: 0,
      formDataHeight: 0,
      form: {
        photo: '',
        photoUrl: '',
        title: '',
      },
    };
  }

  async componentDidMount () {
    const formDataHeight = this.formData.clientHeight;
    this.setState({ formDataHeight });
    const {
      data,
    } = this.props;

    if (data) {
      this.setState({
        form: {
          ...data,
        },
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    const { form } = this.state;

    if (form.photo) {
      delete form.photoUrl;
    }

    if (!form.photo) {
      delete form.photo;
    }

    if (!form.photoUrl) {
      delete form.photoUrl;
    }

    const categorySchema = yup.object().shape({
      title: yup.string().required(I18n.t('routes.panel.categoryDetails.messages.errors.invalid_title')),
    });

    categorySchema.validate(form).then(() => {
      const { data, submitFunction } = this.props;
      submitFunction(data ? data.id : null, form);
    }).catch((error) => {
      message.error(error.message);
    });
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';

    if (!isJpgOrPng) {
      message.error(I18n.t('routes.panel.categoryDetails.messages.errors.invalid_image_type'));
    }

    const isLt2M = file.size / 1024 / 1024 < 30;

    if (!isLt2M) {
      message.error(I18n.t('routes.panel.categoryDetails.messages.errors.invalid_image_size'));
    }

    return isJpgOrPng && isLt2M;
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  handleUploadChange = (info) => {
    if (info && info.file && info.file.originFileObj) {
      this.getBase64(info.file.originFileObj, (imageBase64) => this.fieldChange('photo', imageBase64));
    }
  };

  fieldChange (name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render () {
    const {
      form, current,
    } = this.state;
    const { Step } = Steps;
    return (
      <Row className="category-form-row">
        <Col
          span={6}
          className="category-form-col"
        >
          <Steps
            direction="vertical"
            size="small"
            current={current}
            onChange={this.changeStep}
            className="category-form-steps"
          >
            <Step
              title="Dados"
              className="category-form-step"
              style={{ minHeight: this.state.formDataHeight }}
            />
            <Step
              icon={<div />}
              className="category-form-step"
            />
          </Steps>
        </Col>
        <Col span={18}>
          <form
            name="categoryForm"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            {current >= 0
              && (
                <div
                  ref={(ref) => { this.formData = ref; }}
                >

                  <div
                    className="upload"
                  >
                    <div>
                      {I18n.t('forms.category.imageTitle.label')}
                    </div>
                    <Upload
                      className="upload"
                      showUploadList={false}
                      customRequest={(e) => e}
                      beforeUpload={this.beforeUpload}
                      onChange={this.handleUploadChange}
                    >
                      {form.photo || form.photoUrl ? (
                        <div className="upload__img">
                          <img
                            src={form.photo || form.photoUrl}
                            alt={I18n.t('forms.category.addImage.label')}
                            style={{ width: '100%' }}
                          />
                        </div>
                      ) : (
                        <Button className="upload__button">
                          <UploadOutlined /> {I18n.t('forms.category.addImage.label')}
                        </Button>
                      )}
                    </Upload>
                  </div>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.category.title.label')}
                        value={form && form.title}
                        onChange={(val) => this.fieldChange('title', val)}
                      />
                    </Col>
                  </Row>

                  <Divider />

                  <Row>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.goBackButtonText')}
                        href={I18n.t('routes.panel.categories.url')}
                      />
                      <Divider
                        className="form-button-divider"
                        type="vertical"
                      />
                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('shared.save')}
                        icon={<SaveOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              )}

          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

export default connect(
  mapStateToProps,
  null,
)(
  CategoryForm,
);
