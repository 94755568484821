import React, { useState } from 'react'

export const ControlledForm = ({formRenderer: FormRenderer, onSubmit, onReset}) => {
  const [ fields, setFields ] = useState({});

  const onFieldChange = (field, value) => {
    setFields(prevFields => ({...prevFields, [field]: value}))
  }

  const handleFormSubmit = (event) => {
    if(event) event.preventDefault()

    onSubmit(fields)
  }

  const reset = () => {
    setFields({})
    onReset()
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <FormRenderer
        onFieldChange={onFieldChange}
        submit={handleFormSubmit}
        reset={reset}
        values={fields}/>
    </form>
  )
}