import Immutable from 'seamless-immutable';
import {
  ACTION_COURSES_CHART_FILTERS,
  ACTION_COURSES_CONTENTS_FILTERS,
  ACTION_COURSES_MODULES_FILTERS,
  ACTION_COURSES_STATISTICS_FILTERS,
  ACTION_MESSAGES_CONTENT_FILTERS,
  ACTION_MESSAGES_DIRECT_FILTERS,
  ACTION_MESSAGES_QUESTION_DATABASE_FILTERS,
  ACTION_NOTIFICATION_FILTERS,
  ACTION_QUIZ_QUESTIONS_FILTERS,
  ACTION_QUIZ_SIMULATION_FILTERS,
  ACTION_SETTINGS_CATEGORIES_FILTERS,
  ACTION_SETTINGS_COMPANIES_FILTERS,
  ACTION_SETTINGS_HOSPITAL_FILTERS,
  ACTION_STATISTICS_MAIN_FILTERS,
  ACTION_USER_ADMINS_FILTERS,
  ACTION_USER_STUDENTS_FILTERS,
} from '../actions/filters';

const initialState = Immutable({
  statisticsFilters: {
    mainStatisticsFilters: null,
  },
  userFilters: {
    studentsFilters: null,
    adminsFilters: null,
  },
  coursesFilters: {
    coursesListFilter: null,
    modulesFilter: null,
    contentsFilter: null,
    coursesChartFilter: null
  },
  quizFilters: {
    questionsFilters: null,
    simulationFilters: null,
  },
  notificationFilters: {
    notificationListFilters: null,
  },
  messagesFilter: {
    directFilter: null,
    contentFilter: null,
  },
  messagesFilterQuestionDatabase: {
    questionDatabaseFilter: null,
  },
  settingsFilter: {
    categoriesFilter: null,
    hospitalsFilter: null,
    companiesFilter: null
  }
});

export default function filters (state = initialState, action) {
  switch (action.type) {
    case ACTION_STATISTICS_MAIN_FILTERS:
      state = {
        ...state,
        statisticsFilters: {
          ...state.statisticsFilters,
          mainStatisticsFilters: action.payload,
        },
      };
      return state;
    case ACTION_USER_STUDENTS_FILTERS:
      state = {
        ...state,
        userFilters: {
          ...state.userFilters,
          studentsFilters: action.payload,
        },
      };
      return state;
    case ACTION_MESSAGES_QUESTION_DATABASE_FILTERS:
      state = {
        ...state,
        messagesFilterQuestionDatabase: {
          ...state.messagesFilterQuestionDatabase,
          questionDatabaseFilter: action.payload,
        },
      };
      return state;
    case ACTION_USER_ADMINS_FILTERS:
      state = {
        ...state,
        userFilters: {
          ...state.userFilters,
          adminsFilters: action.payload,
        },
      };
      return state;
    case ACTION_COURSES_STATISTICS_FILTERS:
      state = {
        ...state,
        coursesFilters: {
          ...state.coursesFilters,
          coursesListFilter: action.payload,
        },
      };
      return state;
    case ACTION_COURSES_CHART_FILTERS:
      state = {
        ...state,
        coursesFilters: {
          ...state.coursesFilters,
          coursesChartFilter: action.payload,
        },
      };
      return state;
    case ACTION_COURSES_MODULES_FILTERS:
      state = {
        ...state,
        coursesFilters: {
          ...state.coursesFilters,
          modulesFilter: action.payload,
        },
      };
      return state
    case ACTION_COURSES_CONTENTS_FILTERS:
      state = {
        ...state,
        coursesFilters: {
          ...state.coursesFilters,
          contentsFilter: action.payload,
        },
      };
      return state
    case ACTION_QUIZ_SIMULATION_FILTERS:
      state = {
        ...state,
        quizFilters: {
          ...state.quizFilters,
          simulationFilters: action.payload,
        },
      };
      return state
    case ACTION_QUIZ_QUESTIONS_FILTERS:
      state = {
        ...state,
        quizFilters: {
          ...state.quizFilters,
          questionsFilters: action.payload,
        },
      };
      return state;
    case ACTION_NOTIFICATION_FILTERS:
      state = {
        ...state,
        notificationFilters: {
          ...state.notificationFilters,
          notificationListFilters: action.payload,
        },
      };
      return state
    case ACTION_MESSAGES_DIRECT_FILTERS:
      state = {
        ...state,
        messagesFilter: {
          ...state.messagesFilter,
          directFilter: action.payload,
        },
      };
      return state
    case ACTION_MESSAGES_CONTENT_FILTERS:
      state = {
        ...state,
        messagesFilter: {
          ...state.messagesFilter,
          contentFilter: action.payload,
        },
      };
      return state
    case ACTION_SETTINGS_CATEGORIES_FILTERS:
      state = {
        ...state,
        settingsFilter: {
          ...state.settingsFilter,
          categoriesFilter: action.payload,
        },
      };
      return state
    case ACTION_SETTINGS_HOSPITAL_FILTERS:
      state = {
        ...state,
        settingsFilter: {
          ...state.settingsFilter,
          hospitalsFilter: action.payload,
        },
      }
      return state
    case ACTION_SETTINGS_COMPANIES_FILTERS:
      state = {
        ...state,
        settingsFilter: {
          ...state.settingsFilter,
          companiesFilter: action.payload,
        },
      }
      return state
    default:
      return state;
  }
}

export function getUserFilters (state) {
  return state.filters.userFilters;
}

export function getCoursesFilters (state) {
  return state.filters.coursesFilters;
}

export function getQuizFilters (state) {
  return state.filters.quizFilters;
}

export function getNotificationFilters (state) {
  return state.filters.notificationFilters;
}

export function getMessagesFilters (state) {
  return state.filters.messagesFilter;
}

export function getMessagesFiltersQuestionDatabase (state) {
  return state.filters.messagesFilterQuestionDatabase;
}

export function getStatisticsFilters (state) {
  return state.filters.statisticsFilters;
}

export function getSettingsFilters (state) {
  return state.filters.settingsFilter;
}
