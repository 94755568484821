import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Modal,
  Row,
  Tooltip,
} from 'antd';
import Icon, { AppstoreOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, navigate } from '@reach/router';
import Moment from 'moment';
import DataTable from '../../../components/shared/DataTable';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';
import {
  ContentActions,
  CourseActions,
  QuizActions,
  UserActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CourseSelectors,
  LoadingSelectors,
  QuizSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as QuizIcon } from '../../../app/assets/img/ic_quiz_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import {
  Alternative_Answer,
  ALTERNATIVE_TYPE,
  arrayPercentage,
  CorrectionAnnotationType,
  CorrectionAnswerType,
  UserQuizStatus,
  UserQuizStatusArray,
} from '../../../app/enum/questionEnum';
import { ProfileType } from '../../../app/enum/profileType';
import { removeEmptyField } from '../../../app/utils/functionsUtils';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { CourseType } from '../../../app/enum/courseType';
import logo from '../../../app/assets/img/ic_logo.svg';
import axios from 'axios';
import moment from 'moment-timezone';
import { cleanPagination } from '../../../app/utils/pagination';
import { prepareDataToCsv } from '../../../app/utils/csv';
import QuizRequest from '../../../app/api/quiz'
import { CSVLink } from 'react-csv';

const { Panel } = Collapse;
class QuizExame extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      exportingDataLoading: false,
      csvData: '',
      params: {
        orderBy: 'correctAnswersPercentage',
        isDESC: true,
        userName: '',
        status: '',
        fromCorrectAnswersPercentage: '',
        toCorrectAnswersPercentage: '',
        courseId: '',
        userId: '',
        ...cleanPagination
      },
      form: {
        text: '',
      },
      userIds: {},
      userQuizId: '',
      correctAnswer: 0,
      showModal: false,
      justification: [],
      rowValue: {},
      showjustification: false,
      selectRadio: [],
      annotationText: [],
      contentQuestion: [],
      calculateCorrectDissertation: 0,
      justificationImageUrl: '',
      justificationVideoUrl: '',
      audioJustification: '',
      fileProgress: 0,
      fileAxiosSource: null,
      form: {
        text: '',
      },
      userIds: {},
      userQuizId: '',
      correctAnswer: 0,
      showModal: false,
      justification: [],
      rowValue: {},
      showjustification: false,
      selectRadio: [],
      annotationText: [],
      contentQuestion: [],
      calculateCorrectDissertation: 0,
      justificationImageUrl: '',
      justificationVideoUrl: '',
      audioJustification: '',
      fileProgress: 0,
      fileAxiosSource: null,
    };
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    await this.props.getQuizStatistics(this.props.id);
    await this.props.searchCourses({ type: CourseType.COURSE });

    const userIds = this.props.quizStatisticsRanking?.rows
      .map((item) => item.correctedBy).filter(o => o);
    this.state.userIds = userIds && userIds.length ? userIds.join(',') : '';
    await this.props.getUsersAll({ userIds: this.state.userIds });
  }

  customRequest = async ({
    onProgress,
    file,
    onSuccess,
    onError,
    type,
    progress,
    axiosSource,
    index,
    multiple = false,
  }) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
      });
      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });

      const { contentQuestion } = this.state;
      contentQuestion[index] = {
        ...contentQuestion[index],
        correctionAnnotation: fileNameAndUrl.fileName,
      };
      this.setState({ contentQuestion });

      await uploadFile(
        newFile,
        fileNameAndUrl.url,
        newFile.type,
        onUploadProgress,
        this.state[axiosSource].cancelToken,
        () => {
          message.success(
            I18n.t('routes.panel.contentDetails.messages.fileSuccess'),
          );
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        const fileUrl = await this.props.getUrlToMedia(fileNameAndUrl.fileName);

        switch (type) {
          case 'imageCoverUrl':
            return (
              this.fieldChange('imageFileName', fileNameAndUrl.fileName),
              this.fieldChange('imageFileUrl', fileUrl.url)
            );
          case 'audio':
            return (
              this.fieldChange('audioFileName', fileNameAndUrl.fileName),
              this.fieldChange('audioFileUrl', fileUrl.url)
            );
          case 'videoCoverUrl':
            return (
              this.fieldChange('videoFileName', fileNameAndUrl.fileName),
              this.fieldChange('videoFileUrl', fileUrl.url)
            );
          case 'justificationImageUrl':
            return (
              this.fieldChange('justificationImage', fileNameAndUrl.fileName),
              this.fieldChange('justificationImageUrl', fileUrl.url)
            );
          case 'audioJustification':
            return (
              this.fieldChange('justificationAudio', fileNameAndUrl.fileName),
              this.fieldChange('justificationAudioUrl', fileUrl.url)
            );
          case 'justificationVideoUrl':
            return (
              this.fieldChange('justificationVideo', fileNameAndUrl.fileName),
              this.fieldChange('justificationVideoUrl', fileUrl.url)
            );
        }
      } else {
        this.handleMultFileChange(type, {
          fileName: fileNameAndUrl.fileName,
          title: file.name,
        });
      }
    } catch (error) {
      //
    }
  };

  getStatistics = async (parameters) => {
    const { getQuizStatistics } = this.props;

    let params = { ...parameters };
    params = removeEmptyField(params);
    this.setState({ params })
    await getQuizStatistics(this.props.id, params);
  };

  getStatisticsRanking = async (quizId, parameters, userId) => {
    const { getQuizStatisticsRanking } = this.props;

    let params = { ...parameters, userId };
    params = removeEmptyField(params);
    this.setState({ params })
    await getQuizStatisticsRanking(quizId, parameters, userId);
  };

  getColorDiv = (value) => {
    switch (value) {
      case UserQuizStatus.NOT_DONE:
        return <div className='text-color-hard'>Não realizada</div>;
      case UserQuizStatus.PENDING:
        return <div className='text-color-medium'>Pendente</div>;
      case UserQuizStatus.DONE:
        return <div className='text-color-easy'>Realizada</div>;
      case UserQuizStatus.WAITING:
        return <div className='text-color-waiting'>Aguardando correção</div>;
      default:
        return <div className='side-bar-difficulty' />;
    }
  };

  getCorrectStatus = (value) => {
    switch (value) {
      case UserQuizStatus.NOT_DONE:
        return I18n.t('routes.panel.quiz.dataTable.columns.toCorrect.correct');
      case UserQuizStatus.PENDING:
        return I18n.t('routes.panel.quiz.dataTable.columns.toCorrect.correct');
      case UserQuizStatus.WAITING:
        return I18n.t('routes.panel.quiz.dataTable.columns.toCorrect.correct');
      default:
        return I18n.t(
          'routes.panel.quiz.dataTable.columns.toCorrect.corrected',
        );
    }
  };

  exportQuiz = async (exportAllDataFromApi = false) => {
    this.setState({exportingDataLoading: true})
    const preparedDataToCsv = await prepareDataToCsv(
      this.props.quizStatisticsRanking?.rows,
      (row) => ({
        number: row.ranking,
        userName: row.user.name,
        lastResponseDate: Moment(row.updatedAt).format('DD/MM/YYYY'),
        status: row.status,
        hitPercentage: row.correctAnswersPercentage,
      }),
      exportAllDataFromApi,
      this.props.quizStatisticsRanking?.count,
      QuizRequest.getStatisticsRanking,
      { ...this.state.params, quizId: this.props.id, orderBy: 'correctAnswersPercentage', isDESC: true },
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    params = removeEmptyField(params);
    this.getStatistics({ ...params, ...cleanPagination });
    this.setState({
      params: {...params, ...cleanPagination}
    })
  };

  clearFilter = () => {
    const params = {
      userName: '',
      status: '',
      fromCorrectAnswersPercentage: '',
      toCorrectAnswersPercentage: '',
      courseId: '',
      ...cleanPagination
    };

    this.getStatistics(params);
    this.setState({ params });
  };

  changeParams (name, value) {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  getPosition = (val, index) => {
    const { contentQuestion } = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      correctionAnnotation: '',
    };
    this.setState({ contentQuestion });

    const {selectRadio} = this.state;
    selectRadio[index] = val;

    this.setState({ selectRadio });
  };

  getPositionText = (val, index) => {
    const { contentQuestion } = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      correctionAnnotation: val,
      correctionAnnotationType: CorrectionAnnotationType.TEXT,
    };
    this.setState({ contentQuestion });
  };

  getPositionStatusCorrect = (index, idQuestion, annotationText) => {
    const {contentQuestion} = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      status: CorrectionAnswerType.CORRECT,
      id: idQuestion,
    };

    this.setState({ contentQuestion });
  };

  getPositionStatusWrong = (index, idQuestion, annotationText) => {
    const {contentQuestion} = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      status: CorrectionAnswerType.WRONG,
      id: idQuestion,
    };
    this.setState({ contentQuestion });
  };

  getStatusQuestionCorrect = (index, idQuestion, annotationText) => {
    this.getPositionStatusCorrect(index, idQuestion, annotationText);
  };

  getStatusQuestionWrong = (index, idQuestion, annotationText) => {
    this.getPositionStatusWrong(index, idQuestion, annotationText);
  };

  getJustification = (index) => {
    const stateJustification = this.state.justification;
    stateJustification[index] = !this.state.showjustification;
    this.setState({ stateJustification });
  };

  getStatusJustification = (index) => {
    this.getJustification(index);
  };

  getUserCorrect (id) {
    const userName = this.props.usersAll?.find((item) => item?.id === id);
    return userName?.name;
  }

  transformDate (date) {
    date = moment(date).format('DD/MM/YYYY');
    return date;
  }

  getAlternativeField = (index, idQuestion) => {
    switch (this.state.selectRadio[index]) {
      case ALTERNATIVE_TYPE.TEXT:
        return (
          <Col>
            <AdvancedInput
              placeholder={I18n.t('forms.newQuestion.placeholderTextInput')}
              value={this.state.annotationText[index]}
              defaultValue=''
              onChange={(val) => {
                return this.getPositionText(val, index);
              }}
              maxLength={1000}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.AUDIO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.AUDIO,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='audio/mp3, audio/m4a'
              label={I18n.t('forms.newQuestion.labelAudioContent')}
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              progressIcon='audio'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.VIDEO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.VIDEO,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='video/mp4,video/avi'
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              label={I18n.t('forms.newQuestion.labelVideoContent')}
              progressIcon='video'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.PHOTO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.IMAGE,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='image/png,image/jpeg,image/jpg'
              label={I18n.t('forms.newQuestion.labelImageContent')}
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              progressIcon='image'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      default:
        return (
          <Col>
            <AdvancedInput
              placeholder={I18n.t('forms.newQuestion.placeholderTextInput')}
              value={this.state.annotationText[index]}
              onChange={(val) => {
                return (
                  this.getPositionText(val, index),
                  this.state.contentQuestion[index]?.status ===
                    CorrectionAnswerType.CORRECT &&
                    this.getStatusQuestionCorrect(index, idQuestion, val),
                  this.state.contentQuestion[index]?.status ===
                    CorrectionAnswerType.WRONG &&
                    this.getStatusQuestionWrong(index, idQuestion, val)
                );
              }}
              maxLength={1000}
            />
          </Col>
        );
    }
  };

  correctQuiz = (totalAccert) => {
    this.props.calculateQuiz(
      {
        id: this.state.rowValue?.id,
        correctAnswers: totalAccert,
        quizNumberOfQuestions: this.state.rowValue.quiz.questions.length,
        courseId: this.state.rowValue?.courseId,
      },
      this.getStatistics,
    );
    this.setState({ annotationText: [] });
    this.setState({ contentQuestion: [] });
    this.closeModal();
  };

  onOpen = (url) => {
    window.open(url, '_blank').focus();
  };

  getAudio = (url) => {
    if (url !== '' && url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <div className='margin_top'>
              <Col span={8}>
                <audio controls>
                  <source
                    src={url}
                    type='audio/mp3'
                    onDoubleClick={() => this.onOpen(url)}
                  />
                </audio>
              </Col>
            </div>
          </Row>
        </span>
      );
    }
  };

  getVideo = (url) => {
    if (url) {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='block_media margin_top'>
                <video
                  width='100%'
                  height='100%'
                  controls
                  src={url}
                  onDoubleClick={() => this.onOpen(url)}
                />
              </div>
            </Col>
          </Row>
        </span>
      );
    }
  };

  getImage = (url) => {
    if (url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='margin_top section_photo'>
                <img
                  width='100%'
                  height='100%'
                  alt='Imagem da alternativa'
                  src={url}
                  className='statistics__image'
                  onClick={() => this.onOpen(url)}
                />
              </div>
              <span className='margin_top'>
                {I18n.t('forms.newQuestion.previewImageNewTab')}
              </span>
            </Col>
          </Row>
        </span>
      );
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  getPosition = (val, index) => {
    const { contentQuestion } = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      correctionAnnotation: '',
    };
    this.setState({ contentQuestion });

    const {selectRadio} = this.state;
    selectRadio[index] = val;

    this.setState({ selectRadio });
  };

  getPositionText = (val, index) => {
    const { contentQuestion } = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      correctionAnnotation: val,
      correctionAnnotationType: CorrectionAnnotationType.TEXT,
    };
    this.setState({ contentQuestion });
  };

  getPositionStatusCorrect = (index, idQuestion, annotationText) => {
    const {contentQuestion} = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      status: CorrectionAnswerType.CORRECT,
      id: idQuestion,
    };

    this.setState({ contentQuestion });
  };

  getPositionStatusWrong = (index, idQuestion, annotationText) => {
    const {contentQuestion} = this.state;
    contentQuestion[index] = {
      ...contentQuestion[index],
      status: CorrectionAnswerType.WRONG,
      id: idQuestion,
    };
    this.setState({ contentQuestion });
  };

  getStatusQuestionCorrect = (index, idQuestion, annotationText) => {
    this.getPositionStatusCorrect(index, idQuestion, annotationText);
  };

  getStatusQuestionWrong = (index, idQuestion, annotationText) => {
    this.getPositionStatusWrong(index, idQuestion, annotationText);
  };

  getJustification = (index) => {
    const stateJustification = this.state.justification;
    stateJustification[index] = !this.state.showjustification;
    this.setState({ stateJustification });
  };

  getStatusJustification = (index) => {
    this.getJustification(index);
  };

  getUserCorrect (id) {
    const userName = this.props.usersAll?.find((item) => item?.id === id);
    return userName?.name;
  }

  transformDate (date) {
    date = moment(date).format('DD/MM/YYYY');
    return date;
  }

  getAlternativeField = (index, idQuestion) => {
    switch (this.state.selectRadio[index]) {
      case ALTERNATIVE_TYPE.TEXT:
        return (
          <Col>
            <AdvancedInput
              placeholder={I18n.t('forms.newQuestion.placeholderTextInput')}
              value={this.state.annotationText[index]}
              defaultValue=''
              onChange={(val) => {
                return this.getPositionText(val, index);
              }}
              maxLength={1000}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.AUDIO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.AUDIO,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='audio/mp3, audio/m4a'
              label={I18n.t('forms.newQuestion.labelAudioContent')}
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              progressIcon='audio'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.VIDEO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.VIDEO,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='video/mp4,video/avi'
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              label={I18n.t('forms.newQuestion.labelVideoContent')}
              progressIcon='video'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      case ALTERNATIVE_TYPE.PHOTO:
        return (
          <Col>
            <AdvancedDragger
              customRequest={({ onProgress, onSuccess, onError, file }) => {
                const { contentQuestion } = this.state;
                contentQuestion[index] = {
                  ...contentQuestion[index],
                  file,
                  onProgress,
                  correctionAnnotationType: CorrectionAnnotationType.IMAGE,
                  index,
                  correctionAnnotation: file.name,
                };
                this.setState({ contentQuestion });
              }}
              accept='image/png,image/jpeg,image/jpg'
              label={I18n.t('forms.newQuestion.labelImageContent')}
              fileList={
                this.state.contentQuestion[index]?.correctionAnnotation && [
                  {
                    name: this.state.contentQuestion[index]
                      ?.correctionAnnotation,
                    uid: 70,
                  },
                ]
              }
              progressIcon='image'
              progress={this.state.fileProgress[index]}
            />
          </Col>
        );
      default:
        return (
          <Col>
            <AdvancedInput
              placeholder={I18n.t('forms.newQuestion.placeholderTextInput')}
              value={this.state.annotationText[index]}
              onChange={(val) => {
                return (
                  this.getPositionText(val, index),
                  this.state.contentQuestion[index]?.status ===
                    CorrectionAnswerType.CORRECT &&
                    this.getStatusQuestionCorrect(index, idQuestion, val),
                  this.state.contentQuestion[index]?.status ===
                    CorrectionAnswerType.WRONG &&
                    this.getStatusQuestionWrong(index, idQuestion, val)
                );
              }}
              maxLength={1000}
            />
          </Col>
        );
    }
  };

  correctQuiz = (totalAccert) => {
    this.props.calculateQuiz(
      {
        id: this.state.rowValue?.id,
        correctAnswers: totalAccert,
        quizNumberOfQuestions: this.state.rowValue.quiz.questions.length,
        courseId: this.state.rowValue?.courseId,
      },
      this.getStatistics,
    );
    this.setState({ annotationText: [] });
    this.setState({ contentQuestion: [] });
    this.closeModal();
  };

  onOpen = (url) => {
    window.open(url, '_blank').focus();
  };

  getAudio = (url) => {
    if (url !== '' && url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <div className='margin_top'>
              <Col span={8}>
                <audio controls>
                  <source
                    src={url}
                    type='audio/mp3'
                    onDoubleClick={() => this.onOpen(url)}
                  />
                </audio>
              </Col>
            </div>
          </Row>
        </span>
      );
    }
  };

  getVideo = (url) => {
    if (url) {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='block_media margin_top'>
                <video
                  width='100%'
                  height='100%'
                  controls
                  src={url}
                  onDoubleClick={() => this.onOpen(url)}
                />
              </div>
            </Col>
          </Row>
        </span>
      );
    }
  };

  getImage = (url) => {
    if (url && typeof url !== 'object') {
      return (
        <span className='block_media margin_bottom'>
          <Row>
            <Col span={10}>
              <div className='margin_top section_photo'>
                <img
                  width='100%'
                  height='100%'
                  alt='Imagem da alternativa'
                  src={url}
                  className='statistics__image'
                  onClick={() => this.onOpen(url)}
                />
              </div>
              <span className='margin_top'>
                {I18n.t('forms.newQuestion.previewImageNewTab')}
              </span>
            </Col>
          </Row>
        </span>
      );
    }
  };

  render () {
    const { Content } = Layout;
    const {
      params,
      showModal,
      rowValue,
      selectRadio,
      justification,
      showjustification,
      contentQuestion,
    } = this.state;
    const { loading, quizStatistics, me, getStatisticsById } = this.props;

    const filterCorrectDissertation = this.state.contentQuestion.filter(
      (item) => item?.status === Alternative_Answer.DISSERTATION,
    );

    const totalCorrectDissertation = filterCorrectDissertation.length;

    const multipleAnswer = rowValue.quiz?.questions?.filter(
      (item) => item.type === Alternative_Answer.MULTIPLEANSWER,
    );

    const multipleAnswerCount = multipleAnswer?.length;

    const multipleCorrect = rowValue.quiz?.questions?.filter(
      (item) =>
        item.type === Alternative_Answer.MULTIPLEANSWER &&
        item.userAnswers[0]?.status === CorrectionAnswerType.CORRECT,
    );

    const totalMultipleCorrect = multipleCorrect?.length;

    const totalAccert = totalMultipleCorrect + totalCorrectDissertation;

    const haveAnswer = this.state.contentQuestion.filter((o) => o.status);

    const totalQuestion = haveAnswer?.length + multipleAnswerCount;

    const rowValueDissertation = rowValue.quiz?.questions?.filter(
      (item) => item.type === Alternative_Answer.DISSERTATION,
    );

    const orderQuestion = rowValueDissertation?.sort((x, y) => {
      return x.quizQuestion.questionNumber - y.quizQuestion.questionNumber;
    });

    const correctionQuestions = () => {
      contentQuestion && contentQuestion.map((question) => {
        this.props.updateQuizStatistics(question, () =>
        this.correctQuiz(totalAccert),
      );
      })
      message.success(I18n.t('routes.panel.statisticsList.dataTable.columns.actions.calculate'))
    }

    const modalCorrect = (
      <Modal
        visible={showModal}
        onCancel={() => this.closeModal()}
        okButtonProps={{
          disabled: totalQuestion !== rowValue.quiz?.questions?.length,
        }}
        onOk={async () => {
          if (rowValue?.status !== UserQuizStatus.DONE) {
            const stateMedia = this.state.contentQuestion.filter(
              (item) =>
                item.correctionAnnotationType !== CorrectionAnnotationType.TEXT,
            );

            const promises = stateMedia.map(async (o) => {
              return this.customRequest({
                onProgress: o.onProgress,
                file: o.file,
                progress: this.state.fileProgress,
                axiosSource: this.state.fileAxiosSource,
                index: o.index,
              });
            });
            await Promise.all(promises);

            correctionQuestions()
          }
        }}
        width={850}
        okText={'Finalizar Correção'}
      >
        <div className='ant-modal-body'>
          <div className='statistics__modalMargin'>
            <div>
              <img width='8%'
                height='8%'
                src={logo} />
            </div>
            <div className='statistics__modal__quiz'>
              <h5 className='statistics__modal__quiz__title'>
                {this.state.rowValue.quiz?.name}
              </h5>
              <h6 className='statistics__modal__quiz__descriptionLabel'>
                {I18n.t('routes.panel.statistics.modal.description')}
              </h6>
              <h6 className='statistics__modal__quiz__descriptionLabel__description'>
                {this.state.rowValue.quiz?.description
                  ? this.state.rowValue.quiz?.description
                  : I18n.t('routes.panel.statistics.modal.notHaveDescription')}
              </h6>
            </div>
            {orderQuestion?.map((item, index) => (
              <Col className='statistics__content' key={item.id}>
                <h5 className='statistics__modal__question__number'>
                  {`${I18n.t('routes.panel.statistics.modal.labelQuestion')} ${
                    item.quizQuestion.questionNumber
                  }`}
                </h5>
                <div>
                  <h6>
                    {I18n.t('forms.newQuestion.labelLocal')} -{' '}
                    {I18n.t('forms.newQuestion.labelYear')}
                  </h6>
                  <p>
                    {item.location} - {item.year}
                  </p>
                </div>
                <div>
                  <h6>
                    {I18n.t('routes.panel.statistics.modal.labelQuestion')}
                  </h6>
                  <p>{item.description}</p>
                </div>
                <div>
                  <h6>
                    {I18n.t('routes.panel.statisticsList.models.title_student')}
                  </h6>
                  {!item.userAnswers[0]?.medias[0] && (
                    <p
                      className={
                        item.userAnswers
                          ? 'statistics__haveValue'
                          : 'statistics__notValue'
                      }
                    >
                      {item.userAnswers && item.userAnswers[0]?.value
                        ? item.userAnswers[0]?.value
                        : I18n.t(
                            'routes.panel.statisticsList.models.notHaveAnswer',
                          )}
                    </p>
                  )}
                  {item.userAnswers[0]?.medias[0]?.type ===
                    ALTERNATIVE_TYPE.PHOTO &&
                    this.getImage(item.userAnswers[0]?.medias[0].mediaFileUrl)}
                  {item.userAnswers[0]?.medias[0]?.type ===
                    ALTERNATIVE_TYPE.AUDIO &&
                    this.getAudio(item.userAnswers[0]?.medias[0].mediaFileUrl)}
                  {item.userAnswers[0]?.medias[0]?.type ===
                    ALTERNATIVE_TYPE.VIDEO &&
                    this.getVideo(item.userAnswers[0]?.medias[0].mediaFileUrl)}
                </div>

                <div className='statistics__buttonJustification'>
                  <AdvancedButton
                    text={
                      !this.state.justification[index]
                        ? 'Mostrar justificativa'
                        : 'Fechar justificativa'
                    }
                    onClick={() => {
                      this.setState({ showjustification: !showjustification });
                      this.getStatusJustification(index);
                    }}
                  />
                  {this.state.justification[index] && (
                    <div className='statistics__buttonJustification__content'>
                      <div className='statistics__border'></div>
                      <h6 className='statistics__buttonJustification__content__title'>
                        {I18n.t('routes.panel.statistics.modal.justification')}
                      </h6>
                      {item.justification ||
                      item.justificationImage ||
                      item.justificationVideo ||
                      item.justificationAudio ? (
                        <>
                          <p>{item.justification && item.justification}</p>
                          <div className='statistics__buttonJustification__content'>
                            {item.justificationImage &&
                              this.getImage(item?.justificationImage)}
                          </div>
                          <div className='statistics__buttonJustification__content'>
                            {item.justificationVideo &&
                              this.getVideo(item?.justificationVideo)}
                          </div>
                          <div className='statistics__buttonJustification__content'>
                            {item.justificationAudio &&
                              this.getAudio(item?.justificationAudio)}
                          </div>
                        </>
                      ) : (
                        <p>
                          {I18n.t(
                            'routes.panel.statistics.modal.notHavejustification',
                          )}
                        </p>
                      )}
                      <div className='statistics__border'></div>
                    </div>
                  )}
                </div>
                <div className='statistics__justification'>
                  <h6>{I18n.t('routes.panel.statisticsList.models.title')}</h6>
                  {rowValue?.status !== UserQuizStatus.DONE && (
                    <AdvancedRadioGroup
                      value={this.state.selectRadio[index]}
                      onChange={(val) => {
                        this.getPosition(val.target.value, index);
                      }}
                      defaultValue={ALTERNATIVE_TYPE.TEXT}
                      options={[
                        {
                          id: ALTERNATIVE_TYPE.TEXT,
                          name: I18n.t('forms.newQuestion.labelText'),
                        },
                        {
                          id: ALTERNATIVE_TYPE.PHOTO,
                          name: I18n.t('forms.newQuestion.labelImage'),
                        },
                        {
                          id: ALTERNATIVE_TYPE.VIDEO,
                          name: I18n.t('forms.newQuestion.labelVideo'),
                        },
                        {
                          id: ALTERNATIVE_TYPE.AUDIO,
                          name: I18n.t('forms.newQuestion.labelAudio'),
                        },
                      ]}
                      horizontal
                    />
                  )}
                  {rowValue?.status !== UserQuizStatus.DONE &&
                    this.getAlternativeField(index, item.userAnswers[0]?.id)}
                  {item.userAnswers[0]?.correctionAnnotationType ===
                    CorrectionAnnotationType.TEXT &&
                    item.userAnswers[0]?.correctionAnnotation}
                  {item.userAnswers[0]?.correctionAnnotationType ===
                    CorrectionAnnotationType.IMAGE &&
                    this.getImage(item.userAnswers[0]?.correctionAnnotation)}
                  {item.userAnswers[0]?.correctionAnnotationType ===
                    CorrectionAnnotationType.AUDIO &&
                    this.getAudio(item.userAnswers[0]?.correctionAnnotation)}
                  {item.userAnswers[0]?.correctionAnnotationType ===
                    CorrectionAnnotationType.VIDEO &&
                    this.getVideo(item.userAnswers[0]?.correctionAnnotation)}
                </div>
                <Row className='statistics__button'>
                  <AdvancedButton
                    text='Correto'
                    className={
                      ((item.userAnswers[0]?.status ===
                        CorrectionAnswerType.CORRECT &&
                        rowValue?.status === UserQuizStatus.DONE) ||
                        this.state.contentQuestion[index]?.status === 2) &&
                      'statistics__button__correctChose'
                    }
                    onClick={() => {
                      this.getStatusQuestionCorrect(
                        index,
                        item.userAnswers[0]?.id,
                        this.state.annotationText[index],
                      );
                    }}
                    disabled={rowValue?.status === UserQuizStatus.DONE}
                  />
                  <AdvancedButton
                    className={
                      (item.userAnswers[0]?.status ===
                        CorrectionAnswerType.WRONG &&
                        rowValue?.status === UserQuizStatus.DONE) ||
                      this.state.contentQuestion[index]?.status === 3
                        ? 'statistics__button__incorrectChose'
                        : 'statistics__button__incorrect'
                    }
                    text='Incorreto'
                    disabled={rowValue?.status === UserQuizStatus.DONE}
                    onClick={() => {
                      this.getStatusQuestionWrong(
                        index,
                        item.userAnswers[0]?.id,
                        this.state.annotationText[index],
                      );
                    }}
                  />
                </Row>
                <div className='statistics__border' />
              </Col>
            ))}
            {this.state.rowValue.status === UserQuizStatus.DONE && (
              <>
                <Row>
                  <h6>
                    {I18n.t('routes.panel.statistics.modal.dateCorrection')}
                  </h6>
                  <div className='statistics__modal__correction'>
                    {this.transformDate(this.state.rowValue.correctedAt)}
                  </div>
                </Row>
                <Row>
                  <h6>{I18n.t('routes.panel.statistics.modal.correctBy')}</h6>
                  <div className='statistics__modal__correction'>
                    {this.getUserCorrect(this.state.rowValue.correctedBy)}
                  </div>
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal>
    );

    return (
      <>
        {modalCorrect}
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.quiz.urlQuiz')}`}>
                <span>{I18n.t('routes.panel.quiz.pageQuiz')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.quiz.pageStatistics')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params.userName}
                      onChange={(e) => this.changeParams('userName', e)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={UserQuizStatusArray}
                      label={I18n.t('shared.advancedFilters.form.responseStatus')}
                      value={params?.status}
                      onChange={(val) => this.changeParams('status', val)}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={arrayPercentage}
                      label={I18n.t('shared.advancedFilters.form.percentage')}
                      value={params.toCorrectAnswersPercentage}
                      onChange={(val) => {
                        if (val === '0%') {
                          this.changeParams('toCorrectAnswersPercentage', '0');
                          this.changeParams('fromCorrectAnswersPercentage', '0');
                        } else {
                          this.changeParams('toCorrectAnswersPercentage', val);
                          this.changeParams(
                            'fromCorrectAnswersPercentage',
                            val - 10 <= '0' ? '0' : val - 10,
                          );
                        }
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      value={

                        (this.props.searchedCourses &&

                          params &&

                          params.courseId) ||

                        undefined

                      }
                      onChange={(val) => {
                        this.changeParams('courseId', val);
                      }}
                      options={this.props.searchedCourses}
                      label={I18n.t('shared.advancedFilters.form.parentId')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.clearFilter()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>
        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={QuizIcon} />
                  </span>
                  {I18n.t('routes.panel.quiz.pageStatistics')} - {this.props.quizStatistics?.rows[0]?.quiz.name}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.exportQuiz()}
                      disabled={loading > 0}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={`${I18n.t('routes.panel.quiz.pageStatistics')} - ${this.props.quizStatisticsRanking?.rows[0].quiz.name}`}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>

                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.exportQuiz(true)}
                      disabled={loading > 0 || this.state.exportingDataLoading}
                      loading={this.state.exportingDataLoading}
                    />
                  </>
                )}
              </Col>
            </Row>
            <Divider />
            <DataTable
              getMethod={(parameters) => this.getStatistics(parameters)}
              data={this.props.quizStatistics}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              params={params}
              columns={[
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.numberOfRanking.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.numberOfRanking.title',
                  ),
                  render: (value) => value,
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.nameUser.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.nameUser.title',
                  ),
                  render: (value) => value,
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.lastAnswer.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.lastAnswer.title',
                  ),
                  render: (value) => Moment(value).format('DD/MM/YYYY'),
                },
                {
                  key: I18n.t('routes.panel.quiz.dataTable.columns.status.key'),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.status.title',
                  ),
                  render: (value) => this.getColorDiv(value),
                },
                {
                  key: I18n.t(
                    'routes.panel.quiz.dataTable.columns.corretion.key',
                  ),
                  title: I18n.t(
                    'routes.panel.quiz.dataTable.columns.corretion.title',
                  ),
                  render: (value) => `${value}%`,
                },
                {
                  key: I18n.t(
                    'routes.panel.contentStatistics.dataTable.columns.status.key',
                  ),
                  title: I18n.t('routes.panel.quiz.statuCorrection'),
                  render: (value, record) =>
                    (record && record.quiz.questions.find(
                      (item) =>
                        item.type === Alternative_Answer.DISSERTATION &&
                        this.props.me.profileType !== ProfileType.QUIZER,
                    ) ? (
                        <AdvancedButton
                          onClick={ async () => {
                            await getStatisticsById(record && record.id)
                            this.setState({
                              showModal: !showModal,
                              rowValue: this.props.userQuizById && this.props.userQuizById,
                            });
                          }}
                          disabled={
                          record?.status !== UserQuizStatus.DONE &&
                          record?.status !== UserQuizStatus.WAITING
                          }
                          text={this.getCorrectStatus(value)}
                          className={
                            value === UserQuizStatus.DONE &&
                          'statistics__buttonCorrected'
                          }
                        ></AdvancedButton>
                      ) : (
                        <Tooltip
                          title={
                            this.props.me.profileType !== ProfileType.QUIZER
                              ? 'Não contém questões dissertativas'
                              : 'Você não tem permissão para realizar a correção'
                          }
                        >
                        ---
                        </Tooltip>
                      )),
                },
                {
                  key: I18n.t(
                    'routes.panel.users.dataTable.columns.actions.key',
                  ),
                  title: '',
                  render: (id, row) =>
                    row.toCorrect &&
                    row.status === UserQuizStatus.WAITING && (
                      <Tooltip
                        title={I18n.t('routes.panel.users.dataTable.columns.actions.goToEditText')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<EditOutlined />}
                          onClick={() =>
                            navigate(
                              `${I18n.t(
                                'routes.panel.quiz.urlQuizCorrections',
                              )}${id}`,
                            )
                          }
                        />
                      </Tooltip>
                    ),
                },
              ]}
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  quizStatistics: QuizSelectors.getStatisticsQuiz(state),
  quizStatisticsRanking: QuizSelectors.getStatisticsQuizRanking(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  me: AuthSelectors.getMe(state),
  usersAll: UserSelectors.getUserAll(state),
  me: AuthSelectors.getMe(state),
  usersAll: UserSelectors.getUserAll(state),
  userQuizById: QuizSelectors.getUserQuizById(state),
});

const mapDispatchToProps = (dispatch) => ({
  getQuizStatistics: (id, params) =>
    dispatch(QuizActions.getQuizStatistics(id, params)),
  getStatisticsById: (id) => dispatch(QuizActions.getStatisticsById(id)),
  getQuizStatisticsRanking: (params) =>
    dispatch(QuizActions.getQuizStatisticsRanking(params)),
  exportStatistics: (id, callback) =>
    dispatch(QuizActions.exportStatisticsQuiz(id, callback)),
  updateQuizStatistics: (questions, callback) =>
    dispatch(QuizActions.updateQuizStatistics(questions, callback)),
  calculateQuiz: (quiz, callback) =>
    dispatch(QuizActions.calculateQuiz(quiz, callback)),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),

  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) =>
    dispatch(
      ContentActions.uploadFile(
        file,
        url,
        contentType,
        onProgress,
        cancelToken,
        callback,
      ),
    ),
  getUrlToUploadFile: (fileName, callback) =>
    dispatch(ContentActions.getUrlToUploadFile(fileName, callback)),
  getUrlToMedia: (name) => dispatch(QuizActions.getUrlPreviewMedia(name)),
  getUsersAll: (params) => dispatch(UserActions.getAllUser(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizExame);
