import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import UtilsRequests from '../../api/utils';

export const getAddressByCep = (cep) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return UtilsRequests.getAddressByCep({ cep });
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getStates = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    return UtilsRequests.getStates();
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getCompanies = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const companies = await UtilsRequests.getCompanies();

    if(callback && typeof callback === 'function') {
      callback(companies)
    }
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getCityByState = (state) => async (dispatch) => {
  dispatch(addLoading());

  try {
    return UtilsRequests.getCityByState({ state });
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getCourseByCompany = (state, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const courses = await UtilsRequests.getCourseByCompany({ state });

    if(callback && typeof callback === 'function') {
      callback(courses)
    }
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};
