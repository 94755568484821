import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import CategoryRequests from '../../api/category';

export const ACTION_SAVE_CATEGORY_SELECTIVES = 'ACTION_SAVE_CATEGORY_SELECTIVES';
export const ACTION_SAVE_CATEGORIES_PAGINATED = 'ACTION_SAVE_CATEGORIES_PAGINATED';
export const ACTION_SAVE_CATEGORY_DETAILS = 'ACTION_SAVE_CATEGORY_DETAILS';

export const ACTION_CLEAN_CATEGORY = 'CLEAN_CATEGORY';

export const cleanCategory = () => ({
  type: ACTION_CLEAN_CATEGORY,
  category: null,
});

export const getCategoriesPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: (parameters && parameters.pageSize) || 10,
    offset: (parameters && parameters.offset) || 0,
    orderBy: 'createdAt',
    isDESC: true,
  };

  try {
    const categoriesPaginated = await CategoryRequests.getCategoriesPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CATEGORIES_PAGINATED,
      payload: categoriesPaginated,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getCategorySelectives = () => async (dispatch) => {
  try {
    const categorySelectives = await CategoryRequests.getCategorySelectives();
    if(categorySelectives && categorySelectives.length > 0) {
      categorySelectives.sort((a, b)=> (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
    }
    dispatch({
      type: ACTION_SAVE_CATEGORY_SELECTIVES,
      payload: categorySelectives,
    });
  } catch (err) {
    //
  }
};

export const getCategoryDetails = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let data = null;
    if (id) {
      data = await CategoryRequests.getCategoryDetails(id);
    }
    dispatch({
      type: ACTION_SAVE_CATEGORY_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCategory = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CategoryRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.categoryDetails.messages.errors.${err.message}`));
    throw new Error();
  } finally {
    dispatch(removeLoading());
  }
};


export const createCategory = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
    };
    await CategoryRequests.createCategory(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.categoryDetails.messages.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const removeCategory = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await CategoryRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.categories.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
