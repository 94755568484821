import getInstance from './config';

export default class ChatApi {
  static async getChatPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/question-doubt-chat/inbox', {params});
    return data;
  }

  static async getChatDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/user/${id}`);
    return data;
  }

  static async getMessages (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/question-doubt-chat/${id}/messages`);
    return data;
  }

  static async sendMessage (params) {
    const instance = await getInstance();
    const { data } = await instance.post('/question-doubt-chat/message', params);
    return data;
  }

  static async deleteChat (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/question-doubt-chat/${id}`,);
    return data;
  }

}
