import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Divider,
  Input,
  message,
  Row,
  Switch,
} from 'antd';
import axios from 'axios';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import {
  SaveOutlined,
} from '@ant-design/icons';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors, CourseSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { CourseActions } from '../../redux/actions';
import { ProfileType } from '../../enum/profileType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { CourseType } from '../../enum/courseType';
import { CoverType } from '../../enum/coverType';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';

class ModuleForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      form: {
        title: '',
        parentId: '',
        description: '',
        coverType: CoverType.IMAGE,
        type: CourseType.MODULE,
        isEnabled: true,
        coverUrl: '',
        imageCoverUrl: '',
      },
      imageCoverUrl: [],
      imageFileProgress: 0,
      imageFileAxiosSource: null,
    };
  }

  async componentDidMount () {
    const { getCourseSelective } = this.props;
    await getCourseSelective({ type: CourseType.COURSE });

    const { data, me } = this.props;

    if (data) {
      this.setState({
        form: {
          ...data,
        },
        imageCoverUrl:
          data
          && data.coverType === CoverType.IMAGE
          && data.coverUrl
          && [ { name: data.coverUrl, uid: data.coverUrl } ],
      }, () => {
        const { form } = this.state;

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
            },
          });
        }
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;
    form = {
      ...form,
    };

    if (!form.parentId) {
      form.parentId = null;
    }

    form.coverType = CoverType.IMAGE;

    if (form.imageCoverUrl) {
      form.coverUrl = form.imageCoverUrl;
    } else {
      form.coverUrl = null;
    }

    delete form.imageCoverUrl;

    const moduleSchema = yup.object().shape({
      description: yup.string()
        .required(I18n.t('routes.panel.moduleDetails.messages.errors.invalid_description')),
      title: yup.string()
        .required(I18n.t('routes.panel.moduleDetails.messages.errors.invalid_title')),
    });
    moduleSchema.validate(form).then(async () => {
      const { data, submitFunction } = this.props;
      const createdModule = await submitFunction(data ? data.id : null, form);

      if (createdModule) {
        this.resetForm();
      }
    }).catch((error) => {
      message.error(error.message);
    });
  }

  resetForm = () => {
    const { form } = this.state;
    Object.keys(form).forEach((key) => {
      this.fieldChange([ key ], '');
    });
    this.fieldChange('coverType', CoverType.IMAGE);
    this.fieldChange('type', CourseType.MODULE);
    this.fieldChange('isEnabled', true);
    this.setState({ imageCoverUrl: [] });
  }

  cancelUploadFile = (axiosSource) => {
    this.state[axiosSource].cancel();
  }

  customRequest = async (onProgress, onSuccess, onError, file, type, progress, axiosSource, multiple = false) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
        isPublic: true,
      });
      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });
      await uploadFile(
        newFile, fileNameAndUrl.url, newFile.type, onUploadProgress, this.state[axiosSource].cancelToken,
        () => {
          message.success(I18n.t('routes.panel.courseDetails.messages.fileSuccess'));
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        const shortFileUrl = fileNameAndUrl.url.split(fileNameAndUrl.fileName)[0] + fileNameAndUrl.fileName;
        this.fieldChange(type, shortFileUrl);
      } else {
        this.handleMultFileChange(type, { fileName: fileNameAndUrl.fileName, title: file.name });
      }
    } catch (error) {
      //
    }
  }

  fieldChange (name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render () {
    const {
      form,
    } = this.state;
    const { TextArea } = Input;
    const { courseSelectives, isDuplicating } = this.props;

    return (
      <Row className="module-form-row">
        <Col span={24}>
          <form
            name="moduleForm"
            onSubmit={(ev) => this.onSubmit(ev)}
            className="module-form"
          >

            <AdvancedDragger
              title={I18n.t('forms.course.imageTitle.label')}
              customRequest={
                ({
                  onProgress, onSuccess, onError, file,
                }) => this.customRequest(
                  onProgress,
                  onSuccess,
                  onError,
                  file,
                  'imageCoverUrl',
                  'imageFileProgress',
                  'imageFileAxiosSource',
                )
              }
              accept="image/png,image/jpeg,image/jpg"
              fileList={this.state.imageCoverUrl}
              onRemove={() => {
                this.fieldChange('imageCoverUrl', null);
                this.setState({ imageCoverUrl: [] });
              }}
              label={I18n.t('forms.course.addImage.label')}
              progress={this.state.imageFileProgress}
              progressIcon="image"
              deletedOnClick={() => {
                this.cancelUploadFile('imageFileAxiosSource');
                this.setState({ imageFileProgress: 0 });
              }}
            />

            <Row gutter={[ 24, 24 ]}>
              <Col span={24}>
                <AdvancedSelect
                  value={isDuplicating ? '' : courseSelectives && form.parentId}
                  onChange={(val) => {
                    this.fieldChange('parentId', val);
                  }}
                  options={courseSelectives}
                  label={I18n.t('forms.module.course.label')}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <AdvancedInput
                  label={I18n.t('forms.module.title.label')}
                  value={form && form.title}
                  onChange={(val) => this.fieldChange('title', val)}
                />
              </Col>
            </Row>

            <Row gutter={[ 24, 24 ]}>
              <Col span={24}>
                <div style={{ paddingBottom: 5 }}>
                  {I18n.t('forms.module.description.label')}
                </div>
                <TextArea
                  value={form && form.description}
                  onChange={({ target: { value } }) => this.fieldChange('description', value)}
                  autoSize={{ minRows: 2, maxRows: 4 }}
                />
              </Col>
            </Row>

            <Row gutter={[ 16, 24 ]}>
              <Col span={8}>
                <span style={{ marginRight: 15 }}>
                  {I18n.t('forms.module.isEnabled.label')}
                </span>
                <Switch
                  checked={form && form.isEnabled}
                  onChange={(val) => this.fieldChange('isEnabled', val)}
                />
              </Col>
            </Row>

            <Divider />

            <Row gutter={[ 16, 24 ]}>
              <Col
                span={24}
                className="text-right"
              >
                <AdvancedButton
                  type="link"
                  text={I18n.t('forms.goBackButtonText')}
                  href={I18n.t('routes.panel.modules.url')}
                />
                <Divider
                  className="form-button-divider"
                  type="vertical"
                />
                <AdvancedButton
                  htmlType="submit"
                  text={I18n.t('shared.save')}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  me: AuthSelectors.getMe(state),
  courseSelectives: CourseSelectors.searchCourses(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCourseSelective: (params) => dispatch(
    CourseActions.searchCourses(params),
  ),
  getUrlToUploadFile: (fileName, callback) => dispatch(
    CourseActions.getUrlToUploadFile(fileName, callback),
  ),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) => dispatch(
    CourseActions.uploadFile(file, url, contentType, onProgress, cancelToken, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ModuleForm,
);
