import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_HOSPITAL_DETAILS,
  ACTION_SAVE_HOSPITAL_SELECTIVES,
  ACTION_SAVE_HOSPITALS_PAGINATED,
} from '../actions/hospital';

const initialState = Immutable({
  hospitalsPaginated: null,
  hospitalDetails: null,
  hospitalSelectives: null,
  hospitalsKanban: null,
});

export default function hospital (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_HOSPITALS_PAGINATED:
      state = {
        ...state,
        hospitalsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_HOSPITAL_DETAILS:
      state = {
        ...state,
        hospitalDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_HOSPITAL_SELECTIVES:
      state = {
        ...state,
        hospitalSelectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getHospitalsPaginated (state) {
  return state.hospital.hospitalsPaginated;
}

export function getHospitalDetails (state) {
  return state.hospital.hospitalDetails;
}

export function getHospitalsKanban (state) {
  return state.hospital.hospitalsKanban;
}

export function getHospitalSelectives (state) {
  return state.hospital.hospitalSelectives;
}
