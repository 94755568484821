import getInstance from './config';

export default class CategoryApi {
  static async getCategoriesPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/category', { params });
    return data;
  }

  static async getCategoryDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/category/${id}`);
    return data;
  }

  static async update (id, categoryData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/category/${id}`, categoryData);
    return data;
  }

  static async createCategory (categoryData) {
    const instance = await getInstance();
    const { data } = await instance.post('/category', categoryData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/category/${id}`);
    return data;
  }

  static async getCategorySelectives () {
    const instance = await getInstance();
    const { data } = await instance.get('/category/selectives');
    return data;
  }
}
