import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_COMPANIES_PAGINATED,
  ACTION_SAVE_COMPANY_DETAILS,
  ACTION_SAVE_COMPANY_SELECTIVES,
} from '../actions/company';

const initialState = Immutable({
  companiesPaginated: null,
  companyDetails: null,
  companySelectives: null,
});

export default function company (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_COMPANIES_PAGINATED:
      state = {
        ...state,
        companiesPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_COMPANY_DETAILS:
      state = {
        ...state,
        companyDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_COMPANY_SELECTIVES:
      state = {
        ...state,
        companySelectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getCompaniesPaginated (state) {
  return state.company.companiesPaginated;
}

export function getCompanyDetails (state) {
  return state.company.companyDetails;
}

export function getCompaniesKanban (state) {
  return state.company.companiesKanban;
}

export function getCompanySelectives (state) {
  return state.company.companySelectives;
}
