
import {
  Button, Checkbox, Col, Row,
} from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getSubStringTitle } from '../../app/utils/functionsUtils';


const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'rgb(229, 241, 255)' : 'white',
  borderBottom: '0.5px solid #f0f0f0',
  borderTop: '0.5px solid #f0f0f0',
  ...draggableStyle,
});

const getListStyle = () => ({
  width: '100%',
  marginTop: '50px',
});

class DndVerticalList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tempItems: [],
      checkedItem: [],
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async onDragEnd (result) {
    const { orderContent } = this.props;
    let { items } = this.props;

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    items = this.reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    this.setState({ tempItems: items }, async () => {
      try {
        await orderContent(items);
      } finally {
        this.setState({ tempItems: [] });
      }
    });
  }

  onChange = (e, index) => {
    this.props.functionCheck(e.target.checked, index);
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getDraggableComponent = (item, index) => (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
    >
      {(providedDraggable, snapshotDraggable) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
          style={getItemStyle(
            snapshotDraggable.isDragging,
            providedDraggable.draggableProps.style,
          )}
        >
          <Row
            gutter={[ 16, 8 ]}
            style={{ margin: 0, height: '72px' }}
          >
            <Col
              span={2}
            >
              <span className="checkbox_drag_and_drop">
                <Checkbox
                  onChange={(e) => this.onChange(e, index)}
                  checked={item.checked}
                />
              </span>
            </Col>
            <Col
              span={2}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <span className="index_drag_and_drop">{index + 1}</span>
            </Col>
            <Col
              span={2}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <span className="bar_drag_and_drop">
                {this.props.colorItem(item.difficulty)}
              </span>
            </Col>
            <Col
              span={14}
              style={{ paddingLeft: '1px' }}
            >
              <span className="content_grad_and_drop">{getSubStringTitle(item.description, 0, 70)}</span>
            </Col>
            <Col span={3}>
              <span className="grid_drad_and_drop">
                <Button
                  type="link"
                  icon={<FileSearchOutlined />}
                  onClick={() => this.props.showModalQuestion(item.id)}
                />
              </span>
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  )

  render () {
    const { items, removeitem } = this.props;
    const { tempItems } = this.state;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tempItems.length > 0 && tempItems.map(
                (item, index) => this.getDraggableComponent(item, index, removeitem),
              )}
              {tempItems.length === 0 && items && items.map(
                (item, index) => this.getDraggableComponent(item, index, removeitem),
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DndVerticalList;
