import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProfileType = {
  ADMIN: 1,
  USER: 2,
  OPERATOR: 3,
  MARKETING: 4,
  QUIZER: 5,
  TEACHER: 6,
  UPLOADER: 7,
  KANBAN_MANAGER: 8,
};


export const ProfileTypeArray = [
  { id: ProfileType.ADMIN, name: <Translate value="enum.profileType.admin" />, config: <Translate value="enum.profileType.admin" />},
  { id: ProfileType.USER, name: <Translate value="enum.profileType.user" />},
  { id: ProfileType.OPERATOR, name: <Translate value="enum.profileType.operator" />, config: <Translate value="enum.profileType.operator" /> },
  { id: ProfileType.MARKETING, name: <Translate value="enum.profileType.marketing" />, config: <Translate value="enum.profileType.marketing" /> },
  { id: ProfileType.QUIZER, name: <Translate value="enum.profileType.quizer" />, config: <Translate value="enum.profileType.quizer" /> },
  { id: ProfileType.TEACHER, name: <Translate value="enum.profileType.teacher" />, config: <Translate value="enum.profileType.teacher" /> },
  { id: ProfileType.UPLOADER, name: <Translate value="enum.profileType.uploader" />, config: <Translate value="enum.profileType.uploader" /> },
];

export const GetProfileTypeTitle = (value) => ProfileTypeArray.find((o) => o.id === value).name;
