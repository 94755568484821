import React from 'react';
import { Radio } from 'antd';

const AdvancedRadioGroup = ({
  value,
  options,
  label,
  onChange,
  labelStyle,
  horizontal,
  disabled,
  defaultValue,
}) => (
  <div className='advanced-radio-group'>
    <label className='advanced-radio-group__label'>
      <span style={labelStyle} className='advanced-radio-group__label__inner'>
        {label}
      </span>
      <Radio.Group
        onChange={onChange}
        value={value || undefined}
        disabled={disabled || false}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <Radio
            key={option.id}
            style={{
              display: horizontal ? '' : 'block',
              height: '30px',
              lineHeight: '30px',
            }}
            value={option.id}
          >
            {option.name} <span className='advanced-radio-group__label__inner__config'>{option.config}</span>
          </Radio>
        ))}
      </Radio.Group>
    </label>
  </div>
);

export default AdvancedRadioGroup;
