import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  message,
  Popconfirm,
  Row,
  Select,
  Steps,
  Tooltip,
  Upload,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  RightOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
import {
  CompanyActions,
  CourseActions,
  HospitalActions,
  QuestionDatabaseActions,
  UtilsActions,
} from '../../redux/actions/index';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors,
  CompanySelectors,
  CourseSelectors,
  HospitalSelectors,
  LoadingSelectors,
  UserSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput';
import * as DateUtils from '../../utils/date';
import { ProfileType } from '../../enum/profileType';
import {
  validateEmail,
} from '../../utils/string';
import { UserStatusType, UserStatusTypeArray } from '../../enum/userStatusType';
import { ViewType } from '../../enum/viewType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { SpecializationList } from '../../enum/specializationType';
import { CourseType } from '../../enum/courseType';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import UserPlanOption from '../../enum/userPlanOption';
import { validateCep } from '../../utils/validateCep';
import { returnPlanName } from '../../utils/returnPlanName';
import NextStep from '../../enum/nextStep';

class UserForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      assignUserQuestionDatabase: false,
      changePasswordVisible: false,
      hospitalHelper: [],
      current: 0,
      formPersonalHeight: 0,
      formProfessionalHeight: 0,
      formCoursesHeight: 0,
      formOthersHeight: 0,
      formQuestionDatabaseHeight: 0,
      course: '',
      startAt: '',
      endAt: '',
      brazilCellphone: '',
      otherCellphone: '',
      courseDeletedList: [],
      editUserQuestionDatabase: false,
      questionDatabase: {
        startAt: '',
        endAt: '',
        planOption: '',
      },
      form: {
        name: '',
        nickname: '',
        email: '',
        cpf: '',
        cep: '',
        state: '',
        city: '',
        address: '',
        addressNumber: '',
        neighborhood: '',
        ddi: '',
        cellphone: '',
        password: '',
        specializationIdList: [],
        hospitals: [],
        hasTemi: 1,
        hasUtiExpertise: 1,
        courses: [],
        status: UserStatusType.REQUESTED,
        planExpiry: '',
        startAt: '',
        endAt: '',
        complement: '',
        photo: null,
        photoUrl: null,
        profession: '',
        professionOther: '',
        professionSelected: I18n.t('forms.user.professions.professionMedic'),
        companies: [],
        planOption: UserPlanOption.BRONZE,
        startAtQuestions: '',
        endAtQuestions: '',
        questionPlanOption: UserPlanOption.BRONZE,
      },
    };
    this.hospitalSelectivesMap = [];
  }

  async componentDidMount () {
    const formPersonalHeight = this.formPersonal.clientHeight;
    this.setState({ formPersonalHeight });
    const {
      userDetails,
      me,
      getCompanySelective,
      getHospitalSelective,
      searchCourses,
    } = this.props;
    await Promise.all([
      await getCompanySelective(),
      await getHospitalSelective(),
      await searchCourses({ type: CourseType.COURSE }),
    ]);
    const { hospitalSelectives, viewType } = this.props;

    this.hospitalSelectivesMap = hospitalSelectives.slice();

    if (userDetails) {
      this.hospitalSelectivesMap.push(
        ...userDetails.userHospitals
          .map((hospital) => ({
            id: hospital.hospitalId,
            name: hospital.hospitalName,
          }))
          .filter((o) => !o.id),
      );
      const saveState = {
        form: {
          ...userDetails,
          planExpiry: DateUtils.humanizeDate(
            userDetails.planExpiry,
            'DD/MM/YYYY',
          ),
          hasUtiExpertise: userDetails.hasUtiExpertise ? 1 : -1,
          hasTemi: userDetails.hasTemi ? 1 : -1,
          ddi:
            (userDetails.cellphone &&
              userDetails.cellphone.split(' ').length > 1 &&
              userDetails.cellphone.split(' ')[0]) ||
            '',
          addressNumber: userDetails.number ? userDetails.number : '',
          specializationIdList:
            userDetails.specializations &&
            userDetails.specializations.map((spc) => spc.id),
          companiesIdList:
            userDetails.companies && userDetails.companies.map((spc) => spc.id),
          hospitals:
            userDetails.userHospitals &&
            userDetails.userHospitals.map(
              (hosp) => hosp.hospitalId || hosp.hospitalName,
            ),
          courses:
            userDetails.courses &&
            userDetails.courses.map((course) => ({
              id: course.id,
              title: course.title,
              startAt: course.userCourse.startAt,
              endAt: course.userCourse.endAt,
              planOption: course.userCourse.planOption,
            })),
        },
        questionDatabase:
        userDetails.questionDatabase &&
        {
          startAt: userDetails.questionDatabase.startAt ? userDetails.questionDatabase.startAt : '',
          endAt: userDetails.questionDatabase.endAt ? userDetails.questionDatabase.endAt : '',
          planOption: userDetails.questionDatabase.planOption ? userDetails.questionDatabase.planOption : '',
        },
        hospitalHelper:
          userDetails.userHospitals &&
          userDetails.userHospitals.map((hosp) => {
            if (hosp.hospitalId) {
              return {
                id: hosp.hospitalId,
              };
            }

            return {
              name: hosp.hospitalName,
            };
          }),
        brazilCellphone:
          (userDetails.cellphone &&
            userDetails.cellphone.split(' ').length > 1 &&
            userDetails.cellphone.split(' ')[0] === '55' &&
            userDetails.cellphone.split(' ')[1]) ||
          '',
        otherCellphone:
          (userDetails.cellphone &&
            userDetails.cellphone.split(' ').length > 1 &&
            userDetails.cellphone.split(' ')[0] !== '55' &&
            userDetails.cellphone.split(' ')[1]) ||
          '',
      };

      if (
        userDetails.profession ===
        I18n.t('forms.user.professions.professionMedic') ||
        userDetails.profession ===
        I18n.t('forms.user.professions.professionNurse') ||
        userDetails.profession ===
        I18n.t('forms.user.professions.professionPhysiotherapist')
      ) {
        saveState.form.professionSelected = userDetails.profession;
      }

      if (
        userDetails.profession !==
        I18n.t('forms.user.professions.professionMedic') &&
        userDetails.profession !==
        I18n.t('forms.user.professions.professionNurse') &&
        userDetails.profession !==
        I18n.t('forms.user.professions.professionPhysiotherapist')
      ) {
        saveState.form.professionSelected = I18n.t(
          'forms.user.professions.professionOther',
        );
        saveState.form.professionOther = userDetails.profession;
      }

      this.setState(saveState, () => {
        const { form, brazilCellphone, otherCellphone } = this.state;

        if (!brazilCellphone && !otherCellphone) {
          this.setState({ otherCellphone: userDetails.cellphone });
        }

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
            },
          });
        }
      });

      if(saveState.questionDatabase && saveState.questionDatabase.planOption) {
        this.setState({ assignUserQuestionDatabase: true })
      }
    }
  }

  async onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;
    const { brazilCellphone, otherCellphone, questionDatabase, assignUserQuestionDatabase, editUserQuestionDatabase } = this.state;
    const { hospitalSelectives } = this.props;
    const planDate = moment(form.planExpiry, 'DD/MM/YYYY');
    const isInvalidCep = validateCep(form.cep)
    let newPlanCourse = planDate;
    form.courses.forEach((item) => {
      if (
        !moment(planDate).isAfter(item.endAt) &&
        !moment(newPlanCourse).isAfter(item.endAt)
      ) {
        newPlanCourse = item.endAt;
      }
    });
    form = {
      ...form,
      planExpiry: form.planExpiry
        ? DateUtils.parseDate(newPlanCourse, 'DD/MM/YYYY')
        : null,
      grantType: 'password',
    };

    const { viewType } = this.props;

    if(viewType === ViewType.ADD) {
      if (!form.courses.length && !questionDatabase.startAt) {
        return message.error(I18n.t('routes.panel.userDetails.invalidUser'));
      }
    }

    if (form.ddi === '55') {
      form.cellphone = brazilCellphone;
    } else {
      form.cellphone = otherCellphone;
    }

    if (form.photo) {
      delete form.photoUrl;
    }

    if (!form.photo) {
      delete form.photo;
    }

    if (!form.photoUrl) {
      delete form.photoUrl;
    }

    if (!form.companies) {
      form.companies = null;
    }

    if (form.name) {
      form.nickname = form.name.replace(/\s+/g, '').toLowerCase();
    }

    delete form.companies;

    if (form.courses.length > 0) {
      form.courses = form.courses.map((course) => ({
        id: course.id,
        endAt: moment(course.endAt, moment.ISO_8601).isValid() ? course.endAt : moment(course.endAt, 'DD/MM/YYYY')
          .set({
            hour: 20,
            minute: 59,
            second: 59,
          })
          .toDate(),
        startAt: moment(course.startAt, moment.ISO_8601).isValid() ? course.startAt : moment(course.startAt, 'DD/MM/YYYY')
          .set({
            hour: 0,
            minute: 0,
            second: 0,
          })
          .subtract(
            !moment(course.startAt).isBefore(moment(course.endAt)) ? 1 : 0,
            'days'
          )
          .subtract(
            3,
            'hours'
          )
          .toDate(),
        planOption: course.planOption,
      }));
    }

    if (!form.courses.length && !questionDatabase) {
      return message.error(I18n.t('routes.panel.userDetails.invalidUser'));
    }

    if (!questionDatabase) {
      form.questionDatabase = null
    }

    if(questionDatabase && assignUserQuestionDatabase || editUserQuestionDatabase) {
      const isValidIso = moment(questionDatabase.startAt, moment.ISO_8601).isValid()

      if(!isValidIso) {
        const userQuestionDatabase = {
          startAt: moment(questionDatabase.startAt, 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          endAt: moment(questionDatabase.endAt, 'DD/MM/YYYY').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          planOption: questionDatabase.planOption
        }
        form.questionDatabase = userQuestionDatabase
      }
    }

    if (form.hospitals.length > 0) {
      const finalHospitals = [];
      form.hospitals.forEach((item) => {
        const selective =
          hospitalSelectives && hospitalSelectives.find((o) => o.id === item);

        if (selective) {
          finalHospitals.push({
            id: item,
            name: selective.name,
          });
        }
      });
      form.hospitals = finalHospitals;
    } else {
      form.hospitals = [];
    }

    form.number = form.addressNumber || null;
    delete form.addressNumber;

    if (form.hasTemi > 0) {
      form.hasTemi = true;
    } else {
      form.hasTemi = false;
    }

    if (form.hasUtiExpertise > 0) {
      form.hasUtiExpertise = true;
    } else {
      form.hasUtiExpertise = false;
    }

    if (form.email && !validateEmail(form.email)) {
      return message.error(
        I18n.t('routes.panel.userDetails.messages.errors.invalid_email'),
      );
    }

    if (form.companiesIdList && form.companiesIdList.length === 0) {
      return message.error(
        I18n.t('routes.panel.userDetails.messages.errors.withoutCompany'),
      );
    }

    if (isInvalidCep) {
      return message.error(isInvalidCep)
    }

    const userSchema = yup.object().shape({
      // ddi: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_ddi')),
      // cellphone: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_cellphone')),
      // city: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_city')),
      // state: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_state')),
      // neighborhood: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_neighborhood')),
      // address: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_address')),
      // cep: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_cep')),
      // cpf: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_cpf')),
      // email: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_email')),
      // name: yup.string().required(I18n.t('routes.panel.userDetails.messages.errors.invalid_name')),
    });
    userSchema
      .validate(form)
      .then(() => {
        if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
          if (!(form.password && form.passwordConfirmation)) {
            return message.error(
              I18n.t(
                'routes.panel.userDetails.messages.errors.passwordsRequired',
              ),
            );
          }

          if (form.password !== form.passwordConfirmation) {
            return message.error(
              I18n.t(
                'routes.panel.userDetails.messages.errors.passwordsMismatch',
              ),
            );
          }
        }

        if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
          delete form.password;
          delete form.passwordConfirmation;
        }

        if (viewType === ViewType.EDIT) {
          delete form.email;
          delete form.nickname;
        }

        if (
          form.professionSelected ===
          I18n.t('forms.user.professions.professionMedic') ||
          form.professionSelected ===
          I18n.t('forms.user.professions.professionNurse') ||
          form.professionSelected ===
          I18n.t('forms.user.professions.professionPhysiotherapist')
        ) {
          form.profession = form.professionSelected;
        }

        if (
          form.professionSelected ===
          I18n.t('forms.user.professions.professionOther')
        ) {
          form.profession = form.professionOther;
        }

        form.courseDeletedList = this.state.courseDeletedList.map((item) => ({
          id: item.id,
        }));
        const { data, submitFunction } = this.props;
        submitFunction(data ? data.id : null, form);
      })
      .catch((error) => {
        message.error(error.message);
      });
  }

  changeStep = (current) => {
    this.setState({ current }, () => {
      if (current === 0) {
        this.setState({ formProfessionalHeight: 0 });
      }

      if (current <= 1) {
        this.setState({ formCoursesHeight: 0 });
      }

      if (current <= 2) {
        this.setState({ formQuestionDatabaseHeight: 0 });
      }

      if (current <= 3) {
        this.setState({ formOthersHeight: 0 });
      }

      if (this.state.current >= 1) {
        this.setState({
          formProfessionalHeight: this.formProfessional.clientHeight,
        });
      }

      if (this.state.current >= 2) {
        this.setState({ formCoursesHeight: this.formCourses.clientHeight });
      }

      if (this.state.current >= 3) {
        this.setState({ formQuestionDatabaseHeight: this.formQuestionDatabase.clientHeight });
      }

      if (this.state.current >= 4) {
        this.setState({ formOthersHeight: this.formOthers.clientHeight });
      }
    });
  };

  nextStep = () => {
    this.setState(
      (state) => ({
        current: state.current + 1,
      }),
      () => {
        if (this.state.current >= NextStep.INITIAL) {
          this.setState({
            formProfessionalHeight: this.formProfessional.clientHeight,
          });
        }

        if (this.state.current >= NextStep.PROFESSIONAL) {
          this.setState({ formCoursesHeight: this.formCourses.clientHeight });
        }

        if (this.state.current >= NextStep.COURSES) {
          this.setState({ formQuestionDatabaseHeight: this.formQuestionDatabase.clientHeight });
        }

        if (this.state.current >= NextStep.QUESTION_DATABASE) {
          this.setState({ formOthersHeight: this.formOthers.clientHeight });
        }
      },
    );
  };

  getAddressByCep = async (cep) => {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    this.fieldChange('address', completeAddress.street);
    this.fieldChange('city', completeAddress.city);
    this.fieldChange('state', completeAddress.state);
    this.fieldChange('neighborhood', completeAddress.neighborhood);
  };

  searchCourses = async (val) => {
    const { searchCourses } = this.props;
    await searchCourses({ search: val, type: CourseType.COURSE });
  };

  handleAddClick = async () => {
    const { form, course } = this.state;
    const isInvalidPeriod = DateUtils.validatePeriod(form.startAt, form.endAt)

    if (course && form.startAt && form.endAt) {
      if (isInvalidPeriod) {
        return message.error(isInvalidPeriod)
      }

      if(!form.planOption) {
        return message.error(I18n.t('routes.panel.userDetails.messages.errors.invalid_plan'))
      }

      if (form.courses.some((item) => item.id === course)) {
        message.error(
          I18n.t('routes.panel.userDetails.messages.errors.courseAlreadyAdd'),
        );
        return;
      }

      const courseData = this.props.searchedCourses.find(
        (item) => item.id === course,
      );
      form.courses = form.courses.concat({
        id: courseData.id,
        title: courseData.title,
        startAt: form.startAt,
        endAt: form.endAt,
        planOption: form.planOption,
      });
      this.setState({ form }, () => {
        this.setState({ course: '' });
      });
    }
  };

  handleAddClickUserQuestionDatabase = async () => {
    const { form } = this.state;

    const isInvalidPeriod = DateUtils.validatePeriod(form.startAtQuestions, form.endAtQuestions)

    if (form.startAtQuestions && form.endAtQuestions) {

      if (isInvalidPeriod) {
        return message.error(isInvalidPeriod)
      }

      if(!form.questionPlanOption) {
        return message.error(I18n.t('routes.panel.questionDatabase.messages.error.invalidPlan'))
      }

      const userQuestionDatabase = {
        startAt: form.startAtQuestions,
        endAt: form.endAtQuestions,
        planOption: form.questionPlanOption,
      }
      this.setState({ questionDatabase: userQuestionDatabase });
    }
  };

  handleRemoveClick = (index) => {
    const { form, courseDeletedList } = this.state;
    const coursesList = [ ...form.courses ];
    courseDeletedList.push(coursesList[index]);
    coursesList.splice(index, 1);
    form.courses = coursesList;
    this.setState({ form, courseDeletedList });
  };

  handleRemoveUserQuestionDatabase = () => {
    const clearUserQuestionDatabase = {
      startAt: '',
      endAt: '',
      planOption: '',
    }

    this.setState({ questionDatabase: clearUserQuestionDatabase, editUserQuestionDatabase: true });
  };

  beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';

    if (!isJpgOrPng) {
      message.error(
        I18n.t('routes.panel.userDetails.messages.errors.invalid_image_type'),
      );
    }

    const isLt2M = file.size / 1024 / 1024 < 30;

    if (!isLt2M) {
      message.error(
        I18n.t('routes.panel.userDetails.messages.errors.invalid_image_size'),
      );
    }

    return isJpgOrPng && isLt2M;
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUploadChange = (info) => {
    if (info && info.file && info.file.originFileObj) {
      this.getBase64(info.file.originFileObj, (imageBase64) =>
        this.fieldChange('photo', imageBase64),
      );
    }
  };

  setCourseExpiration = (value) => {
    if (!moment().isAfter(value)) {
      this.setState({ endAt: value });
    }
  };

  fieldChange (name, value) {
    const { form } = this.state;

    form[name] = value;
    this.setState({ form });
  }

  onChangeCheck (e) {
    const { assignUserQuestionDatabase } = this.state;
    const { userDetails } = this.props;

    if(!assignUserQuestionDatabase) {
      this.setState({assignUserQuestionDatabase: e.target.checked})
    } else if(assignUserQuestionDatabase && !userDetails.questionDatabase) {
      this.setState({assignUserQuestionDatabase: false})
    }
  }

  removeUserQuestionDatabase = () => {
    const { removeUserQuestionDatabase, userDetails } = this.props;
    const { questionDatabase } = this.state;

    if(questionDatabase) {
      const cleanQuestionDatabase = null
      this.setState({questionDatabase: cleanQuestionDatabase})
    }

    removeUserQuestionDatabase(userDetails && userDetails.questionDatabase && userDetails.questionDatabase.id, () => {
      message.success(I18n.t('routes.panel.questionDatabase.messages.success.deleted'));
      this.setState({assignUserQuestionDatabase: false})
    });
  }

  setIsChecked = () => {
    const { assignUserQuestionDatabase } = this.state;

    if(!assignUserQuestionDatabase) {
      this.setState({ assignUserQuestionDatabase: true })
    }
  }

  hospitalChange (val) {
    const { form } = this.state;
    form.hospitals = val;
    this.setState({ form });
  }

  render () {
    const { form, current, changePasswordVisible, course, assignUserQuestionDatabase, questionDatabase } = this.state;
    const { viewType, companySelectives, searchedCourses } = this.props;
    const { Step } = Steps;
    const { Option } = Select;

    return (
      <Row className='user-form-row'>
        <Col span={6}
          className='user-form-col'>
          <Steps
            direction='vertical'
            size='small'
            current={current}
            onChange={this.changeStep}
            className='user-form-steps'
          >
            <Step
              title='Dados pessoais'
              className='user-form-step'
              style={{ minHeight: this.state.formPersonalHeight }}
            />
            <Step
              title='Dados profissionais'
              className='user-form-step'
              style={{ minHeight: this.state.formProfessionalHeight }}
            />
            <Step
              title='Cursos'
              className='user-form-step'
              style={{ minHeight: this.state.formCoursesHeight }}
            />
            <Step
              title={I18n.t('forms.user.questionsDatabase.label')}
              className='user-form-step'
              style={{ minHeight: this.state.formQuestionDatabaseHeight }}
            />
            <Step
              title='Outros'
              className='user-form-step'
              style={{ minHeight: this.state.formOthersHeight }}
            />
            <Step icon={<div />}
              className='user-form-step' />
          </Steps>
        </Col>
        <Col span={18}>
          <form name='userForm'
            onSubmit={(ev) => this.onSubmit(ev)}>
            {current >= 0 && (
              <div
                ref={(ref) => {
                  this.formPersonal = ref;
                }}
              >
                <div className='upload'>
                  <div>{I18n.t('forms.user.imageTitle.label')}</div>
                  <Upload
                    className='upload'
                    showUploadList={false}
                    customRequest={(e) => e}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleUploadChange}
                  >
                    {form.photo || form.photoUrl ? (
                      <div className='upload__img'>
                        <img
                          src={form.photo || form.photoUrl}
                          alt={I18n.t('forms.user.addImage.label')}
                          style={{ width: '100%' }}
                        />
                      </div>
                    ) : (
                      <Button className='upload__button'>
                        <UploadOutlined /> {I18n.t('forms.user.addImage.label')}
                      </Button>
                    )}
                  </Upload>
                </div>

                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.user.name.label')}
                      value={form && form.name}
                      onChange={(val) => this.fieldChange('name', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.user.email.label')}
                      value={form && form.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      disabled={viewType === ViewType.EDIT}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedMaskedInput
                      label={I18n.t('forms.user.cpf.label')}
                      value={form && form.cpf}
                      onChange={(val) => this.fieldChange('cpf', val)}
                      mask='999.999.999-99'
                      type='tel'
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedMaskedInput
                      label={I18n.t('forms.user.cep.label')}
                      value={form && form.cep}
                      onChange={(val) => {
                        this.fieldChange('cep', val);
                      }}
                      mask='99999-999'
                      type='tel'
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedMaskedInput
                      label={I18n.t('forms.user.state.label')}
                      value={form && form.state}
                      onChange={(val) => this.fieldChange('state', val)}
                      mask='aa'
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.user.city.label')}
                      value={form && form.city}
                      onChange={(val) => this.fieldChange('city', val)}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.user.address.label')}
                      value={form && form.address}
                      onChange={(val) => this.fieldChange('address', val)}
                    />
                  </Col>
                  <Col span={3}>
                    <AdvancedInput
                      label={I18n.t('forms.user.addressNumber.label')}
                      value={form && form.addressNumber}
                      onChange={(val) => this.fieldChange('addressNumber', val)}
                    />
                  </Col>
                  <Col span={5}>
                    <AdvancedInput
                      label={I18n.t('forms.user.complement.label')}
                      value={form && form.complement}
                      onChange={(val) => this.fieldChange('complement', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('forms.user.neighborhood.label')}
                      value={form && form.neighborhood}
                      onChange={(val) => this.fieldChange('neighborhood', val)}
                    />
                  </Col>
                </Row>

                {viewType === ViewType.EDIT && (
                  <Row gutter={16}
                    className='form-password-divider'>
                    <Col span={24}>
                      <Checkbox
                        onChange={(e) =>
                          this.setState({
                            changePasswordVisible: e.target.checked,
                          })
                        }
                      >
                        {I18n.t(
                          'routes.panel.userDetails.changePasswordPhrase',
                        )}
                      </Checkbox>
                    </Col>
                  </Row>
                )}

                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('forms.user.ddi.label')}
                      value={form && form.ddi ? form.ddi : ''}
                      onChange={(val) => this.fieldChange('ddi', String(Math.abs(val)).substring(0,3))}
                      maxLength={3}
                      type='number'
                    />
                  </Col>
                  {form.ddi === '55' ? (
                    <Col span={6}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.user.cellphone.label')}
                        value={this.state.brazilCellphone}
                        onChange={(val) =>
                          this.setState({ brazilCellphone: val })
                        }
                        mask='(99) 99999-9999'
                      />
                    </Col>
                  ) : (
                    <Col span={6}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.user.cellphone.label')}
                        value={this.state.otherCellphone}
                        onChange={(val) =>
                          this.setState({ otherCellphone: val })
                        }
                        mask='99999-9999'
                      />
                    </Col>
                  )}

                  {(viewType === ViewType.ADD || changePasswordVisible) && (
                    <>
                      <Col span={6}>
                        <AdvancedInput
                          label={I18n.t('forms.user.password.label')}
                          value={form && form.password}
                          onChange={(val) => this.fieldChange('password', val)}
                          isPassword
                        />
                      </Col>
                      <Col span={6}>
                        <AdvancedInput
                          label={I18n.t(
                            'forms.user.passwordConfirmation.label',
                          )}
                          value={form && form.passwordConfirmation}
                          onChange={(val) =>
                            this.fieldChange('passwordConfirmation', val)
                          }
                          isPassword
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Divider />

                <Row>
                  <Col span={24}
                    className='text-right'>
                    <AdvancedButton
                      type='link'
                      text={I18n.t('forms.submitButtonText')}
                      icon={<RightOutlined />}
                      onClick={this.nextStep}
                      disabled={current > 0}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {current >= 1 && (
              <div
                ref={(ref) => {
                  this.formProfessional = ref;
                }}
              >
                <Row gutter={[ 24, 24 ]}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={companySelectives}
                      label={I18n.t('forms.user.company.label')}
                      value={form && form.companiesIdList}
                      onChange={(val) =>
                        this.fieldChange('companiesIdList', val)
                      }
                      mode='multiple'
                    />
                  </Col>
                </Row>
                <Row gutter={[ 24, 24 ]}>
                  <Col span={6}>
                    <AdvancedRadioGroup
                      value={form && form.professionSelected}
                      label={I18n.t('forms.user.professions.title')}
                      onChange={(val) =>
                        this.fieldChange('professionSelected', val.target.value)
                      }
                      options={[
                        {
                          id: I18n.t('forms.user.professions.professionMedic'),
                          name: I18n.t(
                            'forms.user.professions.professionMedic',
                          ),
                        },
                        {
                          id: I18n.t('forms.user.professions.professionNurse'),
                          name: I18n.t(
                            'forms.user.professions.professionNurse',
                          ),
                        },
                        {
                          id: I18n.t(
                            'forms.user.professions.professionPhysiotherapist',
                          ),
                          name: I18n.t(
                            'forms.user.professions.professionPhysiotherapist',
                          ),
                        },
                        {
                          id: I18n.t('forms.user.professions.professionOther'),
                          name: I18n.t(
                            'forms.user.professions.professionOther',
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                {(form.professionSelected ===
                  I18n.t('forms.user.professions.professionMedic') ||
                  form.professionSelected ===
                  I18n.t('forms.user.professions.professionNurse') ||
                  form.professionSelected ===
                  I18n.t(
                    'forms.user.professions.professionPhysiotherapist',
                  )) && (
                  <>
                    <Row gutter={[ 24, 24 ]}>
                      <Col span={8}>
                        <AdvancedSelect
                          options={SpecializationList}
                          label={I18n.t('forms.user.specializations.label')}
                          value={form && form.specializationIdList}
                          onChange={(val) =>
                            this.fieldChange('specializationIdList', val)
                          }
                          mode='multiple'
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          value={this.hospitalSelectivesMap && form.hospitals}
                          onChange={(val) => this.hospitalChange(val)}
                          options={this.hospitalSelectivesMap}
                          label={I18n.t('forms.user.hospitals.label')}
                          mode='multiple'
                        />
                      </Col>
                    </Row>

                    <Row gutter={[ 16, 24 ]}>
                      <Col span={12}>
                        <AdvancedSelect
                          value={form && form.hasTemi}
                          onChange={(val) => {
                            this.fieldChange('hasTemi', val);
                          }}
                          options={[
                            { id: 1, title: I18n.t('shared.yes') },
                            { id: -1, title: I18n.t('shared.no') },
                          ]}
                          label={`${I18n.t('forms.user.hasTemi.label')}?`}
                        />
                      </Col>

                      <Col span={12}>
                        <AdvancedSelect
                          value={form && form.hasUtiExpertise}
                          onChange={(val) => {
                            this.fieldChange('hasUtiExpertise', val);
                          }}
                          options={[
                            { id: 1, title: I18n.t('shared.yes') },
                            { id: -1, title: I18n.t('shared.no') },
                          ]}
                          label={`${I18n.t(
                            'forms.user.hasUtiExpertise.label',
                          )}?`}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {form.professionSelected ===
                  I18n.t('forms.user.professions.professionOther') && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <AdvancedInput
                        label={I18n.t(
                          'forms.user.professions.professionOtherLabel',
                        )}
                        value={form && form.professionOther}
                        onChange={(val) =>
                          this.fieldChange('professionOther', val)
                        }
                      />
                    </Col>
                  </Row>
                )}

                <Divider />

                <Row>
                  <Col span={24}
                    className='text-right'>
                    <AdvancedButton
                      type='link'
                      text={I18n.t('forms.submitButtonText')}
                      icon={<RightOutlined />}
                      onClick={this.nextStep}
                      disabled={current > 1}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {current >= 2 && (
              <div
                ref={(ref) => {
                  this.formCourses = ref;
                }}
              >
                <Row gutter={24}>
                  <Col span={7}>
                    <div style={{ marginBottom: '5px' }}>
                      {I18n.t('forms.user.courses.label')}
                    </div>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={I18n.t('shared.selectSomeValue')}
                      value={course || undefined}
                      showArrow
                      filterOption={false}
                      onSearch={(val) => this.searchCourses(val)}
                      onChange={(val) => this.setState({ course: val })}
                      notFoundContent={
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={I18n.t('shared.notFoundSearch')}
                        />
                      }
                      allowClear
                    >
                      {searchedCourses &&
                        searchedCourses.map((d) => (
                          <Option key={d.id}>{d.title}</Option>
                        ))}
                    </Select>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={7}>
                    <AdvancedMaskedInput
                      label={I18n.t('forms.user.startAt.label')}
                      value={form && form.startAt}
                      onChange={(val) => this.fieldChange('startAt', val)}
                      mask='99/99/9999'
                      type='tel'
                    />
                  </Col>
                  <Col span={7}>
                    <AdvancedMaskedInput
                      label={I18n.t('forms.user.endAt.label')}
                      value={form && form.endAt}
                      onChange={(val) => this.fieldChange('endAt', val)}
                      mask='99/99/9999'
                      type='tel'
                    />
                  </Col>
                  <Col span={7}>
                    <AdvancedSelect
                      value={form && form.planOption}
                      onChange={(val) => {
                        this.fieldChange('planOption', val);
                      }}
                      options={[
                        {
                          id: UserPlanOption.BRONZE,
                          title: I18n.t('userPlanOption.bronze'),
                        },
                        {
                          id: UserPlanOption.SILVER,
                          title: I18n.t('userPlanOption.silver'),
                        },
                        {
                          id: UserPlanOption.GOLD,
                          title: I18n.t('userPlanOption.gold'),
                        },
                        {
                          id: UserPlanOption.BLACK,
                          title: I18n.t('userPlanOption.black'),
                        },
                      ]}
                      label={`${I18n.t('routes.panel.userDetails.planOptionCourse')}`}
                    />
                  </Col>
                  <Col className='d-flex align-items-end'>
                    <Tooltip
                      title={I18n.t('forms.user.btnAddCourse.label')}
                      overlayStyle={{ fontSize: 11 }}
                    >
                      <Button
                        type='link'
                        icon={<PlusCircleOutlined />}
                        onClick={this.handleAddClick}
                        className='plus-button'
                      />
                    </Tooltip>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col>
                    <br />
                    <small className='form-hint'>
                      {I18n.t('forms.user.hint')}
                    </small>
                  </Col>
                </Row>

                {form.courses.length === 0 ? (
                  <Row gutter={[ 16, 24 ]}>
                    <Col span={24}>
                      {I18n.t('routes.panel.userDetails.noCourses')}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row gutter={[ 16, 24 ]}>
                      <Col span={24}>
                        {I18n.t('routes.panel.userDetails.addedCourses')}
                      </Col>
                    </Row>
                    {form.courses.map((selectedCourses, i) => (
                      <React.Fragment key={selectedCourses.id}>
                        <Row gutter={[ 16, 24 ]}>
                          <Col span={21}>
                            <Row gutter={[ 16, 24 ]}
                              className='selected-courses'>
                              <Col span={6}>{selectedCourses.title}</Col>
                              <Col span={6}>
                                {
                                  moment(selectedCourses.startAt, moment.ISO_8601).isValid() ?
                                    DateUtils.humanizeDate(selectedCourses.startAt, 'DD/MM/YYYY') :
                                    selectedCourses.startAt
                                }
                              </Col>
                              <Col span={6}>
                                {
                                  moment(selectedCourses.endAt, moment.ISO_8601).isValid() ?
                                    DateUtils.humanizeDate(selectedCourses.endAt, 'DD/MM/YYYY') :
                                    selectedCourses.endAt
                                }
                              </Col>
                              <Col span={6}>{returnPlanName(selectedCourses.planOption)}</Col>
                            </Row>
                          </Col>
                          <Col className='d-flex align-items-center'>
                            <AdvancedButton
                              type='link'
                              icon={
                                <MinusCircleOutlined style={{ color: 'red' }} />
                              }
                              onClick={() => this.handleRemoveClick(i)}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                  </>
                )}
                <Divider />
                <Row>
                  <Col span={24}
                    className='text-right'>
                    <AdvancedButton
                      type='link'
                      text={I18n.t('forms.submitButtonText')}
                      icon={<RightOutlined />}
                      onClick={this.nextStep}
                      disabled={current > 2}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {current >= 3 && (
              <div
                ref={(ref) => {
                  this.formQuestionDatabase = ref;
                }}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Popconfirm
                      placement='left'
                      title={I18n.t('routes.panel.questionDatabase.confirmUnlinkUser')}
                      onConfirm={() => this.removeUserQuestionDatabase()}
                      onCancel={() => this.setIsChecked()}
                      okText={I18n.t('shared.yes')}
                      cancelText={I18n.t('shared.no')}
                      disabled={!assignUserQuestionDatabase}
                    >
                      <Tooltip
                        title={I18n.t(
                          'routes.panel.users.dataTable.columns.actions.removeText',
                        )}
                        overlayStyle={{ fontSize: 11 }}
                      >
                      </Tooltip>
                      <Checkbox
                        onChange={(e) => this.onChangeCheck(e)}
                        checked={assignUserQuestionDatabase}
                      >
                        { assignUserQuestionDatabase ?
                          I18n.t('routes.panel.questionDatabase.uncheckUserQuestionDatabase')
                          :
                          I18n.t('routes.panel.questionDatabase.assignUserQuestionDatabase')
                        }
                      </Checkbox>
                    </Popconfirm>

                  </Col>
                </Row>
                {assignUserQuestionDatabase && (
                  <Row gutter={24}
                    className='mt-5'>
                    <Col span={7}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.user.startAt.label')}
                        value={form && form.startAtQuestions}
                        onChange={(val) => this.fieldChange('startAtQuestions', val)}
                        mask='99/99/9999'
                        type='tel'
                      />
                    </Col>
                    <Col span={7}>
                      <AdvancedMaskedInput
                        label={I18n.t('forms.user.endAt.label')}
                        value={form && form.endAtQuestions}
                        onChange={(val) => this.fieldChange('endAtQuestions', val)}
                        mask='99/99/9999'
                        type='tel'
                      />
                    </Col>
                    <Col span={7}>
                      <AdvancedSelect
                        value={form && form.questionPlanOption}
                        onChange={(val) => {
                          this.fieldChange('questionPlanOption', val);
                        }}
                        options={[
                          {
                            id: UserPlanOption.BRONZE,
                            title: I18n.t('userPlanOption.bronze'),
                          },
                          {
                            id: UserPlanOption.SILVER,
                            title: I18n.t('userPlanOption.silver'),
                          },
                          {
                            id: UserPlanOption.GOLD,
                            title: I18n.t('userPlanOption.gold'),
                          },
                          {
                            id: UserPlanOption.BLACK,
                            title: I18n.t('userPlanOption.black'),
                          },
                        ]}
                        label={`${I18n.t('routes.panel.questionDatabase.assignDetails.planOption')}`}
                        disabled={!assignUserQuestionDatabase}
                      />
                    </Col>
                    <Col className='d-flex align-items-end'>
                      <Tooltip
                        title={I18n.t('routes.panel.questionDatabase.assignUser')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type='link'
                          icon={<PlusCircleOutlined />}
                          onClick={this.handleAddClickUserQuestionDatabase}
                          className='plus-button'
                          disabled={!assignUserQuestionDatabase}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col>
                    <br />
                    <small className='form-hint'>
                      {I18n.t('forms.user.hint')}
                    </small>
                  </Col>
                </Row>
                {!questionDatabase ? (
                  <Row gutter={[ 16, 24 ]}>
                    <Col span={24}>
                      {I18n.t('routes.panel.questionDatabase.assignDetails.form.noAssignUser')}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row gutter={[ 16, 24 ]}>
                      <Col span={24}>
                        {I18n.t('routes.panel.questionDatabase.assignDetails.form.assignUser')}
                      </Col>
                    </Row>
                    <React.Fragment>
                      <Row gutter={[ 16, 24 ]}>
                        <Col span={21}>
                          <Row gutter={[ 16, 24 ]}
                            className='selected-courses'>
                            <Col span={6}>{I18n.t('routes.panel.questionDatabase.sidebarTitle')}</Col>
                            <Col span={6}>
                              {
                                moment(questionDatabase.startAt, moment.ISO_8601).isValid() ?
                                  DateUtils.humanizeDate(questionDatabase.startAt, 'DD/MM/YYYY') :
                                  questionDatabase.startAt
                              }
                            </Col>
                            <Col span={6}>
                              {
                                moment(questionDatabase.endAt, moment.ISO_8601).isValid() ?
                                  DateUtils.humanizeDate(questionDatabase.endAt, 'DD/MM/YYYY') :
                                  questionDatabase.endAt
                              }
                            </Col>
                            <Col span={6}>{returnPlanName(questionDatabase.planOption)}</Col>
                          </Row>
                        </Col>
                        <Col className='d-flex align-items-center'>
                          <AdvancedButton
                            type='link'
                            icon={
                              <MinusCircleOutlined style={{ color: 'red' }} />
                            }
                            onClick={() => this.handleRemoveUserQuestionDatabase()}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  </>
                )}
                <Row>
                  <Col span={24}
                    className='text-right'>
                    <AdvancedButton
                      type='link'
                      text={I18n.t('forms.submitButtonText')}
                      icon={<RightOutlined />}
                      onClick={this.nextStep}
                      disabled={current > 3}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {current >= 4 && (
              <div
                ref={(ref) => {
                  this.formOthers = ref;
                }}
              >
                <Row gutter={[ 16, 24 ]}>
                  <Col span={8}>
                    <div style={{ paddingBottom: 5 }}>
                      {I18n.t('forms.user.status.label')}
                    </div>
                    <Select
                      defaultValue={form && form.status}
                      value={form && form.status}
                      style={{ minWidth: '100%' }}
                      onChange={(val) => this.fieldChange('status', val)}
                    >
                      {UserStatusTypeArray.map((userStatus) => (
                        <Option key={userStatus.id}
                          value={userStatus.id}>
                          {userStatus.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col span={24}
                    className='text-right'>
                    <AdvancedButton
                      type='link'
                      text={I18n.t('forms.goBackButtonText')}
                      href={I18n.t('routes.panel.users.url')}
                    />
                    <Divider className='form-button-divider'
                      type='vertical' />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.save')}
                      icon={<SaveOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  userDetails: UserSelectors.getUserDetails(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  hospitalSelectives: HospitalSelectors.getHospitalSelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
  getCompanySelective: () => dispatch(CompanyActions.getCompanySelectives()),
  getHospitalSelective: () => dispatch(HospitalActions.getHospitalSelectives()),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  removeUserQuestionDatabase: (id, callback) => dispatch(QuestionDatabaseActions.removeUserQuestionDatabase(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
