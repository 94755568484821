import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import getInstance from './config';

export default class ContentApi {
  static async getContentsPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/content', { params });
    return data;
  }

  static async getContentsHistory (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/content/history', { params });
    return data;
  }

  static async getContentPaginatedByUserId (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/content/user', { params });
    return data;
  }

  static async getUserContentPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/statistic/user-contents', { params });
    return data;
  }

  static async getContentsCalendar (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/content/calendar', { params });
    return data;
  }

  static async getContentDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/content/${id}`);
    return data;
  }

  static async update (id, contentData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/content/${id}`, contentData);
    return data;
  }

  static async create (contentData) {
    const instance = await getInstance();
    const { data } = await instance.post('/content', contentData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/content/${id}`);
    return data;
  }

  static async getContentSelectives (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/content/selectives', { params });
    return data;
  }

  static async importFile (file, url, contentType, onUploadProgress, cancelToken) {
    const instance = await getInstance({}, { contentType });

    try {
      const { data } = await instance.put(url, file, { onUploadProgress, cancelToken });
      return data;
    } catch (error) {
      throw new Error('cancelado');
    }
  }

  static async getUrlToUploadFile (params) {
    try {
      const instance = await getInstance();
      const { data } = await instance.get('/utils/request-upload', { params });
      return data;
    } catch (err) {
      message.error(I18n.t(`routes.panel.contentDetails.messages.fileError.${err.message}`));
    }
  }

  static async getContentStatisticsData (contentId, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/statistic/dashboard-content?contentId=${contentId}`, { params });
    return data;
  }

  static async getContentStatisticsUsers (contentId, params) {
    const instance = await getInstance();
    const { data } = await instance.get(`/content/statistics-users/${contentId}`, { params });
    return data;
  }

  static async getDashboardSelectives (contentId) {
    const instance = await getInstance();
    const { data } = await instance.get(`/statistic/dashboard-selective?contentId=${contentId}`);
    return data;
  }

  static async exportUsers (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/user/export', { params });
    return data;
  }

  static async saveOrder (courseId, order) {
    const instance = await getInstance();
    const { data } = await instance.put(`/content/order/${courseId}`, {order});
    return data;
  }
}
