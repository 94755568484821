import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_CONTENT_DASHBOARD_SELECTIVES,
  ACTION_SAVE_CONTENT_DETAILS,
  ACTION_SAVE_CONTENT_SEARCH,
  ACTION_SAVE_CONTENT_SELECTIVES,
  ACTION_SAVE_CONTENT_STATISTICS_DATA,
  ACTION_SAVE_CONTENTS_CALENDAR,
  ACTION_SAVE_CONTENTS_HISTORY,

  ACTION_SAVE_CONTENTS_PAGINATED,
  ACTION_SAVE_CONTENTS_USER_ID,
} from '../actions/content';

const initialState = Immutable({
  contentsPaginated: null,
  contentsHistory: null,
  contentsByUserId: null,
  contentDetails: null,
  contentSelectives: null,
  contentCalendar: null,
  contentStatisticsData: null,
  dashboardSelectives: null,
  contentSearched: null
});

export default function content (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_CONTENTS_PAGINATED:
      state = {
        ...state,
        contentsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENTS_HISTORY:
      state = {
        ...state,
        contentsHistory: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENTS_USER_ID:
      state = {
        ...state,
        contentsByUserId: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENT_DETAILS:
      state = {
        ...state,
        contentDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENT_SELECTIVES:
      state = {
        ...state,
        contentSelectives: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENTS_CALENDAR:
      state = {
        ...state,
        contentCalendar: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENT_STATISTICS_DATA:
      state = {
        ...state,
        contentStatisticsData: action.payload,
      };
      return state;

    case ACTION_SAVE_CONTENT_DASHBOARD_SELECTIVES:
      state = {
        ...state,
        dashboardSelectives: action.payload,
      };
      return state;
    case ACTION_SAVE_CONTENT_SEARCH:
      state = {
        ...state,
        contentSearched: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getContentsPaginated (state) {
  return state.content.contentsPaginated;
}

export function getContentsHistory (state) {
  return state.content.contentsHistory;
}

export function getContentPaginatedByUserId (state) {
  return state.content.contentsByUserId;
}

export function getContentDetails (state) {
  return state.content.contentDetails;
}

export function getContentSelectives (state) {
  return state.content.contentSelectives;
}

export function getContentCalendar (state) {
  return state.content.contentCalendar;
}

// new items
export function getContentStatisticsData (state) {
  return state.content.contentStatisticsData;
}

export function getDashboardSelectives (state) {
  return state.content.dashboardSelectives;
}

export function getContentSearched (state) {
  return state.content.contentSearched;
}