import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Empty,
  Input,
  message,
  Row,
  Select,
  Steps,
  Tag,
  Tooltip,
} from 'antd';
import axios from 'axios';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import {
  MinusCircleOutlined, PlusCircleOutlined, RightOutlined, SaveOutlined,
} from '@ant-design/icons';
import moment from 'moment-timezone';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors, CategorySelectors, ContentSelectors, CourseSelectors, LoadingSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { CategoryActions, ContentActions, CourseActions } from '../../redux/actions';
import { ProfileType } from '../../enum/profileType';
import { CourseType } from '../../enum/courseType';
import { StudyContentType, StudyContentTypeArray } from '../../enum/studyContentType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';
import { getFileDuration } from '../../../app/utils/getFileInfo';
import { YearsType } from '../../enum/years';

const years = [];

for (let i = YearsType.TWO_THOUSAND_AND_THIRTY; i >= 1900; i--) {
  years.push({ id: i.toString(), name: i });
}

class ContentForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      current: 0,
      formContentDataHeight: 0,
      formFilesHeight: 0,
      course: '',
      module: '',
      courseOptions: [],
      moduleOptions: [],
      enableModuleOptions: false,
      form: {
        studyType: StudyContentType.ALL,
        title: '',
        description: '',
        categories: [],
        categoryMain: '',
        baseArticle: '',
        year: null,
        summary: '',
        attachments: [],
        audioFileName: '',
        audioDuration: 0,
        videoFileName: '',
        videoDuration: 0,
        postDate: null,
        courses: [],
      },
      coursesHelper: [],
      summary: [],
      attachments: [],
      audioFileName: [],
      videoFileName: [],
      summaryProgress: 0,
      attachmentsProgress: 0,
      audioProgress: 0,
      videoProgress: 0,
      summaryAxiosSource: null,
      attachmentsAxiosSource: null,
      audioAxiosSource: null,
      videoAxiosSource: null,
    };
  }

  async componentDidMount () {
    const formContentDataHeight = this.formContentData.clientHeight;
    this.setState({ formContentDataHeight });
    const { getCategorySelective, searchCourses } = this.props;
    const { form } = this.state;

    await Promise.all([
      await getCategorySelective(),
      await searchCourses({ type: CourseType.COURSE }),
    ]);
    this.setState({ courseOptions: this.props.searchedCourses });
    const { contentDetails, me } = this.props;

    if(!form.year) {
      form.year = moment().year().toString();
    }

    if (contentDetails) {
      this.setState({
        form: {
          ...contentDetails,
          courses: [],
          categories:
            contentDetails.categories
              .filter((category) => !category.contentCategory.isMain)
              .map((category) => category.id),
          categoryMain:
            contentDetails.categories
            && contentDetails.categories
              .filter((category) => category.contentCategory.isMain)
              .map((o) => o.id).join(''),
          postDate:
            contentDetails
            && contentDetails.modules
            && contentDetails.modules[0]
            && contentDetails.modules[0].courseContent.postDate,
        },
        coursesHelper:
          contentDetails
          && contentDetails.modules
          && contentDetails.modules.map((item) => ({
            courseId: item.course.id,
            courseTitle: item.course.title,
            moduleId: item.id,
            moduleTitle: item.title,
          })),
        summary:
          contentDetails
          && contentDetails.summary
          && [ { name: contentDetails.summary, uid: contentDetails.summary } ],
        audioFileName:
          contentDetails
          && contentDetails.audioFileName
          && [ { name: contentDetails.audioFileName, uid: contentDetails.audioFileName } ],
        videoFileName:
          contentDetails
          && contentDetails.videoFileName
          && [ { name: contentDetails.videoFileName, uid: contentDetails.videoFileName } ],
        attachments:
          contentDetails
          && contentDetails.attachments
          && contentDetails.attachments.map((e) => ({ name: e.title, uid: e.id })),
      }, async () => {
        const { form } = this.state;

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
            },
          });
        }
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;
    const { coursesHelper } = this.state;
    form = {
      ...form,
      categories: this.setMainCategory(),
    };

    if (!form.year) {
      form.year = null;
    }

    if (!form.videoFileName) {
      form.videoFileName = null;
    }

    if (!form.videoDuration || form.videoDuration == 0) {
      form.videoDuration = null;
    }

    if (!form.audioDuration || form.audioDuration == 0) {
      form.audioDuration = null;
    }

    if (!form.audioFileName) {
      form.audioFileName = null;
    }

    if (!form.summary) {
      form.summary = null;
    }

    if (!form.attachments) {
      form.attachments = [];
    }

    if (coursesHelper && coursesHelper.length >= 1) {
      coursesHelper.forEach((selecteds, i) => {
        form.courses[i] = { id: selecteds.moduleId, postDate: form.postDate };
      });
    }

    const contentSchema = yup.object().shape({
      courses: yup.array()
        .min(1, I18n.t('routes.panel.contentDetails.messages.errors.invalid_course_length')),
      categories: yup.array()
        .min(1, I18n.t('routes.panel.contentDetails.messages.errors.categoryList')),
      categoryMain: yup.string()
        .required(I18n.t('routes.panel.contentDetails.messages.errors.mainCategory')),
      description: yup.string()
        .required(I18n.t('routes.panel.contentDetails.messages.errors.invalid_description')),
      title: yup.string()
        .required(I18n.t('routes.panel.contentDetails.messages.errors.invalid_title')),
    });

    if (!form.postDate) {
      message.error(I18n.t('routes.panel.contentDetails.messages.errors.invalid_post_date'));
      return;
    }

    if (!form.videoFileName && !form.audioFileName && !form.summary && form.attachments.length === 0) {
      message.error(I18n.t('routes.panel.contentDetails.messages.errors.postWithoutContent'));
      return;
    }

    contentSchema.validate(form).then(() => {
      const { data, submitFunction } = this.props;
      submitFunction(data ? data.id : null, form);
    }).catch((error) => {
      message.error(error.message);
    });
  }

  setMainCategory = () => {
    const { form } = this.state;
    const newCategories = form.categories.map((id) => {
      if (id === form.categoryMain) {
        return {
          id,
          isMain: true,
        };
      }

      return {
        id,
        isMain: false,
      };
    });
    const main = newCategories.some((category) => category.isMain);

    if (main) {
      return newCategories;
    }

    newCategories.push({ id: form.categoryMain, isMain: true });
    return newCategories;
  }

  cancelUploadFile = (axiosSource) => {
    this.state[axiosSource].cancel();
  }


  customRequest = async (onProgress, onSuccess, onError, file, type, progress, axiosSource, multiple = false) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
      });

      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });

      if (type == 'videoFileName' || type == 'audioFileName') {
        getFileDuration(file).then((duration) => {
          if (type == 'videoFileName') {
            this.state.form.videoDuration = duration
          } else {
            this.state.form.audioDuration = duration
          }
        });
      }

      await uploadFile(
        newFile, fileNameAndUrl.url, newFile.type, onUploadProgress, this.state[axiosSource].cancelToken,
        () => {
          message.success(I18n.t('routes.panel.contentDetails.messages.fileSuccess'));
        },
      );

      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        this.fieldChange(type, fileNameAndUrl.fileName);
      } else {
        this.handleMultFileChange(type, { fileName: fileNameAndUrl.fileName, title: file.name });
      }
    } catch (error) {
      //
    }
  }

  changeStep = (current) => {
    this.setState({ current }, () => {
      if (current === 0) {
        this.setState({ formFilesHeight: 0 });
      }

      if (this.state.current === 1) {
        this.setState({ formFilesHeight: this.formFiles.clientHeight });
      }
    });
  };

  nextStep = () => {
    this.setState((state) => ({
      current: state.current + 1,
    }), () => {
      if (this.state.current === 1) {
        this.setState({ formFilesHeight: this.formFiles.clientHeight });
      }
    });
  }

  searchModulesFromCourses = async (val) => {
    const { searchCourses } = this.props;
    this.setState({ enableModuleOptions: false }, async () => {
      try {
        await searchCourses({ parentId: val, type: CourseType.MODULE });
        this.setState({ moduleOptions: this.props.searchedCourses, module: '' });
      } finally {
        this.setState({ enableModuleOptions: true });
      }
    });
  }

  searchCourses = async (val) => {
    const { searchCourses } = this.props;
    await searchCourses({ search: val, type: CourseType.COURSE });
    this.setState({ courseOptions: this.props.searchedCourses });
  }

  searchModules = async (val) => {
    const { searchCourses } = this.props;
    const { course } = this.state;
    await searchCourses({ search: val, type: CourseType.MODULE, parentId: course });
    this.setState({ moduleOptions: this.props.searchedCourses });
  }

  handleAddClick = async () => {
    const {
      coursesHelper, course, module, courseOptions, moduleOptions,
    } = this.state;

    if (course && module) {
      if (coursesHelper.some((item) => item.courseId === course && item.moduleId === module)) {
        message.error(I18n.t('routes.panel.contentDetails.messages.errors.courseAlreadyAdd'));
        return;
      }

      const courseData = courseOptions.find((item) => item.id === course);
      const moduleData = moduleOptions.find((item) => item.id === module);
      const newCoursesHelper = coursesHelper.concat({
        courseId: courseData.id, courseTitle: courseData.title, moduleId: moduleData.id, moduleTitle: moduleData.title,
      });
      this.setState({ coursesHelper: newCoursesHelper }, () => {
        this.setState({ course: '', module: '', moduleOptions: [] });
      });
    }
  };

  handleRemoveClick = (index) => {
    const { coursesHelper } = this.state;
    const coursesList = [ ...coursesHelper ];
    coursesList.splice(index, 1);
    const newCoursesHelper = coursesList;
    this.setState({ coursesHelper: newCoursesHelper });
  };


  fieldChange (name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  handleMultFileChange (name, value) {
    const { form } = this.state;
    form[name] = form[name].concat(value);
    this.setState({ form });
  }

  handleChangeStudyType (tag, checked) {
    if (checked) {
      this.fieldChange('studyType', tag);
    }
  }

  render () {
    const {
      form,
      current,
      videoProgress,
      audioProgress,
      summaryProgress,
      attachmentsProgress,
      course,
      module,
      courseOptions,
      moduleOptions,
      enableModuleOptions,
      coursesHelper,
    } = this.state;
    const { categorySelectives } = this.props;
    const { Step } = Steps;
    const { TextArea } = Input;
    const { Option } = Select;
    const { CheckableTag } = Tag;

    return (
      <Row className="content-form-row">
        <Col
          span={6}
          className="content-form-col"
        >
          <Steps
            direction="vertical"
            size="small"
            current={current}
            onChange={this.changeStep}
            className="content-form-steps"
          >
            <Step
              title={I18n.t('forms.content.steps.contentData.label')}
              className="content-form-step"
              style={{ minHeight: this.state.formContentDataHeight }}
            />
            <Step
              title={I18n.t('forms.content.steps.files.label')}
              className="content-form-step"
              style={{ minHeight: this.state.formFilesHeight }}
            />
            <Step
              icon={<div />}
              className="content-form-step"
            />
          </Steps>
        </Col>
        <Col span={18}>
          <form
            name="contentForm"
            onSubmit={(ev) => this.onSubmit(ev)}
            className="content-form"
          >
            {current >= 0
              ? (
                <div
                  ref={(ref) => { this.formContentData = ref; }}
                >

                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedInput
                        label={I18n.t('forms.content.title.label')}
                        value={form && form.title}
                        onChange={(val) => this.fieldChange('title', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <div style={{ paddingBottom: 5 }}>
                        {I18n.t('forms.content.description.label')} {form && form.description && `(${form.description.length} / 255)`}
                      </div>
                      <TextArea
                        value={form && form.description}
                        onChange={({ target: { value } }) => this.fieldChange('description', value)}
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        maxLength={255}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <AdvancedInput
                        label={I18n.t('forms.content.baseArticle.label')}
                        value={form && form.baseArticle}
                        onChange={(val) => this.fieldChange('baseArticle', val)}
                      />
                    </Col>

                    <Col span={12}>
                      <AdvancedSelect
                        options={years}
                        label={I18n.t('forms.content.year.label')}
                        value={form && form.year || moment().year().toString()}
                        onChange={(val) => this.fieldChange('year', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <AdvancedSelect
                        value={categorySelectives && form.categories && form.categoryMain}
                        onChange={(val) => {
                          this.fieldChange('categoryMain', val);
                        }}
                        options={categorySelectives}
                        optionClassName="category-options"
                        label={I18n.t('forms.content.mainCategory.label')}
                        photoUrl
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <AdvancedSelect
                        mode="multiple"
                        value={categorySelectives && form.categories}
                        onChange={(val) => this.fieldChange('categories', val)}
                        dropdownClassName="category-select"
                        options={categorySelectives}
                        optionClassName="category-options"
                        label={I18n.t('forms.content.category.label')}
                        photoUrl
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <span style={{ marginRight: 8, fontWeight: 'bold' }}>
                        {I18n.t('forms.content.studyType.label')}:
                      </span>
                      {StudyContentTypeArray.map((tag) => (
                        <CheckableTag
                          key={tag.id}
                          checked={form.studyType === tag.id}
                          onChange={(checked) => this.handleChangeStudyType(tag.id, checked)}
                        >
                          {tag.name}
                        </CheckableTag>
                      ))}
                    </Col>
                  </Row>

                  <Row gutter={[ 16, 24 ]}>
                    <Col span={10}>
                      <div>
                        {I18n.t('forms.user.courses.label')}
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        placeholder={I18n.t('shared.selectSomeValue')}
                        showSearch
                        value={course || undefined}
                        showArrow
                        filterOption={false}
                        onSearch={(val) => this.searchCourses(val)}
                        onChange={(val) => this.setState({ course: val })}
                        onSelect={(val) => this.searchModulesFromCourses(val)}
                        notFoundContent={(
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={I18n.t('shared.notFoundSearch')}
                          />
                        )}
                        allowClear
                      >
                        {courseOptions
                          && courseOptions.map((d) => <Option key={d.id}>{d.title}</Option>)}
                      </Select>
                    </Col>

                    <Col span={10}>
                      <div>
                        {I18n.t('forms.user.modules.label')}
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={I18n.t('shared.selectSomeValue')}
                        value={module || undefined}
                        showArrow
                        filterOption={false}
                        onSearch={(val) => this.searchModules(val)}
                        onChange={(val) => this.setState({ module: val })}
                        notFoundContent={(
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={I18n.t('shared.notFoundSearch')}
                          />
                        )}
                        allowClear
                        disabled={!enableModuleOptions || !course}
                      >
                        {moduleOptions
                          && moduleOptions.map((d) => <Option key={d.id}>{d.title}</Option>)}
                      </Select>
                    </Col>

                    <Col className="d-flex flex-column align-items-center">
                      <div style={{ visibility: 'hidden' }}>
                        adicionar
                      </div>
                      <Tooltip
                        title={I18n.t('forms.content.btnAddCourse.label')}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button
                          type="link"
                          icon={<PlusCircleOutlined />}
                          onClick={this.handleAddClick}
                          style={{ position: 'absolute', top: '30px', left: '17px' }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col>
                      <small className="form-hint">
                        {I18n.t('forms.user.hint')}
                      </small>
                    </Col>
                  </Row>

                  {coursesHelper.length === 0
                    ? (
                      <Row
                        span={24}
                        className="mb-5"
                      >
                        <Col span={24}>
                          {I18n.t('routes.panel.contentDetails.noCourses')}
                        </Col>
                      </Row>
                    )
                    : (
                      <>
                        <Row
                          span={24}
                          className="mb-3"
                        >
                          <Col span={24}>
                            {I18n.t('routes.panel.contentDetails.addedCourses')}
                          </Col>
                        </Row>
                        {coursesHelper.map((selectedCourses, i) => (
                          <React.Fragment key={`${selectedCourses.courseId}${selectedCourses.moduleId}`}>
                            <Row gutter={[ 16, 24 ]}>
                              <Col span={20}>
                                <Row
                                  gutter={[ 16, 24 ]}
                                  className="selected-courses"
                                >
                                  <Col span={12}>
                                    {selectedCourses.courseTitle}
                                  </Col>
                                  <Col span={12}>
                                    {selectedCourses.moduleTitle}
                                  </Col>
                                </Row>
                              </Col>
                              <Col className="d-flex align-items-center">
                                <AdvancedButton
                                  type="link"
                                  icon={<MinusCircleOutlined style={{ color: 'red' }} />}
                                  onClick={() => this.handleRemoveClick(i)}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                      </>
                    )}


                  <Row gutter={[ 24, 24 ]}>
                    <Col>
                      <span style={{ paddingBottom: 5 }}>
                        {I18n.t('forms.content.postDate.label')}
                      </span>
                    </Col>
                    <Col>
                      <DatePicker
                        value={form && form.postDate && moment(form.postDate)}
                        placeholder=""
                        showTime={{ format: 'HH:mm' }}
                        format="DD/MM/YYYY HH:mm"
                        onChange={(val) => this.fieldChange('postDate', val)}
                      />
                    </Col>
                  </Row>

                  <Divider />

                  <Row gutter={[ 16, 24 ]}>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.submitButtonText')}
                        icon={<RightOutlined />}
                        onClick={this.nextStep}
                        disabled={current > 0}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

            {current >= 1
              ? (
                <div
                  ref={(ref) => { this.formFiles = ref; }}
                >
                  <AdvancedDragger
                    title={I18n.t('forms.content.pdfTitle.label')}
                    customRequest={
                      ({
                        onProgress, onSuccess, onError, file,
                      }) => this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'summary',
                        'summaryProgress',
                        'summaryAxiosSource',
                      )
                    }
                    accept="application/pdf"
                    fileList={this.state.summary}
                    onRemove={() => {
                      this.fieldChange('summary', null);
                      this.setState({ summary: [] });
                    }}
                    label={I18n.t('forms.content.addPdf.label')}
                    progress={summaryProgress}
                    progressIcon="pdf"
                    deletedOnClick={() => {
                      this.cancelUploadFile('pdfAxiosSource');
                      this.setState({ summaryProgress: 0 });
                    }}
                  />

                  <AdvancedDragger
                    title={I18n.t('forms.content.attachmentsTitle.label')}
                    multiple
                    customRequest={
                      ({
                        onProgress, onSuccess, onError, file,
                      }) => this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'attachments',
                        'attachmentsProgress',
                        'attachmentsAxiosSource',
                        true,
                      )
                    }
                    accept="application/pdf application/image/*"
                    fileList={this.state.attachments}
                    onRemove={(file) => {
                      const indexFileName = form.attachments.map((e) => e.title).indexOf(file.name);
                      const newFilenameList = form.attachments.slice();
                      newFilenameList.splice(indexFileName, 1);
                      this.fieldChange('attachments', newFilenameList);
                      this.setState((state) => {
                        const index = state.attachments.indexOf(file);
                        const newFileList = state.attachments.slice();
                        newFileList.splice(index, 1);
                        return {
                          attachments: newFileList,
                        };
                      });
                    }}
                    label={I18n.t('forms.content.attachmentsPdf.label')}
                    progress={attachmentsProgress}
                    progressIcon="pdf"
                    deletedOnClick={() => {
                      this.cancelUploadFile('attachmentsAxiosSource');
                      this.setState({ attachmentsProgress: 0 });
                    }}
                  />

                  <AdvancedDragger
                    title={I18n.t('forms.content.videoTitle.label')}
                    customRequest={
                      ({
                        onProgress, onSuccess, onError, file,
                      }) => this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'videoFileName',
                        'videoProgress',
                        'videoAxiosSource',
                      )
                    }
                    accept="video/mp4,video/avi"
                    fileList={this.state.videoFileName}
                    onRemove={() => {
                      this.fieldChange('videoFileName', null);
                      this.setState({ videoFileName: [] });
                    }}
                    label={I18n.t('forms.content.addVideo.label')}
                    progress={videoProgress}
                    progressIcon="video"
                    deletedOnClick={() => {
                      this.cancelUploadFile('videoAxiosSource');
                      this.setState({ videoProgress: 0 });
                    }}
                  />

                  <AdvancedDragger
                    title={I18n.t('forms.content.audioTitle.label')}
                    customRequest={
                      ({
                        onProgress, onSuccess, onError, file,
                      }) => this.customRequest(
                        onProgress,
                        onSuccess,
                        onError,
                        file,
                        'audioFileName',
                        'audioProgress',
                        'audioAxiosSource',
                      )
                    }
                    accept="audio/mp3, audio/m4a"
                    fileList={this.state.audioFileName}
                    onRemove={() => {
                      this.fieldChange('audioFileName', null);
                      this.setState({ audioFileName: [] });
                    }}
                    label={I18n.t('forms.content.addAudio.label')}
                    progress={audioProgress}
                    progressIcon="audio"
                    deletedOnClick={() => {
                      this.cancelUploadFile('audioAxiosSource');
                      this.setState({ audioProgress: 0 });
                    }}
                  />


                  <Divider />

                  <Row gutter={[ 16, 24 ]}>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.goBackButtonText')}
                        href={I18n.t('routes.panel.contents.url')}
                      />
                      <Divider
                        className="form-button-divider"
                        type="vertical"
                      />
                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('shared.save')}
                        icon={<SaveOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  contentDetails: ContentSelectors.getContentDetails(state),
  categorySelectives: CategorySelectors.getCategorySelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCategorySelective: () => dispatch(
    CategoryActions.getCategorySelectives(),
  ),
  getContentDetails: (id) => dispatch(
    ContentActions.getContentDetails(id),
  ),
  getUrlToUploadFile: (fileName, callback) => dispatch(
    ContentActions.getUrlToUploadFile(fileName, callback),
  ),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) => dispatch(
    ContentActions.uploadFile(file, url, contentType, onProgress, cancelToken, callback),
  ),
  searchCourses: (params) => dispatch(
    CourseActions.searchCourses(params),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ContentForm,
);
