import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider, Layout, Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { ReleaseNotesActions } from '../../../app/redux/actions';
import { ReleaseNotesSelectors } from '../../../app/redux/reducers';
import { ReactComponent as NotesIcon } from '../../../app/assets/img/notes_icon.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';


class NoteDetails extends React.Component {
  async componentDidMount () {
    const { getReleaseNoteDetails, id } = this.props;
    await getReleaseNoteDetails(id);
  }

  render () {
    const { Content } = Layout;
    const {
      noteDetails,
      loading,
      id,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.releaseNotes.url')}`}>
                <span>{I18n.t('routes.panel.releaseNotes.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.releaseNotes.noteDetails.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="user-info">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <Row>
                <Col span={12}>
                  <h5>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon component={NotesIcon} />
                    </span>
                    {noteDetails && noteDetails.title}
                  </h5>
                </Col>
              </Row>

              <Divider />

              <div className="row d-flex justify-content-center">
                <div className="col-5">
                  <div className="table-header row py-3 mb-1">
                    <div className="col-12">
                      <span><strong>{I18n.t('routes.panel.releaseNotes.noteDetails.pageTitle')}</strong></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <strong>{I18n.t('routes.panel.releaseNotes.form.title.label')}: </strong>
                      {noteDetails && noteDetails.title}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('routes.panel.releaseNotes.form.description.label')}: </strong>
                      {noteDetails && noteDetails.description}
                    </div>
                    <div className="col-12 my-2">
                      <strong>{I18n.t('routes.panel.releaseNotes.form.numberOfVersion.label')}: </strong>
                      {noteDetails && noteDetails.version}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div className="row">
                <div
                  className="col-12 text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('forms.goBackButtonText')}
                    href={I18n.t('routes.panel.releaseNotes.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    style={{ borderRadius: 5 }}
                    text={I18n.t('routes.panel.releaseNotes.editNote')}
                    onClick={() => navigate(`${I18n.t('routes.panel.releaseNotes.url')}${id}`)}
                  />
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  noteDetails: ReleaseNotesSelectors.getReleaseNotesDetails(state),
})

const mapDispatchToProps = (dispatch) => ({
  createReleaseNote: (params, callback) => dispatch(ReleaseNotesActions.createReleaseNote(params, callback)),
  getReleaseNoteDetails: (id) => dispatch(ReleaseNotesActions.getNotesDetails(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  NoteDetails,
);
