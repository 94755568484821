import React from 'react';
import { Input } from 'antd';

const AdvancedInput = ({
  label,
  placeholder,
  onChange,
  type,
  value,
  disabled,
  isPassword,
  isTextarea,
  name,
  required,
  maxLength,
  defaultValue,
  onKeyDown,
  style,
  allowClear = true
}) => (
  <div
    className='advanced-input'
    style={style}
  >
    <label className='advanced-input__label'>
      <span className='advanced-input__label__inner'>{label}</span>
      {isPassword && (
        <Input.Password
          placeholder={placeholder}
          onChange={(ev) => onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          name={name || undefined}
        />
      )}
      {isTextarea && (
        <Input.TextArea
          placeholder={placeholder}
          onChange={(ev) => onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          autoSize={{ minRows: 4, maxRows: 10 }}
          allowClear={allowClear}
          name={name || undefined}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
      )}
      {!isTextarea && !isPassword && (
        <Input
          placeholder={placeholder}
          onChange={(ev) => onChange(ev.currentTarget.value)}
          type={type || 'text'}
          value={value}
          disabled={disabled || false}
          name={name || undefined}
          maxLength={maxLength}
          required={required || undefined}
          defaultValue={defaultValue}
        />
      )}
    </label>
  </div>
);

export default AdvancedInput;
