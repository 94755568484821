import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import UserRequests from '../../api/user';
import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_USERS_PAGINATED = 'ACTION_SAVE_USERS_PAGINATED';
export const ACTION_SAVE_USERS_PAGINATED_SECONDARY =
  'ACTION_SAVE_USERS_PAGINATED_SECONDARY';
export const ACTION_SAVE_USER_ALL = 'ACTION_SAVE_USER_ALL';
export const ACTION_SAVE_USER_DETAILS = 'ACTION_SAVE_USER_DETAILS';
export const ACTION_SAVE_USER_KANBAN = 'ACTION_SAVE_USER_KANBAN';
export const ACTION_SAVE_USER_SELECTIVES = 'ACTION_SAVE_USER_SELECTIVES';

export const ACTION_CLEAN_USER = 'CLEAN_USER';

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const getUsersPaginated =
  (parameters, me, secondary, clear) => async (dispatch) => {
    dispatch(addLoading());
    parameters = {
      ...parameters,
      limit: (parameters && parameters.pageSize) || 10,
      offset: (parameters && parameters.offset) || 0,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (parameters.profileType) {
      parameters = {
        ...parameters,
        profileType: parameters.profileType,
      };
    } else if (parameters.profileTypeList) {
      parameters = {
        ...parameters,
        profileTypeList: parameters.profileTypeList,
      };
    }

    if (me && me.profileType !== ProfileType.ADMIN) {
      parameters = {
        ...parameters,
      };
    }

    try {
      const usersPaginated = await UserRequests.getUsersPaginated(parameters);

      if (secondary) {
        dispatch({
          type: ACTION_SAVE_USERS_PAGINATED_SECONDARY,
          payload: clear ? { rows: [], count: 0 } : usersPaginated,
        });
      } else {
        dispatch({
          type: ACTION_SAVE_USERS_PAGINATED,
          payload: clear ? { rows: [], count: 0 } : usersPaginated,
        });
      }
    } catch (err) {
      message.error(I18n.t('routes.panel.statistics.errors.allUser'));
    } finally {
      dispatch(removeLoading());
    }
  };

export const getAllUser = (params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;
    data = await UserRequests.getAllUser(params);
    dispatch({
      type: ACTION_SAVE_USER_ALL,
      payload: data,
    });
  } catch (err) {
    message.error(I18n.t('routes.panel.statistics.errors.allUser'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserDetails = (id) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let data = null;

    if (id) {
      data = await UserRequests.getUserDetails(id);
    }

    dispatch({
      type: ACTION_SAVE_USER_DETAILS,
      payload: data,
    });
  } catch (err) {
    //
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersKanban = (params) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const users = await UserRequests.getUsersKanban(params);
    dispatch({
      type: ACTION_SAVE_USER_KANBAN,
      payload: users,
    });
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserSelectives = (me, params) => async (dispatch) => {
  try {
    const userDetails = await UserRequests.getUserSelectives(params);
    dispatch({
      type: ACTION_SAVE_USER_SELECTIVES,
      payload: userDetails,
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const updateUser = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UserRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(true);
    }
  } catch (err) {
    message.error(
      I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`),
    );
    callback(false)
  } finally {
    dispatch(removeLoading());
  }
};

export const updateAdmin = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UserRequests.updateAdmin(id, data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(
      I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`),
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const createUserDaily = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    data = {
      ...data,
      profileType: ProfileType.USER,
    };
    await UserRequests.createDaily(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(
      I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`),
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const createAdmin = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    data = {
      ...data,
    };
    await UserRequests.createAdmin(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(
      I18n.t(`routes.panel.userDetails.messages.errors.${err.message}`),
    );
  } finally {
    dispatch(removeLoading());
  }
};

export const removeUser = (id, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    await UserRequests.remove(id);
    callback();
  } catch (err) {
    message.error(I18n.t('routes.panel.users.errors.generic'));
  } finally {
    dispatch(removeLoading());
  }
};
