export function getInitials (name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }

  return initials;
}

export function validateEmail (str) {
  // eslint-disable-next-line max-len,no-useless-escape
  const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return exp.test(str);
}

export function removeSpecialChars (str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : null;
}

export function removeSpecialCharsStr (str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : '';
}

export function isUUIDV5 (uuid) {
  let s = `${uuid}`;
  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  return s !== null;
}

export function parsePhone (value) {
  if (value && value.slice(0, 2) === '55' && value.length === 14) {
    return `${value.slice(0, 2)} (${value.slice(3, 5)}) ${value.slice(5, 10)}-${value.slice(10)}`;
  }

  if (value && value.split(' ').length > 1) {
    return `${value.split(' ')[0]} - ${value.split(' ')[1]}`;
  }

  if (value) {
    return value;
  }

  return '--';
}

export async function getBase64 (file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}

export function onlyNumbers (string) {
  const numsStr = string.replace(/[^0-9]/g,'');

  if (!numsStr) {
    return null
  }

  return Number(numsStr);
}
