import { connect } from 'react-redux';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { Content } from 'antd/lib/layout/layout';
import { Divider, message } from 'antd';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { SendOutlined } from '@ant-design/icons';
import { ReleaseNotesActions } from '../../../app/redux/actions';
import { navigate } from '@reach/router';
import { ViewType } from '../../../app/enum/viewType';
import { ReleaseNotesSelectors } from '../../../app/redux/reducers';
import { CaractersCount } from '../../../components/shared/CaractersCount/CaractersCount';

class CreateReleaseNotes extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      viewType: ViewType.ADD,
      noteDetails: null,
      params: {
        title: '',
        description: '- ',
        version: '',
      }
    }
    this.dataTableRef = React.createRef();
    this.loadingSend = false;
  }

  async componentDidMount () {
    const { getReleaseNoteDetails, id} = this.props;
    await getReleaseNoteDetails(id);
    const { noteDetails } = this.props;

    if (id) {
      this.setState({
        viewType: ViewType.EDIT,
      });
    }

    if (noteDetails) {
      this.setState({
        params: {
          title: noteDetails && noteDetails.title,
          description: noteDetails && noteDetails.description,
          version: noteDetails && noteDetails.version,
        }
      })
    }
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  };

  handleKeyPress = (keyPress) => {
    const { params } = this.state
    const enterPressed = keyPress.key === 'Enter'

    if(enterPressed) {
      setTimeout(() => {
        params['description'] = params.description.concat('- ');
        this.setState({ params })
      },50)
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { params, viewType } = this.state;
    const { createReleaseNote, id, updateNote } = this.props;

    if (params && !params.title) {
      message.error(I18n.t('routes.panel.releaseNotes.messages.error.invalidTitle'))
      return;
    }

    if (params && !params.description) {
      message.error(I18n.t('routes.panel.releaseNotes.messages.error.invalidDescription'))
      return;
    }

    if (params && !params.version) {
      message.error(I18n.t('routes.panel.releaseNotes.messages.error.invalidVersion'))
      return;
    } else if (params && params.version.length > 8 || params.version.length < 5) {
      message.error(I18n.t('routes.panel.releaseNotes.messages.error.invalidVersionLength'))
      return;
    }

    try {
      if(viewType === ViewType.EDIT) {
        await updateNote(id, params, () => {
          message.success(I18n.t('routes.panel.releaseNotes.messages.success.editNote'));
          navigate(I18n.t('routes.panel.releaseNotes.url'));
        })
      } else {
        await createReleaseNote(params, () => {
          message.success(I18n.t('routes.panel.releaseNotes.messages.success.created'));
          navigate(I18n.t('routes.panel.releaseNotes.url'));
        })
      }

    } catch (err) {
      return message.error(I18n.t('routes.panel.releaseNotes.messages.error.generic'));
    }

  }

  render () {
    const { params } = this.state;

    return (
      <>
        <Content className="panel__layout__content">
          <form
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            <div className="push-notification">
              <div className="row">
                <div className="col-12">
                  <h5 className="push-notification__block-title">
                    {I18n.t('routes.panel.releaseNotes.form.pageTitle')}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AdvancedInput
                    label={I18n.t('routes.panel.releaseNotes.form.title.label')}
                    onChange={(value) => this.fieldChange('title', value)}
                    placeholder={I18n.t('shared.type')}
                    value={params && params.title}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AdvancedInput
                    label={I18n.t('routes.panel.releaseNotes.form.description.label')}
                    onChange={(value) => this.fieldChange('description', value)}
                    isTextarea
                    placeholder={I18n.t('shared.type')}
                    value={params && params.description ? params.description : '- '}
                    onKeyDown={(keyPress) => this.handleKeyPress(keyPress)}
                    maxLength={255}
                    style={{padding: 0}}
                  />
                  <CaractersCount
                    current={params?.description?.length}
                    maximum={255}
                    style={{paddingBottom: 20}}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AdvancedInput
                    label={I18n.t('routes.panel.releaseNotes.form.numberOfVersion.label')}
                    onChange={(value) => this.fieldChange('version', value)}
                    placeholder={I18n.t('shared.type')}
                    value={params && params.version}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 text-right"
              >
                <AdvancedButton
                  type="link"
                  text={I18n.t('forms.goBackButtonText')}
                  href={I18n.t('routes.panel.releaseNotes.url')}
                />
                <Divider
                  className="form-button-divider"
                  type="vertical"
                />
                <AdvancedButton
                  htmlType="submit"
                  text={I18n.t('forms.sendButtonText')}
                  icon={<SendOutlined/>}
                />
              </div>
            </div>
          </form>
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  noteDetails: ReleaseNotesSelectors.getReleaseNotesDetails(state),
})

const mapDispatchToProps = (dispatch) => ({
  createReleaseNote: (params, callback) => dispatch(ReleaseNotesActions.createReleaseNote(params, callback)),
  getReleaseNoteDetails: (id) => dispatch(ReleaseNotesActions.getNotesDetails(id)),
  updateNote: (id, params, callback) => dispatch(ReleaseNotesActions.updateNote(id, params, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateReleaseNotes);