import Immutable from 'seamless-immutable';
import {
  GET_ALL_STUDENTS,
  GET_SELECTED_STUDENTS,
  GET_STUDENTS_BY_QUIZ,
} from '../actions/assignStudents';

const initialState = Immutable({
  allStudents: [],
  selectedStudents: [],
  studentsByQuiz: []
});

export default function assignStudents (
  state = initialState,
  action,
) {
  switch (action.type) {
    case GET_ALL_STUDENTS:
      state = {
        ...state,
        allStudents: action.payload,
      };
      return state;
    case GET_STUDENTS_BY_QUIZ:
      state = {
        ...state,
        studentsByQuiz: action.payload,
      };
      return state;
    case GET_SELECTED_STUDENTS:
      state = {
        ...state,
        selectedStudents: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getAllStudents (state) {
  return state.assignStudents.allStudents;
}

export function getStudentsByQuiz (state) {
  return state.assignStudents.studentsByQuiz;
}

export function getSelectedStudents (state) {
  return state.assignStudents.selectedStudents;
}
