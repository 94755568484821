import React from 'react';
import { Translate } from 'react-redux-i18n';

export const NotificationType = {
  TEXT: 1,
  URL: 2,
  CONTENT: 3,
  QUIZ: 4,
};

export const NotificationTypeArray = [
  { id: NotificationType.TEXT, name: <Translate value="enum.NotificationType.text" /> },
  { id: NotificationType.URL, name: <Translate value="enum.NotificationType.url" /> },
  { id: NotificationType.CONTENT, name: <Translate value="enum.NotificationType.content" /> },
  { id: NotificationType.QUIZ, name: <Translate value="enum.NotificationType.quiz" /> },
];
