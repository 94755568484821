import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  OrderedListOutlined,
  PlusOutlined,
  RightSquareOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  CourseActions,
  FilterActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CourseSelectors,
  FilterSelector,
  LoadingSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as CourseIcon } from '../../../app/assets/img/course_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import Column from '../../../components/Column/Column';
import { CourseType } from '../../../app/enum/courseType';
import { ProfileType } from '../../../app/enum/profileType';
import { cleanPagination } from '../../../app/utils/pagination';
import CourseApi from '../../../app/api/course'
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { prepareDataToCsv } from '../../../app/utils/csv';
import { exportPdfFromGraphic } from '../../../app/utils/pdf/graphicPdf';
import SearchableInput from '../../../components/SearchableInput/SearchableInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';

const { Panel } = Collapse;

class Courses extends React.Component {
  constructor (props) {
    super(props);
    const { coursesFilters } = props;
    const { coursesListFilter } = coursesFilters;
    this.state = {
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      optionsPdf: {
        orientation: 'landscape',
      },
      csvData: '',
      params: {
        ...cleanPagination,
        ...coursesListFilter,
        title: coursesListFilter?.title ?? '',
        orderBy: 'createdAt',
        isDESC: true,
        type: CourseType.COURSE
      },
      chartFilterState: {
        courses: coursesFilters.coursesChartFilter?.map(selectedCourse => ({
          id: selectedCourse.key,
          title: selectedCourse.children
        })) || [],
        title: '',
        page: 0,
        loadMore: true,
        coursesSelected: coursesFilters.coursesChartFilter || [],
        loading: false,
      },
      company: '',
      companyList: [],
    };
    this.pdfLink = React.createRef();
    this.csvLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  componentDidMount () {
    const { coursesFilters } = this.props;
    const { coursesListFilter } = coursesFilters;
    this.getCompanyList()
    this.setState({
      params: { ...this.state.params, ...coursesListFilter },
    });

    this.getCourses(this.state.params)
  }

  csvExport = async (exportAllDataFromApi) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.coursesPaginated?.rows,
      (row) => ({
        title: row.title,
        userCount: row.userCount,
        isEnabled: row.isEnabled,
        moduleCount: row.moduleCount,
      }),
      exportAllDataFromApi,
      this.props.coursesPaginated?.count,
      CourseApi.getCoursesPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  exportPdfData = async (exportAllDataFromApi = false) => {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.courses.dataTable.columns.title.title'),
      I18n.t('routes.panel.courses.dataTable.columns.moduleCount.title'),
      I18n.t('routes.panel.courses.dataTable.columns.userCount.title'),
      I18n.t('routes.panel.courses.dataTable.columns.company.title'),
      I18n.t('routes.panel.courses.dataTable.columns.isEnabled.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.coursesPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.courses.sidebarTitle'),
      11,
      8,
      (row) => ([
        row.title || '--',
        row.moduleCount || '--',
        row.userCount || '--',
        row.company?.name || '--',
        row.isEnabled ? I18n.t('shared.affirmative.yes') : I18n.t('shared.affirmative.no'),
      ]),
      exportAllDataFromApi,
      this.props.coursesPaginated?.count,
      CourseApi.getCoursesPaginated,
      this.state.params,
      I18n.t('routes.panel.courses.sidebarTitle')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  updateCourse = (id, data) => {
    const { updateCourse, getCoursesPaginated, me } = this.props;
    const { params } = this.state

    try {
      updateCourse(id, data, () => {
        message.success(I18n.t('routes.panel.courseDetails.messages.success'));
        getCoursesPaginated({...params, orderBy: 'createdAt'}, me)
      });
    } catch (e) {
      message.error(
        I18n.t('routes.panel.courseDetails.messages.errors.generic'),
      );
    }
  };

  getCourses = (params, isFromDataTable, isReset) => {
    const { me, coursesFilters, getCoursesPaginated } = this.props;
    const { coursesListFilter } = coursesFilters;

    params = {
      ...this.state.params,
      ...params,
      type: CourseType.COURSE,
    };

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...coursesListFilter,
        ...params,
      };
    }

    this.setState({ params: { ...params } });
    getCoursesPaginated(params, me);
    this.props.setCoursesListFilters(params);
    this.props.getCourseGraph({
      ...params,
      courseList:
        !isReset ?
          this.state.chartFilterState.coursesSelected?.map(course => course.value).join(',') || ''
          :
          null
    });
  };

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    const { company } = this.state

    if (company) {
      params.companyId = company;
    }

    this.props.setCoursesListFilters({ ...params, ...cleanPagination });
    this.getCourses({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => {
    const cleanParams = {
      title: '',
      companyId: '',
      ...cleanPagination
    };
    this.props.setCoursesListFilters(null);
    this.getCourses(cleanParams, true, true);
    this.props.setCoursesChartFilters(null)
    this.setState({
      chartFilterState: {
        ...this.state.chartFilterState,
        coursesSelected: [],
      },
      company: '',
    })
  };

  getCompanyList = async () => {
    const { getCompanies } = this.props;
    getCompanies((data) => {
      this.setState({ companyList: data });
    });
  }

  removeCourse (id) {
    this.props.removeCourse(id, () => {
      message.success(I18n.t('routes.panel.courses.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  parseDataForChart = (courses) =>
    courses.map((course) => ({
      id: course.title,
      label:
        course.title || I18n.t('routes.panel.contentStatistics.undefinedData'),
      value: parseInt(course.moduleCount, 10),
    }));

  parseDataForChartUsers = (courses) =>
    courses.map((course) => ({
      id: course.title,
      label:
        course.title || I18n.t('routes.panel.contentStatistics.undefinedData'),
      value: parseInt(course.userCount, 10),
    }));

  handleChartFilterSearch = async (valueToSearch) => {
    this.setState({chartFilterState: {
      ...this.state.chartFilterState,
      loading: true
    }})

    try {
      const coursesToUseOnChartFilter = await CourseApi.getCoursesPaginated({
        ...this.state.params,
        title: valueToSearch,
        offset: 0,
        page: 0
      })

      if(coursesToUseOnChartFilter?.rows) {
        this.setState({
          chartFilterState: {
            ...this.state.chartFilterState,
            title: valueToSearch,
            loading: false,
            courses: coursesToUseOnChartFilter.rows,
            page: 0,
            loadMore: true
          }
        })
      } else {
        this.setState({chartFilterState: {
          ...this.state.chartFilterState,
          loading: false,
          loadMore: false
        }})
      }
    } catch (err) {
      this.setState({chartFilterState: {
        ...this.state.chartFilterState,
        loading: false,
        loadMore: false
      }})
    }
  }

  handleGetCoursesNextPageForChart = async () => {
    if(this.state.chartFilterState.loading || !this.state.chartFilterState.loadMore) return

    this.setState({chartFilterState: {
      ...this.state.chartFilterState,
      loading: true
    }})

    try {
      const coursesToUseOnChartFilter = await CourseApi.getCoursesPaginated({
        ...this.state.params,
        offset: this.state.chartFilterState.page + 1,
        title: this.state.chartFilterState.title
      })

      if(coursesToUseOnChartFilter && coursesToUseOnChartFilter.rows.length) {
        this.setState({
          chartFilterState: {
            ...this.state.chartFilterState,
            loading: false,
            page: this.state.chartFilterState.page + 1,
            courses: [ ...this.state.chartFilterState.courses , ...coursesToUseOnChartFilter.rows ]
          }
        })
      } else {
        this.setState({chartFilterState: {
          ...this.state.chartFilterState,
          loading: false,
          loadMore: false
        }})
      }
    } catch (err) {
      this.setState({chartFilterState: {
        ...this.state.chartFilterState,
        loading: false,
        loadMore: false
      }})
    }
  }

  render () {
    const { Content } = Layout;
    const { params, company, companyList } = this.state;
    const { coursesPaginated, loading, courseGraph, me } = this.props;

    return (
      <div>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.courses.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>

                  <SearchableInput
                    title={I18n.t('routes.panel.courses.filters.chart.label')}
                    placeholder={I18n.t('shared.type')}
                    onSearch={this.handleChartFilterSearch}
                    searchTimeout={500}
                    onChange={(_selectedValues, options) => {
                      this.props.setCoursesChartFilters(options)
                      this.setState({
                        chartFilterState: {
                          ...this.state.chartFilterState,
                          coursesSelected: options
                        }
                      }) }
                    }
                    onEndReached={this.handleGetCoursesNextPageForChart}
                    data={this.state.chartFilterState.courses}
                    loading={this.state.chartFilterState.loading}
                    defaultValue={this.state.chartFilterState.coursesSelected}
                    value={this.state.chartFilterState.coursesSelected}
                  />

                  <Col span={6}>
                    <AdvancedSelect
                      label={I18n.t('routes.panel.questionDatabase.dataTable.filters.company')}
                      value={company}
                      onChange={(val) => this.setState({company: val})}
                      options={companyList}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='courses'>
            <div className='courses__margin'>
              <h3>
                {I18n.t(
                  'routes.panel.courses.dataTable.columns.moduleCount.title',
                )}
              </h3>
              {([
                ProfileType.ADMIN,
              ].includes(me?.profileType)) && (
                <AdvancedButton
                  text={I18n.t('shared.exportPdfButton')}
                  onClick={() => exportPdfFromGraphic(
                    I18n.t('routes.panel.courses.dataTable.columns.moduleCount.title'),
                    courseGraph.rows,
                    'moduleCount',
                    I18n.t('routes.panel.courses.dataTable.columns.moduleCount.pdfFileName'),
                  )}
                />
              )}
              {courseGraph && (
                <Column data={this.parseDataForChart(courseGraph.rows)} />
              )}
            </div>
            <div className='courses__margin'>
              <h3>
                {I18n.t(
                  'routes.panel.courses.dataTable.columns.userCount.title',
                )}
              </h3>
              {([
                ProfileType.ADMIN,
              ].includes(me?.profileType)) && (
                <AdvancedButton
                  text={I18n.t('shared.exportPdfButton')}
                  onClick={() => exportPdfFromGraphic(
                    I18n.t('routes.panel.courses.dataTable.columns.userCount.title'),
                    courseGraph.rows,
                    'userCount',
                    I18n.t('routes.panel.courses.dataTable.columns.userCount.pdfFileName'),
                  )}
                />
              )}
              {courseGraph && (
                <Column data={this.parseDataForChartUsers(courseGraph.rows)} />
              )}
            </div>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={CourseIcon} />
                  </span>
                  {I18n.t('routes.panel.courses.pageTitle')}
                </h3>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      loading={this.state.exportingPfdDataLoading}
                      disabled={this.state.exportingPfdDataLoading || loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      loading={loading > 0}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      loading={this.state.exportingDataLoading}
                      disabled={this.state.exportingDataLoading || loading > 0}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.courseDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink></>
                )}

                <Divider type='vertical' />

                <AdvancedButton
                  text={I18n.t('routes.panel.courses.addNewButtonText')}
                  href={`${I18n.t('routes.panel.courseDetails.url')}add`}
                />
              </Col>
            </Row>

            <Divider />
            <div ref={this.pdfLink}>
              <DataTable
                notGetOnStart
                getMethod={(parameters) => this.getCourses(parameters, true)}
                data={coursesPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                showSizeChanger
                pageSizeOptions={
                  coursesPaginated &&
                    coursesPaginated.count &&
                    coursesPaginated.count > 100
                    ? [ '10', '50', '100', coursesPaginated.count ]
                    : [ '10', '20', '50', '100' ]
                }
                params={params}
                columns={[
                  {
                    ellipsis: true,
                    key: I18n.t(
                      'routes.panel.courses.dataTable.columns.title.key',
                    ),
                    title: I18n.t(
                      'routes.panel.courses.dataTable.columns.title.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    width: '170px',
                    key: I18n.t(
                      'routes.panel.courses.dataTable.columns.moduleCount.key',
                    ),
                    title: I18n.t(
                      'routes.panel.courses.dataTable.columns.moduleCount.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {' '}
                        {value || '--'}
                      </div>
                    ),
                  },
                  {
                    width: '170px',
                    key: I18n.t(
                      'routes.panel.courses.dataTable.columns.userCount.key',
                    ),
                    title: I18n.t(
                      'routes.panel.courses.dataTable.columns.userCount.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {' '}
                        {value || '--'}
                      </div>
                    ),
                  },
                  {
                    width: '170px',
                    align: 'center',
                    key: I18n.t(
                      'routes.panel.courses.dataTable.columns.company.key',
                    ),
                    title: I18n.t(
                      'routes.panel.courses.dataTable.columns.company.title',
                    ),
                    render: (value) => (
                      <div className='display_flex justify_content'>
                        {' '}
                        {(value && value.name) || '--'}
                      </div>
                    ),
                  },
                  {
                    width: '90px',
                    key: I18n.t(
                      'routes.panel.contents.dataTable.columns.isEnabled.key',
                    ),
                    title: I18n.t(
                      'routes.panel.contents.dataTable.columns.isEnabled.title',
                    ),
                    render: (value, row) => (
                      <div className='display_flex justify_content'>
                        <Switch
                          checked={value}
                          onChange={() =>
                            this.updateCourse(row.id, { isEnabled: !value })
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    width: '200px',
                    key: I18n.t(
                      'routes.panel.courses.dataTable.columns.actions.key',
                    ),
                    title: '',
                    render: (id, row) => (
                      <div className='dataTable__item--right'>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.quiz.dataTable.columns.actions.goDuplicate',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<PlusOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.courseDetails.urlDuplicate',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t('routes.panel.courses.dataTable.columns.actions.orderContents')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<OrderedListOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.orderCourses.url',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        {(row.moduleCount > 0 || row.userCount > 0) && (
                          <Tooltip
                            title={I18n.t(
                              'routes.panel.courses.dataTable.columns.actions.goToCalendar',
                            )}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type='link'
                              icon={<CalendarOutlined />}
                              onClick={() =>
                                navigate(
                                  `${I18n.t(
                                    'routes.panel.contentCalendar.url',
                                  )}${id}`,
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.courses.dataTable.columns.actions.goToUsers',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<RightSquareOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t('routes.panel.userDetails.url')}` +
                                `${id}${I18n.t(
                                  'routes.panel.userDetails.sufixUrl',
                                )}`,
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.courses.dataTable.columns.actions.goToEditText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.courseDetails.url',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                        {([ ProfileType.TEACHER, ProfileType.ADMIN ]).includes(this.props.me?.profileType) && (
                          <>
                            <Popconfirm
                              placement='left'
                              title={I18n.t('shared.confirmTitle')}
                              onConfirm={() => this.removeCourse(id)}
                              okText={I18n.t('shared.yes')}
                              cancelText={I18n.t('shared.no')}
                            >
                              <Tooltip
                                title={I18n.t(
                                  'routes.panel.courses.dataTable.columns.actions.removeText',
                                )}
                                overlayStyle={{ fontSize: 11 }}
                              >
                                <Button type='link'
                                  icon={<DeleteOutlined />} />
                              </Tooltip>
                            </Popconfirm>
                          </>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  coursesPaginated: CourseSelectors.getCoursesPaginated(state),
  courseGraph: CourseSelectors.getCourseGraph(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  coursesFilters: FilterSelector.getCoursesFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCoursesPaginated: (parameters, me) =>
    dispatch(CourseActions.getCoursesPaginated(parameters, me)),
  getCourseGraph: (parameters) =>
    dispatch(CourseActions.getCourseGraph(parameters)),
  updateCourse: (id, data, callback) =>
    dispatch(CourseActions.updateCourse(id, data, callback)),
  removeCourse: (id, callback) =>
    dispatch(CourseActions.removeCourse(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  setCoursesListFilters: (params) =>
    dispatch(FilterActions.setCoursesListFilters(params)),
  setCoursesChartFilters: (params) =>
    dispatch(FilterActions.setCoursesChartFilters(params)),
  getCompanies: (callback) => dispatch(UtilsActions.getCompanies(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
