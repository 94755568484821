import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ContentType = {
  DAILY: 1,
  FIXED: 2,
};

export const ContentTypeArray = [
  { id: ContentType.DAILY, name: <Translate value="enum.contentType.daily" /> },
  { id: ContentType.FIXED, name: <Translate value="enum.contentType.fixed" /> },
];
