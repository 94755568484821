import React from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Divider,
  Input,
  message,
  Popconfirm,
  Row,
  Steps,
  Switch,
  Tooltip,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import axios from 'axios';
import {
  RightOutlined, SaveOutlined,
} from '@ant-design/icons';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors, CompanySelectors, CourseSelectors, LoadingSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { CompanyActions, CourseActions } from '../../redux/actions';
import { ProfileType } from '../../enum/profileType';
import { ContentType } from '../../enum/contentType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import { CourseType } from '../../enum/courseType';
import { CoverType } from '../../enum/coverType';
import AdvancedDragger from '../../../components/shared/AdvancedDragger/AdvancedDragger';
import { navigate } from '@reach/router';

class CourseForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      current: 0,
      formCourseDataHeight: 0,
      formFilesHeight: 0,
      form: {
        title: '',
        description: '',
        coverType: CoverType.IMAGE,
        type: CourseType.COURSE,
        supportsDaily: ContentType.FIXED,
        isEnabled: true,
        isHomeVisible: true,
        parentId: '',
        isTrial: -1,
        imageCoverUrl: '',
        videoCoverUrl: '',
      },
      videoCoverUrl: [],
      videoFileProgress: 0,
      videoFileAxiosSource: null,
      imageCoverUrl: [],
      imageFileProgress: 0,
      imageFileAxiosSource: null,
      isDuplicate: false,
    };
  }

  async componentDidMount () {
    const formCourseDataHeight = this.formCourseData.clientHeight;
    this.setState({ formCourseDataHeight });
    const { getCompanySelective } = this.props;
    await getCompanySelective();
    const { data, me, isDuplicating } = this.props;

    if (data) {
      this.setState({
        form: {
          ...data,
          supportsDaily: data.supportsDaily ? ContentType.DAILY : ContentType.FIXED,
          isTrial: data.isTrial ? 1 : -1,
          parentId: isDuplicating ? '' : data && data.company && data.company.id,
        },
        imageCoverUrl:
          data
          && data.coverType === CoverType.IMAGE
          && data.coverUrl
          && [ { name: data.coverUrl, uid: data.coverUrl } ],
        videoCoverUrl:
          data
          && data.coverType === CoverType.VIDEO
          && data.coverUrl
          && [ { name: data.coverUrl, uid: data.coverUrl } ],
      }, () => {
        const { form } = this.state;

        if (me && me.profileType !== ProfileType.ADMIN) {
          this.setState({
            form: {
              ...form,
            },
          });
        }
      });
    }
  }

  onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;

    form = {
      ...form,
      supportsDaily: form.supportsDaily === ContentType.DAILY || false,
      isTrial: form.isTrial === 1 || false,
    };

    if (form.coverType === CoverType.IMAGE) {
      form.coverUrl = form.imageCoverUrl;
    }

    if (form.coverType === CoverType.VIDEO) {
      form.coverUrl = form.videoCoverUrl;
    }

    if (!form.videoCoverUrl && !form.imageCoverUrl) {
      form.coverUrl = null;
    }

    delete form.videoCoverUrl;
    delete form.imageCoverUrl;

    const courseSchema = yup.object().shape({
      description: yup.string()
        .required(I18n.t('routes.panel.courseDetails.messages.errors.invalid_description')),
      title: yup.string()
        .required(I18n.t('routes.panel.courseDetails.messages.errors.invalid_title')),
      parentId: yup.string().nullable()
        .required(I18n.t('routes.panel.assignStudents.selectCompany')),
    });
    courseSchema.validate(form).then(() => {
      const { data, submitFunction, isDuplicating, createCourse, duplicateCourse } = this.props;
      const { isDuplicate } = this.state;

      if(!isDuplicating) {
        submitFunction(data ? data.id : null, form);
      } else if (isDuplicating && !isDuplicate) {
        createCourse(form, () => {
          message.success(I18n.t('routes.panel.courseDetails.messages.successDuplicate'));
          navigate(I18n.t('routes.panel.courses.url'));
        })
      } else if (isDuplicating && isDuplicate) {
        duplicateCourse(form, () => {
          message.success(I18n.t('routes.panel.courseDetails.messages.successDuplicate'));
          navigate(I18n.t('routes.panel.courses.url'));
        })
      }
    }).catch((error) => {
      message.error(error.message);
    });
  }

  cancelUploadFile = (axiosSource) => {
    this.state[axiosSource].cancel();
  };

  customRequest = async (onProgress, onSuccess, onError, file, type, progress, axiosSource, multiple = false) => {
    const { uploadFile, getUrlToUploadFile } = this.props;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    this.setState({
      [axiosSource]: {
        cancelToken: source.token,
        cancel: source.cancel,
      },
    });

    const onUploadProgress = (event) => {
      const percent = Math.floor((event.loaded / event.total) * 100);
      this.setState({ [progress]: percent });

      if (percent === 100) {
        setTimeout(() => this.setState({ [progress]: 0 }), 1000);
      }

      onProgress({ percent: (event.loaded / event.total) * 100 });
    };

    try {
      if (!multiple) {
        this.setState({ [type]: [] });
      }

      const fileNameAndUrl = await getUrlToUploadFile({
        fileOriginalName: file.name,
        isPublic: true,
      });
      const newFile = new File([ file ], fileNameAndUrl.fileName, {
        type: file.type,
      });
      await uploadFile(
        newFile, fileNameAndUrl.url, newFile.type, onUploadProgress, this.state[axiosSource].cancelToken,
        () => {
          message.success(I18n.t('routes.panel.courseDetails.messages.fileSuccess'));
        },
      );
      this.setState((state) => {
        const list = state[type].concat(file);
        return {
          [type]: list,
        };
      });

      if (!multiple) {
        const shortFileUrl = fileNameAndUrl.url.split(fileNameAndUrl.fileName)[0] + fileNameAndUrl.fileName;
        this.fieldChange(type, shortFileUrl);
      } else {
        this.handleMultFileChange(type, { fileName: fileNameAndUrl.fileName, title: file.name });
      }
    } catch (error) {
      //
    }
  };

  changeStep = (current) => {
    this.setState({ current }, () => {
      if (current === 0) {
        this.setState({ formFilesHeight: 0 });
      }

      if (this.state.current === 1) {
        this.setState({ formFilesHeight: this.formFiles.clientHeight });
      }
    });
  };

  nextStep = () => {
    this.setState((state) => ({
      current: state.current + 1,
    }), () => {
      if (this.state.current === 1) {
        this.setState({ formFilesHeight: this.formFiles.clientHeight });
      }
    });
  };

  fieldChange (name, value) {
    const { form, isDuplicate } = this.state;

    if (name === 'title' && (value.length <= 40 || form.title.length > value.length)) {
      form[name] = value;
    } else if (name !== 'title') {
      form[name] = value;
    }

    this.setState({ form });
  }

  render () {
    const {
      form,
      current,
      isDuplicate,
    } = this.state;
    const { Step } = Steps;
    const { TextArea } = Input;
    const { companySelectives, isDuplicating } = this.props;

    return (
      <Row className="course-form-row">
        <Col
          span={6}
          className="course-form-col"
        >
          <Steps
            direction="vertical"
            size="small"
            current={current}
            onChange={this.changeStep}
            className="course-form-steps"
          >
            <Step
              title={I18n.t('forms.course.steps.courseData.label')}
              className="course-form-step"
              style={{ minHeight: this.state.formCourseDataHeight }}
            />
            <Step
              title={I18n.t('forms.course.steps.attachments.label')}
              className="course-form-step"
              style={{ minHeight: this.state.formFilesHeight }}
            />
            <Step
              icon={<div />}
              className="course-form-step"
            />
          </Steps>
        </Col>
        <Col span={18}>
          <form
            name="courseForm"
            onSubmit={(ev) => this.onSubmit(ev)}
            className="course-form"
          >
            {current >= 0
              ? (
                <div
                  ref={(ref) => { this.formCourseData = ref; }}
                >

                  <Row gutter={16}>
                    <Col span={24}>
                      <AdvancedInput
                        label={
                          !form.title.length
                            ? I18n.t('forms.course.title.label')
                            : `${I18n.t('forms.course.title.label')} (${form.title.length}/40)`
                        }
                        value={form && form.title}
                        onChange={(val) => this.fieldChange('title', val)}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <div style={{ paddingBottom: 5 }}>
                        {
                          !form.description.length
                            ? I18n.t('forms.course.description.label')
                            : `${I18n.t('forms.course.description.label')} (${form.description.length}/250)`
                        }
                      </div>
                      <TextArea
                        value={form && form.description}
                        onChange={({ target: { value } }) => this.fieldChange('description', value)}
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        maxLength={250}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[ 24, 24 ]}>
                    <Col span={24}>
                      <AdvancedSelect
                        value={isDuplicating ? '' : companySelectives && form.parentId}
                        onChange={(val) => {
                          this.fieldChange('parentId', val);
                        }}
                        options={companySelectives}
                        label={I18n.t('forms.course.company.label')}
                      />
                    </Col>
                  </Row>

                  {(this.props.me?.profileType === ProfileType.ADMIN || this.props.me?.profileType === ProfileType.TEACHER) && (
                    <>
                      <Row gutter={[ 16, 24 ]}>
                        <Col span={6}>
                          <AdvancedRadioGroup
                            value={form && form.supportsDaily}
                            label={I18n.t('forms.course.supportsDaily.label')}
                            onChange={(val) => this.fieldChange('supportsDaily', val.target.value)}
                            options={[
                              { id: ContentType.DAILY, name: I18n.t('shared.yes') },
                              { id: ContentType.FIXED, name: I18n.t('shared.no') },
                            ]}
                            horizontal
                          />
                        </Col>
                      </Row>

                      <Row gutter={[ 16, 24 ]}>
                        <Col span={6}>
                          <AdvancedRadioGroup
                            value={form && form.isTrial}
                            label={I18n.t('forms.course.isTrial.label')}
                            onChange={(val) => this.fieldChange('isTrial', val.target.value)}
                            options={[
                              { id: 1, name: I18n.t('shared.yes') },
                              { id: -1, name: I18n.t('shared.no') },
                            ]}
                            horizontal
                          />
                        </Col>
                      </Row>

                      <Row gutter={[ 16, 24 ]}>
                        <Col span={8}>
                          <Switch
                            checked={form && form.isEnabled}
                            onChange={(val) => this.fieldChange('isEnabled', val)}
                          />
                          <span style={{ marginLeft: 15 }}>
                            {I18n.t('forms.course.isEnabled.label')}
                          </span>
                        </Col>
                      </Row>

                      <Row gutter={[ 16, 24 ]}
                        className="mt-2">
                        <Col span={8}>
                          <Switch
                            checked={form && form.isHomeVisible}
                            onChange={(val) => this.fieldChange('isHomeVisible', val)}
                          />
                          <span style={{ marginLeft: 15 }}>
                            {I18n.t('forms.course.isHomeVisible.label')}
                          </span>
                        </Col>
                      </Row>

                      {isDuplicating && (
                        <Popconfirm
                          placement='left'
                          title={I18n.t('routes.panel.courseDetails.confirmDuplicate')}
                          onConfirm={() => this.setState({ isDuplicate: true })}
                          onCancel={() => this.setState({ isDuplicate: false })}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t(
                              'routes.panel.users.dataTable.columns.actions.removeText',
                            )}
                            overlayStyle={{ fontSize: 11 }}
                          />
                          <Col span={12}
                            className="mt-2">
                            <Switch
                              checked={isDuplicate}
                              onChange={(val) => this.fieldChange('isDuplicate', val)}
                            />
                            <span style={{ marginLeft: 15 }}>
                              {I18n.t('forms.course.isDuplicating.label')}
                            </span>
                          </Col>
                        </Popconfirm>
                      )}
                    </>
                  )}

                  <Divider />

                  <Row gutter={[ 16, 24 ]}>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.submitButtonText')}
                        icon={<RightOutlined />}
                        onClick={this.nextStep}
                        disabled={current > 0}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

            {current >= 1
              ? (
                <div
                  ref={(ref) => { this.formFiles = ref; }}
                >
                  <Row gutter={[ 16, 24 ]}>
                    <Col span={6}>
                      <AdvancedRadioGroup
                        value={form && form.coverType}
                        label={I18n.t('forms.course.coverType.label')}
                        onChange={(val) => this.fieldChange('coverType', val.target.value)}
                        options={[
                          { id: CoverType.IMAGE, name: I18n.t('forms.course.coverTypeImg.label') },
                          { id: CoverType.VIDEO, name: I18n.t('forms.course.coverTypeVideo.label') },
                        ]}
                        horizontal
                      />
                    </Col>
                  </Row>

                  {form.coverType === CoverType.IMAGE && (
                    <AdvancedDragger
                      title={I18n.t('forms.course.imageTitle.label')}
                      customRequest={
                        ({
                          onProgress, onSuccess, onError, file,
                        }) => this.customRequest(
                          onProgress,
                          onSuccess,
                          onError,
                          file,
                          'imageCoverUrl',
                          'imageFileProgress',
                          'imageFileAxiosSource',
                        )
                      }
                      accept="image/png,image/jpeg,image/jpg"
                      fileList={this.state.imageCoverUrl}
                      onRemove={() => {
                        this.fieldChange('imageCoverUrl', null);
                        this.setState({ imageCoverUrl: [] });
                      }}
                      label={I18n.t('forms.course.addImage.label')}
                      progress={this.state.imageFileProgress}
                      progressIcon="image"
                      deletedOnClick={() => {
                        this.cancelUploadFile('imageFileAxiosSource');
                        this.setState({ imageFileProgress: 0 });
                      }}
                    />
                  )}

                  {form.coverType === CoverType.VIDEO && (
                    <AdvancedDragger
                      title={I18n.t('forms.course.videoTitle.label')}
                      customRequest={
                        ({
                          onProgress, onSuccess, onError, file,
                        }) => this.customRequest(
                          onProgress,
                          onSuccess,
                          onError,
                          file,
                          'videoCoverUrl',
                          'videoFileProgress',
                          'videoFileAxiosSource',
                        )
                      }
                      accept="video/mp4,video/avi"
                      fileList={this.state.videoCoverUrl}
                      onRemove={() => {
                        this.fieldChange('videoCoverUrl', null);
                        this.setState({ videoCoverUrl: [] });
                      }}
                      label={I18n.t('forms.course.addVideo.label')}
                      progress={this.state.videoFileProgress}
                      progressIcon="video"
                      deletedOnClick={() => {
                        this.cancelUploadFile('videoFileAxiosSource');
                        this.setState({ videoFileProgress: 0 });
                      }}
                    />

                  )}

                  <Divider />

                  <Row gutter={[ 16, 24 ]}>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('forms.goBackButtonText')}
                        href={I18n.t('routes.panel.courses.url')}
                      />
                      <Divider
                        className="form-button-divider"
                        type="vertical"
                      />
                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('shared.save')}
                        icon={<SaveOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              )
              : null}

          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  courseDetails: CourseSelectors.getCourseDetails(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCompanySelective: () => dispatch(
    CompanyActions.getCompanySelectives(),
  ),
  getCourseDetails: (id) => dispatch(
    CourseActions.getCourseDetails(id),
  ),
  getUrlToUploadFile: (fileName, callback) => dispatch(
    CourseActions.getUrlToUploadFile(fileName, callback),
  ),
  uploadFile: (file, url, contentType, onProgress, cancelToken, callback) => dispatch(
    CourseActions.uploadFile(file, url, contentType, onProgress, cancelToken, callback),
  ),
  createCourse: (data, callback) => dispatch(CourseActions.createCourse(data, callback)),
  duplicateCourse: (data, callback) => dispatch(CourseActions.duplicateCourse(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CourseForm,
);