export async function prepareDataToCsv (
  localData,
  formatRowFunction,
  useDataFromApi,
  linesAmountToFetchFromApi,
  apiHandlerFunction,
  paramsToFetchFromAPi,
) {
  let dataToUse = localData

  if (useDataFromApi && linesAmountToFetchFromApi && apiHandlerFunction && paramsToFetchFromAPi) {
    dataToUse = await apiHandlerFunction({
      ...paramsToFetchFromAPi,
      limit: linesAmountToFetchFromApi,
      offset: null,
      page: null,
      pageSize: null
    })
  }

  if (dataToUse) {
    if (dataToUse.rows?.length > 0) {
      return dataToUse.rows.map(formatRowFunction);
    } else if (dataToUse.length > 0) {
      return dataToUse.map(formatRowFunction);
    }
  }
}