import Url from '../config/api.config';

function getBaseUrl () {
  const { hostname } = window.location;
  let apiUrl = null;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
      apiUrl = Url.development;
      break;
    case 'dev.portal.ccdensino.com.br':
      apiUrl = Url.development;
      break;
    case 'hmg.portal.ccdensino.com.br':
      apiUrl = Url.homolog;
      break;
    case 'portal.ccdensino.com.br':
      apiUrl = Url.production;
      break;
    default:
      apiUrl = null;
  }

  return apiUrl;
}

function getApiKey () {
  const { hostname } = window.location;

  switch (hostname) {
    case 'portal.ccdensino.com.br':
      return '833a781b-fd16-4caf-a973-5ba723f7c72f';
    case 'hmg.portal.ccdensino.com.br':
      return 'fe5552e3-5859-4af4-abe3-a79543e99f42';
    case 'dev.portal.ccdensino.com.br':
      return '6e7d84da-2f74-4823-8a45-21ee492a0de6';
    case 'localhost':
      return '6e7d84da-2f74-4823-8a45-21ee492a0de6';
    default:
      return '833a781b-fd16-4caf-a973-5ba723f7c72f';
  }
}

function ErrorUpdateTrial (message) {
  this.name = 'can_only_exist_one_trial';
  this.message = message || '';
}

ErrorUpdateTrial.prototype = Error.prototype;

export function handleAxiosError (err) {
  if (
    err.response &&
    err.response.status === 400 &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error === 'can_only_exist_one_trial'
  ) {
    const e = new ErrorUpdateTrial(err.response.data.options.title);
    throw new Error(e);
  }

  if (
    err.response &&
    err.response.status === 400 &&
    err.response.data &&
    err.response.data.error
  ) {
    throw new Error(err.response.data.error);
  }

  if (err.response && err.response.status === 401) {
    // throw new Error(CodeErrors.INVALID_TOKEN);
  }

  const error = {
    url: err.config && err.config.url,
    method: err.config && err.config.method,
    headers: err.config && err.config.headers,
    data: err.config && err.config.data,
    response: null,
    status: null,
    message: err.message,
  };

  if (err.response) {
    error.response = err.response.data;
    error.status = err.response.status;
  }

  return error;
}

export default {
  getBaseUrl,
  getApiKey,
};
