import React from 'react';
import { I18n } from 'react-redux-i18n';

export const CaractersCount = ({current, maximum, style}) => {
  return (
    <div
      style={style}
      className='count_container'
    >
      <i>{I18n.t('shared.caractersCount.text')}{current} / {maximum}</i>
    </div>
  )
};