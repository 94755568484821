import React from 'react';

/**
 * @param {Object} props
 * @param {Object[]} props.fields
 * @param {string} props.fields[].title
 * @param {string} props.fields[].className
 */

export const FakeTableHeader = ({fields}) => {
  return (
    <div className='fake-table-header'>
      {fields.map(field => (
        <th key={field.title}
          style={{flex: field.flex}}
          className={field.className}>{field.title}</th>
      ))}
    </div>
  )
};