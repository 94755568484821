import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  IdcardOutlined,
  MessageOutlined,
  PieChartOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import moment from 'moment-timezone';
import { CSVLink } from 'react-csv';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  CompanyActions,
  CourseActions,
  FilterActions,
  HospitalActions,
  UserActions,
  UtilsActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  CompanySelectors,
  CourseSelectors,
  FilterSelector,
  HospitalSelectors,
  LoadingSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ReactComponent as UserIcon } from '../../../app/assets/img/user_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { SpecializationList } from '../../../app/enum/specializationType';
import {
  getInitials,
  parsePhone,
  removeSpecialChars,
} from '../../../app/utils/string';
import { CourseType } from '../../../app/enum/courseType';
import { UserPlanStatusArray } from '../../../app/enum/userStatusType';
import { hasPermission } from '../../../app/services/permission';
import { PermissionType } from '../../../app/enum/permissionType';
import { getPhoneNumber } from '../../../app/utils/masks';
import {
  UserPlanOptionsArray,
} from '../../../app/enum/userPlanOption';
import { cleanPagination } from '../../../app/utils/pagination';
import UserApi from '../../../app/api/user'
import { prepareDataToCsv } from '../../../app/utils/csv';
import { generateAndDownloadPdf } from '../../../app/utils/pdf/tablePdf';
import { UserActivityArray } from '../../../app/enum/userActivity';
import { returnPlanName }from '../../../app/utils/returnPlanName';
import { showCompanies } from '../../../app/utils/showCompanies';

const { Panel } = Collapse;

class Users extends React.Component {
  constructor (props) {
    super(props);
    const { userFilters } = props;
    const { studentsFilters } = userFilters;

    this.state = {
      courseFiltered: false,
      exportingDataLoading: false,
      exportingPfdDataLoading: false,
      optionsPdf: {
        orientation: 'landscape',
      },
      cityList: [],
      stateList: [],
      course: null,
      company: null,
      csvData: '',
      params: {
        orderBy: 'createdAt',
        isDESC: true,
        ...cleanPagination,
        ...studentsFilters,
        name: studentsFilters?.name ?? '',
        email: studentsFilters?.email ?? '',
        courseId: studentsFilters?.courseId ?? '',
        companyId: studentsFilters?.companyId ?? '',
        specializationId: studentsFilters?.specializationId ?? null,
        state: studentsFilters?.state ?? null,
        city: studentsFilters?.city ?? '',
        hospitalId: studentsFilters?.hospitalId ?? '',
        expirationStatus: studentsFilters?.expirationStatus ?? '',
        planOption: studentsFilters?.planOption ?? '',
        userActivity: studentsFilters?.userActivity ?? '',
      },
    };
    this.csvLink = React.createRef();
    this.pdfLink = React.createRef();
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const {
      getCompanySelective,
      getHospitalSelective,
      getStates,
      searchCourses,
      userFilters,
    } = this.props;
    const { studentsFilters } = userFilters;

    if(studentsFilters && studentsFilters.courseId) {
      this.setState({
        courseFiltered: true,
      });
    }

    getCompanySelective();
    getHospitalSelective();
    await searchCourses({ type: CourseType.COURSE });
    const states = await getStates();
    this.setState({
      stateList: this.parseStateList(states),
      params: { ...this.state.params, ...studentsFilters },
    });
    this.getUsers({...this.state.params, ...studentsFilters})
  }

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({exportingDataLoading: true})

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.usersPaginated?.rows,
      (row) => ({
        name: row.name,
        especializations: this.parseSpecialization(row.specializations),
        cellphone: row.cellphone,
        cep: row.cep,
        city: row.city,
      }),
      exportAllDataFromApi,
      this.props.usersPaginated?.count,
      UserApi.getUsersPaginated,
      this.state.params,
    )

    this.setState({exportingDataLoading: false})

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    }else{
      message.error(I18n.t('shared.awaitData'))
    }
  };

  async exportPdfData (exportDataFromApi = false) {
    this.setState({exportingPfdDataLoading: true})

    const tableHeadersToPrint = [
      I18n.t('routes.panel.users.dataTable.columns.name.title'),
      I18n.t('routes.panel.users.dataTable.columns.email.title'),
      I18n.t('routes.panel.users.dataTable.columns.cellphone.title'),
      I18n.t('routes.panel.users.dataTable.columns.specializations.title'),
      I18n.t('routes.panel.users.dataTable.columns.plan.title'),
      I18n.t('routes.panel.users.dataTable.columns.city.title'),
      I18n.t('routes.panel.users.dataTable.columns.planExpiry.title'),
    ]

    const generated = await generateAndDownloadPdf(
      this.props.usersPaginated.rows,
      tableHeadersToPrint,
      I18n.t('routes.panel.users.sidebarTitle'),
      11,
      8,
      (row) => ([
        row.name || '--',
        row.email || '--',
        row.cellphone ? getPhoneNumber(row.cellphone) : '--',
        this.parseSpecialization(row.specializations) || '--',
        returnPlanName(row.planOption) || '--',
        row.city || '--',
        moment(row.planExpiry).format('DD/MM/YYYY') || '--'
      ]),
      exportDataFromApi,
      this.props.usersPaginated?.count,
      UserApi.getUsersPaginated,
      this.state.params,
      I18n.t('routes.panel.users.sidebarTitle')
    )

    this.setState({exportingPfdDataLoading: false})

    if (!generated) {
      message.error(I18n.t('shared.awaitData'))
    }
  }

  getUsers = async (params, isFromDataTable, isReset) => {
    const {
      me,
      id,
      getCourseDetails,
      getUsersPaginated,
      isFilteredByCourse,
      isFilteredByCompany,
      getCompanyDetails,
      userFilters,
    } = this.props;
    const { studentsFilters } = userFilters;

    if (isFilteredByCourse && (id || params.courseId)) {
      await getCourseDetails(id ? id : params.courseId);
      this.fieldChange('courseId', id ? id : params.courseId);
      this.setState({ course: this.props.courseDetails });
      params.courseId = this.state.params.courseId;
    } else if (id && isFilteredByCompany) {
      await getCompanyDetails(id);
      this.fieldChange('companyId', id);
      this.setState({ company: this.props.companyDetails });
      params.companyId = this.state.params.companyId;
    }

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...studentsFilters,
        ...params,
      };
    }

    params = {
      ...params,
      profileType: ProfileType.USER,
    };

    this.setState({ params });
    getUsersPaginated(params, me);
    this.props.setUserStudentsFilters({ ...params });
  };

  getCityByState = async (value) => {
    const { getCityByState } = this.props;
    const cityListByState = await getCityByState(value);
    const { params } = this.state;
    params.city = null;
    this.setState({ cityList: this.parseCityList(cityListByState), params });
  };

  parseCityList = (list) =>
    list.map((cityItem) => ({ id: cityItem.city, name: cityItem.city }));

  parseStateList = (list) =>
    list.map((stateItem) => ({ id: stateItem.state, name: stateItem.state }));

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  };

  handleSpecialization = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getUsers({ ...params, offset: 0, page: 1});
    this.props.setUserStudentsFilters({ ...params, page: 1 });

    if(params && params.courseId) {
      this.setState({ courseFiltered: true })
    }
  };

  cleanAdvancedFilters = (id) => {
    const cleanParams = {
      name: '',
      email: '',
      courseId: '',
      companyId: '',
      specializationId: null,
      state: null,
      city: '',
      hospitalId: '',
      expirationStatus: '',
      planOption: '',
      offset: 0,
      page: 1,
      pageSize: 10
    };
    const { isFilteredByCompany, isFilteredByCourse } = this.props;
    this.props.setUserStudentsFilters(null);
    this.setState({ params: {}, courseFiltered: false })

    if (isFilteredByCompany) {
      this.getUsers({ companyId: id, ...cleanParams }, true, true);
    } else if (isFilteredByCourse) {
      this.getUsers({ courseId: id, ...cleanParams }, true, true);
    } else {
      this.getUsers(cleanParams, true, true);
    }
  };

  getColorDiv = (value) => {
    const atualDate = moment();
    const expiryDate = moment(value);
    const diff = moment(expiryDate).diff(atualDate, 'days', true);

    if (!value) {
      return '--'
    }

    if (diff < 0) {
      return (
        <div className='expiredPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    if (diff >= 0 && diff < 16) {
      return (
        <div className='fifteenDaysPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    return <div className='planOk'>{expiryDate.format('DD/MM/YYYY')}</div>;
  };

  parseSpecialization = (value) =>
    (value
      ? value
        .map((item, i, self) =>
          (i !== self.length - 1 ? `${item.title}, ` : item.title),
        )
        .join('')
      : '');

  getColorDiv = (value) => {
    const atualDate = moment();
    const expiryDate = moment(value);
    const diff = moment(expiryDate).diff(atualDate, 'days', true);

    if (diff < 0) {
      return (
        <div className='expiredPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    if (diff >= 0 && diff < 16) {
      return (
        <div className='fifteenDaysPlan'>{expiryDate.format('DD/MM/YYYY')}</div>
      );
    }

    return <div className='planOk'>{expiryDate.format('DD/MM/YYYY')}</div>;
  };

  removeUser (id) {
    this.props.removeUser(id, () => {
      message.success(I18n.t('routes.panel.users.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  userTable = () => {
    const { params, courseFiltered } = this.state;
    const { usersPaginated, loading } = this.props;

    return (
      <div ref={this.pdfLink}>
        <DataTable
          notGetOnStart
          getMethod={(parameters) => this.getUsers(parameters, true)}
          data={usersPaginated}
          loading={loading > 0}
          ref={this.dataTableRef}
          showSizeChanger
          pageSizeOptions={
            usersPaginated && usersPaginated.count && usersPaginated.count > 100
              ? [ '10', '50', '100', usersPaginated.count ]
              : [ '10', '20', '50', '100' ]
          }
          params={params}
          columns={[
            {
              width: '50px',
              key: I18n.t('routes.panel.users.dataTable.columns.photoUrl.key'),
              render: (value, row) =>
                (value ? (
                  <Avatar src={value} />
                ) : (
                  <Avatar>{getInitials(row.name)}</Avatar>
                )),
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.name.key'),
              title: I18n.t('routes.panel.users.dataTable.columns.name.title'),
              render: (value) => value || '--',
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.email.key'),
              title: I18n.t('routes.panel.users.dataTable.columns.email.title'),
              render: (value) => value || '--',
            },
            {
              width: '200px',
              ellipsis: true,
              key: I18n.t('routes.panel.statisticsList.dataTable.columns.company.key'),
              title: I18n.t('routes.panel.statisticsList.dataTable.columns.company.title'),
              render: (value) => (value && showCompanies(value)) || '--',
            },
            {
              width: '150px',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.cellphone.key'),
              title: I18n.t(
                'routes.panel.users.dataTable.columns.cellphone.title',
              ),
              render: (value) =>
                (value && getPhoneNumber(value) ? (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${removeSpecialChars(
                      parsePhone(value),
                    )}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {getPhoneNumber(value)}{' '}
                    <MessageOutlined
                      style={{ position: 'relative', top: -3 }}
                    />
                  </a>
                ) : (
                  '--'
                )),
            },
            {
              width: '150px',
              ellipsis: true,
              key: I18n.t(
                'routes.panel.users.dataTable.columns.specializations.key',
              ),
              title: I18n.t(
                'routes.panel.users.dataTable.columns.specializations.title',
              ),
              render: (value) =>
                (value &&
                  value.length > 0 &&
                  this.parseSpecialization(value)) ||
                '--',
            },
            {
              width: courseFiltered ?  '100px' : '0',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.coursePlan.key'),
              title: I18n.t('routes.panel.users.dataTable.columns.coursePlan.title'),
              render: (value) => value && returnPlanName(value.planOption),
            },
            {
              width: '150px',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.city.key'),
              title: I18n.t('routes.panel.users.dataTable.columns.city.title'),
              render: (value) => value || '--',
            },
            {
              width: courseFiltered ?  '150px' : '0',
              ellipsis: true,
              key: I18n.t('routes.panel.users.dataTable.columns.userCourseExpiry.key')
              ,
              title: I18n.t('routes.panel.users.dataTable.columns.userCourseExpiry.title')
              ,
              render: (value) => value && value.endAt && this.getColorDiv(value.endAt)
            },
            {
              width: '140px',
              key: I18n.t('routes.panel.users.dataTable.columns.actions.key'),
              title: '',
              render: (id) => (
                <div className='dataTable__item--right'>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToDetailsText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<IdcardOutlined />}
                      onClick={() =>
                        navigate(`${I18n.t('routes.panel.userInfo.url')}${id}`)
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToEditText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <Button
                      type='link'
                      icon={<EditOutlined />}
                      onClick={() =>
                        navigate(
                          `${I18n.t('routes.panel.userDetails.url')}${id}`,
                        )
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    title={I18n.t(
                      'routes.panel.users.dataTable.columns.actions.goToStatisticsText',
                    )}
                    overlayStyle={{ fontSize: 11 }}
                  >
                    <a
                      type='link'
                      href={`${I18n.t('routes.panel.userStatistics.url')}${id}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Button type='link'
                        icon={<PieChartOutlined />} />
                    </a>
                  </Tooltip>
                  {([
                    ProfileType.ADMIN,
                    ProfileType.QUIZER,
                    ProfileType.TEACHER,
                    ProfileType.UPLOADER,
                  ].includes(this.props.me?.profileType)) && (
                    <Popconfirm
                      placement='left'
                      title={I18n.t('shared.confirmTitle')}
                      onConfirm={() => this.removeUser(id)}
                      okText={I18n.t('shared.yes')}
                      cancelText={I18n.t('shared.no')}
                    >
                      <Tooltip
                        title={I18n.t(
                          'routes.panel.users.dataTable.columns.actions.removeText',
                        )}
                        overlayStyle={{ fontSize: 11 }}
                      >
                        <Button type='link'
                          icon={<DeleteOutlined />} />
                      </Tooltip>
                    </Popconfirm>
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  };

  render () {
    const { Content } = Layout;
    const { params, course, company, cityList, stateList, courseFiltered } = this.state;
    const {
      usersPaginated,
      loading,
      id: idFilter,
      isFilteredByCompany,
      isFilteredByCourse,
      companySelectives,
      hospitalSelectives,
      searchedCourses,
      me,
    } = this.props;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            {isFilteredByCourse && (
              <Breadcrumb.Item>
                <Link to={`${I18n.t('routes.panel.courses.url')}`}>
                  <span>{I18n.t('routes.panel.courses.pageTitle')}</span>
                </Link>
              </Breadcrumb.Item>
            )}
            {isFilteredByCompany && (
              <Breadcrumb.Item>
                <Link to={`${I18n.t('routes.panel.companies.url')}`}>
                  <span>{I18n.t('routes.panel.companies.pageTitle')}</span>
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.users.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.email')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={SpecializationList}
                      label={I18n.t(
                        'shared.advancedFilters.form.specialization',
                      )}
                      value={params && params.specializationId}
                      onChange={(val) =>
                        this.handleSpecialization('specializationId', val)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedSelect
                      options={stateList}
                      label={I18n.t('shared.advancedFilters.form.state')}
                      value={params && params.state}
                      onChange={(val) => {
                        this.fieldChange('state', val);
                        this.fieldChange('cityList', null);
                      }}
                      onSelect={(val) => this.getCityByState(val)}
                      disableSearch
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={cityList}
                      value={params && params.state && params.city}
                      onChange={(val) => this.fieldChange('city', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.city')}
                      disableSearch
                      disabled={!(params && params.state)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      value={
                        (searchedCourses && params && params.courseId) ||
                        undefined
                      }
                      onChange={(val) => {
                        this.fieldChange('courseId', val);
                      }}
                      options={searchedCourses}
                      label={I18n.t('shared.advancedFilters.form.parentId')}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  {!isFilteredByCompany && (
                    <Col span={8}>
                      <AdvancedSelect
                        options={companySelectives}
                        label={I18n.t('shared.advancedFilters.form.company')}
                        value={params && params.companyId}
                        onChange={(val) => this.fieldChange('companyId', val)}
                      />
                    </Col>
                  )}
                  <Col span={8}>
                    <AdvancedSelect
                      options={hospitalSelectives}
                      label={I18n.t('shared.advancedFilters.form.hospital')}
                      value={params && params.hospitalId}
                      onChange={(val) => this.fieldChange('hospitalId', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={UserActivityArray}
                      label={I18n.t('shared.advancedFilters.form.isUserActive')}
                      value={params.userActivity}
                      onChange={(val) => this.fieldChange('userActivity', val)}
                      disabled={!(params && params.courseId)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={UserPlanOptionsArray}
                      label={I18n.t('shared.advancedFilters.form.userPlan')}
                      value={params.planOption}
                      onChange={(val) => this.fieldChange('planOption', val)}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={UserPlanStatusArray}
                      label={I18n.t('shared.advancedFilters.form.planStatus')}
                      value={params.expirationStatus}
                      onChange={(val) =>
                        this.fieldChange('expirationStatus', val)
                      }
                      colorDots
                      disabled={params && !params.courseId}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters(idFilter)}
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='users'>
            <Row className='justify-content-between'>
              <Col>
                <h3>
                  <span className='panel__layout__content__title__value__icon'>
                    <Icon component={UserIcon} />
                  </span>
                  {loading === 0 && isFilteredByCourse && (idFilter || params.courseId && courseFiltered)
                    ? `${I18n.t('routes.panel.users.pageTitle')}: ${course && course.title
                    }`
                    : null}
                  {idFilter && loading === 0 && isFilteredByCompany
                    ? `${I18n.t('routes.panel.users.pageTitle')}: ${company && company.name
                    }`
                    : null}
                  {!idFilter && !courseFiltered && `${I18n.t('routes.panel.pageTitle')}`}
                </h3>
                {idFilter && loading === 0 && (
                  <h6>
                    {I18n.t('routes.panel.users.sidebarQtd')}:{' '}
                    {usersPaginated && usersPaginated.count}
                  </h6>
                )}
              </Col>
              <Col className='text-right justify-content-between'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportPdfButton')}
                      onClick={() => this.exportPdfData()}
                      disabled={loading > 0}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<FilePdfOutlined />}
                      text={I18n.t('shared.exportAllPdfButton')}
                      onClick={() => this.exportPdfData(true)}
                      disabled={this.state.exportingPfdDataLoading || loading > 0}
                      loading={this.state.exportingPfdDataLoading}
                    />
                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                    />

                    <Divider type='vertical' />
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      disabled={this.state.exportingDataLoading}
                      loading={this.state.exportingDataLoading}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t('routes.panel.userDetails.csvFileName')}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>
                  </>
                )}

                <Divider type='vertical' />

                {hasPermission(
                  I18n.t('routes.panel.users.url'),
                  PermissionType.WRITE,
                  this.props.me?.profileType,
                ) && (
                  <>
                    <AdvancedButton
                      text={I18n.t('routes.panel.users.addNewButtonText')}
                      href={`${I18n.t('routes.panel.userDetails.url')}add`}
                    />

                    <Divider type='vertical' />
                  </>
                )}
              </Col>
            </Row>
            <Divider />
            {this.userTable()}
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getUsersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  courseDetails: CourseSelectors.getCourseDetails(state),
  companyDetails: CompanySelectors.getCompanyDetails(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  hospitalSelectives: HospitalSelectors.getHospitalSelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  userFilters: FilterSelector.getUserFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersPaginated: (parameters, me) =>
    dispatch(UserActions.getUsersPaginated(parameters, me)),
  removeUser: (id, callback) => dispatch(UserActions.removeUser(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCourseDetails: (id) => dispatch(CourseActions.getCourseDetails(id)),
  getCompanyDetails: (id) => dispatch(CompanyActions.getCompanyDetails(id)),
  getCompanySelective: () => dispatch(CompanyActions.getCompanySelectives()),
  getHospitalSelective: () => dispatch(HospitalActions.getHospitalSelectives()),
  getCityByState: (state) => dispatch(UtilsActions.getCityByState(state)),
  getStates: (state) => dispatch(UtilsActions.getStates(state)),
  searchCourses: (params) => dispatch(CourseActions.searchCourses(params)),
  setUserStudentsFilters: (params) =>
    dispatch(FilterActions.setUserStudentsFilters(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
