import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Collapse, Divider, Layout, message, Popconfirm, Row, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import { CategoryActions, FilterActions } from '../../../app/redux/actions';
import { CategorySelectors, FilterSelector, LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as CategoryIcon } from '../../../app/assets/img/configuration_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import * as DateUtils from '../../../app/utils/date';
import { cleanPagination } from '../../../app/utils/pagination';

const { Panel } = Collapse;

class Categories extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      params: {
        ...cleanPagination,
        ...this.props.settingsFilter.categoriesFilter,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount () {
    this.getCategories(this.state.params)
  }

  getCategories = (params) => {
    const { me } = this.props;
    const { getCategoriesPaginated } = this.props;
    this.setState({ params });
    getCategoriesPaginated(params, me);
    this.props.setCategoriesFilters(params)
  }

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getCategories({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => {
    this.getCategories(cleanPagination, true)
  };

  removeCategory (id) {
    this.props.removeCategory(id, () => {
      message.success(I18n.t('routes.panel.categories.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render () {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      categoriesPaginated,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.categories.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.title}
                      onChange={(val) => this.fieldChange('title', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.title')}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="categories">

            <Row>
              <Col span={12}>
                <h3>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={CategoryIcon} />
                  </span>
                  {I18n.t('routes.panel.categories.pageTitle')}
                </h3>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.categories.addNewButtonText')}
                  href={`${I18n.t('routes.panel.categoryDetails.url')}add`}
                />

              </Col>
            </Row>

            <Divider />

            <DataTable
              notGetOnStart
              getMethod={(parameters) => this.getCategories(parameters)}
              data={categoriesPaginated}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              pageSizeOptions={
                categoriesPaginated
                  && categoriesPaginated.count
                  && categoriesPaginated.count > 100
                  ? [ '10', '50', '100', categoriesPaginated.count ]
                  : [ '10', '20', '50', '100' ]
              }
              params={params}
              columns={
                [
                  {
                    width: '45px',
                    key: I18n.t('routes.panel.users.dataTable.columns.photoUrl.key'),
                    title: I18n.t('routes.panel.categories.dataTable.columns.photoUrl.title'),
                    render: (value) => (
                      value
                        ? <Avatar src={value} />
                        : ' --'
                    ),
                  },
                  {
                    key: I18n.t('routes.panel.categories.dataTable.columns.title.key'),
                    title: I18n.t('routes.panel.categories.dataTable.columns.title.title'),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t('routes.panel.categories.dataTable.columns.contentCount.key'),
                    title: I18n.t('routes.panel.categories.dataTable.columns.contentCount.title'),
                    render: (value) => <div className="paddingLeft">{value}</div>,
                  },
                  {
                    width: '120px',
                    key: I18n.t('routes.panel.categories.dataTable.columns.createdAt.key'),
                    title: I18n.t('routes.panel.categories.dataTable.columns.createdAt.title'),
                    render: (createdAt) => <div className="display_flex justify_content">{DateUtils.humanizeDate(createdAt, 'DD/MM/YYYY')}</div>,
                  },
                  {
                    width: '90px',
                    key: I18n.t('routes.panel.categories.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">
                        <Tooltip
                          title={I18n.t('routes.panel.categories.dataTable.columns.actions.goToEditText')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${I18n.t('routes.panel.categoryDetails.url')}${id}`)}
                          />
                        </Tooltip>
                        <Popconfirm
                          placement="left"
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.removeCategory(id)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t('routes.panel.categories.dataTable.columns.actions.removeText')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]
              }
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  categoriesPaginated: CategorySelectors.getCategoriesPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  settingsFilter: FilterSelector.getSettingsFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCategoriesPaginated: (parameters, me) => dispatch(CategoryActions.getCategoriesPaginated(parameters, me)),
  removeCategory: (id, callback) => dispatch(CategoryActions.removeCategory(id, callback)),
  setCategoriesFilters: (params) => dispatch(FilterActions.setCategoriesFilters(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Categories,
);
