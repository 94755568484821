import Immutable from 'seamless-immutable';
import {
  ACTION_SAVE_CHAT_DETAILS,
  ACTION_SAVE_CHAT_PAGINATED,
  ACTION_SAVE_MESSAGES
} from '../actions/chat';

const initialState = Immutable({
  chatPaginated: {rows: [], count:0},
  chatDetails: null,
});

export default function chat (
  state = initialState,
  action,
) {
  switch (action.type) {
    case ACTION_SAVE_CHAT_PAGINATED:
      state = {
        ...state,
        chatPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_CHAT_DETAILS:
      state = {
        ...state,
        chatDetails: action.payload,
      };
      return state;
    case ACTION_SAVE_MESSAGES:
      state = {
        ...state,
        messages: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getChatPaginated (state) {
  return state.chat.chatPaginated;
}

export function getChatDetails (state) {
  return state.chat.chatDetails;
}

export function getMessages (state) {
  return state.chat.messages;
}