import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider, Layout, message, Popconfirm, Row, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon, {
  DeleteOutlined,
  EditOutlined,
  RightSquareOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import { CompanyActions, FilterActions, UtilsActions } from '../../../app/redux/actions';
import { CompanySelectors, FilterSelector, LoadingSelectors } from '../../../app/redux/reducers';
import { ReactComponent as CompanyIcon } from '../../../app/assets/img/configuration_icon_blue.svg';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../components/shared/AdvancedInput';
import * as DateUtils from '../../../app/utils/date';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { cleanPagination } from '../../../app/utils/pagination';

const { Panel } = Collapse;

class Companies extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      cityList: [],
      stateList: [],
      params: {
        ...cleanPagination,
        ...this.props.settingsFilter.companiesFilter
      },
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount () {
    const { getStates } = this.props;
    const states = await getStates();
    this.setState({ stateList: this.parseStateList(states) });
    this.getCompanies(this.state.params)
  }

  getCompanies = (params) => {
    const { me } = this.props;
    const { getCompaniesPaginated } = this.props;
    this.setState({ params: { ...params } });
    getCompaniesPaginated(params, me);
    this.props.setCompaniesFilters(params)
  }

  getCityByState = async (value) => {
    const { getCityByState } = this.props;
    const cityListByState = await getCityByState(value);
    const { params } = this.state;
    params.city = null;
    this.setState({ cityList: this.parseCityList(cityListByState), params });
  }

  parseCityList = (list) => list.map((cityItem) => ({ id: cityItem.city, name: cityItem.city }));

  parseStateList = (list) => list.map((stateItem) => ({ id: stateItem.state, name: stateItem.state }));

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getCompanies({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = () => this.getCompanies(cleanPagination);

  removeCompany (id) {
    this.props.removeCompany(id, () => {
      message.success(I18n.t('routes.panel.companies.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  render () {
    const { Content } = Layout;
    const { params, cityList, stateList } = this.state;
    const {
      companiesPaginated,
      loading,
    } = this.props;

    return (
      <>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.companies.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={I18n.t('shared.advancedFilters.title')}
              key="1"
            >
              <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={stateList}
                      label={I18n.t('shared.advancedFilters.form.state')}
                      value={params && params.state}
                      onChange={(val) => {
                        this.fieldChange('state', val);
                        this.fieldChange('cityList', null);
                      }}
                      onSelect={(val) => this.getCityByState(val)}
                      disableSearch
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={cityList}
                      value={params && params.state && params.city}
                      onChange={(val) => this.fieldChange('city', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.city')}
                      disableSearch
                      disabled={!(params && params.state)}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      htmlType="submit"
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="companies">

            <Row>
              <Col span={12}>
                <h3>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon component={CompanyIcon} />
                  </span>
                  {I18n.t('routes.panel.companies.pageTitle')}
                </h3>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                {/* <AdvancedButton
                  text={I18n.t('routes.panel.companies.companyImportCsvButtonText')}
                  onClick={() => this.setState({ importModalVisible: true })}
                  icon={<FileExcelOutlined />}
                />
                {importModalVisible && (
                <CompanyImportModal
                  visible={importModalVisible}
                  handleCancel={() => this.setState({ importModalVisible: false })}
                  csvImportSucces={() => this.csvImportSucces()}
                />
                )}

                <Divider type="vertical" /> */}

                <AdvancedButton
                  text={I18n.t('routes.panel.companies.addNewButtonText')}
                  href={`${I18n.t('routes.panel.companyDetails.url')}add`}
                />

              </Col>
            </Row>

            <Divider />

            <DataTable
              notGetOnStart
              getMethod={(parameters) => this.getCompanies(parameters)}
              data={companiesPaginated}
              loading={loading > 0}
              ref={this.dataTableRef}
              showSizeChanger
              pageSizeOptions={
                companiesPaginated
                  && companiesPaginated.count
                  && companiesPaginated.count > 100
                  ? [ '10', '50', '100', companiesPaginated.count ]
                  : [ '10', '20', '50', '100' ]
              }
              params={params}
              columns={
                [
                  {
                    ellipsis: true,
                    key: I18n.t('routes.panel.companies.dataTable.columns.name.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.name.title'),
                    render: (value) => value || '--',
                  },
                  {
                    width: '180px',
                    key: I18n.t('routes.panel.companies.dataTable.columns.userCount.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.userCount.title'),
                    render: (value) => <div className="display_flex justify_content">{!isNaN(value) ? value : '--'}</div>,
                  },
                  {
                    width: '150px',
                    ellipsis: true,
                    key: I18n.t('routes.panel.companies.dataTable.columns.phone.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.phone.title'),
                    render: (value) => (
                      value
                      && `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6)}`
                    ) || '--',
                  },
                  {
                    width: '100px',
                    ellipsis: true,
                    key: I18n.t('routes.panel.companies.dataTable.columns.cep.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.cep.title'),
                    render: (value) => <div className="display_flex justify_content">{(value && `${value.slice(0, 5)}-${value.slice(5)}`) || '--'}</div>,
                  },
                  {
                    width: '180px',
                    ellipsis: true,
                    key: I18n.t('routes.panel.companies.dataTable.columns.city.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.city.title'),
                    render: (value) => value || '--',
                  },
                  {
                    width: '120px',
                    ellipsis: true,
                    key: I18n.t('routes.panel.companies.dataTable.columns.createdAt.key'),
                    title: I18n.t('routes.panel.companies.dataTable.columns.createdAt.title'),
                    render: (createdAt) => <div className="display_flex justify_content">{DateUtils.humanizeDate(createdAt, 'DD/MM/YYYY')}</div>,
                  },
                  {
                    width: '110px',
                    key: I18n.t('routes.panel.companies.dataTable.columns.actions.key'),
                    title: '',
                    render: (id) => (
                      <div className="dataTable__item--right">
                        <Tooltip
                          title={I18n.t('routes.panel.companies.dataTable.columns.actions.goToUsers')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<RightSquareOutlined />}
                            onClick={
                              () => navigate(
                                `${I18n.t('routes.panel.userDetails.url')}`
                                + `${id}${I18n.t('routes.panel.userDetails.sufixCompaniesUrl')}`,
                              )
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title={I18n.t('routes.panel.companies.dataTable.columns.actions.goToEditText')}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => navigate(`${I18n.t('routes.panel.companyDetails.url')}${id}`)}
                          />
                        </Tooltip>
                        <Popconfirm
                          placement="left"
                          title={I18n.t('shared.confirmTitle')}
                          onConfirm={() => this.removeCompany(id)}
                          okText={I18n.t('shared.yes')}
                          cancelText={I18n.t('shared.no')}
                        >
                          <Tooltip
                            title={I18n.t('routes.panel.companies.dataTable.columns.actions.removeText')}
                            overlayStyle={{ fontSize: 11 }}
                          >
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]
              }
            />
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesPaginated: CompanySelectors.getCompaniesPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  settingsFilter: FilterSelector.getSettingsFilters(state)
});

const mapDispatchToProps = (dispatch) => ({
  getCompaniesPaginated: (parameters, me) => dispatch(CompanyActions.getCompaniesPaginated(parameters, me)),
  removeCompany: (id, callback) => dispatch(CompanyActions.removeCompany(id, callback)),
  getCityByState: (state) => dispatch(UtilsActions.getCityByState(state)),
  getStates: (state) => dispatch(UtilsActions.getStates(state)),
  setCompaniesFilters: (state) => dispatch(FilterActions.setCompaniesFilters(state))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Companies,
);
