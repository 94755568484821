import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Divider,
  Icon,
  Layout,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import DataTable from '../../../components/shared/DataTable';
import {
  AuthActions,
  FilterActions,
  UserActions,
} from '../../../app/redux/actions';
import {
  AuthSelectors,
  FilterSelector,
  LoadingSelectors,
  UserSelectors,
} from '../../../app/redux/reducers';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../app/utils/date';
import { getInitials } from '../../../app/utils/string';
import { hasPermission } from '../../../app/services/permission';
import { PermissionType } from '../../../app/enum/permissionType';
import { CSVLink } from 'react-csv';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { cleanPagination } from '../../../app/utils/pagination';
import UserApi from '../../../app/api/user';
import { prepareDataToCsv } from '../../../app/utils/csv';

const { Panel } = Collapse;

class Administrators extends React.Component {
  constructor (props) {
    super(props);
    const { userFilters } = props;
    const { adminsFilters } = userFilters;
    this.state = {
      exportingDataLoading: false,
      csvData: '',
      profileTypeList: [],
      params: {
        ...cleanPagination,
        ...adminsFilters,
        name: adminsFilters?.name ?? '',
        email: adminsFilters?.email ?? '',
        profileType: adminsFilters?.profileType ?? null,
      },
    };
    this.dataTableRef = React.createRef();
    this.csvLink = React.createRef();
  }

  async componentDidMount () {
    const { userFilters } = this.props;
    const { adminsFilters } = userFilters;
    const profileTypeList = this.getProfiles();
    this.setState({
      profileTypeList,
      params: { ...this.state.params, ...adminsFilters },
    });
    this.getUsers({ ...this.state.params, ...adminsFilters })
  }

  csvExport = async (exportAllDataFromApi = false) => {
    this.setState({ exportingDataLoading: true })

    const preparedDataToCsv = await prepareDataToCsv(
      this.props.usersPaginated?.rows,
      (row) => ({
        name: row.name || '--',
        email: row.email || '--',
        profileType: this.validationProfile(row.profileType) || '--',
        createdAt: DateUtils.humanizeDateTime(row.createdAt, 'DD/MM/YYYY HH:mm') || '--',
      }),
      exportAllDataFromApi,
      this.props.usersPaginated?.count,
      UserApi.getUsersPaginated,
      this.state.params,
    )

    this.setState({ exportingDataLoading: false })

    if (preparedDataToCsv) {
      this.setState({ csvData: preparedDataToCsv }, () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      })
    } else {
      message.error(I18n.t('shared.awaitData'))
    }
  };

  getUsers = async (params, isFromDataTable, isReset) => {
    const { me, getUsersPaginated, userFilters } = this.props;
    const { adminsFilters } = userFilters;

    let profileTypeList;

    if (!isFromDataTable) {
      this.setState({
        params: {
          ...this.state.params,
          ...params,
        },
      });
    }

    if (!isReset) {
      params = {
        ...adminsFilters,
        ...params,
      };
    }

    if (!params.profileType) {
      profileTypeList = [
        ProfileType.ADMIN,
        ProfileType.MARKETING,
        ProfileType.OPERATOR,
        ProfileType.QUIZER,
        ProfileType.TEACHER,
        ProfileType.UPLOADER,
        ProfileType.KANBAN_MANAGER,
      ].join(',')
    }

    this.setState({ params });
    getUsersPaginated({ ...params, profileTypeList }, me);
    this.props.setUserAdminsFilters({ ...params });
  };

  getProfiles = () => [
    { id: ProfileType.ADMIN, name: I18n.t('enum.profileType.admin') },
    { id: ProfileType.OPERATOR, name: I18n.t('enum.profileType.operator') },
    { id: ProfileType.MARKETING, name: I18n.t('enum.profileType.marketing') },
    { id: ProfileType.QUIZER, name: I18n.t('enum.profileType.quizer') },
    { id: ProfileType.TEACHER, name: I18n.t('enum.profileType.teacher') },
    { id: ProfileType.UPLOADER, name: I18n.t('enum.profileType.uploader') },
    { id: ProfileType.KANBAN_MANAGER, name: I18n.t('enum.profileType.kanbanManager') },
  ];

  parseProfileList = (list) =>
    list.map((profileItem) => ({ id: profileItem.id, name: profileItem.name }));

  fieldChange = (name, value) => {
    const { params } = this.state;
    params[name] = value;
    this.setState({ params });
  };

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.getUsers({ ...params, ...cleanPagination });
    this.props.setUserAdminsFilters({ ...params, ...cleanPagination });
  };

  cleanAdvancedFilters = (params) => {
    const cleanParams = {
      name: '',
      email: '',
      profileType: null,
    };

    this.props.setUserAdminsFilters(null);
    this.getUsers({ ...cleanParams, ...cleanPagination }, true, true);
  };

  removeUser (id) {
    this.props.removeUser(id, () => {
      message.success(I18n.t('routes.panel.administrators.deleteSucces'));
      this.dataTableRef.current.reset();
    });
  }

  validationProfile (profileType) {
    switch (profileType) {

      case ProfileType.ADMIN:
        return I18n.t('enum.profileType.admin');

      case ProfileType.OPERATOR:
        return I18n.t('enum.profileType.operator');

      case ProfileType.MARKETING:
        return I18n.t('enum.profileType.marketing');

      case ProfileType.QUIZER:
        return I18n.t('enum.profileType.quizer');

      case ProfileType.TEACHER:
        return I18n.t('enum.profileType.teacher');

      case ProfileType.KANBAN_MANAGER:
        return I18n.t('enum.profileType.kanbanManager');

      default:
        return I18n.t('enum.profileType.uploader');

    }
  }

  render () {
    const { Content } = Layout;
    const { usersPaginated, loading, me } = this.props;
    const { params, profileTypeList } = this.state;

    return (
      <>
        <Content className='panel__layout__content panel__layout__content--breadcrumb'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type='dashboard' />{' '}
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administrators.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className='panel__layout__content panel__layout__content--advanced-filter'>
          <Collapse className='advanced-filter'>
            <Panel header={I18n.t('shared.advancedFilters.title')}
              key='1'>
              <form
                onSubmit={(event) =>
                  this.applyAdvancedFilters(event, this.state.params)
                }
              >
                <Row gutter={24}>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.name}
                      onChange={(val) => this.fieldChange('name', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.name')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedInput
                      value={params && params.email}
                      onChange={(val) => this.fieldChange('email', val)}
                      placeholder={I18n.t('shared.type')}
                      label={I18n.t('shared.advancedFilters.form.email')}
                    />
                  </Col>
                  <Col span={8}>
                    <AdvancedSelect
                      options={profileTypeList}
                      label={I18n.t('shared.advancedFilters.form.profileType')}
                      value={params && params.profileType}
                      onChange={(val) => {
                        this.fieldChange('profileType', val);
                      }}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className='advanced-filter__search-button text-right'
                  >
                    <AdvancedButton
                      type='link'
                      text={I18n.t('shared.advancedFilters.clearButtonText')}
                      onClick={(parameters) =>
                        this.cleanAdvancedFilters(parameters)
                      }
                    />
                    <AdvancedButton
                      htmlType='submit'
                      text={I18n.t('shared.advancedFilters.filterButtonText')}
                      icon={<SearchOutlined />}
                    />
                  </Col>
                </Row>
              </form>
            </Panel>
          </Collapse>
        </Content>

        <Content className='panel__layout__content'>
          <div className='administrators'>
            <Row className='d-flex justify-content-between'>
              <Col>
                <h2>
                  <span className='panel__layout__content__title__value__icon'>
                    <LockOutlined />
                  </span>
                  {I18n.t('routes.panel.administrators.pageTitle')}
                </h2>
              </Col>
              <Col className='text-right'>
                {([
                  ProfileType.ADMIN,
                ].includes(me?.profileType)) && (
                  <>
                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportCsvButtonText')}
                      onClick={() => this.csvExport()}
                      disabled={loading > 0}
                      loading={loading > 0}
                    />

                    <CSVLink
                      data={this.state.csvData}
                      filename={I18n.t(
                        'routes.panel.administratorDetails.csvFileName',
                      )}
                      ref={this.csvLink}
                      style={{ display: 'none' }}
                    >
                      {I18n.t('shared.exportCsvButtonText')}
                    </CSVLink>

                    <Divider type='vertical' />

                    <AdvancedButton
                      icon={<AppstoreOutlined />}
                      text={I18n.t('shared.exportAllCsvButtonText')}
                      onClick={() => this.csvExport(true)}
                      loading={this.state.exportingDataLoading}
                      disabled={this.state.exportingDataLoading || loading > 0}
                    />
                  </>
                )}

                <Divider type='vertical' />

                {hasPermission(
                  I18n.t('routes.panel.administrators.url'),
                  PermissionType.WRITE,
                  this.props.me?.profileType,
                ) && (
                  <AdvancedButton
                    text={I18n.t(
                      'routes.panel.administrators.addNewButtonText',
                    )}
                    href={`${I18n.t(
                      'routes.panel.administratorDetails.url',
                    )}add`}
                  />
                )}
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                notGetOnStart
                getMethod={(parameters) => this.getUsers(parameters, true)}
                data={usersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                params={params}
                showSizeChanger
                columns={[
                  {
                    width: '50px',
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.photoUrl.key',
                    ),
                    render: (value, row) =>
                      (value ? (
                        <Avatar src={value} />
                      ) : (
                        <Avatar>{getInitials(row.name)}</Avatar>
                      )),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.name.key',
                    ),
                    title: I18n.t(
                      'routes.panel.administrators.dataTable.columns.name.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.email.key',
                    ),
                    title: I18n.t(
                      'routes.panel.administrators.dataTable.columns.email.title',
                    ),
                    render: (value) => value || '--',
                  },
                  {
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.profileType.key',
                    ),
                    title: I18n.t(
                      'routes.panel.administrators.dataTable.columns.profileType.title',
                    ),
                    render: (value) => this.validationProfile(value) || '--',
                  },
                  {
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.createdAt.key',
                    ),
                    title: I18n.t(
                      'routes.panel.administrators.dataTable.columns.createdAt.title',
                    ),
                    render: (createdAt) =>
                      DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                  },
                  {
                    key: I18n.t(
                      'routes.panel.administrators.dataTable.columns.actions.key',
                    ),
                    title: '',
                    render: (id) => (
                      <div className='dataTable__item--right'>
                        {this.props.me?.profileType !== ProfileType.MARKETING && (
                          <Popconfirm
                            placement='left'
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.removeUser(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                          >
                            <Tooltip
                              title={I18n.t(
                                'routes.panel.administrators.dataTable.columns.actions.removeText',
                              )}
                              overlayStyle={{ fontSize: 11 }}
                            >
                              <Button
                                type='link'
                                disabled={me && me.id === id}
                                icon={<DeleteOutlined />}
                              />
                            </Tooltip>
                          </Popconfirm>
                        )}
                        <Tooltip
                          title={I18n.t(
                            'routes.panel.administrators.dataTable.columns.actions.goToEditText',
                          )}
                          overlayStyle={{ fontSize: 11 }}
                        >
                          <Button
                            type='link'
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(
                                `${I18n.t(
                                  'routes.panel.administratorDetails.url',
                                )}${id}`,
                              )
                            }
                          />
                        </Tooltip>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getUsersPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  userFilters: FilterSelector.getUserFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersPaginated: (parameters, me) =>
    dispatch(UserActions.getUsersPaginated(parameters, me)),
  removeUser: (id, callback) => dispatch(UserActions.removeUser(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  setUserAdminsFilters: (params) =>
    dispatch(FilterActions.setUserAdminsFilters(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
