import getInstance from './config';

export default class UtilsApi {
  static async getAddressByCep (params) {
    const instance = await getInstance();
    const { data } = await instance.get('utils/address', { params });
    return data;
  }

  static async getStates () {
    const instance = await getInstance();
    const { data } = await instance.get('utils/state');
    return data;
  }

  static async getCompanies () {
    const instance = await getInstance();
    const { data } = await instance.get('/utils/company');
    return data;
  }

  static async getCityByState (params) {
    const instance = await getInstance();
    const { data } = await instance.get('utils/city', { params });
    return data;
  }

  static async getCourseByCompany (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/utils/course', { params });
    return data;
  }
}
