import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ChatMessage from './ChatMessage';

const ChatPanel = (props) => {
  const ref = useRef();
  const [ messagesSize, setMessageSize ] = useState(0);

  useEffect(() => {
    if (ref.current && props.messages && props.messages.length > messagesSize) {
      ref.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        },
      );
    }

    if(props && props.messages) {
      setMessageSize(props.messages.length)
    }
  },[ props.messages ])

  const isLoading = () => {
    let loading = false;

    if(!props || !props.messages || !props.whoSent || (props.messages && props.messages.length === 0) || !props.loadedChat) {
      loading = true;
    }

    return loading;
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 45 }}
    spin />

  return (
    <div className="chat__panel">
      <div className="chat__panel__container">
        <div ref={ref} />
        {isLoading() ?
          <div className="chat__panel__container__loading">
            <Spin indicator={antIcon} />
          </div>
          :
          <>
            {props.loadedChat && props.messages.length && props.messages.map((message) =>
              <ChatMessage
                key={message.id}
                message={message}
                mine={message.whoSent !== props.whoSent.id}
                file={message.fileType}
              />
            )}
          </>
        }
      </div>
    </div>
  )
}

export default ChatPanel