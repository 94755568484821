import React from 'react';
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Layout,
  message,
  Modal,
  Row,
  Spin,
} from 'antd';
import Icon, { SaveOutlined, SearchOutlined, SendOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { navigate } from '@reach/router';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import DataTable from '../../../components/shared/DataTable/DataTable';
import {
  CompanySelectors, ContentSelectors, CourseSelectors, HospitalSelectors, LoadingSelectors, PushNotificationSelectors, QuizSelectors,
} from '../../../app/redux/reducers';
import {
  CompanyActions,
  ContentActions,
  CourseActions,
  HospitalActions,
  PushNotificationActions,
  QuizActions,
  UtilsActions,
} from '../../../app/redux/actions';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { ProfileType } from '../../../app/enum/profileType';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { ReactComponent as UserIcon } from '../../../app/assets/img/user_icon_blue.svg';
import { getInitials } from '../../../app/utils/string';
import { SpecializationList } from '../../../app/enum/specializationType';
import { CourseType } from '../../../app/enum/courseType';
import { NotificationType } from '../../../app/enum/notificationType';
import { cleanPagination } from '../../../app/utils/pagination';
import { showCompanies } from '../../../app/utils/showCompanies';

class PushNotificationDetails extends React.Component {
  constructor (props) {
    super(props);
    this.dataTableRef = React.createRef();
    this.state = {
      sendToAll: false,
      cityList: [],
      stateList: [],
      isSchedule: false,
      params: {
        name: '',
        email: '',
        courseId: '',
        companyId: '',
        specializationId: null,
        state: null,
        city: '',
        hospitalId: '',
        contentId: '',
        quizId: '',
        ...cleanPagination
      },
      form: {
        title: '',
        body: '',
        type: NotificationType.TEXT,
        linkAction: '',
        userIdList: null,
        scheduleDate: null,
      },
      modalVisible: false,
      formToModal: {},
    };
    this.loadingUser = false;
    this.loadingSend = false;
  }

  async componentDidMount () {
    const {
      getPushNotificationDetails,
      id,
      getCompanySelective,
      getHospitalSelective,
      getStates,
      searchCourses,
      getContentSelectives,
      getQuizSelectives,
    } = this.props;
    getCompanySelective();
    getHospitalSelective();
    getContentSelectives();
    getQuizSelectives();
    await searchCourses({ type: CourseType.COURSE });
    const states = await getStates();
    this.setState({ stateList: this.parseStateList(states) });
    this.props.getCompanySelective();

    if (id && id !== 'add') {
      await getPushNotificationDetails(id);
      const { pushNotificationDetails } = this.props;
      this.setState({
        form: {
          title: pushNotificationDetails && pushNotificationDetails.title,
          body: pushNotificationDetails && pushNotificationDetails.body,
          scheduleDate: pushNotificationDetails && pushNotificationDetails.scheduleDate,
          type: pushNotificationDetails && pushNotificationDetails.type,
          linkAction: pushNotificationDetails && pushNotificationDetails.linkAction,
          userIdList:
            (pushNotificationDetails && pushNotificationDetails.users.map((users) => users.id))
            || [],
        },
        isSchedule: true,
      });
    }
  }

  async onSubmit (e) {
    e.preventDefault();
    let { form } = this.state;
    const { sendToAll, isSchedule } = this.state;
    let userWithoutPermission = false;

    if ((!form.userIdList || form.userIdList.length === 0) && !sendToAll) {
      message.error(I18n.t('pushNotification.messages.errors.invalid_users'));
      return;
    }

    if (!isSchedule) {
      delete form.scheduleDate;
    } else if (moment().isAfter(form.scheduleDate)) {
      message.error(I18n.t('pushNotification.messages.errors.invalid_past_scheduleDate'));
      return;
    } else if (!form.scheduleDate) {
      message.error(I18n.t('pushNotification.messages.errors.invalid_scheduleDate'));
      return;
    }

    const pushNotificationSchema = yup.object().shape({
      body: yup.string().required(I18n.t('pushNotification.messages.errors.invalid_description')),
      title: yup.string().required(I18n.t('pushNotification.messages.errors.invalid_title')),
    });

    if (form.type === NotificationType.QUIZ || form.type === NotificationType.CONTENT) {
      const res = await this.props.getPermissionNotification({ userIdList: form.userIdList, type: form.type, linkAction: form.linkAction });

      if (res && res.forbidden.length > 0) {
        userWithoutPermission = true;
        form.userIdList = res.allowed;
      }

      if (res && res.allowed.length === 0) {
        return message.error(I18n.t('pushNotification.messages.errors.noUser'));
      }
    }

    pushNotificationSchema.validate(form).then(async () => {
      const { sendPushNotification } = this.props;
      form = {
        userIdList: sendToAll ? [] : form.userIdList,
        notification: {
          title: form.title,
          body: form.body,
          type: form.type,
          linkAction: form.linkAction,
        },
        scheduleDate: form.scheduleDate ? form.scheduleDate.toDate() : null,
      };

      if (!form.scheduleDate) {
        delete form.scheduleDate;
      }

      try {
        this.loadingSend = true;

        if (userWithoutPermission) {
          this.setState({ modalVisible: true, formToModal: form });
        } else {
          await sendPushNotification(form, () => {
            message.success(I18n.t('pushNotification.messages.successCreate'));
            navigate(I18n.t('routes.panel.pushNotifications.url'));
          });
        }
      } catch (err) {
        message.error(I18n.t('pushNotification.messages.errors.generic'));
      } finally {
        this.loadingSend = false;
      }
    }).catch((error) => {
      message.error(error.message);
    });
  }

  onInputChange (name, value) {
    const { form } = this.state;

    if (name === 'type') {
      form.linkAction = '';
    }

    form[name] = value;
    this.setState({ form });
  }

  fieldChange = (name, value) => {
    const { params } = this.state;

    if (name === 'contentId') {
      params.courseId = null;
    }

    if (name === 'courseId') {
      params.contentId = null;
    }

    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  handleSpecialization = (name, value) => {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  getUsers = async (params) => {
    const { me } = this.props;
    params = {
      ...this.state.params,
      ...params,
      profileType: ProfileType.USER,
    };

    const { getUsersForPushNotificationPaginated } = this.props;

    this.setState({ params: { ...params } });

    try {
      this.loadingUser = true;
      await getUsersForPushNotificationPaginated({...params, pageSize: 1000}, me);
    } finally {
      this.loadingUser = false;
    }
  }

  getCityByState = async (value) => {
    const { getCityByState } = this.props;
    const cityListByState = await getCityByState(value);
    const { params } = this.state;
    params.city = null;
    this.setState({ cityList: this.parseCityList(cityListByState), params });
  };

  cleanAdvancedFilters = async () => {
    const params = {
      name: '',
      email: '',
      courseId: '',
      companyId: '',
      specializationId: null,
      state: null,
      city: '',
      hospitalId: '',
      contentId: '',
      quizId: '',
      ...cleanPagination
    };
    await this.getUsers(params);
    this.setState({ params });
  };

  parseCityList = (list) => list.map((cityItem) => ({ id: cityItem.city, name: cityItem.city }));

  parseStateList = (list) => list.map((stateItem) => ({ id: stateItem.state, name: stateItem.state }));

  applyAdvancedFilters = (event, params) => {
    event.preventDefault();
    this.setState({params: {
      ...params,
      ...cleanPagination
    }})
    this.getUsers(params);
  };

  parseSpecialization = (value) => (value
    ? (value.map((item, i, self) => (i !== self.length - 1
      ? `${item.title}, `
      : item.title))).join('')
    : '');

  getColumns = (params) => params.map((o) => (
    {
      ...o,
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }
  ))

  onSelectRows = (rowsKeys) => {
    const { id } = this.props;

    if (!id) {
      const { form } = this.state;
      form.userIdList = rowsKeys;
      this.setState({ form });
    }
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOklModal = async () => {
    const { formToModal } = this.state;
    const { sendPushNotification } = this.props;

    try {
      await sendPushNotification(formToModal, () => {
        message.success(I18n.t('pushNotification.messages.successCreate'));
        navigate(I18n.t('routes.panel.pushNotifications.url'));
      });
    } catch (err) {
      message.error(I18n.t('pushNotification.messages.errors.generic'));
    } finally {
      this.loadingSend = false;
    }

    this.setState({
      modalVisible: false,
    });
  };

  filterUsersSelected = (allUsers) => {
    const { userIdList } = this.state.form
    const { id } = this.props

    if(allUsers && allUsers.rows && userIdList && (id || id === 'edit')) {
      const items = allUsers.rows.filter(user => userIdList.includes(user.id))
      return {count: items.length, rows: items }
    } else if(!id || id === 'add') {
      return allUsers
    } else {
      return {count:0, rows: [],}
    }
  }

  handleCancelModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  getColorDiv = (value) => {
    const atualDate = moment();
    const expiryDate = moment(value);
    const diff = moment(expiryDate).diff(atualDate, 'days', true);

    if (diff < 0) {
      return (<div className="expiredPlan">{expiryDate.format('DD/MM/YYYY')}</div>);
    }

    if (diff >= 0 && diff < 16) {
      return (<div className="fifteenDaysPlan">{expiryDate.format('DD/MM/YYYY')}</div>);
    }

    return (<div className="planOk">{expiryDate.format('DD/MM/YYYY')}</div>);
  }

  render () {
    const { Content } = Layout;
    const { Panel } = Collapse;
    const {
      form, params, cityList, stateList, isSchedule,
    } = this.state;

    const {
      usersForPushNotificationPaginated,
      loading,
      companySelectives,
      searchedCourses,
      isFilteredByCompany,
      hospitalSelectives,
      QuizSelectives,
      ContentSelectives,
      id,
    } = this.props;
    const ModalUserWithoutPermission = (
      <Modal
        visible={this.state.modalVisible}
        onOk={this.handleOklModal}
        onCancel={this.handleCancelModal}
        width="40%"
        footer={[
          <Button
            key="back"
            onClick={this.handleCancelModal}
          >
            {I18n.t('pushNotification.messages.cancel')}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={this.handleOklModal}
          >
            {I18n.t('pushNotification.messages.confirm')}
          </Button>
        ]}
      >
        <div>

          <span>{I18n.t('pushNotification.messages.errors.usersWithoutPermission')}</span>
        </div>
        <div>
          <span>{I18n.t('pushNotification.messages.errors.continue')}</span>
        </div>
      </Modal>
    );

    return (
      <>
        {ModalUserWithoutPermission}
        <div className="row">
          <div className="col-lg-12">
            <Content className="panel__layout__content panel__layout__content--breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <span>{I18n.t('routes.panel.pageTitle')}</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{I18n.t('pushNotification.sidebarTitle')}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Content>

            <Content className="panel__layout__content panel__layout__content--advanced-filter">
              <Collapse
                className="advanced-filter"
              >
                <Panel
                  header={I18n.t('shared.advancedFilters.title')}
                  key="1"
                >
                  <form onSubmit={(event) => this.applyAdvancedFilters(event, this.state.params)}>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.name}
                          onChange={(val) => this.fieldChange('name', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.name')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedInput
                          value={params && params.email}
                          onChange={(val) => this.fieldChange('email', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.email')}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          options={SpecializationList}
                          label={I18n.t('shared.advancedFilters.form.specialization')}
                          value={params && params.specializationId}
                          onChange={(val) => this.handleSpecialization('specializationId', val)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedSelect
                          options={stateList}
                          label={I18n.t('shared.advancedFilters.form.state')}
                          value={params && params.state}
                          onChange={(val) => {
                            this.fieldChange('state', val);
                            this.fieldChange('cityList', null);
                          }}
                          onSelect={(val) => this.getCityByState(val)}
                          disableSearch
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          options={cityList}
                          value={params && params.state && params.city}
                          onChange={(val) => this.fieldChange('city', val)}
                          placeholder={I18n.t('shared.type')}
                          label={I18n.t('shared.advancedFilters.form.city')}
                          disableSearch
                          disabled={!(params && params.state)}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          value={(searchedCourses && params && params.courseId) || undefined}
                          onChange={(val) => {
                            this.fieldChange('courseId', val);
                          }}
                          options={searchedCourses}
                          label={I18n.t('shared.advancedFilters.form.parentId')}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      {!isFilteredByCompany && (
                        <Col span={8}>
                          <AdvancedSelect
                            options={companySelectives}
                            label={I18n.t('shared.advancedFilters.form.company')}
                            value={params && params.companyId}
                            onChange={(val) => this.fieldChange('companyId', val)}
                          />
                        </Col>
                      )}
                      <Col span={8}>
                        <AdvancedSelect
                          options={hospitalSelectives}
                          label={I18n.t('shared.advancedFilters.form.hospital')}
                          value={params && params.hospitalId}
                          onChange={(val) => this.fieldChange('hospitalId', val)}
                        />
                      </Col>
                      <Col span={8}>
                        <AdvancedSelect
                          options={ContentSelectives}
                          label={I18n.t('shared.advancedFilters.form.content')}
                          value={params && params.contentId}
                          onChange={(val) => this.fieldChange('contentId', val)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedSelect
                          options={QuizSelectives}
                          label={I18n.t('shared.advancedFilters.form.quiz')}
                          value={params && params.quizId}
                          onChange={(val) => this.fieldChange('quizId', val)}
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="advanced-filter__search-button text-right"
                      >
                        <Col
                          span={8}
                          className="d-flex align-items-center"
                        >
                          <Checkbox onChange={(e) => this.setState({ sendToAll: e.target.checked })}>
                            {I18n.t('pushNotification.form.sendToAll.label')}
                          </Checkbox>
                        </Col>
                        <AdvancedButton
                          type="link"
                          text={I18n.t('shared.advancedFilters.clearButtonText')}
                          onClick={() => this.cleanAdvancedFilters()}
                        />
                        <AdvancedButton
                          htmlType="submit"
                          text={I18n.t('shared.advancedFilters.filterButtonText')}
                          icon={<SearchOutlined />}
                        />
                      </Col>
                    </Row>
                  </form>
                </Panel>
              </Collapse>
            </Content>
          </div>
        </div>

        <form
          className="push-notification__form"
          onSubmit={(ev) => this.onSubmit(ev)}
        >
          <div className="row">
            <div className="col-lg-12">

              {!this.state.sendToAll && (
                <Content className="panel__layout__content">
                  <div className="push-notification">

                    <div className="row mb-3">
                      <div className="col-12">
                        <h5>
                          <span className="panel__layout__content__title__value__icon">
                            <Icon component={UserIcon} />
                          </span>
                          {I18n.t('pushNotification.form.datatable.title')}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <DataTable
                          params={params}
                          getMethod={(parameters) => this.getUsers({
                            ...parameters,
                            profileType: ProfileType.USER,
                          })}
                          data={this.filterUsersSelected(usersForPushNotificationPaginated)}
                          loading={loading > 0 && this.loadingUser}
                          disablePagination
                          ref={this.dataTableRef}
                          columns={this.getColumns(
                            [
                              {
                                key: I18n.t('routes.panel.users.dataTable.columns.photoUrl.key'),
                                render: (value, row) => (
                                  value
                                    ? <Avatar src={value} />
                                    : (
                                      <Avatar>
                                        {getInitials(row.name)}
                                      </Avatar>
                                    )
                                ),
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.name.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.name.title'),
                                render: (value) => value || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.specializations.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.specializations.title'),
                                render: (value) => (value && value.length > 0 && this.parseSpecialization(value)) || '--',
                              },
                              {
                                ellipsis: true,
                                key: I18n.t('routes.panel.users.dataTable.columns.cellphone.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.cellphone.title'),
                                render: (value) => (
                                  value
                                  && value !== 'null null'
                                  && `${value.slice(0, 2)} (${value.slice(3, 5)}) ${value.slice(5, 10)}-${value.slice(10)}`
                                ) || '--',
                              },
                              {
                                key: I18n.t('pushNotification.dataTable.company.key'),
                                title: I18n.t('pushNotification.dataTable.company.title'),
                                render: (value) => showCompanies(value),
                              },
                              {
                                key: I18n.t('routes.panel.users.dataTable.columns.city.key'),
                                title: I18n.t('routes.panel.users.dataTable.columns.city.title'),
                                render: (value) => value || '--',
                              },
                            ],
                          )}
                          bordered={false}
                          pagination={false}
                          size="middle"
                          locale={{
                            emptyText: I18n.t('shared.nothingToShow'),
                          }}
                          selectable={!id || id === 'add'}
                          selectedRowKeys={form.userIdList}
                          rowSelection={form.userIdList}
                          onSelectRow={(rows) => this.onSelectRows(rows)}
                        />
                      </div>
                    </div>

                  </div>
                </Content>
              )}

              <Content className="panel__layout__content">
                <Spin
                  spinning={loading > 0 && this.loadingSend}
                  tip={I18n.t('shared.loading')}
                >
                  <div className="push-notification">
                    <div className="row">
                      <div className="col-12">
                        <h5 className="push-notification__block-title">
                          {I18n.t('pushNotification.form.blockTitle')}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <AdvancedInput
                          label={I18n.t('pushNotification.form.title.label')}
                          onChange={(value) => this.onInputChange('title', value)}
                          value={form && form.title}
                          placeholder={I18n.t('shared.type')}
                          disabled={!!id}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <AdvancedInput
                          label={I18n.t('pushNotification.form.body.label')}
                          onChange={(value) => this.onInputChange('body', value)}
                          value={form && form.body}
                          isTextarea
                          placeholder={I18n.t('shared.type')}
                          disabled={!!id}
                        />
                      </div>
                    </div>
                    <Row>
                      <Col span={20}>
                        <h6>{I18n.t('forms.notifications.labelTypeNotification')}</h6>
                        <AdvancedRadioGroup
                          value={form.type}
                          onChange={(val) => this.onInputChange('type', val.target.value)}
                          disabled={!!id}
                          options={[
                            { id: NotificationType.TEXT, name: I18n.t('forms.notifications.labelText') },
                            { id: NotificationType.URL, name: I18n.t('forms.notifications.labelUrl') },
                            { id: NotificationType.CONTENT, name: I18n.t('forms.notifications.labelContent') },
                            { id: NotificationType.QUIZ, name: I18n.t('forms.notifications.labelQuiz') },
                          ]}
                          horizontal
                        />
                      </Col>
                    </Row>

                    {this.state.form.type === NotificationType.URL && (
                      <Row>
                        <Col span={20}>
                          <AdvancedInput
                            disabled={!!id}
                            label={I18n.t('forms.notifications.labelUrl')}
                            value={form && form.linkAction}
                            onChange={(value) => this.onInputChange('linkAction', value)}
                          />
                        </Col>
                      </Row>
                    )}
                    {this.state.form.type === NotificationType.CONTENT && (
                      <Row>
                        <Col span={20}>
                          <AdvancedSelect
                            disabled={!!id}
                            label={I18n.t('forms.notifications.labelContent')}
                            value={form && form.linkAction}
                            onChange={(val) => this.onInputChange('linkAction', val)}
                            options={this.props.ContentSelectives}
                          />
                        </Col>
                      </Row>
                    )}
                    {this.state.form.type === NotificationType.QUIZ && (
                      <Row>
                        <Col span={20}>
                          <AdvancedSelect
                            disabled={!!id}
                            label={I18n.t('forms.notifications.labelQuiz')}
                            value={form && form.linkAction}
                            onChange={(val) => this.onInputChange('linkAction', val)}
                            options={QuizSelectives}
                          />
                        </Col>
                      </Row>
                    )}
                    <div className="row mb-3">
                      <div className="col-12">
                        <Checkbox
                          checked={isSchedule}
                          onChange={(e) => this.setState({ isSchedule: e.target.checked })}
                          disabled={!!id}
                        >
                          {I18n.t('pushNotification.form.isSchedule.label')}
                        </Checkbox>
                      </div>
                    </div>

                    {isSchedule && (
                      <div className="row">
                        <div className="col-12">
                          <div>
                            {I18n.t('pushNotification.form.scheduleDate.label')}
                          </div>
                          <DatePicker
                            value={form && form.scheduleDate && moment(form.scheduleDate)}
                            placeholder=""
                            showTime={{ format: 'HH:mm' }}
                            format="DD/MM/YYYY HH:mm"
                            onChange={(val) => this.onInputChange('scheduleDate', val)}
                            disabled={!!id}
                          />
                        </div>
                      </div>
                    )}

                    <Divider />


                    <div className="row">
                      <div
                        className="col-12 text-right"
                      >
                        <AdvancedButton
                          type="link"
                          text={I18n.t('forms.goBackButtonText')}
                          href={I18n.t('routes.panel.pushNotifications.url')}
                        />
                        <Divider
                          className="form-button-divider"
                          type="vertical"
                        />
                        <AdvancedButton
                          htmlType="submit"
                          text={isSchedule ? I18n.t('forms.scheduleButtonText') : I18n.t('forms.sendButtonText')}
                          icon={isSchedule ? <SaveOutlined /> : <SendOutlined />}
                          disabled={!!id}
                        />
                      </div>
                    </div>
                  </div>
                </Spin>
              </Content>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  usersForPushNotificationPaginated: PushNotificationSelectors.getUsersForPushNotificationPaginated(state),
  pushNotificationDetails: PushNotificationSelectors.getPushNotificationDetails(state),
  companySelectives: CompanySelectors.getCompanySelectives(state),
  loading: LoadingSelectors.getLoading(state),
  hospitalSelectives: HospitalSelectors.getHospitalSelectives(state),
  searchedCourses: CourseSelectors.searchCourses(state),
  ContentSelectives: ContentSelectors.getContentSelectives(state),
  QuizSelectives: QuizSelectors.getQuizSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersForPushNotificationPaginated: (parameters) => dispatch(
    PushNotificationActions.getUsersForPushNotification(parameters),
  ),
  getPushNotificationDetails: (id) => dispatch(PushNotificationActions.getPushNotificationDetails(id)),
  sendPushNotification: (data, callback) => dispatch(PushNotificationActions.sendPushNotification(data, callback)),
  getCompanySelective: () => dispatch(
    CompanyActions.getCompanySelectives(),
  ),
  getCourseDetails: (id) => dispatch(CourseActions.getCourseDetails(id)),
  getCompanyDetails: (id) => dispatch(CompanyActions.getCompanyDetails(id)),
  getHospitalSelective: () => dispatch(
    HospitalActions.getHospitalSelectives(),
  ),
  getCityByState: (state) => dispatch(UtilsActions.getCityByState(state)),
  getStates: (state) => dispatch(UtilsActions.getStates(state)),
  searchCourses: (params) => dispatch(
    CourseActions.searchCourses(params),
  ),
  getContentSelectives: (params) => dispatch(ContentActions.getContentSelectives(params)),
  getQuizSelectives: (params) => dispatch(QuizActions.getQuizSelectives(params)),
  getPermissionNotification: (params) => dispatch(PushNotificationActions.getPermissionNotification(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotificationDetails);
