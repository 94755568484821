/**
 * Calculates the duration of an audio file.
 * @param {File} file - The audio file for which you want to calculate the duration.
 * @returns {Promise} A Promise that resolves with the duration of the audio in milliseconds.
 */
function getFileDuration(file) {
  return new Promise((resolve) => {
    var objectURL = URL.createObjectURL(file);
    var mySound = new Audio([objectURL]);
    mySound.addEventListener(
      "canplaythrough",
      () => {
        URL.revokeObjectURL(objectURL);
        var durationMilliseconds = Math.trunc(mySound.duration * 1000);
        resolve(durationMilliseconds);
      },
      false,
    );
  });
}

export {
  getFileDuration,
};