import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import getInstance from './config';

export default class CourseApi {
  static async getCoursesPaginated (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/course', { params });
    return data;
  }

  static async getOrderedContentsByCourse (courseId) {
    const instance = await getInstance();
    const { data } = await instance.get(`/course/${courseId}/with-content`);
    return data;
  }

  static async getCourseDetails (id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/course/${id}`);
    return data;
  }

  static async searchCourses (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/course/search', { params });
    return data;
  }

  static async selectivesCourses (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/course/search', { params });
    return data;
  }

  static async update (id, courseData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/course/${id}`, courseData);
    return data;
  }

  static async create (courseData) {
    const instance = await getInstance();
    const { data } = await instance.post('/course', courseData);
    return data;
  }

  static async duplicate (courseData) {
    const instance = await getInstance();
    const { data } = await instance.post('/course/duplicate', courseData);
    return data;
  }

  static async remove (id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/course/${id}`);
    return data;
  }

  static async saveOrder (id, order) {
    const instance = await getInstance();
    const { data } = await instance.put(`/course/${id}/order`, order);
    return data;
  }

  static async getCourseSelectives (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/course/search', { params });
    return data;
  }

  static async getCourseGraph (params) {
    const instance = await getInstance();
    const { data } = await instance.get('/course/for-chart', { params });
    return data;
  }

  static async importFile (file, url, contentType, onUploadProgress, cancelToken) {
    const instance = await getInstance({}, { contentType });

    try {
      const { data } = await instance.put(url, file, { onUploadProgress, cancelToken });
      return data;
    } catch (error) {
      throw new Error('cancelado');
    }
  }

  static async getUrlToUploadFile (params) {
    try {
      const instance = await getInstance();
      const { data } = await instance.get('/utils/request-upload', { params });
      return data;
    } catch (err) {
      message.error(I18n.t(`routes.panel.courseDetails.messages.fileError.${err.message}`));
    }
  }
}
