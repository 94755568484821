export const parseCompanies = (item) => {
  const companies = item.length && item?.map(company => company?.name)

  if(!companies) return '--'

  return companies.join(', ')
}

export const showCompanies = (array) => {
  let res = '';

  if(array && array.length) {
    for (let i = 0; i < array.length - 1; i++) {
      if (array.length > 1) {
        res += `${array[i]?.name}, `;
      } else {
        res += `${array[i]?.name}`;
      }
    }

    res += array[array.length - 1]?.name;

  } else {
    res += '--';
  }

  return res;
};