import { ResponsiveBar } from '@nivo/bar';
import { Result } from 'antd';

import React from 'react';

const Column = (props) => {
  const { data } = props;

  return (
    <div className="column">
      {data.length > 0 ? (
        <div className="column__wrapper">
          <div className="column__chart"
            style={{height: (data.length * 100) + 100}}>
            <ResponsiveBar
              data={data}
              margin={{ top: 50, right: 50, bottom: 100, left: 270 }}
              padding={0.1}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={{ scheme: 'category10' }}
              axisBottom={{
                ariaHidden: true,
                tickRotation: 0,
              }}
              layout={{type: 'vertical'}}
              borderColor={{
                from: 'color',
                modifiers: [ [ 'darker', 1.6 ] ],
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 10,
                tickPadding: 0,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={2}
              labelTextColor='#FFFF'
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={function (e) {
                return `${e.id  }: ${  e.formattedValue  } in country: ${  e.indexValue}`;
              }}
            />
          </div>
        </div>
      ) : (
        <div className="line-chart__empty">
          <Result title={'OLA'} />
        </div>
      )}
    </div>
  );
};

export default Column;
