import React from 'react';
import { Translate } from 'react-redux-i18n';

export const StatusCorrection = {
  INITIAL_CORRECT: 0,
  TO_CORRECT: 1,
  CORRECT: 2,
  INCORRECT: 3,
  REPLIED_AGAIN: 4,
};

const StatusCorrectionOptions = {
  TO_CORRECT: 1,
  CORRECTED: 2,
};

export const StatusCorrectionOptionsArray = [
  { id: StatusCorrectionOptions.TO_CORRECT, name: <Translate value="routes.panel.questionDatabase.dataTableCorrections.filters.toCorrect" />},
  { id: StatusCorrectionOptions.CORRECTED, name: <Translate value="routes.panel.questionDatabase.dataTableCorrections.filters.corrected" />},
];
