import Immutable from 'seamless-immutable';
import * as QuestionDatabaseActions from '../actions/questionDatabase';

const initialState = Immutable({
  questionDatabasePaginated: null,
  userQuestionDatabase: null,
  questionDatabaseCorrections: null,
});

export default function questionDatabase (
  state = initialState,
  action,
) {
  switch (action.type) {
    case QuestionDatabaseActions.ACTION_SAVE_QUESTION_DATABASE_PAGINATED:
      return ({
        ...state,
        questionDatabasePaginated: action.payload,
      });
    case QuestionDatabaseActions.ACTION_SAVE_USERS_QUESTION_DATABASE:
      state = {
        ...state,
        userQuestionDatabase: action.payload,
      };
      return state;
    case QuestionDatabaseActions.ACTION_SAVE_CORRECTIONS_QUESTION_DATABASE:
      return ({
        ...state,
        questionDatabaseCorrections: action.payload,
      });
    case QuestionDatabaseActions.ACTION_SAVE_QUESTION_DATABASE_FILTERS:
      return ({
        ...state,
        questionDatabaseFilters: action.payload,
      });
    default:
      return state;
  }
}

export function getQuestionDatabasePaginated (state) {
  return state.questionDatabase.questionDatabasePaginated;
}

export function getUserQuestionDatabase (state) {
  return state.questionDatabase.userQuestionDatabase;
}

export function getUserQuestionCorrectionsDatabase (state) {
  return state.questionDatabase.questionDatabaseCorrections;
}

export function getQuestionDatabaseFilters (state) {
  return state.questionDatabase.questionDatabaseFilters;
}
